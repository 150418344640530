import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";

import T from 'components/i18n';
import Image from 'react-bulma-components/lib/components/image';

import Level from 'react-bulma-components/lib/components/level';
// import { Field, Input } from 'react-bulma-components/lib/components/form';
import Heading from 'react-bulma-components/lib/components/heading';
import Container from 'react-bulma-components/lib/components/container';
import Hero from 'react-bulma-components/lib/components/hero';
import Footer from 'react-bulma-components/lib/components/footer';
import Columns from 'react-bulma-components/lib/components/columns';
import { Link } from "react-router-dom";
import User from 'user/user-store';
import AppStore from 'app-store';


class FooterBlock extends Component { 
  render () {
        let policy_link = AppStore.getLink("policy"), arg_link = AppStore.getLink("agreement"),
            _return = AppStore.getLink("return"),
            messager = AppStore.getContactData('phone'),
            img_link = "/img/visa-mastercard.png";

        // if (User.get("country") === "RU") {
        //   policy_link = AppStore.getLink("policy_ru");
        //   arg_link = AppStore.getLink("agreement_ru");
        //   img_link = "/img/visa-mastercard_ru.png";
        //   messager = '+7 903 485 80 17';
        // }

        return (
              <Hero id="footer" color="dark" renderAs='footer'>
                <Hero.Body>
                  <Columns>
                    <Columns.Column size="one-fifth" align="left">
                    <Link to={AppStore.getLink("")}><img src="/img/hp-logo-beta-white.webp" border="0" alt="Huge Profit" width="180" /></Link>
                      <ul style={{marginBottom: '20px'}}>
                        <li><a href={AppStore.getLink("landing/store-program")}>{T('program_for_store')}</a></li>
                        <li><a href={AppStore.getLink("landing/accounting-software")}>{T('program_for_sales')}</a></li>
                        <li><a href={AppStore.getLink("landing/warehouse-software")}>{T('program_for_warehouse')}</a></li>
                        <li><a href={AppStore.getLink("landing/small-production")}>{T('small-production')}</a></li>
                        <li><a href="/blog/" rel="noopener noreferrer" target="_blank">{T('blog')}</a></li>
                        <li><a href="/blog/dokumentacziya/" rel="noopener noreferrer" target="_blank">{T('documentation')}</a></li>
                        <li><a href={AppStore.getLink("public_contacts")}>{T('contact-link')}</a></li>
                        {AppStore.isEnableForWhiteLable("partners") && AppStore.getDocsLink("partners") &&
                          <li><a
                            href={AppStore.getDocsLink("partners")}>{T('partners-programm')}</a>
                          </li>}
                      </ul>
                    </Columns.Column>
                    <Columns.Column>
                      <Container align="centered">
                       <Heading renderAs='p'>{T('connect-to-socialnetwork')}</Heading>
                       <Level className="home-social-network is-mobile">
                          <Level.Item>
                              <a href={AppStore.getContactData('facebook')} target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Facebook_F_icon.svg" border="0" /></a>
                          </Level.Item>
                          <Level.Item>
                              <a href={AppStore.getContactData('telegram_page')} target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
                          </Level.Item>
                       </Level>
                       <Heading subtitle renderAs='p'>{T('for-partner')}</Heading>
                       <Heading subtitle renderAs='p'><b>{AppStore.getContactData('phone')}</b></Heading>
                       </Container>
                    </Columns.Column>
                    <Columns.Column size="one-fifth" align="left">
                      <ul>
                        <li>Telegram, Viber<br />
                          <b><big>{messager}</big></b>
                          <br /><br />
                        </li>
                        {User.get("country") !== "RU" ? 
                        <li>{T('Ukraine')}<br />
                          <b><big>+{messager}</big></b><br /><br />
                        </li>
                        : null}                      

                        <li>{T('write_us')}<br /><br />
                          <Level className="home-social-network is-mobile" align="left">
                              <Level.Item>
                                  <a href={AppStore.getContactData('facebook')} target="_blank" rel="noopener noreferrer"><Image size={32}  src="/img/Facebook_F_icon.svg" border="0" /></a>
                              </Level.Item>
                              <Level.Item>
                                  <a href={AppStore.getContactData('telegram')} target="_blank" rel="noopener noreferrer"><Image size={32}  src="/img/Telegram_logo.svg" border="0" /></a>
                              </Level.Item>                              
                              <Level.Item>
                                  <a href={AppStore.getContactData('viber')} target="_blank" rel="noopener noreferrer"><Image size={32}  src="/img/Viber-Icon.svg" border="0" /></a>
                              </Level.Item>
                          </Level>
                        </li>
                      </ul>
                    </Columns.Column>
                  </Columns>

                </Hero.Body>
                <Hero.Footer>
                  <Footer className="has-background-dark" style={{padding: '0 20px'}}>
                      <Level renderAs="nav">
                          <Level.Side align="left">
                            <Level.Item>
                                <strong>2020-2024 HugeProfit</strong>.
                            </Level.Item>
                          </Level.Side>
                          <Level.Side align="center">
                            <Level.Item>
                                <Link to={policy_link}>{T('policy')}</Link>&nbsp;|&nbsp;<Link to={arg_link}>{T('agreement')}</Link>&nbsp;|&nbsp;<Link to={_return}>{T('pay_return_garant')}</Link>
                            </Level.Item>
                          </Level.Side>
                          <Level.Side align="right" className="visa-footer">
                              <Image src={img_link} border="0" />
                          </Level.Side>
                     </Level>
                  </Footer>
                </Hero.Footer>
              </Hero>
          )   
    }
}
export default connectToStores(FooterBlock, { 
    _: User
});