import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";

import TransactionsStore from "./transactions-store";
import Actions from "./transactions-actions";
import ExpensesStore from "../expenses/expenses-store";
import ExpensesActions from "../expenses/expenses-actions";
import SelectComponent from "tools/select-component";
import PriceFromToFilter from "../../components/base-filter-layout/price-from-to-filter";
import T from "../../components/i18n";
import User from "../../user/user-store";
import RadioButtonsListControl
  from "../../components/radio-buttons-list/radio-button-list-control";
import WarehouseStore from "../warehouse/warehouse-store";
import {Checkbox, Field} from "react-bulma-components/lib/components/form";

class TransactionsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    Actions.filter(name, value);
  }

  onChangeSelect(selectedOption, actionMeta) {
    Actions.filter(actionMeta.name, selectedOption);
  }

  onChangeFilter({name, value}) {
    Actions.filter(name, value)
  }

  onChangeSelectMulti(selectedOption, action) {
    if (!selectedOption) {
      Actions.filter(action.name, [{ value: 0, label: T("all") }]);
    } else if (action.option?.value === 0) {
      Actions.filter(action.name, [action.option]);
    } else {
      Actions.filter(
        action.name,
        selectedOption.filter((item) => item?.value !== 0)
      );
    }
  }

  selectAccount() {
    return (
      <SelectComponent
        name="account"
        label="account"
        empty="all"
        onChange={this.onChangeSelect}
        value={TransactionsStore.getFilter("account")}
        list={ExpensesStore.getAccountsList}
        load={ExpensesActions.loadAccounts}
        creatable={false}
      />
    );
  }

  selectActions() {
    const isFilterAll = TransactionsStore.getFilter("action_id")?.value === 0;
    const actionTypeNumberAndCount = TransactionsStore.get(
      "actionTypeNumberAndCount"
    );

    const tab = TransactionsStore.get('tab')

    const list = ExpensesStore.getActionsList();

    const filterWithActiveAction = list.filter((item) =>
      Object.keys(actionTypeNumberAndCount).some((action) => item.id === action)
    );

    return (
      isFilterAll && tab !== 'move-found' && (
        <SelectComponent
          name="multiTypeAction"
          label="transactions-type"
          className="full-size-select"
          empty="all"
          isMulti
          onChange={this.onChangeSelectMulti}
          value={TransactionsStore.getFilter("multiTypeAction")}
          list={() => filterWithActiveAction}
          creatable={false}
        />
      )
    );
  }

  selectCategory() {
    return (
      <SelectComponent
        name="expenses_category"
        empty="all-categories"
        label="expenses-for-category"
        onChange={this.onChangeSelect}
        value={TransactionsStore.getFilter("expenses_category")}
        list={ExpensesStore.getAllCategory}
        load={Actions.loadCategory}
        creatable={false}
      />
    );
  }

  disabledIsDeleted() {
    const list = [
      {
        title: 'show-off',
        value: "1"
      },
      {
        title: "show-on",
        value: "0"
      },
    ]

    return <RadioButtonsListControl
      propsWrapper={{style: {textAlign: 'left'}}}
      list={list}
      name='disabledDeleted'
      onChange={this.onChangeFilter}
      label='deleted-transactions'
      valueRadio={TransactionsStore.getFilter("disabledDeleted")}/>
  }

  selectWarehouse() {
    return <SelectComponent
      empty='all'
      name="mid"
      label='expenses-marketplace-filter'
      onChange={this.onChangeSelect}
      value={TransactionsStore.getFilter("mid")}
      list={WarehouseStore.getWarehouseList}
    />
  }

  filterCommission() {
    const list = [
      { name: "with-commission", id: "with-commission" },
      { name: "without-commission", id: "without-commission" },
    ];

    return (
      <SelectComponent
        name="commission"
        label="commission"
        empty="all"
        onChange={this.onChangeSelect}
        value={TransactionsStore.getFilter("commission")}
        list={() => list}
      />
    );
  }

  filterDateCreated() {
    return <Field style={{textAlign: 'left'}}>
      <Checkbox name='chronology' onChange={({target: {name, checked}}) => this.onChangeFilter({name, value: checked})} value={TransactionsStore.getFilter('chronology')}>{T('chronology-creation')}</Checkbox>
    </Field>
  }

  render() {
    return (
      <>
        {this.selectAccount()}
        {User.getModuleEnable("payments") && this.selectActions()}
        {this.selectCategory()}
        {this.selectWarehouse()}
        {User.getPermissionEnable("show-cashflow") && (
          <PriceFromToFilter
            label="amount"
            from={{
              name: "amount-from",
              value: TransactionsStore.getFilter("amount-from"),
            }}
            to={{
              name: "amount-to",
              value: TransactionsStore.getFilter("amount-to"),
            }}
            onChange={this.onChange}
          />
        )}
        {this.disabledIsDeleted()}
        {this.filterCommission()}
        {this.filterDateCreated()}
      </>
    );
  }
}

export default connectToStores(TransactionsFilter, { _: TransactionsStore });
