import React, { useState } from "react";
import SalesStore from "../../sales/sales-store";
import T from "../../../components/i18n";
import GroupDaysFilter from "./group-days-filter";
import ExpensesStore from "../../expenses/expenses-store";
import GeneralRowDesktop from "./desktop/general-row-desktop";
import Reports from "../../../components/reports";
import Box from "react-bulma-components/lib/components/box";
import Charts from "./charts/charts";
import StatisticsBlock from "../../../components/statistics-block";
import Money from "../../../components/money";
import GeneralReportFilter from "./general-report-filter";
import GeneralRowMobile from "./mobile/general-row-mobile";
import { useIsMobile } from "../../../components/is-mobile-context/is-mobile-context";
import {
  calcMargin,
  calcReportExpenses,
  calcReportSale,
  calcReportShipment,
  mergeObjects,
} from "./utils";
import ShipmentsStore from "../../shipments/shipments-store";
import usePaginationHook from "../../../tools/pagination-hook";
import DynamicTabList
  from "../../../components/dynamic-tab-list/dynamic-tab-list";

const EmptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

const COLUMNS = [
  "",
  "date",
  "sales-all",
  "Expenses",
  "shipment",
  "report_result",
];

const GeneralReport = ({defaultTab,listTabs,onChangeTab}) => {
  const { isMobileSize } = useIsMobile();
  const [groupFilter, setGroupFilter] = useState("days");

  const startDate = SalesStore.getFilter("date_start");
  const endDate = SalesStore.getFilter("date_end");

  const sales = SalesStore.getSales();
  const expenses = ExpensesStore.getExpensesByDate(startDate, endDate);
  const shipments = ShipmentsStore.getShipments();

  const statisticsSalePerDate = calcReportSale(sales, groupFilter);
  const statisticsExpensesPerDate = calcReportExpenses(expenses, groupFilter);
  const statisticsShipmentPerDate = calcReportShipment(shipments, groupFilter);

  const newData = mergeObjects(
    statisticsSalePerDate,
    statisticsExpensesPerDate,
    statisticsShipmentPerDate
  );

  const statistics = Object.values(newData).reduce(
    (acc, cur) => {
      acc.amount_sale += cur.amount_sale || 0;
      acc.count += cur.count || 0;
      acc.profit += cur.profit || 0;
      acc.quantity += cur.quantity || 0;
      acc.receipt += cur.receipt || 0;
      acc.shipmentExpenses += cur.shipmentExpenses || 0;
      acc.notCalcExpenses += cur.notCalcExpenses || 0;
      acc.expensesProfit += cur.expensesProfit || 0;
      acc.amountExpenses += cur.amountExpenses || 0;

      acc.margin = calcMargin(acc.amount_sale, acc.profit);
      acc.averageCheck = acc.amount_sale / acc.count;

      return acc;
    },
    {
      amount_sale: 0,
      count: 0,
      profit: 0,
      quantity: 0,
      receipt: 0,
      shipmentExpenses: 0,
      notCalcExpenses: 0,
      expensesProfit: 0,
      amountExpenses: 0,
    }
  );

  const statisticsList = [
    {
      name: "t-sales-main",
      value: <Money amount={statistics.amount_sale + statistics.receipt} />,
      info: "total-profit-tip",
    },
    {
      name: "dashboard-sales-profit",
      value: <Money amount={statistics.profit - statistics.expensesProfit} />,
      info: "net-profit-tip",
    },
    {
      name: "total-sales",
      value: `${statistics.count} (${statistics.quantity} ${T("items-e")})`,
    },
    {
      name: "average-check",
      value: <Money amount={statistics.averageCheck} />,
      info: "average-receipt-tip",
    },
    {
      name: "expenses",
      value: (
        <Money
          amount={statistics.amountExpenses + statistics.expensesProfit}
          className="has-text-danger"
        />
      ),
      info: "all-expenses-tip",
    },
    {
      name: "shipments",
      value: <Money amount={statistics.shipmentExpenses} />,
      info: "shipments-expenses-tip",
    },
    {
      name: "sales-clean-profit",
      value: (
        <Money
          amount={
            statistics.profit +
            statistics.receipt -
            statistics.amountExpenses -
            statistics.expensesProfit
          }
        />
      ),
      info: "income-from-sales-other-expenses-without-shipments",
    },
  ];

  const { start, end, total, page, setPage, PaginationBlock } =
    usePaginationHook({perPage: 20, listLength: Object.values(newData).length});

  const listReport = Object.values(newData)

  listReport.sort((a,b) => b.label - a.label);

  return (
    <div>
      {!isMobileSize && <GeneralReportFilter />}
      <StatisticsBlock list={statisticsList} classNameText="title" />
      <GroupDaysFilter group={groupFilter} onChange={setGroupFilter} />
      {isMobileSize && <DynamicTabList
        list={listTabs}
        onChangeTab={onChangeTab}
        defaultTab={defaultTab}
      />}
      <Box style={{overflow: 'auto'}} className="border-not-top">
        {isMobileSize && <GeneralReportFilter />}
        <PaginationBlock setPage={setPage} totalPage={total} currentPage={page} />
        <Reports
          minWidthList={1000}
          columns={COLUMNS}
          emptyMessage={EmptyMessage}
          rows={listReport.slice(start, end)}
        >
          {isMobileSize ? (
            <GeneralRowMobile role="row" groupDate={groupFilter} />
          ) : (
            <GeneralRowDesktop role="row" groupDate={groupFilter} />
          )}
        </Reports>
        <PaginationBlock setPage={setPage} totalPage={total} currentPage={page} />
      </Box>
      <Charts
        daysGroup={groupFilter}
        onChangeDaysGroup={setGroupFilter}
        data={newData}
      />
    </div>
  );
};

export default GeneralReport;
