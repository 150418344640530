// import React from "react";

import WarehouseStore from "modules/warehouse/warehouse-store";
import ProductsStore from "modules/products/products-store";
import SalesStore from "modules/sales/sales-store";
import { confirmAlert } from "react-confirm-alert"; // Import
import ProductActions from "modules/products/products-actions";
import T from "components/i18n";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import calcMargin from "tools/calc-margin";
import User from "user/user-store";
import AppStore from "../../../app-store";
import CheckReservShipModal from "../check-reserv-ship-modal";
import ColorControl from "../../../tools/color-control";
import React from "react";

const getDefaultWarehouseValue = (props) => {
  const data = {
    mid: { value: props.mid || WarehouseStore.getDefaultWarehouse() },
    quantity: {
      error: "",
      value: props.quantity || 0,
    },
    netprice: {
      error: "",
      value: props.netprice || "",
    },
    price: {
      error: "",
      value: props.price || "",
    },
    sku: props.sku,
    current_sid: props.current_sid || false,
    wid: props.wid || false,
    is_calc: props.is_calc || false,
  };

  if(props.isNew) {
    data.isNew = true
  }
  return data
};

const prepareWarehouse = (data) => {
  let prepare_warehouse = [];
  for (let i in data) {
    let w = data[i];
    prepare_warehouse.push({
      mid: w["mid"]["value"],
      wid: w["wid"] || 0,
      quantity: w["quantity"]["value"],
      netprice: w["netprice"]["value"],
      price: w["price"]["value"],
      is_calc: w["is_calc"] || !!w["current_sid"],
    });
  }

  return JSON.stringify({ stock: prepare_warehouse });
};

const showAdditionalField = (data) => {
  if (!data) return null;

  data = JSON.parse(data);
  let response = [];
  for (let i in data) {
    if (data[i] === "" || i === "custom_price") continue;
    response.push(i + ": " + data[i]);
  }

  return response.join(" | ");
};

const prepareProductData = (data, stock) => {
  const needName = WarehouseStore.isNeedName(),
    pid = data["id"],
    warehouse_name =
      " (" + T(WarehouseStore.getWarehouseName(stock["marketplace_id"])) + ")",
    name = needName ? data["name"] + warehouse_name : data["name"],
    report = SalesStore.getSaleReportsByProduct(pid, stock["marketplace_id"]),
    margin = calcMargin(
      stock["net_price"],
      stock["price"],
      stock["sale_price"]
    );

  let img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";

  if (ProductsStore.needRemoveImageCache(pid)) {
    img += "?" + Math.random();
  }

  const product = {
    net_price: stock["net_price"],
    price: stock["price"],
    sale_price: stock["sale_price"],
    pid: pid,
    name: name,
    parent_id: data["parent_id"],
    type_product: data["type_product"],
    af: data["af"],
    mid: stock["marketplace_id"],
    wid: stock["id"],
    instock: stock["instock"],
    waiting: stock["waiting"],
    is_ff: stock["is_ff"],
    quantity: stock["quantity"],
    currency_id: stock["currency_id"] || User.get("currency_id"),
    edit: true,
    category_id: data["category_id"],
    desc: data["description"],
    barcode: data["asin"],
    units: data["units"] || "units",
    brand_id: data["brand_id"],
    mp: data["mp"],
    vendorCode: stock["sku"],
    step: 0,
    img: img,
    report: report,
    margin: margin,
  };

  return product;
};

const deleteProduct = (pid, mid, count) => {
  let text = T("are-you-sure-to-delete-this-product");

  if (count) {
    text = T("are-you-sure-to-delete-this-product-count", { count: count });
  }

  confirmAlert({
    title: T("confirm-delete"),
    message: text,
    buttons: [
      {
        label: T("delete"),
        onClick: () => ProductActions.deleteProduct(pid, mid),
      },
      {
        label: T("no-delete"),
        onClick: () => {},
      },
    ],
  });
};

const printTags = (pids, count) => {
  let text = T("confirm-pids-count", { count: count });

  confirmAlert({
    title: T("confirm-action"),
    message: text,
    buttons: [
      {
        label: T("print"),
        onClick: () => ProductActions.printTags(pids),
      },
      {
        label: T("no-print"),
        onClick: () => {},
      },
    ],
  });
};

const buildStock = (props) => {
  let stock = [];
  if (typeof props.stock !== "undefined") {
    for (let i in props.stock) {
      let s = props.stock[i];
      stock.push(getDefaultWarehouseValue(s));
    }
  } else {
    stock.push(getDefaultWarehouseValue(props));
  }

  return stock;
};

const getStockDesktop = (instock, quantity, waiting, pid, mid, isFixed, isDanger) => {
  let value = isFixed ? instock.toFixed(3) : instock;
  if (instock !== quantity) {
    value = instock + "/" + quantity;
  }

  if (waiting) {
    value = (
      <span>
        {value} <span className="text-warning">({waiting})</span>
      </span>
    );
  }

  const useSmallFont = instock !== quantity && waiting && true;

  return (
    <span
      className={
        (instock !== quantity || waiting) && pid
          ? "cursor-pointer zoom-hover"
          : ""
      }
      onClick={
        (instock !== quantity || waiting) && pid
          ? (e) => {
            e.stopPropagation();
            AppStore.openModal(
              <CheckReservShipModal
                pid={pid}
                reservLength={quantity - instock}
                mid={mid}
                realQuantity={quantity}
              />
            )
          }
          : () => {}
      }
    >
      {ColorControl(value, null, null, null, useSmallFont, isDanger)}
    </span>
  );
};

const getStockMobile = (instock, quantity, waiting, pid, mid, offColor, isMinMaxStock) => {
  const isFixed = instock !== 0 && instock % 1 !== 0 && true;

  let value = isFixed ? instock.toFixed(3) : instock;
  if (instock !== quantity) {
    value = (
      <span className={offColor ? "" : "text-success"}>
        {instock + "/" + quantity}
      </span>
    );
  }

  if (waiting) {
    value = (
      <span>
        {value}{" "}
        <span className={offColor ? "" : "text-warning"}>({waiting})</span>
      </span>
    );
  }

  return (
    <span
      className={
        `${!offColor && (instock !== quantity || waiting)
          ? "underline-with-color"
          : ""}
         ${isMinMaxStock ? 'has-text-danger' : ''}
          `
      }
      onClick={
        (instock !== quantity || waiting) && pid && mid
          ? () =>
              AppStore.openModal(
                <CheckReservShipModal
                  pid={pid}
                  reservLength={quantity - instock}
                  mid={mid}
                  realQuantity={quantity}
                />
              )
          : () => {}
      }
    >
      {value}
    </span>
  );
};

export {
  getDefaultWarehouseValue,
  buildStock,
  showAdditionalField,
  deleteProduct,
  printTags,
  prepareWarehouse,
  prepareProductData,
  getStockDesktop,
  getStockMobile,
};
