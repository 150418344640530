import React from 'react'
import {Select} from "react-bulma-components/lib/components/form";

const PerPageSelect = ({countPerPage, onChangePerPage, options, style}) => {

	const handleChangePage = ({target: {value}}) => {
		onChangePerPage && onChangePerPage(parseInt(value))
	}

	return (
		<Select style={{order: 3, width: 50, marginRight: 7, ...style}} value={countPerPage} onChange={handleChangePage}>
			{!!options ? (
				options.map((option, index) => (
					<option key={`${option.value}-${option.label || index}`} value={option.value}>{option.label || option.value}</option>
				))
			) : (
				<>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
					<option value={200}>200</option>
				</>
			)}

		</Select>
	);
};

export default PerPageSelect;