import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";


import Notification from 'react-bulma-components/lib/components/notification';
import Actions from './notification-actions' 
import NotificationStore from './notification-store' 
import Button from 'react-bulma-components/lib/components/button';
import {parseJSON} from "../../tools/error-handler";
import T from '../../components/i18n'
import AppStore from "../../app-store";
import StockMarketplacesViewModal
    from "../history/stock-marketplaces/stock-marketplaces-view-modal";

// import T from "components/i18n";

class NotificationBlock extends Component {
    // constructor(props) {
    //     super(props);
    //     // this.onChange = this.onChange.bind(this);
    // }

    componentDidMount() {
        // let product_id = getQueryParams("product");
        // if (product_id) {
        //   this.setState({"product": {"value": product_id, "error": ""}});
        // }

        // this.listenAdd = NotificationActions.addFrond.listen((res) => {
        //     let mess = this.state.
        //     this.setState({
        //       'message': {
        //       }
        //     })
        // });
    }

    compoxnentWillUnmount() {
      // if (typeof(this.listenAdd) == "function") {
      //     this.listenAdd();
      // }
    } 


    getNotification() {
        let response = [];
        const notification = this.props.notification.front

        for (let i in notification) {
            const n = notification[i];
            const messageData = parseJSON(n.messageData)
            let message = n['message']
            let additionalComponent
            if(messageData && messageData?.type && messageData.type === 'history-stock') {
                let searchText = T('contact-technical-support')
                message = message.replace(`${searchText}.`, ' ')
                message = message.replace(searchText, ' ')
                additionalComponent = <Button className='button-link-without-style' style={{color: "white", textDecoration: "underline"}}
                        onClick={() => AppStore.openModal(
                          <StockMarketplacesViewModal
                            id={messageData.id}/>)}>{T('more-label')}</Button>
            }
            response.push(
              <Notification key={i} color={n['priority']}>
                  {message} {additionalComponent} {n['data'] || null}
                  <Button remove onClick={() => Actions.removeFrontNotification(i)} />
                </Notification>
            )
        }

        return response;
    }

    render() {  
        const notification = this.props.notification.front;     
        return (
            notification ? this.getNotification() : ""
        );
    }
}

export default connectToStores(NotificationBlock, { notification: NotificationStore });