import React from "react";
import T from "../../../components/i18n";
import ExpensesStore from "../../expenses/expenses-store";
import Money from "../../../components/money";
import { Link } from "react-router-dom";
import {Button} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppStore from "../../../app-store";
import AddExpensesCategoryModal
  from "../../expenses/add-expenses-category-modal";
import {confirmDelete} from "../../../tools/confirm-delete";
import ProductsActions from "../../products/products-actions";
const FinanceCategoryRowDesktop = ({ data, num }) => {
  const getExpensesByCategoryId = ExpensesStore.getExpensesByCategoryId(
    data.id
  );
  const calcStatistics = ExpensesStore.getCalcStatisticsExpenses(
    getExpensesByCategoryId
  );

  const handleDelete = () => {
    confirmDelete(ProductsActions.deleteCatalog("payments-category", data.id))
  }

  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";

  return (
    <tr className={className}>
      <td>
        <Link
          to={{
            pathname: "/transactions",
            state: { expenses_category: data.id },
          }}
        >
          {T(data.name)}
        </Link>
      </td>
      <td>
        {Object.entries(calcStatistics.sumExpensesByCurrency).map(
          ([currency, value]) => (
            <Money
              key={`${currency}-${data.id}-expenses`}
              amount={value}
              currency={currency}
            />
          )
        )}
        {!Object.entries(calcStatistics.sumExpensesByCurrency).length && "-"}
      </td>
      <td>
        {Object.entries(calcStatistics.sumReceiptsByCurrency).map(
          ([currency, value]) => (
            <Money
              key={`${currency}-${data.id}-receipts`}
              amount={value}
              currency={currency}
            />
          )
        )}
        {!Object.entries(calcStatistics.sumReceiptsByCurrency).length && "-"}
      </td>
      <td>{getExpensesByCategoryId.length || "-"}</td>
      <td>
        {!data.is_global && <div className='display-flex-row'>
          <Button className='button-link-icon' onClick={() => AppStore.openModal(<AddExpensesCategoryModal {...data} isEdit/>)}>
            <FontAwesomeIcon icon='edit'/>
          </Button>
          <Button className='button-link-icon' onClick={handleDelete}>
            <FontAwesomeIcon icon='trash-alt'/>
          </Button>
        </div>}
      </td>
    </tr>
  );
};

export default FinanceCategoryRowDesktop;
