import React, {useEffect, useState} from 'react'
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import BindingClientModal from "../../binding-client";
import AddClientModal from "../../../clients/modal/add-client-modal/add-client-modal";
import ClientsActions from "../../../clients/clients-actions";
import ChatsActions from "../../chats-actions";

const ButtonsCreate = ({activeChat}) => {
	const [isBinding, setIsBinding] = useState(false)
	useEffect(() => {
		const addClient = ClientsActions.add.completed.listen(res => {
			if(isBinding) {
				const clientID = res.id
				ChatsActions.bindingClient({'client_id': clientID, 'chat_id': activeChat['id']})
				setIsBinding(false)
			}
		})

		return () => {
			addClient()
		}
	}, [activeChat, isBinding]);

	return (
		<div className='display-flex-column-gap'>
				<OpenInsideModal size="large" text="binding-client-btn" onClick={() => setIsBinding(true)}>
					<BindingClientModal modal="binding-client" data={activeChat}/>
				</OpenInsideModal>
				<OpenInsideModal size="large" text="add-client-btn" color='success' onClick={() => setIsBinding(true)}>
					<AddClientModal data={activeChat} className='modal-start' unMoundFunc={() => setTimeout(() => setIsBinding(false), 5000)}/>
				</OpenInsideModal>
		</div>
	);
};

export default ButtonsCreate;