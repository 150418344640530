import React from "react";
import WarehouseStore from "../../../warehouse/warehouse-store";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import ClientsStore from "../../../clients/clients-store";
import SalesStore from "../../../sales/sales-store";
import T from "../../../../components/i18n";
import InfoWrapper from "../../../../components/info-wrapper";

const GeneralSubReportRowDesktop = ({ data, activeFilter }) => {
  let name = data.name;
  let phone = ''

  if (activeFilter === "mid") {
    name = WarehouseStore.getWarehouseName(data.name);
  } else if (activeFilter === "clients") {
    const client = ClientsStore.getClientById(data.name);
    name = client?.simple_name || data.name;
    phone = client?.phone ? `+${client.phone}` : ''
  } else if (activeFilter === "channels") {
    const channelName = SalesStore.getChannelsName(data.name);
    name = T(channelName);
  }

  return (
    <tr>
      <td>{name}</td>
      {activeFilter === "clients" && <td>{phone}</td>}
      <td>
        <Money amount={data?.amount_sale || 0} wrapper={DOM.span} />
      </td>
      <td>
        <Money amount={(data?.profit || 0) - (data?.expensesProfit || 0)} wrapper={DOM.span} />
      </td>
      <td>{data?.count || 0} ({data?.quantity || 0} {T('items-e')})</td>
      <td>
        <Money amount={data?.averageCheck || 0} wrapper={DOM.span} />
      </td>
      <td>{data?.margin || 0}%</td>
      {activeFilter === "mid" && <td>
        <div>
          <span className="silver">{T("expenses-shipment")}: </span>
          <b>
            <Money
              amount={data?.shipmentExpenses || 0}
              wrapper={DOM.span}
              className="has-text-danger"
            />
          </b>
        </div>
        <div>
          {!!data?.expensesProfit && (
            <InfoWrapper info={T("these-expenses-are-deducted-tip")}>
              <span className="silver">{T("expenses-profit-sales")}: </span>
              <b>
                <Money
                  amount={data?.expensesProfit || 0}
                  wrapper={DOM.span}
                  className="has-text-danger"
                />
              </b>
            </InfoWrapper>
          )}
        </div>
        <div>
          <span className="silver">{T("expenses-without-shipment")}: </span>
          <b>
            <Money
              amount={data?.amountExpenses || 0}
              wrapper={DOM.span}
              className="has-text-danger"
            />
          </b>
        </div>
        {!!data?.notCalcExpenses && (
          <div>
            <span className="silver">{T("expenses-not-deducted")}: </span>
            <b>
              <Money
                amount={data?.notCalcExpenses || 0}
                wrapper={DOM.span}
              />
            </b>
          </div>
        )}
      </td>}
    </tr>
  );
};

export default GeneralSubReportRowDesktop;
