import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import T from "../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import SelectComponent from "../../../tools/select-component";
import ClientsStore from "../clients-store";
import ClientsActions from "../clients-actions";
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import ErrorMessageBlock
	from "../../../components/error-message-block/error-message-block";
import {errorHandler} from "../../../tools/error-handler";
import Tag from "../../../components/tag";
import {Label} from "react-bulma-components/lib/components/form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {confirmDelete} from "../../../tools/confirm-delete";
import List from "react-bulma-components/lib/components/list";
import AppStore from "../../../app-store";
import AddClientsTagModal from "./add-clients-tag-modal";
import {connectToStores} from "../../../tools/reflux-tools";

const AddToClientTagModal = ({id, onClose, onAddClientsTags,onDeletedClientsTags, tagsGroupIds}) => {
	const tagClient = ClientsStore.getGroupClientTagByClientId(id)

	const [selectValue, setSelectValue] = useState(null)
	const [errorMessage, setErrorMessage] = useState(null)
	const [waiting, setWaiting] = useState(false)
	const [tagsGroup, setTagsGroup] = useState(tagClient)

	const handleChangeSelect = (value) => {
		setSelectValue(value)
	}

	const handleSubmit = () => {
		const data = {
			cid: id,
			tid:  selectValue.value
		}
		if(onAddClientsTags) {
			onAddClientsTags(selectValue.value)
			onClose()
		} else {
			ClientsActions.addToClientsTags(data)
			setWaiting(true)
		}
	}

	const handleDelete = (tagId) => {
		const data = {
			cid: id,
			tid: tagId
		}
		if(onDeletedClientsTags) {
			onDeletedClientsTags(tagId)
			onClose()
		} else {
			if(data.cid && data.tid) ClientsActions.deleteClientsTag(data)
		}
	}


	useEffect(() => {
		const addToClientsTagCompleted = ClientsActions.addToClientsTags.completed.listen(() => {
			setWaiting(false)

			if(onClose) onClose()
		})

		const addToClientsTagFailed = ClientsActions.addToClientsTags.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			setErrorMessage(message)

			setTimeout(() => setErrorMessage(null),4000)
		})

		const deleteToClientsTagCompleted = ClientsActions.load.completed.listen(() => {
			if(id) {
				setTagsGroup(ClientsStore.getGroupClientTagByClientId(id))
			}
		})

		if(tagsGroupIds && tagsGroupIds.length) {
				const tagsData = tagsGroupIds.map(item => ClientsStore.getClientTagById(item))
				setTagsGroup(tagsData)
		}

			return () =>  {
				addToClientsTagCompleted()
				addToClientsTagFailed()
				deleteToClientsTagCompleted()
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading size={3} align='center'>{T('tags-to-clients-label')}</Heading>
				<SelectComponent
					name="tags"
					onChange={handleChangeSelect}
					value={selectValue}
					load={ClientsActions.load}
					addItem={(value) => AppStore.openModal(<AddClientsTagModal name={value.name}/>)}
					list={() => id ? ClientsStore.getGroupClientTagListWithoutActiveForSelect(id) : ClientsStore.getGroupTagsWithoutIds(tagsGroupIds)}
					addItemModal={() => AppStore.openModal(<AddClientsTagModal />)}
					creatable={true}
				/>
				{!!tagsGroup?.length && (
					<>
						<Label>{T('clients-tags')}</Label>
						<List style={{width: '50%'}}>
								{tagsGroup.map(item => (
									<List.Item className='display-flex-row-gap' key={`tag-${item.id}`}>
										<Tag color={item.color} name={item.name}/>
										<Link to='#' onClick={()=> confirmDelete(() => handleDelete(item.id))}>
											<FontAwesomeIcon icon='trash-alt' size='1x'/>
										</Link>
									</List.Item>))}

						</List>
					</>)}


				<Button.Group position='right' className='margin-top-10'>
					<SubmitButton
						text='btn-save'
						fullwidth={false}
						waiting={waiting}
						size='large'
						rounded color="primary"
						submit={handleSubmit} />
					<Button rounded color='light' size='large' onClick={onClose}>
						{T('close')}
					</Button>
				</Button.Group>
			</Box>
		</>

	);
};

export default connectToStores(AddToClientTagModal, {_: ClientsStore});