import React from 'react'
import {Dropdown} from "react-bulma-components";
import T from "../../../components/i18n";
import {Link} from "react-router-dom";
import { LocalIcon } from 'whitelables/wl-name/icons'
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";
import TransactionsStore from "../transactions-store";

const ExportDocumentDropdown = () => {
	return (
		<Dropdown
			right={false} label={<LocalIcon icon="download" size="large" />}
		>
			<Dropdown.Item value='pdf'>
				<Link
					className='drop-item'
					to={TransactionsStore.printTransactionsURL('pdf')}
					target="_blank"
					onClick={(evt) => createMobileAppLink(TransactionsStore.printTransactionsURL('pdf'), evt)}
				>
					{T('export-pdf')}
				</Link>
			</Dropdown.Item>
			<Dropdown.Item value='excel'>
				<Link
					className='drop-item'
					to={TransactionsStore.printTransactionsURL('xls')}
					target="_blank"
					download
					onClick={(evt) => createMobileAppLink(TransactionsStore.printTransactionsURL('xls'), evt)}
				>
					{T('export-excel')}
				</Link>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default ExportDocumentDropdown;