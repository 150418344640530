import React from "react";

// import BeginModal from "../../modules/start/begin-modal";
import AddCategoryExpenses from "../../modules/expenses/add-expenses-category-modal";
import AddFundsModal from "../../modules/expenses/add-funds-modal";
import AddPlanExpensesModal from "../../modules/expenses/add-plan-expenses-modal";
import AddMarketplaceModal from "../../modules/start/add-marketplace-modal";
import AddWarehouseModal from "../../modules/warehouse/add-warehouse-modal";
import LoadExcelModal from "../../modules/products/load-excel-modal";
import ImportClientsModal from "../../modules/clients/import-clients-modal";
import AddProductsModal from "../../modules/products/modal/add-product/add-products-modal";
import EditProductsModal from "../../modules/products/edit-products-modal";
import ProductReportModal from "../../modules/products/report-product-modal";
import AddSalesModalLink from "../../modules/sales/add-sales-modal-link";
import ProcessDeliveryModalLink from "../../modules/sales/process-delivery-modal";
import ChangeAccountModal from "../../modules/sales/change-account-modal";
import RefundModal from "../../modules/sales/refund-modal";
import FinishReservModalLink from "../../modules/sales/finish-reserv-modal.js";
import AddSupplierModal from "../../modules/shipments/add-supplier-modal";
import AddAccountModal from "modules/transactions/add-account-modal";
import MoveFundsModal from "modules/transactions/move-funds-modal";
import AddClientModalLink from "modules/clients/add-client-modal-link";
import EditClientModal from "modules/clients/edit-client-modal";
import AddUserModalLink from "modules/settings/permissions/add-user-modal/add-user-modal";
import OpenModalFactory from "components/modal/open-modal-factory";
import LoginModal from "../../user/login/login-form";
import RecoveryPassForm from "../../user/login/recovery-pass-form";
import RecoveryChangePassForm from "../../user/login/change-pass-form";
import RegFormModal from "modules/registration";
import FirstRegModal from "modules/registration/first-reg-modal";
import AddDeliveryLink from "modules/clients/delivery";
import NpApiInfo from "public/info/wl-name/integrations/np.js";
import VideoHelpModal from "public/info/wl-name/video/";
import OnBoardingMobileModal from "modules/start/onboarding/mobile-modal";
import CreateNovaposhtaModal from "modules/sales/delivery/create-novaposhta";
import LoadXmlModal from "modules/products/load-xml-modal";
import AddChannelModal from "modules/settings/add-channel-modal";
import PriceModal from "modules/products/modal/price-modal/price-modal";
import AddStatusModal from "modules/sales/add-status-modal";
import DiscountSettingsModal from "modules/clients/discount-settings/discount-settings-modal";
// import MobileAppLogin from "../../public/mobile/login";

const modal_routes = [
  {
    path: "/:lang/login",
    auth: false,
    main: () => OpenModalFactory(<LoginModal />),
  },
  {
    path: "/:lang/recovery-pass",
    auth: false,
    main: () => OpenModalFactory(<RecoveryPassForm />),
  },
  {
    path: "/:lang/recovery-change-pass",
    auth: false,
    main: () => OpenModalFactory(<RecoveryChangePassForm />),
  },
  {
    path: "/:lang/recovery-change-pass/:code",
    auth: false,
    main: () => OpenModalFactory(<RecoveryChangePassForm />),
  },
  {
    path: "/:lang/app/recovery-pass",
    auth: false,
    main: () => OpenModalFactory(<RecoveryPassForm />),
  },
  {
    path: "/:lang/app/recovery-change-pass",
    auth: false,
    main: () => OpenModalFactory(<RecoveryChangePassForm />),
  },
  {
    path: "/:lang/app/recovery-change-pass/:code",
    auth: false,
    main: () => OpenModalFactory(<RecoveryChangePassForm />),
  },
  {
    path: "/:lang/registration",
    auth: false,
    main: () => OpenModalFactory(<RegFormModal />),
  },
  {
    path: "/expenses/add-category",
    auth: true,
    main: () => OpenModalFactory(<AddCategoryExpenses />),
  },
  {
    path: "/expenses/add-funds",
    auth: true,
    main: () => OpenModalFactory(<AddFundsModal className='modal-start'/>),
  },
  {
    path: "/expenses/add-plan-expenses",
    auth: true,
    main: () => OpenModalFactory(<AddPlanExpensesModal />),
  },
  {
    path: "/start/add-marketplace",
    auth: true,
    main: () => OpenModalFactory(<AddMarketplaceModal />),
  },
  {
    path: "/start/first-reg",
    auth: true,
    main: () => OpenModalFactory(<FirstRegModal />),
  },
  {
    path: "/start/onboarding",
    auth: true,
    main: () => OpenModalFactory(<OnBoardingMobileModal />),
  },
  // {
  //   path: "/start/begin",
  //   main: () => OpenModalFactory(<BeginModal />)
  // },
  {
    path: "/products/add-warehouse",
    auth: true,
    main: () => OpenModalFactory(<AddWarehouseModal />),
  },
  {
    path: "/shipments/add-supplier",
    auth: true,
    main: () => OpenModalFactory(<AddSupplierModal />),
  },
  {
    path: "/products/add-products",
    auth: true,
    main: () => OpenModalFactory(<AddProductsModal className='modal-start' modal="product-modal" />),
  },
  {
    path: "/transactions/add-account",
    auth: true,
    main: () => OpenModalFactory(<AddAccountModal />),
  },
  // {
  //   path: "/products/edit-products",
  //   main: () => (
  //       <OpenModalFactoryComponent>
  //           <EditProductsModal />
  //       </OpenModalFactoryComponent>)
  // },
  {
    path: "/products/edit-products",
    auth: true,
    main: EditProductsModal,
  },
  {
    path: "/clients/edit-client",
    auth: true,
    main: EditClientModal,
  },
  {
    path: "/products/report",
    auth: true,
    main: ProductReportModal,
  },
  {
    path: "/products/upload-excel",
    auth: true,
    main: () => OpenModalFactory(<LoadExcelModal />),
  },
  {
    path: "/products/upload-xml",
    auth: true,
    main: () => OpenModalFactory(<LoadXmlModal />),
  },
  {
    path: "/products/pricelist",
    auth: true,
    main: () => OpenModalFactory(<PriceModal />),
  },
  {
    path: "/clients/import-clients",
    auth: true,
    main: () => OpenModalFactory(<ImportClientsModal />),
  },
  {
    path: "/sales/add-sale",
    auth: true,
    main: AddSalesModalLink,
  },
  {
    path: "/sales/edit-sale",
    auth: true,
    main: AddSalesModalLink,
  },
  {
    path: "/sales/add-status",
    auth: true,
    main: () => OpenModalFactory(<AddStatusModal />),
  },
  {
    path: "/transactions/move-funds",
    auth: true,
    main: MoveFundsModal,
  },
  {
    path: "/sales/change-account",
    auth: true,
    main: ChangeAccountModal,
  },
  {
    path: "/sales/refund",
    auth: true,
    main: RefundModal,
  },
  {
    path: "/sales/reserv/finish",
    auth: true,
    main: FinishReservModalLink,
  },
  {
    path: "/sales/delivery/process",
    auth: true,
    main: ProcessDeliveryModalLink,
  },
  {
    path: "/clients/add-client",
    auth: true,
    main: AddClientModalLink,
  },
  {
    path: "/clients/add-delivery",
    auth: true,
    main: AddDeliveryLink,
  },
  {
    path: "/clients/discounts",
    auth: true,
    main: DiscountSettingsModal,
  },
  {
    path: "/settings/add-user",
    auth: true,
    main: AddUserModalLink,
  },
  {
    path: "/sales/add-channel",
    auth: true,
    main: () => OpenModalFactory(<AddChannelModal />),
  },
  {
    path: "/modal/help/npapi",
    auth: true,
    main: () => OpenModalFactory(<NpApiInfo />),
  },
  {
    path: "/sales/create-ttn-novaposhta",
    auth: true,
    main: () => OpenModalFactory(<CreateNovaposhtaModal />),
  },
  {
    path: "/modal/help/video",
    auth: false,
    main: VideoHelpModal,
  },
];

export default modal_routes;
