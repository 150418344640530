import React, {useEffect, useState} from 'react'
import T from '../../../components/i18n'
import UserStore from "../../../user/user-store";
import ChannelBlock from "../../../components/channel-block/channel-block";
import SalesStore from "../../sales/sales-store";
import Tag from "../../../components/tag";
import HistoryStore from "../history-store";
import { LocalIcon } from 'whitelables/wl-name/icons'
import StockMarketplaceProductRow from "./stock-marketplace-product-row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bulma-components";
import HistoryActions from "../history-actions";
import {
	isMoreThanXMinutesOld
} from "../../../tools/is-more-than-minutes-old/is-more-than-minutes-old";
import {parseJSON} from "../../../tools/error-handler";
import formatDate from "../../../tools/format-date";

const StockMarketplacesRowDesktop = ({data, num}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [disabledLoad, setDisabledLoad] = useState(false)

	const channel_id = SalesStore.foundChannelsByIntegrationId(data?.integration_id)
	const getIntegration = UserStore.getIntegrationById(data?.integration_id);
	const statusInfo = HistoryStore.getStatusMarketplace(data.status)

	const handleOpen = (evt) => {
		evt.stopPropagation()
		if(data.stock.length) {
			setIsOpen(prev => !prev)
		}
	}

	const handleRepeatDownload = () => {
		setDisabledLoad(true)
		HistoryActions.repeatStockMarketplaces(data.id)
	}

	useEffect(() => {
		const repeatCompl = HistoryActions.repeatStockMarketplaces.completed.listen(() => {
			setDisabledLoad(false)
		})
		const repeatFail = HistoryActions.repeatStockMarketplaces.failed.listen(() => {
			setDisabledLoad(false)
		})

		return () => {
			repeatCompl()
			repeatFail()
		}
	}, []);

	const parseErrors = parseJSON(data.errors || [])

	const globalErrors = parseErrors.filter(item => !item.pid)

	let className = " is-one-";
	className += num % 2 === 0 ? "even" : "odd";

	return (
		<>
		<tr onClick={handleOpen} className={`${isOpen ? 'selected-border' : ''} ${className}`}>
			<td>
				{!!data.stock.length && <div
					className={`display-flex-center black icon-open ${isOpen ? 'active' : ''}`}>
					<LocalIcon icon='arrow-left' size='small'/>
				</div>}
			</td>
			<td>
				ID: {data.id}
				<br/>
				{formatDate(data.created_at)}
				<br/>
				<small>
					<b>{T('update-label')}: </b> {formatDate(data.updated_at)}
				</small>
			</td>
			<td>
				<ChannelBlock channel_id={channel_id}/>
				{getIntegration?.name}
			</td>
			<td>
					<Tag name={statusInfo.name} color={statusInfo.background}/>
			</td>
			<td>
				{(data.stock.length || data.count_products) ? (data.stock.length || data.count_products) : '-'}
			</td>
			<td>
				{(data.status === 2 || (data.status === 0 && isMoreThanXMinutesOld(data.created_at, 5))) && <Button className='button-link-icon' disabled={disabledLoad} title={T('retry-upload-label')} onClick={handleRepeatDownload}>
					<FontAwesomeIcon icon="undo" size="1x"/>
				</Button>}
			</td>
		</tr>
		{isOpen && <tr className={`selected-border ${className}`}>
			<td colSpan={6}>
				{!!globalErrors && !!globalErrors.length && <div className='display-flex-column has-text-danger'
							style={{padding: '10px'}}>
					{globalErrors.map((item, index) => <div
						key={`global-errors-${index}`}>{T(item.error)}</div>)}
				</div>}
				{data.stock.map(item => <StockMarketplaceProductRow key={`product-${item.product_id}`}
																														data={item} errors={data?.errors}/>)}
			</td>
		</tr>}
		</>
	);
};

export default StockMarketplacesRowDesktop;