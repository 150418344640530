import React, { useEffect, useState } from "react";
import T from "../../components/i18n";
import UserActions from "../user-actions";
import UserStore from "../user-store";
import {
  Field,
  Control,
  Input,
  Label,
  Help,
} from "react-bulma-components/lib/components/form";
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "app-store";
import { Link, useHistory } from "react-router-dom";
import SocialLogin from "../../modules/registration/social-login";
import {isAndroid} from "react-device-detect";
import {redirectTo} from "../../tools/redirect-to";

const HelpError = ({ error }) => {
  return <Help color="danger">{T(error)}</Help>;
};

const LoginForm = () => {
  const history = useHistory();
  const [email, setEmail] = useState({
    value: "",
    error: "",
  });
  const [password, setPassword] = useState({
    value: "",
    error: "",
  });

  const handleSubmit = () => {
    console.log("start login...");
    UserActions.login(email.value, password.value);
  };

  const whereToSend = () => {
    let main_rounte = "/dashboard";

    if(UserStore.isFirstReg()) {
        return "/start"
    }

    if (UserStore.isCashier()) {
      main_rounte = "/sales";
    } else if (UserStore.isManager()) {
      main_rounte = "/sales";
    } else if (UserStore.isWarehouseMan()) {
      main_rounte = "/products";
    }

    if (!UserStore.getModuleEnable(main_rounte.substring(1))) {
      main_rounte = "/products";
    }

    return main_rounte;
  };

  const handleChangeInput = ({ target: { value, name } }) => {
    if (name === "email") {
      setEmail({ value });
    }
    if (name === "password") {
      setPassword({ value });
    }
  };

  useEffect(() => {
    const loginFailed = UserActions.login.failed.listen((data) => {
      setEmail({ error: data });
    });

    const loadChangeToken = UserActions.changeToken.completed.listen(() => {
      history.push(whereToSend());

      loadChangeToken();
    });

    return () => {
      loginFailed();
    };
  }, [history]);

  return (
    <Box>
      {!AppStore.isMobileApp() && (
        <>
          <SocialLogin />
          <div align="center" className="margin-top-10">
            <Heading size={5} renderAs='p'>{T("or")}</Heading>
          </div>
        </>
      )}
      <Field>
        <Control>
          <Label>E-mail or Phone</Label>
          <Input
            size="large"
            name="email"
            type="text"
            inputMode="email"
            placeholder="E-mail"
            onChange={handleChangeInput}
            color={email.error ? "danger" : "primary"}
            value={email.value}
          />
        </Control>
        {!!email.error && <HelpError error={email.error} />}
      </Field>
      <Field>
        <Control>
          <Label>{T("password-placeholder")}</Label>
          <Input
            size="large"
            name="password"
            type="password"
            placeholder={T("password-placeholder")}
            onChange={handleChangeInput}
            color={password.error ? "danger" : "primary"}
            value={password.value}
          />
        </Control>
        <Columns className="is-mobile font-size-80">
          <Columns.Column align="left">
            {parseInt(process.env.REACT_APP_ALLOW_REG) === 1 ? (
              <Link to={AppStore.getLink("registration")}>
                {T("create-account")}
              </Link>
            ) : null}
          </Columns.Column>
          <Columns.Column align="right">
            <Link to={AppStore.getLink("recovery-pass")}>
              {T("recovery-password-btn")}
            </Link>
          </Columns.Column>
        </Columns>
      </Field>
      <Button.Group>
        <Button
          fullwidth
          size="large"
          rounded
          color="primary"
          onClick={handleSubmit}
        >
          {T("login-btn-label")}
        </Button>
      </Button.Group>
      {isAndroid && AppStore.isMobileApp() && !UserStore.isGuest() && redirectTo(whereToSend())}
    </Box>
  );
};

export default LoginForm;
