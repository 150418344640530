const messages = {
	"how-work-section?": {
		ru: "Как работать с этим разделом?",
		en: "How to work with this section?",
		ua: "Як працювати з цим розділом?"
	},
	"adding-product-doc": {
		ru: "Добавление товара",
		en: "Adding a product",
		ua: "Додавання товару"
	},
	"adding-variable-product-doc": {
		ru: "Как добавить Вариативный товар",
		en: "How to add a variant product",
		ua: "Як додати Варіативний товар"
	},
	"add-product-sets-doc": {
		ru: "Добавить товар: Комплекты",
		en: "Add product: Sets",
		ua: "Додати товар: Комплекти"
	},
	"filters-product-search-doc": {
		ru: "Фильтры и поиск товаров",
		en: "Filters and product search",
		ua: "Фільтри і пошук товарів"
	},
	"how-keep-different-multicurrency-doc": {
		ru: "Как вести учет товаров с закупкой в разной валюте (мультивалютность)",
		en: "How to keep records of goods purchased in different currencies (multicurrency)",
		ua: "Як вести облік товарів з закупкою в різній валюті (мультивалютність)	"
	},
	"what-fractions-parentheses-doc": {
		ru: "Что означают дроби и скобки в остатках товара",
		en: "What fractions and parentheses mean in product balances",
		ua: "Що означають дроби та дужки в залишках товару"
	},
	"how-import-products-my-store-doc": {
		ru: "Как импортировать товары и клиенты из Мой склад",
		en: "How to import products and customers from “My Store”",
		ua: "Як імпортувати товари та клієнтів з Мой склад"
	},
	"how-import-products-cloudshop-doc": {
		ru: "Как импортировать товары и клиентов из Cloudshop",
		en: "How to import products and customers from Cloudshop",
		ua: "Як імпортувати товари та клієнтів з Cloudshop"
	},
	"uploading-products-xml-doc": {
		ru: "Загрузка товаров в XML",
		en: "Uploading products to XML",
		ua: "Завантаження товарів у XML"
	},
	"printing-price-tags-doc": {
		ru: "Печать ценников",
		en: "Printing price tags",
		ua: "Друк цінників"
	},
	"printing-checks-doc": {
		ru: "Печать чеков",
		en: "Printing checks",
		ua: "Друк чеків"
	},
	"how-conduct-inventory-doc": {
		ru: "Как провести инвентаризацию",
		en: "How to conduct an inventory",
		ua: "Як провести інвентаризацію"
	},
	"add-sale-doc": {
		ru: "Добавление продажи: Завершенная продажа",
		en: "Add Sale: Completed Sale",
		ua: "Додавання продажу: завершений продаж"
	},
	"adding-sale-reserving-doc": {
		ru: "Добавление продажи: Резервирование товара",
		en: "Adding a sale: reserving a product",
		ua: "Додавання продажу: резервування товару"
	},
	"addition-sale-delivery-doc": {
		ru: "Доставка/Наложенный платеж",
		en: "Addition of sale: Delivery",
		ua: "Додавання продажу: Доставка"
	},
	"sales-return-doc": {
		ru: "Возврат продажи",
		en: "Sales return",
		ua: "Повернення продажу"
	},
	"delete-edit-sale-doc": {
		ru: "Удаление и редактирование продаж",
		en: "Delete and Edit a sale",
		ua: "Видалення та Редагування продажу"
	},
	"sales-section-statuses-doc": {
		ru: "Раздел Продажи: значение статусов",
		en: "Sales section: meaning of statuses",
		ua: "Розділ Продажі: значення статусів"
	},
	"creation-ttn-service-doc": {
		ru: "Создание ТТН Новой Почты внутри сервиса",
		en: "Creation of TTN of Nova Pochti within the service",
		ua: "Створення ТТН Нової Почти всередині сервісу"
	},
	"how-process-orders-doc": {
		ru: "Как обработать поступившие заказы",
		en: "How to process incoming orders",
		ua: "Як обробити замовлення, що надійшли"
	},
	"accounts-available-create-ones-doc": {
		ru: "Счета: какие из них доступны сразу и как создавать новые",
		en: "Accounts: which ones are available immediately and how to create new ones",
		ua: "Рахунки: які з них доступні відразу і як створювати нові"
	},
	"adding-inflow-funds-doc": {
		ru: "Добавление поступления средств",
		en: "Adding inflow of funds",
		ua: "Додавання надходження коштів"
	},
	"transfer-funds-between-accounts-doc": {
		ru: "Перевод средств между счетами",
		en: "Transfer of funds between accounts",
		ua: "Переказ коштів між рахунками"
	},
	"correct-accounts-functionality-doc": {
		ru: "Правильное использование функционала счетов",
		en: "Correct use of the accounts functionality",
		ua: "Правильне використання функціоналу рахунків"
	},
	"adding-costs-doc": {
		ru: "Добавление расходов",
		en: "Adding costs",
		ua: "Додавання витрат"
	},
	"client-base-doc": {
		ru: "Клиентская база",
		en: "Client base",
		ua: "Клієнтська база"
	},
	"discount-system-customers-doc": {
		ru: "Система скидок для клиентов",
		en: "Discount system for customers",
		ua: "Система знижок для клієнтів"
	},
	"how-use-chats-doc": {
		ru: "Как пользоваться Чатами",
		en: "How to use Chats",
		ua: "Як користуватись Чатами"
	},
	"how-track-production": {
		ru: "Как вести учет производства",
		en: "How to keep track of Production",
		ua: "Як вести облік виробництва"
	},
	"access-settings-users-doc": {
		ru: "Настройки доступа другим пользователям: администратор, кассир, кладовщик",
		en: "Access settings for other users: administrator, cashier, storekeeper",
		ua: "Налаштування доступу іншим користувачам: адміністратор, касир, кладовщик"
	},
	"function-calculating-sales-employees-doc": {
		ru: "Функция начисления процентов с продаж наемным работникам",
		en: "The function of calculating interest on sales to employees",
		ua: "Функція нарахування відсотків з продажу найманим працівникам"
	},
	"bonus-system-doc": {
		ru: "Система бонусов",
		en: "Bonus system",
		ua: "Система бонусів"
	},
	"shipments-creation-design-doc": {
		ru: "Поставка: создание и оформление",
		en: "Shipments: creation and design",
		ua: "Поставка: створення та оформлення"
	}
};

export default messages;
