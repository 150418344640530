import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";
import T from "components/i18n";
import Money from "components/money";
import Reports from "components/reports";
import TarifBlock from "components/tarif-block";
import Messages from 'components/messages';
import { redirectTo } from 'tools/redirect-to'
import User from 'user/user-store';
import UserAction from 'user/user-actions';
import AppStore from 'app-store';
import trackEvent from 'tools/track-events';
import formatDate from 'tools/format-date';
import { confirmAlert } from 'react-confirm-alert';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';
import TransactionsRow from "./payments-row";
import CardRow from "./card-row";
import Loader from 'react-loader-spinner'
import {isIOS} from "react-device-detect";
import Columns from "react-bulma-components/lib/components/columns";
import UserStore from "user/user-store";
import AccessDenied from "../dashboard/access_denied";
import { withRouter} from "react-router-dom";
import {Checkbox, Field} from "react-bulma-components/lib/components/form";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import {
  ToLocaleDateString
} from "../../tools/to-locale-date-string/to-locale-date-string";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";

const COLUMNS = [
    "id",
    "date",
    "description",
    "amount",
    "status"
];

const COLUMNS_CARDS = [
    "date",
    "account_num"
];

class Subscribe extends Component {

    constructor(props) {
        super(props);
        this.state = {
          comment: "",
          waiting: false,
          message: null,
          go_main: false,
          consentToRegularDebits: false,
          step: 0   
        };

        this.goToPay = this.goToPay.bind(this);
        this.getButton = this.getButton.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepConfirmChangeTarif = this.renderStepConfirmChangeTarif.bind(this);
        this.onGetPostMessage = this.onGetPostMessage.bind(this);
    }


    componentWillUnmount() {
      if (typeof(this.listener) == "function") {
          this.listener();
      }
      if (typeof(this.listenerCancel) == "function") {
          this.listenerCancel();
      }
      if (typeof(this.listenerFailed) == "function") {
        this.listenerFailed();
      }      

      if (typeof(this.listenerConfirm) == "function") {
        this.listenerConfirm();
      }

       if (window.removeEventListener) {
          window.removeEventListener("message",  this.onGetPostMessage);
        } else {
          window.detachEvent("onmessage",  this.onGetPostMessage);
        }

    }

    componentDidMount() {
      if(this.props?.location?.state?.tarif_register) {
        this.goToPay(this.props.location.state.tarif_register)
      }

        this.listenerConfirm =  UserAction.confirmChangeTarif.completed.listen((response) => {

            if (response['success']) {
                alert(T('changing-tarif-success'));
                UserAction.loadProfile();
                UserAction.getCurrentStateTarif();

                this.setState({'go_main': true})
            } else {
                alert(T('failed-tarif-success'))
                this.setState({'go_main': true})
            }
        });

        this.listener =  UserAction.changeTarif.completed.listen((response) => {
            console.log("response from server", response, AppStore.isMobileApp());
          
            if (response['success']) {

                if (AppStore.isMobileApp() && isIOS) {
                    let name = response['data']['name'];
                    if (name === "middle") {
                        name = "sure";
                    }
                    User.postMessage("subscribe::"+name);
                    trackEvent('users', 'payment', 'apple', response['data']['amount'], 'USD');
                    
                    this.setState({
                        "params": response['data'],
                    })  
                } else if (typeof(response['data']['html']['confirm']) !== "undefined") {
                    this.setState({
                        "step": 3,
                        "waiting": false,
                        "params": response['data'],
                    })
                } else {
                    this.setState({
                        "step": 1,
                        "waiting": false,
                        "params": response['data'],
                    })
                }
            }
        });

        this.listenerFailed =  UserAction.changeTarif.failed.listen((response) => {
            const data = JSON.parse(response['response']);
            this.setState({
              'waiting': false,
              'message': T(data['error'])
            })
        });

        this.listenerCancel =  UserAction.cancelSubscribe.completed.listen((response) => {
            this.setState({
                "step": 2,
                "waiting": false,
                "params": response['result'],
            })
        });


        if (window.addEventListener) {
          window.addEventListener("message", this.onGetPostMessage);
        } else {
          window.attachEvent("onmessage", this.onGetPostMessage);
        }

        UserAction.loadTransactions();

    }

    onGetPostMessage(evt) {
        if (evt.data && typeof(evt.data) === "string") {
          let res = evt.data.split("::");
          if (res[0] === "subscription") {
            if (res[1] === "close") {
                this.setState({'waiting': false})
            } else if (res[1] === "approved") {
                this.setState({'go_main': true})
            } 
            // UserActions.paymentSuccess(res[1])
          } 
        }
    }

    goToPay(tarif) {
        const name_to_id = {
            1: 'traine',
            2: 'begin',
            3: 'standart',
            4: 'profi', 
            5: 'shark',
            6: 'middle',
            8: 'surenew',
            9: 'pronew',
            10: 'sharknew',
        }

        this.setState({
            waiting: tarif
        })
        let name = T('pay-tarif-plan')+": "+T('tarif-plan-'+name_to_id[tarif]);
        UserAction.changeTarif(tarif, name);
        trackEvent('users', 'payment', 'start', User.getTarifCost());
    } 

    cancelSubscribe() {
        confirmAlert({
          title: T('confirm-cancel-subscribe'),
          message: T('are-you-sure-to-cancel-subscribe'),
          buttons: [
            {
              label: T('cancel-subscribe'),
              onClick: () => {
                trackEvent('users', 'payment', 'cancel');
                UserAction.cancelSubscribe();
                }
            },
            {
              label: T('not-cancel-subscribe'),
              onClick: () => {}
            }
          ]
        });
    }

    goback() {
        this.setState({
            step: 0
        })
    }

    // else if (parseInt(tarif) < User.getTarif()) {
    //         response.push(
    //             <Button key="b1" size="medium" rounded disabled color="light">{T('select')}*</Button>
    //         )
    //     }

    getButton(tarif) {
        if (this.state.waiting === tarif) {
            return (
                <Button key="b-waiting" size="medium" disabled rounded color="success">
                    <Loader type="Circles" className="button_waiting" color="#000000" height={20} width={20} /> {T('in-processing')}
                </Button>
            )
        }
        let response = [];
        if (User.getTarif() === parseInt(tarif)) {
            response.push(
                <Button key="b1" size="medium" rounded disabled color="success">{T('active')}</Button>
            )
        }  else {
            response.push(
                <Button key="b1" size="medium" disabled={this.state.waiting} className={`change_tarif tarif-${tarif}`} rounded color="info" onClick={() => this.goToPay(tarif)}>{T('select')}</Button>
            )
        }

        return response;
    }

    _getInputs() {
        let response = [];
        for (let i in this.state.params['html']['params']) {
            response.push(
                <input type="hidden" key={i} name={i} value={this.state.params['html']['params'][i]}/>
                )
        }

        return response;
    }

    _getForms() {
        if (typeof(this.state.params['url']) !== "undefined") {
            return (
                <>
                    <Heading>Обязательно при оплате укажите ваш E-mail: {User.get("email")}</Heading>
                <a href={this.state.params['url']} onClick={(e) => { if (!this.state.consentToRegularDebits) {
                        e.preventDefault(); 
                }}} 
                style={this.state.consentToRegularDebits ? {} : {opacity: 0.5}}  
                className="pay-button">{T('pay-subscrube')}</a>
                </>
            )
        } else {
            return (
                <form method="post" action={this.state.params['html']['action']} acceptCharset="utf-8">
                    {this._getInputs()}
                    <input type="image" disabled={!this.state.consentToRegularDebits} style={this.state.consentToRegularDebits ? {} : {opacity: 0.5}} alt="Pay" src="/img/p1ru.radius.png" name="btn_text" />
                </form>
            );
        }
    }

    emptyMessage(message) {
        return (
            <Box align="center">
                <h2>{T(message)}</h2>
            </Box>

        ) 
    }

    renderReport() {
         const props = this.props,
              emptyMessage = () => this.emptyMessage('not-have-transactions');
        return (
            <Box>
                <Heading>{T('your-payments')}</Heading>
                <Reports {...props} 
                    load={UserAction.loadTransactions} 
                    emptyMessage={emptyMessage} 
                    columns={COLUMNS} 
                    hideFooterOnEmpty
                    rows={props.transactions}>

                    <TransactionsRow role="row"/>
                </Reports>
            </Box>    
        );
    }  

    renderCard() {
         const props = this.props,
              emptyMessage = () => this.emptyMessage('not-have-card');

        return (
            <Box>
                <Heading>{T('your-cards')}</Heading>
                <Reports {...props} 
                    load={UserAction.loadTransactions} 
                    emptyMessage={emptyMessage} 
                    columns={COLUMNS_CARDS} 
                    hideFooterOnEmpty
                    rows={props.cards}>

                    <CardRow role="row"/>
                </Reports>
            </Box>    
        );
    }

    tarifChanged() {
        return (
            <Heading className="has-text-success">{T('tarif-success-change')}</Heading>
            )
    }

    renderStepThird() {
      return (
        <Box>
            <Heading>{T('cancel-subscribe-'+this.state.params)}</Heading>
        </Box>
        );
    }    

    renderStepConfirmChangeTarif() {
      let cur_id = this.getCurrency();
      return (
        <Box>
            <Heading>{T('changing-tarif')} {T('tarif-plan-'+this.state.params['name'])} </Heading>

            <Heading subtitle>{T('recalc-cost')}</Heading>
            <div>
                <b>{T('product-info-price')}:</b> {this.state.params['amount']} {T('cur-month-'+cur_id)} <br />
                <b>{T('paid-days')}:</b> {this.state.params['html']['remaining_days']} <br />
                <b>{T('new-next-date')}: </b>{formatDate(this.state.params['html']['next_payment_date'])} <br />
            </div>

            <Button.Group position="centered" className="margin-top-10">
                    <Button key="back" size="medium" rounded color="warning" onClick={() => this.goback()}>{T('back')}</Button>
                    <Button key="b1" size="medium" rounded color="success" onClick={() => UserAction.confirmChangeTarif(this.state.params['html']['tarif_id'])}>{T('confirm-order')}</Button>
            </Button.Group>
        </Box>
        );
    }

    getBonusText(bonus_days, start_date) {
        return (<Heading className="has-text-success">
            {T('subscribe-bonus-days', {bonus_days, start_date})}
        </Heading>
        )
    }

    getCurrency() {
        return User.getPaymentCurrency()
    }

    renderStepSecond() {
        const tarif_name = 'tarif-plan-'+this.state.params['name'],
              date = new Date(this.state.params['date']),
              start_date = ToLocaleDateString(date),
              bonus_days = this.state.params['days'];
              

        date.setMonth(date.getMonth()+1);
        const end_date = ToLocaleDateString(date);

        let cur_id = this.getCurrency();

        const handleChangeCheckbox = ({target: {checked}}) => {
          this.setState({
            consentToRegularDebits: checked
          })
        }

        return (
            <Box>
                <Heading>{T('pay-tarif')}: {T(tarif_name)}</Heading>
                <Heading className="has-text-primary">{this.state.params['amount']} {T('cur-month-'+cur_id)}</Heading>
                {this.state.params['html'] === "changed" ? this.tarifChanged() : this._getForms()}
                <Field className='font-size-120' style={{userSelect: 'none'}}>
                  <Checkbox checked={this.state.consentToRegularDebits} onChange={handleChangeCheckbox}>
                    {T('consent-to-regular-debits')}
                  </Checkbox>
                </Field>

                {bonus_days > 0 ? this.getBonusText(bonus_days, start_date) : null}

                <Box className="margin-top-10">
                <div>{T('start_subscribe')}: {start_date}</div>
                <div><b>{T('next_subscribe')}: {end_date}</b></div>
                <div>
                    <small>{T('next_subscribe_desc')}. </small>
                </div>
                </Box>

                <Button key="back" size="medium" rounded color="warning" onClick={() => this.goback()}>{T('back')}</Button>
            </Box>  
        )
    }

    renderStepOne() {
      const dataTarif = User.getTarifDataAccount()
      const tarifID = User.getTarif()


        return (
            <Content>
                <Box className="tarif-plan">
                    <div align="left">
                    <Heading renderAs="h3">{T('current-state')}</Heading>
                      <Columns>
                        <Columns.Column>
                          {T('left_sales')} <b>{User.getCurrentState('sales')}</b> {T('of-sales')}<br />
                          {T('you_can_add')} <b>{User.getCurrentState('products')}</b> {T('items')}<br />
                          {T('next_payment_date')}: {User.getNextPaymentDate()}<br/>
                          <div style={{width: this.props.isMobileSize ? 'fit-content' :'100%'}}>
                            {T('your-tarif-plan')}:&nbsp;<b>{T('tarif-plan-'+dataTarif['name'])}</b>{tarifID <= 6 && tarifID !== 1 && <span className='bold has-text-danger'>&nbsp;({T('obsolete')})</span>}
                          </div>
                        </Columns.Column>
                        <Columns.Column>
                          {T('account-tarif-info-tip')}
                          <DocumentationButton sectionID='payment-setting'/>
                        </Columns.Column>
                      </Columns>
                      <hr />
                    {User.isOnBoarding() && !isIOS ? <Heading size={4} className="has-text-success">{T('your-bonus')}:&nbsp;<Money amount={User.getBonusSum()} currency={this.getCurrency()}/></Heading> : null}
                    </div>
                    <Heading>{T('setting-tarif-plan')}</Heading>
                    <Messages message={this.state.message} close={() => this.setState({message: null})} />
                    <TarifBlock button={this.getButton}/>
                    <small>*{T('downgrade_tarif')}</small>
                </Box>  
                {AppStore.getWlName() !== "modeler" ? this.renderCard() : null}
                {this.renderReport()}
                <div align="right" >
                    {User.getTarif() !== 1 ? <Button key="cancel" size="small" rounded color="warning" onClick={() => this.cancelSubscribe()}>{T('cancel-subscribe')}</Button> : "" }
                </div>
            </Content>
            )
    }

    render() {
        // const props = this.props;
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond, this.renderStepThird, this.renderStepConfirmChangeTarif],
              currentStep = stepNaviganor[this.state.step];

        return UserStore.isMainUser() ? (
                <div>
                  {currentStep()}
                  {this.state.go_main ?  redirectTo("/") : ""}
                </div>

                ): <AccessDenied/>;
    }
}

export default connectToStores(withIsMobileSize(withRouter(Subscribe)), { _: User });
