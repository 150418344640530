import React from 'react';
import BaseFilter from "components/modules/base-filters";
import ProductsStore from "./products-store";
import Actions from './products-actions';
import PriceFromToFilter
  from "../../components/base-filter-layout/price-from-to-filter";
import SelectComponent from "../../tools/select-component";
import ProductsActions from "./products-actions";
import T from '../../components/i18n'
import UserStore from "../../user/user-store";
import StockFilter from "../../components/base-filter-layout/stock-filter";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class ProductsFilter extends BaseFilter {
    constructor(props) {
        super(props);
        this.store = ProductsStore;
        this.actions = Actions;
        this.state = {
          attrList: ProductsStore.getAttrList()
        }
    }

    componentDidMount() {
      this.updateProduct = ProductsActions.load.completed.listen(() => {
        this.setState({
          attrList:ProductsStore.getAttrList()
        })
      })
    }
    componentWillUnmount() {
      if (typeof(this.updateProduct) == "function") { this.updateProduct(); }
    }

  onChangeCheckbox(evt) {
        const value = evt.target.checked,
              name = evt.target.name

         Actions.filter(name, value);
    }

    onChange(evt) { 
        const value = evt.target.value,
              name = evt.target.name

        Actions.filter(name, value);
    }

    onChangeSelect(selectedOption, actions) {

      Actions.filter(actions.name, selectedOption);
    }


    onChangeAttrFilter(value, {name}) {
      const attrFilter = ProductsStore.getFilter('attr')

      attrFilter[name] = value

      ProductsActions.filter('attr', attrFilter)
    }

    filterAttr(getListAttr) {
      return getListAttr?.map(item => {
        const getValueItem = ProductsStore.getFilter('attr')[item.id] || {value: 0, label: T('all')}
        return !!ProductsStore.getListDescByAttr(item.id) && (
          <SelectComponent
            empty='all'
            key={item.id}
            name={item.id}
            label={item.name}
            onChange={this.onChangeAttrFilter}
            value={getValueItem}
            list={() => ProductsStore.getListDescByAttr(item.id)}
            creatable={false}
          />)
      })
    }

    filterReserv() {

      const list = () => [{id: 'reserv', name: T('filter-reserv')},{id: 'notreserv', name: T('filter-not-reserv')}]

      return (
        <SelectComponent
        empty='all'
        name='reserv'
        onChange={this.onChangeSelect}
        label='filter-reserv-label'
        value={this.store.getFilter("reserv")}
        list={list}
        creatable={false}
      />)
    }

    filterIntegration(){
      const integrationList = UserStore.getIntegrationListWithChannelName()

      const list = [{id: 'no-integration',  name: T('without-integration')},...integrationList]

      return !!integrationList?.length && (
        <SelectComponent
          empty='all'
          name='integration'
          onChange={this.onChangeSelect}
          label='integration'
          value={this.store.getFilter("integration")}
          list={() => list}
          creatable={false}
        />)
    }

    filterMinStock() {
      const list = [
        {id: 'required', name: T('requires-purchase')},
        {id: 'no-required', name: T('no-purchase-required')},
      ]

      return <SelectComponent
        empty='all'
        name='minStock'
        onChange={this.onChangeSelect}
        label='min-balance-label'
        value={this.store.getFilter("minStock")}
        list={() => list}
        creatable={false}
      />
    }

    filterAdditionalField() {
      const getAddField = UserStore.getAdditionalFieldsList('product');
      const mapForSelect = getAddField.map(item => ({name: item, id: item}))

      return !!mapForSelect.length && <SelectComponent
        empty='all'
        name='addFields'
        onChange={this.onChangeSelect}
        label='additional-fields-short'
        value={this.store.getFilter("addFields")}
        list={() => mapForSelect}
        creatable={false}
      />
    }

    filterExpirationDate() {
      const listSelect = [
        {id: 'overdue-exp', name: "overdue-exp"},
        {id: 'expires-exp', name: "expires-exp"},
      ]

      return <SelectComponent
        empty='all'
        name='expirationDate'
        onChange={this.onChangeSelect}
        label='expired-date'
        value={this.store.getFilter("expirationDate")}
        list={() => listSelect}
      />
    }

    filterTypeProduct() {
      const listSelect = [
        {id: 1, name: T("is-simple") },
        {id: 2, name: T('is-variable-product')},
        {id: 3, name: T('is-complect')},
        {id: 4, name: T('services')},
      ]

      return <SelectComponent
        empty='all'
        name='type_product'
        onChange={this.onChangeSelect}
        label='type-product'
        value={this.store.getFilter("type_product")}
        list={() => listSelect}
      />
    }

    render () {
      return (
            <>
              {this.props.isMobileSize && <StockFilter/>}
              {/*{this.getWarehouseFilter('history-data-marketplace_id')}*/}
              {this.getCategoryFilter('product-category')}
              {this.getBrandFilter('brand', true)}
              {this.getShipmentFilter('shipment')}
              {this.filterIntegration()}
              <PriceFromToFilter
                label='products-price'
                from={{
                  name: 'price_from',
                  value: ProductsStore.getFilter("price_from"),
                }}
                to={{
                  name: "price_to",
                  value: ProductsStore.getFilter("price_to")
                }}
                onChange={this.onChange}
              />
              {this.filterReserv()}
              {this.filterMinStock()}
              {this.filterAdditionalField()}
              {this.filterExpirationDate()}
              {this.filterTypeProduct()}
              {this.filterAttr(this.state.attrList)}
            </>
          )
     }

}

export default withIsMobileSize(ProductsFilter)