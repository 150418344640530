import React, {useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import AppStore from "../../../../../app-store";
import FacebookLogin from "react-facebook-login";
import yajax from "yajax";
import ErrorMessageControlHook
	from "../../../../../components/error-message-block/error-message-control.hook";
import {Heading} from "react-bulma-components";
import T from '../../../../../components/i18n'

const FacebookMobile = () => {
	const location = useLocation()
	const search = new URLSearchParams(location.search)
	const history = useHistory();
	const token = search.get('token')
	const access = search.get('access')
	const [isSuccess, setIsSuccess] = useState(false)
	const {handleAutoRemoveError, errorMessage, ErrorMessageBlock, setErrorMessage} = ErrorMessageControlHook()

	const responseFacebook = (response) => {
			if (response.accessToken && token) {
				let data = {
					integration_id: 14,
					api_key: response.accessToken,
					userID: response.userID,
					ident: JSON.stringify({
						userID: response.userID,
					}),
				};

				yajax.post('/userprofile/integrations', { data: JSON.stringify(data) }, {
					headers: {
						"X-Auth-Token": encodeURIComponent(token),
						"Content-type": "application/x-www-form-urlencoded"
					},
				}).then(
							() => {
								setIsSuccess(true)
								setTimeout(() => {
									history.goBack()
								}, 5000)
							},
							(err) => {
								handleAutoRemoveError(JSON.stringify(err))
							}
						)
			}
	}


	return (
		<>
		<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<div className='display-flex-center'>
				{(access || isSuccess) ? (
					<div>
						<Heading>{T('add-integration-completed-title')}</Heading>
					</div>
				) : (
					<FacebookLogin
						appId={AppStore.isEnableForWhiteLable("facebook-app")}
						autoLoad={true}
						redirectUri={`${window.location.origin}/facebook-connect?access=true`}
						fields="name,email,picture"
						cssClass="my-facebook-button-class"
						scope="business_management,instagram_basic,pages_read_engagement,pages_show_list,pages_messaging,pages_manage_metadata,instagram_manage_messages,instagram_manage_comments"
						callback={responseFacebook}
						icon="fa-facebook"
					/>
				)}
			</div>

		</>
	);
};

export default FacebookMobile;