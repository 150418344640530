import React, {Component} from "react";

import { AVAILABLE_LANGS } from "configs/i18n";

import T from "components/i18n";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import { Link, withRouter } from "react-router-dom";
import DOM from "react-dom-factories";

import UserStore from "user/user-store";
import AppStore from "app-store";
import UserActions from "user/user-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SalesActions from "modules/sales/sales-actions";
import WarehouseStore from "modules/warehouse/warehouse-store";
import Money from "components/money";
import {LocalIcon} from 'whitelables/wl-name/icons'


import OnBoardingBar from "modules/start/onboarding";
import AddExpensesModal from "modules/expenses/add-expenses-modal";
import NotificationDropdown
  from "../../modules/notification/notification-dropdown";
import IconVote from "../../modules/voting/common/icon-vote";

class AccountTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goHome: false,
    };
    this.UserLogout = this.UserLogout.bind(this);
  }

  getBalance() {
    return UserStore.getBalance();
  }

  UserLogout() {
    UserActions.logout();
    this.props.history.push('/')
  }

  getInfoBalance() {
    if (!UserStore.getPermissionEnable("showbalance")) return null;

    return (
      <strong>
        {T("balance")}:{" "}
        <Money amount={this.getBalance() || 0} wrapper={DOM.span} />
      </strong>
    );
  }

  componentDidMount() {
      if(window.HelpCrunch && AppStore.isMobileApp()) {
        window.HelpCrunch('onChatClose', () => {
          UserStore.postMessage('show-barcode')
        });
        window.HelpCrunch('onChatOpen', () => {
          UserStore.postMessage('hide-barcode')
        });
      }
  }

  render() {
    let lang_text = (
      <span className="uppercase bold-text">{UserStore.getLang()}</span>
    );
    const handleAddExpenses = () => {
      AppStore.openModal(<AddExpensesModal className='modal-start'/>);
    };

    let langs = [];
    for (let lang in AVAILABLE_LANGS) {
      langs.push(
        <Dropdown.Item key={lang} value={lang}>
          <Link
            to={"/" + lang + "/"}
            onClick={() => (document.location.href = "/" + lang + "/")}
          >
            {AVAILABLE_LANGS[lang]["full"]}
          </Link>
        </Dropdown.Item>
      );
    }

    if(window.HelpCrunch) {
      window.HelpCrunch('hideChatWidget')
    }

    const handleClickOnlineChat = () => {
      if(window.HelpCrunch) {
        window.HelpCrunch('openChat')
      }
    }


    return (
      <>
        <Box id="account-top-bar">
          <nav className="nav-bar">
            <div className="nav-item">
              {UserStore.isMainUser() ? null : (
                <Heading className="margin-left-5">
                  {WarehouseStore.getWarehouseName()}
                </Heading>
              )}
            </div>
            <div className="nav-item">
              <Link to="/transactions">{this.getInfoBalance()}</Link>
              {UserStore.isCanAddSale() && (
                <Button
                  size="large"
                  className={
                    UserStore.getOnBoaringStep() === 3 ? "button_blink" : null
                  }
                  rounded
                  color="primary"
                  onClick={() => SalesActions.openSaleModal()}
                >
                  {T("add-sale-btn")}
                </Button>
              )}
              {UserStore.getModuleEnable("payments") && (
                <Button
                  size="large"
                  rounded
                  className={
                    UserStore.getOnBoaringStep() === 4 ? "button_blink" : null
                  }
                  color="danger"
                  onClick={handleAddExpenses}
                >
                  {T("add-expenses-btn")}
                </Button>
              )}
              <Link
                to='/start'
              >
                <LocalIcon icon='start-fill' size='large'/>
              </Link>
              <IconVote/>
              {<NotificationDropdown/>}
              {AppStore.isEnableForWhiteLable("contacts") && (
                <Dropdown className='disabled-default' right
                          style={{marginTop: '-6px'}} hoverable
                          label={<FontAwesomeIcon icon="comments" size="2x"/>}>
                  <Dropdown.Item value={T('contact-link')}>
                    <Link to={AppStore.getLink("public_contacts")} style={{
                      padding: '10px',
                      display: 'block',
                      fontSize: '15px',
                      width: '100%'
                    }}>
                      {T('contact-link')}
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item value={T('online-chat')}>
                    <div id='contact-us' style={{
                      padding: '10px',
                      fontSize: '15px',
                      width: '100%'
                    }} onClick={handleClickOnlineChat}>
                      {T('online-chat')}
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              )}
              <a
                href={AppStore.getDocsLink("docs")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="question-circle" size="2x"/>
              </a>
              <Link to="/settings">
                <FontAwesomeIcon icon="cog" size="2x"/>
              </Link>
              <FontAwesomeIcon
                onClick={this.UserLogout}
                icon="power-off"
                size="2x"
              />
              <Dropdown hoverable right label={lang_text}>
                {langs}
              </Dropdown>
            </div>
          </nav>
        </Box>
        {this.props.location.pathname.indexOf("chats") === -1 && (
          <OnBoardingBar/>
        )}
      </>
    );
  }
}

export default withRouter(AccountTopBar);
