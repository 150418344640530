import React from "react";
import HeroBlock from "../../../templates/home/hero-block/hero-block";
import IntegrationBlock from "../../../templates/home/integration-block/integration-block";
import PartnerSliderBlock from "../../../templates/home/partner-slider-block/partner-slider-block";
import WorkflowsBlock from "../../../templates/home/workflows-block/workflows-block";
import TarifBlock from "../../../templates/home/tarif-block/tarif-block";
import ReviewBlock from "../../../templates/home/review-block/review-block";
import SectionBlock from "../../../templates/home/section-block/section-block";
import InfoBlock from "../../../templates/home/info-block/info-block";
import OfferBlock from "../../../templates/home/offer-block/offer-block";
import ClientStatistics from "../../../templates/home/client-statistics/client-statistics";
import QuizBlock from "../../../templates/home/quiz-block/quiz-block";
import Security from "../img/security.svg";
import Anonymous from "../img/anonymous.svg";
import Cloud from "../img/cloud.svg";
import Reserv from "../img/reserv.svg";
import FooterBlock from "../../../public/common/footer";
import SocialLogin from "../../../modules/registration/social-login";
import UserStore from "../../../user/user-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FooterRegistration from "../../../templates/home/footer-registration/footer-registration";
import { Helmet } from "react-helmet";
import T from "../../../components/i18n";

import video from "../img/video.mp4";
import qrGoogle from "../img/qr-google.png";
import qrAppStore from "../img/qr-appstore.png";

import mishop from "../img/parnter-logo/mishop.png";
import veloaccs from "../img/parnter-logo/veloaccs.png";
import bestik from "../img/parnter-logo/bestik.webp";
import ciaoBella from "../img/parnter-logo/ciao-bella.webp";
import device from "../img/parnter-logo/device.webp";
import handMade from "../img/parnter-logo/hand-made.webp";
import kitty from "../img/parnter-logo/kitty.webp";
import manka from "../img/parnter-logo/manka.webp";
import maribra from "../img/parnter-logo/maribra.webp";
import movaTila from "../img/parnter-logo/mova-tila.webp";
import paplyk from "../img/parnter-logo/paplyk.webp";
import pixelmania from "../img/parnter-logo/pixelmania.svg";
import redeem from "../img/parnter-logo/redeem.webp";
import rjcGarage from "../img/parnter-logo/rjc_garage.webp";
import truckparts from "../img/parnter-logo/truckparts.webp";

import ProductsModule from "../img/modules/products.webp";
import OrderModule from "../img/modules/orders.webp";
import SalesModule from "../img/modules/sales.webp";
import FinanceModule from "../img/modules/finance.webp";
import ClientsModule from "../img/modules/clients.webp";
import ChatModule from "../img/modules/chat.webp";
import ShipmentModule from "../img/modules/shipment.webp";
import ProductionModule from "../img/modules/production.webp";
import EmployeesModule from "../img/modules/employees.webp";
import ReportsModule from "../img/modules/reports.webp";

import Avatar1 from "../img/avatar1.webp";
import Avatar2 from "../img/avatar2.webp";
import Avatar3 from "../img/avatar3.webp";
import Avatar4 from "../img/avatar4.webp";
import Avatar5 from "../img/avatar5.webp";
import Avatar6 from "../img/avatar6.webp";
import Avatar7 from "../img/avatar7.webp";
import Avatar8 from "../img/avatar8.webp";
import Avatar9 from "../img/avatar9.webp";

const appInfo = {
  android: {
    link: "https://play.google.com/store/apps/details?id=com.huprof.hugeprofit",
    qrCode: qrGoogle,
  },
  ios: {
    link: "https://apps.apple.com/app/hugeprofit-%D0%BE%D0%B1%D0%BB%D1%96%D0%BA-%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2/id1544248357",
    qrCode: qrAppStore,
  },
};

const integrationList = [
  {
    src: "/img/integrations/6.svg",
    alt: "rozetka",
  },
  {
    src: "/img/integrations/5.svg",
    alt: "prom",
  },
  {
    src: "/img/integrations/7.svg",
    alt: "opencart",
  },
  {
    src: "/img/integrations/2.svg",
    alt: "woocommerce",
  },
  {
    src: "/img/integrations/8.svg",
    alt: "horoshop",
  },
  {
    src: "/img/integrations/4.svg",
    alt: "checkbox",
  },
  {
    src: "/img/integrations/1.svg",
    alt: "nova postha",
  },
  {
    src: "/img/integrations/3.svg",
    alt: "ukr poshta",
  },

  {
    src: "/img/integrations/17.svg",
    alt: "viber",
  },
  {
    src: "/img/integrations/13.png",
    alt: "telegram",
  },
  {
    src: "/img/integrations/11.svg",
    alt: "turbo sms",
  },
  {
    src: "/img/integrations/15.svg",
    alt: "api",
  },
  {
    src: "/img/integrations/16.svg",
    alt: "loyal cards",
  },
  {
    src: "/img/integrations/14.svg",
    alt: "instagram",
  },
  {
    src: "/img/integrations/9.svg",
    alt: "vchasno kasa",
  },
  {
    src: "/img/integrations/binotel-logo.svg",
    alt: "binotel",
  },
  {
    src: "/img/integrations/19.svg",
    alt: 'olx chat'
  },
  {
    src: "/img/integrations/20.svg",
    alt: 'ringostat',
  },
  {
    src: "/img/integrations/21.svg",
    alt: 'shop express',
  },
  {
    src: "/img/integrations/22.svg",
    alt: 'shopify',
    soon: true
  }
];

const partnerList = [
  { src: mishop, alt: "mi shop" },
  { src: veloaccs, alt: "veloaccs" },
  { src: ciaoBella, alt: "ciao Bella" },
  { src: device, alt: "device" },
  { src: handMade, alt: "hand Made" },
  { src: kitty, alt: "kitty" },
  { src: manka, alt: "manka" },
  { src: maribra, alt: "maribra" },
  { src: movaTila, alt: "mova Tila" },
  { src: paplyk, alt: "paplyk" },
  { src: pixelmania, alt: "pixelmania" },
  { src: redeem, alt: "redeem" },
  { src: rjcGarage, alt: "rjc Garage" },
  { src: truckparts, alt: "truck parts" },
  { src: bestik, alt: "bestik" },
];

const workflowsList = [
  {
    label: "products",
    icon: "productsIcon",
  },
  {
    label: "order",
    icon: "ordersIcon",
  },
  {
    label: "sales",
    icon: "salesIcon",
  },
  {
    label: "finance",
    icon: "financeIcon",
  },
  {
    label: "clients",
    icon: "clientsIcon",
  },
  {
    label: "chats",
    icon: "chatsIcon",
  },
  {
    label: "shipments",
    icon: "shipmentsIcon",
  },
  {
    label: "production",
    icon: "productionIcon",
  },
  { label: "employees", icon: "employeesIcon" },
  { label: "reports", icon: "reportsIcon" },
];

const contentList = [
  {
    value: "products",
    img: ProductsModule,
    subTitle: "products-module-sub-title",
    benefits: [
      "products-module-benefit-1",
      "products-module-benefit-2",
      "products-module-benefit-3",
      "products-module-benefit-4",
      "products-module-benefit-5",
      "products-module-benefit-6",
      "products-module-benefit-7",
    ],
  },
  {
    value: "order",
    img: OrderModule,
    subTitle: "order-module-sub-title",
    benefits: [
      "order-module-benefit-1",
      "order-module-benefit-2",
      "order-module-benefit-3",
      "order-module-benefit-4",
      "order-module-benefit-5",
      "order-module-benefit-6",
      "order-module-benefit-7",
      "order-module-benefit-8",
    ],
  },
  {
    value: "sales",
    img: SalesModule,
    subTitle: "sales-module-sub-title",
    benefits: [
      "sales-module-benefit-1",
      "sales-module-benefit-2",
      "sales-module-benefit-3",
      "sales-module-benefit-4",
      "sales-module-benefit-5",
      "sales-module-benefit-6",
      "sales-module-benefit-7",
    ],
  },
  {
    value: "finance",
    img: FinanceModule,
    subTitle: "finance-module-sub-title",
    benefits: [
      "finance-module-benefit-1",
      "finance-module-benefit-2",
      "finance-module-benefit-3",
      "finance-module-benefit-4",
      "finance-module-benefit-5",
    ],
  },
  {
    value: "clients",
    img: ClientsModule,
    subTitle: "clients-module-sub-title",
    benefits: [
      "clients-module-benefit-1",
      "clients-module-benefit-2",
      "clients-module-benefit-3",
      "clients-module-benefit-4",
      "clients-module-benefit-5",
    ],
  },
  {
    value: "chats",
    img: ChatModule,
    subTitle: "chats-module-sub-title",
    benefits: [
      "chats-module-benefit-1",
      "chats-module-benefit-2",
      "chats-module-benefit-3",
      "chats-module-benefit-4",
      "chats-module-benefit-5",
      "chats-module-benefit-6",
    ],
  },
  {
    value: "shipments",
    img: ShipmentModule,
    subTitle: "shipments-module-sub-title",
    benefits: [
      "shipments-module-benefit-1",
      "shipments-module-benefit-2",
      "shipments-module-benefit-3",
      "shipments-module-benefit-4",
      "shipments-module-benefit-5",
      "shipments-module-benefit-6",
      "shipments-module-benefit-7",
    ],
  },
  {
    value: "production",
    img: ProductionModule,
    subTitle: "production-module-sub-title",
    benefits: [
      "production-module-benefit-1",
      "production-module-benefit-2",
      "production-module-benefit-3",
      "production-module-benefit-4",
    ],
  },
  {
    value: "employees",
    img: EmployeesModule,
    subTitle: "employees-module-sub-title",
    benefits: [
      "employees-module-benefit-1",
      "employees-module-benefit-2",
      "employees-module-benefit-3",
      "employees-module-benefit-4",
      "employees-module-benefit-5",
    ],
  },
  {
    value: "reports",
    img: ReportsModule,
    subTitle: "reports-module-sub-title",
    benefits: [
      "reports-module-benefit-1",
      "reports-module-benefit-2",
      "reports-module-benefit-3",
      "reports-module-benefit-4",
      "reports-module-benefit-5",
    ],
  },
];

const reviewBlock = {
  linkMoreReview:
    "https://h-profit.com/blog/dekilka-vidgukiv-nashih-kliyentiv-dyakuyemo-shho-vi-z-nami",
  listReview: [
    {
      avatar: Avatar1,
      name: "review-1-name",
      review: "review-1",
    },
    {
      avatar: Avatar2,
      name: "review-2-name",
      review: "review-2",
    },
    {
      avatar: Avatar3,
      name: "review-3-name",
      review: "review-3",
    },
    {
      avatar: Avatar4,
      name: "review-4-name",
      review: "review-4",
    },
    {
      avatar: Avatar5,
      name: "review-5-name",
      review: "review-5",
    },
    {
      avatar: Avatar6,
      name: "review-6-name",
      review: "review-6",
    },
    {
      avatar: Avatar7,
      name: "review-7-name",
      review: "review-7",
    },
    {
      avatar: Avatar8,
      name: "review-8-name",
      review: "review-8",
    },
    {
      avatar: Avatar9,
      name: "review-9-name",
      review: "review-9",
    },
  ],
};

const supportList = [
  {
    text: "support-team-item-1",
    title: "support-team-title-1",
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
  {
    text: "support-team-item-2",
    title: "support-team-title-2",
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
  {
    text: "support-team-item-3",
    title: "support-team-title-3",
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
  {
    text: "support-team-item-4",
    title: "support-team-title-4",
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
  {
    text: "support-team-item-5",
    title: "support-team-title-5",
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
  {
    text: "support-team-item-6",
    title: "support-team-title-6",
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
  },
];

const questionList = [
  {
    name: "todo-1",
    title: "question1",
  },
  {
    name: "todo-2",
    title: "question2",
  },
  {
    name: "todo-3",
    title: "question3",
  },
  {
    name: "todo-4",
    title: "question4",
  },
  {
    name: "todo-5",
    title: "question5",
  },
  {
    name: "todo-6",
    title: "question6",
  },
];

const secureList = [
  {
    img: Security,
    title: "secure-title-1",
    text: "secure-desk-1",
  },
  {
    img: Anonymous,
    title: "secure-title-2",
    text: "secure-desk-2",
  },
  {
    img: Cloud,
    title: "secure-title-3",
    text: "secure-desk-3",
  },
  {
    img: Reserv,
    title: "secure-title-4",
    text: "secure-desk-4",
  },
];

const benefitsList = [
  {
    img: "/img/ic-1-16.svg",
    title: "feature-1",
    text: "feature-1-desk",
  },
  {
    img: "/img/ic-2-17.svg",
    title: "feature-2",
    text: "feature-2-desk",
  },
  {
    img: "/img/ic-4-19.svg",
    title: "feature-3",
    text: "feature-3-desk",
  },
  {
    img: "/img/icons-7-21.svg",
    title: "feature-4",
    text: "feature-4-desk",
  },
  {
    img: "/img/ic-3-18.svg",
    title: "feature-5",
    text: "feature-5-desk",
  },
  {
    img: "/img/ic-5-20.svg",
    title: "feature-6",
    text: "feature-6-desk",
  },
];

const Home = () => {
  const tarif_data = UserStore.get("tarif_data");

  const valuesTarift = Object.values(tarif_data).filter(
    (item) => !!item.active
  );

  return (
    <>
      <Helmet>
        <title>{T("home-title")}</title>
        <link rel="canonical" href={window.location} />
        <meta name="description" content={T("home-desc")} />
      </Helmet>
      <HeroBlock video={video} android={appInfo.android} ios={appInfo.ios} />
      <IntegrationBlock
        title="integration-heading"
        subTitle="integration-sub-heading"
        list={integrationList}
      />
      <PartnerSliderBlock title="we-are-trusted" list={partnerList} />
      <WorkflowsBlock workflowsList={workflowsList} contentList={contentList} />
      <TarifBlock list={valuesTarift} />
      <ReviewBlock
        moreReviewLink={reviewBlock.linkMoreReview}
        listReview={reviewBlock.listReview}
      />
      <SectionBlock
        list={supportList}
        title="support-team"
        imgSrc="/img/support.webp"
      />
      <InfoBlock list={secureList} />
      <OfferBlock title="free-tarif" subTitle="free-tarif-desk" />
      <ClientStatistics />
      <InfoBlock list={benefitsList} title="why-we" />
      <QuizBlock
        title="thirt-block-title"
        subTitle="thirt-block-subtitle"
        footerTitle="thirt-block-finish"
        questionList={questionList}
      />
      <FooterRegistration
        android={appInfo.android}
        ios={appInfo.ios}
        title="home-reg-block-title"
        subTitle="home-reg-block-subtitle"
      />
      <FooterBlock />
      <div className="is-hidden">
        <SocialLogin />
      </div>
    </>
  );
};

export default Home;
