import React from 'react';
import T from 'components/i18n';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import UserStore from 'user/user-store';
import { Checkbox } from 'react-bulma-components/lib/components/form';
import Content from 'react-bulma-components/lib/components/content';
import AppStore from "../../../../app-store";


const AddUserCheckboxList = ({ settings, onChangeCheckbox, onChangeCheckboxPerm, group }) => {
  const renderItem = (name, enable, change_func) => {
    return <List.Item key={name}>
      <Checkbox
        name={name}
        onChange={evt => change_func(evt)}
        checked={!!enable[name]}
      >
        {T(name)}
      </Checkbox>
    </List.Item>
  }

  const getCheckBoxItems = (names_func, type) => {
    let items = {
      modules: [],
      permissions: {
        viewing: [],
        editing: [],
        deleting: [],
      },
    };

    const enable = type === 'modules' ? settings.modules : settings.permissions;
    const change_func = type === 'modules' ? onChangeCheckbox : onChangeCheckboxPerm;

    let names = names_func();

    if(group && (group.value === 4 || AppStore.getWlName() === 'navkolo') ) {
      names = names.filter(item => item !== 'cashier-panel')
    }

    for (let i in names) {
      const name = names[i];
      if (type === 'modules') {
        items.modules.push(renderItem(name, enable, change_func));
      } else if (type === 'permissions') {
        if (['show-all-sales', 'salesprice', 'showbalance', 'show-cashflow', 'show-all-expenses', 'incomeinformation'].includes(name)) {
          items.permissions.viewing.push(renderItem(name, enable, change_func));
        } else if (['editinstock', 'editproducts', 'rawcost', 'edit-sales', 'refund-products', 'edit-balance', 'edit-discount', 'date-sale-edit', 'ship-rawcost'].includes(name)) {
          items.permissions.editing.push(renderItem(name, enable, change_func));
        } else if (['deleteproducts', 'deleteshipments', 'deletesales', 'delete-client'].includes(name)) {
          items.permissions.deleting.push(renderItem(name, enable, change_func));
        }
      }
    }

    return type === 'modules' ? items.modules : items.permissions;
  }
  
  const modulesItems = getCheckBoxItems(UserStore.getModulesNames, "modules");
  const permItems = getCheckBoxItems(UserStore.getPermissionNames, "permissions");

  const permBlocks = ['viewing', 'editing', 'deleting']
  const renderPermBlock = (block) => {
    return (<div key={block}><Content className="permissions-list-title">{T(block)}</Content>
          <List>
            {permItems[block]}
          </List>
        </div>)
  }

  return (
    <Box>
      <Columns>
        <Columns.Column>
          <Heading size={6} className="margin-bottom-10">{T('section')}</Heading>
          <hr className="permissions-line"/>
          <Content className="margin-bottom-10 margin-top-10">&nbsp;</Content>
          <List>
            {modulesItems}
          </List>
        </Columns.Column>
        <Columns.Column>
          <Heading size={6} className="margin-bottom-10">{T('permission')}</Heading>
          <hr className="permissions-line"/>
          {permBlocks.map(block => renderPermBlock(block))}
        </Columns.Column>
      </Columns>
    </Box>)
};

export default AddUserCheckboxList;