import React from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from "../../../../components/i18n";
import s from './styles.module.scss'
import UserStore from "../../../../user/user-store";

const documentation = {
	products: {
		label: 'products',
		links: [
			{
				label: 'adding-product-doc',
				link: {
					ru: "https://h-profit.com/blog/ru/dobavlenie-tovara/",
					en: "https://h-profit.com/blog/en/adding-a-product/",
					ua: "https://h-profit.com/blog/dodavannya-ta-redaguvannya-tovaru/"
				}
			},
			{
				label: 'adding-variable-product-doc',
				link: {
					ru: "https://h-profit.com/blog/ru/kak-dobavit-variativnyj-tovar/",
					en: "https://h-profit.com/blog/en/how-to-add-a-variant-product/",
					ua: "https://h-profit.com/blog/yak-dodati-variativnij-tovar/"
				}
			},
			{
				label: 'add-product-sets-doc',
				link: {
					ru: "https://h-profit.com/blog/ru/dobavit-tovar-komplekty/",
					en: "https://h-profit.com/blog/en/add-product-sets-2/",
					ua: "https://h-profit.com/blog/dodati-tovar-komplekti/"
				}
			},
			{
				label: 'filters-product-search-doc',
				link: {
					ru: "https://h-profit.com/blog/ru/filtry-i-poisk-tovarov/",
					en: "https://h-profit.com/blog/en/filters-and-product-search/",
					ua: "https://h-profit.com/blog/filtri-i-poshuk-tovariv/"
				}
			},
			{
				label: 'how-keep-different-multicurrency-doc',
				link: {
					ru: "https://h-profit.com/blog/ru/kak-vesti-uchet-tovarov-s-zakupkoj-v-raznoj-valyute/",
					en: "https://h-profit.com/blog/en/how-to-keep-records-of-goods-purchased-in-different-currencies/",
					ua: "https://h-profit.com/blog/multivalyutnist/"
				}
			},
			{
				label: "what-fractions-parentheses-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/chto-oznachayut-drobi-v-ostatkah-tovara/",
					en: "https://h-profit.com/blog/en/what-fractions-and-parentheses-mean-in-product-balances/",
					ua: "https://h-profit.com/blog/shho-oznachayut-drobi-v-zalishkah-tovaru/"
				}
			},
			{
				label: "printing-price-tags-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/pechat-czennikov/",
					en: "https://h-profit.com/blog/en/printing-price-tags/",
					ua: "https://h-profit.com/blog/druk-czinnikiv/"
				}
			},
			{
				label: "printing-checks-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/pechat-chekov/",
					en: "https://h-profit.com/blog/en/printing-checks/",
					ua: "https://h-profit.com/blog/druk-chekiv/"
				}
			},
			{
				label: "how-import-products-my-store-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-importirovat-tovary-i-klienty-iz-moj-sklad-v-hugeprofit/",
					en: "https://h-profit.com/blog/en/how-to-import-products-and-customers-from-my-warehouse-to-hugeprofit/",
					ua: "https://h-profit.com/blog/1237-2/"
				}
			},
			{
				label: "how-import-products-cloudshop-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-importirovat-tovary-i-klientov-iz-cloudshop-v-hugeprofit/",
					en: "https://h-profit.com/blog/en/how-to-import-products-and-customers-from-cloudshop-to-hugeprofit/",
					ua: "https://h-profit.com/blog/yak-importuvati-tovari-z-cloudshop-u-hugeprofit/"
				}
			},
			{
				label: "uploading-products-xml-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/zagruzka-tovarov-v-xml//",
					en: "https://h-profit.com/blog/en/uploading-products-to-xml/",
					ua: "https://h-profit.com/blog/zavantazhennya-tovariv-u-xml/"
				}
			},
		]
	},
	shipments: {
		label: 'shipments',
		links: [
			{
				label: "shipments-creation-design-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/postavka-sozdanie-i-oformlenie/",
					en: "https://h-profit.com/blog/en/shipments-creation-and-design/",
					ua: "https://h-profit.com/blog/postavka-stvorennya-ta-oformlennya/"
				}
			},
			{
				label:"how-keep-different-multicurrency-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-vesti-uchet-tovarov-s-zakupkoj-v-raznoj-valyute/",
					en: "https://h-profit.com/blog/en/how-to-keep-records-of-goods-purchased-in-different-currencies/",
					ua: "https://h-profit.com/blog/multivalyutnist/"
				}
			}
		]
	},
	inventory: {
		label: 'inventory',
		links: [
			{
				label: "how-conduct-inventory-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-provesti-inventarizacziyu-v-servise-hugeprofit/",
					en: "https://h-profit.com/blog/en/how-to-conduct-an-inventory-in-the-hugeprofit-service/",
					ua: "https://h-profit.com/blog/yak-provesti-inventarizacziyu-v-servisi-hugeprofit/"
				}
			}
		]
	},
	sales: {
		label: 'sales',
		links: [
			{
				label: 'add-sale-doc',
				link: {
					ru: "https://h-profit.com/blog/ru/dobavlenie-prodazhi-zavershennaya-prodazha/",
					en: "https://h-profit.com/blog/en/add-sale-completed-sale/",
					ua: "https://h-profit.com/blog/dobavlenie-prostoj-prodazhi/"
				}
			},
			{
				label: "adding-sale-reserving-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/dobavlenie-prodazhi-rezervirovanie-tovara/",
					en: "https://h-profit.com/blog/en/dodavannya-prodazhu-rezervuvannya-tovaru/",
					ua: "https://h-profit.com/blog/rezervirovanie-tovara/"
				}
			},
			{
				label: "addition-sale-delivery-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/dostavka-nalozhennyj-platezh-2/",
					en: "https://h-profit.com/blog/en/addition-of-sale-delivery-nova-poshta-as-the-main-example-of-delivery-service/",
					ua: "https://h-profit.com/blog/dostavka-nalozhennyj-platezh/"
				}
			},
			{
				label: "sales-return-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/vozvrat-prodazhi-2/",
					en: "https://h-profit.com/blog/en/sales-return/",
					ua: "https://h-profit.com/blog/vozvrat-prodazhi/"
				}
			},
			{
				label: "delete-edit-sale-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/udalenie-i-redaktirovanie-prodazh/",
					en: "https://h-profit.com/blog/en/delete-and-edit-a-sale/",
					ua: "https://h-profit.com/blog/prodazh-dodavannya-vidalennya-redaguvannya/"
				}
			},
			{
				label: "sales-section-statuses-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/razdel-prodazhi-znachenie-statusov/",
					en: "https://h-profit.com/blog/en/sales-section-meaning-of-statuses/",
					ua: "https://h-profit.com/blog/rozdil-prodazhi-znachennya-statusiv/"
				}
			},
			{
				label: "creation-ttn-service-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/sozdanie-ttn-novoj-pochty-vnutri-servisa/",
					en: "https://h-profit.com/blog/en/creation-of-ttn-of-nova-pochti-within-the-service/",
					ua: "https://h-profit.com/blog/stvorennya-ttn-novoyi-pochti-vseredini-servisu/"
				}
			},
		]
	},
	orders: {
		label: 'orders',
		links: [
			{
				label: "how-process-orders-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-obrabotat-postupivshie-zakazy/",
					en: "https://h-profit.com/blog/en/how-to-process-incoming-orders/",
					ua: "https://h-profit.com/blog/yak-obrobiti-zamovlennya-shho-nadijshli/"
				},
			},
			{
				label: "creation-ttn-service-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/sozdanie-ttn-novoj-pochty-vnutri-servisa/",
					en: "https://h-profit.com/blog/en/creation-of-ttn-of-nova-pochti-within-the-service/",
					ua: "https://h-profit.com/blog/stvorennya-ttn-novoyi-pochti-vseredini-servisu/"
				}
			},
		]
	},
	transactions: {
		label: 'transactions',
		links: [
			{
				label: "accounts-available-create-ones-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/scheta-kakie-iz-nih-dostupny-srazu-i-kak-sozdavat-novye/",
					en: "https://h-profit.com/blog/en/accounts-which-ones-are-available-immediately-and-how-to-create-new-ones/",
					ua: "https://h-profit.com/blog/rahunok-yaki-z-nih-dostupni-vidrazu-i-yak-stvoryuvati-novi-rahunki/"
				}
			},
			{
				label: "adding-inflow-funds-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/dobavlenie-postupleniya-sredstv/",
					en: "https://h-profit.com/blog/en/adding-inflow-of-funds/",
					ua: "https://h-profit.com/blog/dodavannya-nadhodzhennya-koshtiv/"
				}
			},
			{
				label: "transfer-funds-between-accounts-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/perevod-sredstv-mezhdu-schetami/",
					en: "https://h-profit.com/blog/en/transfer-of-funds-between-accounts/",
					ua: "https://h-profit.com/blog/perekaz-koshtiv-mizh-rahunkami/"
				}
			},
			{
				label: "correct-accounts-functionality-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/pravilnoe-ispolzovanie-funkczionala-schetov-v-servise-hugeprofit/",
					en: "https://h-profit.com/blog/en/proper-use-of-the-account-functionality-in-the-hugeprofit-service/",
					ua: "https://h-profit.com/blog/pravylne-vykorystannya-funkczionalu-rahunkiv-u-servisi-hugeprofit/"
				}
			},
			{
				label: "adding-costs-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/dobavlenie-rashodov/",
					en: "https://h-profit.com/blog/en/adding-costs/",
					ua: "https://h-profit.com/blog/dodavannya-vitrat/"
				}
			},
		]
	},
	clients: {
		label: 'clients',
		links: [
			{
				label: "client-base-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/klientskaya-baza/",
					en: "https://h-profit.com/blog/en/client-base/",
					ua: "https://h-profit.com/blog/kli%d1%94ntska-baza/"
				}
			},
			{
				label: "discount-system-customers-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/sistema-skidok-dlya-klientov/",
					en: "https://h-profit.com/blog/en/discount-system-for-customers/",
					ua: "https://h-profit.com/blog/systema-znizhok-dlya-kliyentiv/"
				}
			},
			{
				label: "how-use-chats-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-polzovatsya-chatami/",
					en: "https://h-profit.com/blog/en/how-to-use-chats/",
					ua: "https://h-profit.com/blog/yak-korystuvatys-chatamy/"
				},
			}
		]
	},
	production: {
		label: "production",
		links: [
			{
				label: "how-track-production",
				link: {
					ru: "https://h-profit.com/blog/ru/kak-vesti-uchet-proizvodstva-v-programme-hugeprofit/",
					en: "https://h-profit.com/blog/en/how-to-keep-track-of-production-in-the-hugeprofit-program/",
					ua: "https://h-profit.com/blog/virobnicztvo/"
				}
			}
		]
	},
	'report-products': {
		label: "report-products",
		links: [
			{
				label: "access-settings-users-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/nastrojki-dostupa-drugim-polzovatelyam-administrator-kassir-kladovshhik/",
					en: "https://h-profit.com/blog/en/access-settings-for-other-users-administrator-cashier-storekeeper/",
					ua: "https://h-profit.com/blog/nalashtuvannya-dostupu-inshim-koristuvacham/"
				}
			},
			{
				label: "function-calculating-sales-employees-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/funkcziya-nachisleniya-proczentov-s-prodazh-naemnym-rabotnikam/",
					en: "https://h-profit.com/blog/en/the-function-of-calculating-interest-on-sales-to-employees/",
					ua: "https://h-profit.com/blog/narahuvannya-z-prodazhiv-praczivnykam/"
				}
			},
		]
	},
	"payment-setting": {
		label: "payment-setting",
		links: [
			{
				label: "bonus-system-doc",
				link: {
					ru: "https://h-profit.com/blog/ru/sistema-bonusov-v-servise-hugeprofit-i-kak-ona-rabotaet/",
					en: "https://h-profit.com/blog/en/bonus-system-in-the-hugeprofit-service-and-how-it-works/",
					ua: "https://h-profit.com/blog/systema-bonusiv-u-servisi-hugeprofit-ta-yak-vona-praczyuye/"
				}
			}
		]
	}
}


const DocumentationModal = ({sectionID, onClose}) => {
	return (
		<Box>
			<Heading>{T('how-work-section?')}</Heading>
			<ul className={s.list_link}>
				{documentation[sectionID].links.map(link => (
					<li className={s.link} key={link.label}>
						<a href={link.link[UserStore.getLang()]} target="_blank" rel="noopener noreferrer">
							{T(link.label)}
						</a>
					</li>))}
			</ul>
			<div align='right'>
				<Button onClick={onClose}>{T('close')}</Button>
			</div>
		</Box>
	);
};

export default DocumentationModal;