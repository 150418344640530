import React, { useEffect, useState } from "react";
import { Box } from "react-bulma-components";
import User from "../../user/user-store";
import { redirectTo } from "../../tools/redirect-to";
import { connectToStores } from "../../tools/reflux-tools";
import OrdersStore from "./orders-store";
import SalesActions from "../sales/sales-actions";
import ClientsActions from "../clients/clients-actions";
import OrdersActions from "./orders-actions";
import FilterPanel from "./common/filter-panel";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import ReportOrder from "./common/report-order";
import ReportRefund from "./common/report-refund";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import ClientsStore from "../clients/clients-store";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";

const Orders = () => {
  const {isMobileSize} = useIsMobile()
  const [isActiveTab, setIsActiveTab] = useState("all");

  const handleChangeTab = (tab) => {
    setIsActiveTab(tab);
    OrdersActions.filter("status", tab);
  };

  useEffect(() => {
    OrdersActions.filter("status", "all");
    SalesActions.loadChannels();
    ClientsActions.load();
    OrdersActions.loadStatus();
    OrdersActions.load();

    const changeStatus = OrdersActions.updateStatusOrder.completed.listen(
      () => {
        OrdersActions.load();
      }
    );

    const deleteOrder = OrdersActions.delete.completed.listen(() => {
      OrdersActions.load();
    });

    const confirmSale = SalesActions.add.completed.listen(() => {
      OrdersActions.load();
    });

    OrdersActions.loadReserveWaiting()

    return () => {
      changeStatus();
      confirmSale();
      deleteOrder();
      OrdersStore.clearFilter();
    };
  }, []);

  return User.getModuleEnable("orders") ? (
    <div className="orders-page">
      <div align='left' className="margin-bottom-10">
        <DocumentationButton sectionID='orders'/>
      </div>
      <DynamicTabList
        list={OrdersStore.getTabs()}
        onChangeTab={handleChangeTab}
      />
      {!isMobileSize && <FilterPanel tab={isActiveTab} />}
      {/*<div*/}
      {/*  className="ex-open-modal-wrapper display-flex-row flex-wrap"*/}
      {/*  style={{ justifyContent: "flex-end" }}*/}
      {/*>*/}
      {/*  <Button*/}
      {/*    color="success"*/}
      {/*    className="display-flex-row"*/}
      {/*    onClick={() => AppStore.openModal(<CreateOrderModal />)}*/}
      {/*  >*/}
      {/*    <FontAwesomeIcon icon="plus-circle" />*/}
      {/*    {T("add-order")}*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <Box className="orders-report border-not-top" >
        {isMobileSize && <FilterPanel tab={isActiveTab} />}
        {isActiveTab !== 'refund' && <ReportOrder/>}
        {isActiveTab === 'refund' && <ReportRefund/>}
      </Box>
    </div>
  ) : (
    redirectTo("/access-denied")
  );
};

export default connectToStores(Orders, { _: OrdersStore, ClientsStore });
