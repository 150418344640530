import React from "react";
import { Button } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import T from "../../../components/i18n";
import AppStore from "../../../app-store";
import AddExpensesModal from "../../expenses/add-expenses-modal";
import AddFundsModal from "../../expenses/add-funds-modal";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import AddExpensesCategoryModal
  from "../../expenses/add-expenses-category-modal";

const ActionsButton = () => {
  const {isMobileSize} = useIsMobile()
  const handleAddExpenses = () => {
    AppStore.openModal(<AddExpensesModal className='modal-start'/>);
  };

  const handleAddFounds = () => {
    AppStore.openModal(<AddFundsModal className='modal-start'/>);
  };

  const handleAddExpensesCategory = () => {
    AppStore.openModal(<AddExpensesCategoryModal modal="add-expenses-category"/>)
  }

  const size_button = isMobileSize ? "small" : "medium";

  return (
    <div
      className="ex-open-modal-wrapper display-flex-row flex-wrap"
      style={{ justifyContent: "flex-end" }}
    >
      <Button
        color="danger"
        rounded
        size={size_button}
        className="display-flex-row"
        onClick={handleAddExpenses}
      >
        <FontAwesomeIcon icon="minus-circle" size="1x" />
        {T("add-expenses-btn")}
      </Button>
      <Button
        color="success"
        rounded
        size={size_button}
        className="display-flex-row"
        onClick={handleAddFounds}
      >
        <FontAwesomeIcon icon="plus-circle" />
        {T("add-funds-btn")}
      </Button>
      <Button
        color="info"
        rounded
        size={size_button}
        className="display-flex-row"
        onClick={handleAddExpensesCategory}
      >
        <FontAwesomeIcon icon="plus-circle" />
        {T("add-category-btn-label")}
      </Button>
    </div>
  );
};

export default ActionsButton;
