import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from 'tools/redirect-to'

import { 
    Field, 
    Control, 
    Radio,
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Actions from './warehouse-actions' 
import WarehouseStore from './warehouse-store' 
import AppStore from 'app-store';
import User from 'user/user-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import ProductsStore from 'modules/products/products-store';
import Notification from 'react-bulma-components/lib/components/notification';
import NumberInput from "../../components/number-input/number-input";


class AddWarehouseModal extends Component {
    constructor(props) {
        super(props);

        let data = {}
        if(props.data) {
          data = props.data
        }
        if(props.id) {
          const getData = WarehouseStore.getWarehouseById(props.id)
          if(getData) {
            data = getData
          }
        }


        this.state = {
          wid: props.id || data.id || null,
          edit: props.edit || false,
          name: {
                error: "",
                value: data.name || ""
          },
          position: {
            value: data.position || ""
          },
          warehouseType: (data.w_type ? data.w_type+"" : data.w_type) || "1",
          close: false,
          newWhId: ""
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }
      if (typeof(this.listenDelete) == "function") {
          this.listenDelete();
      }
    }

  componentDidMount() {
        this.listenAdd =  Actions.addWarehouse.completed.listen((response) => {
          if (!this.state.edit) {
            this.setState({ close: true, newWhId: response.warehouse_id });
            ProductsStore.setState({
            'tab': response.warehouse_id 
            })
          }
          AppStore.closeModal();
          if (User.getOnBoaringStep() === 1) {
            User.setOnBoaringStep(2);
        }
        });
    
    this.listenDelete = Actions.deleteWarehouse.completed.listen((response) => {
            if (response['result'] === "not_empty") {
                this.setState({
                    message: T('you-cant-delete-not-empty-warehouse')
                });
                setTimeout(() => {this.setState({message: null})}, 3000);
            }
          if (response['result'] === true) {
            ProductsStore.setState({ 'tab': 'all' })
            this.setState({ close: true});
            AppStore.closeModal();
          }
        });
    }
  
    submit() {
      if(!this.state.name.value.trim()) {
        this.setState({
          name: {
            error: 'not all fields required',
          },
          message: T('not all fields required')
        })
      } else {
        Actions.addWarehouse(this.state);
      }
    }
  
    delete(wid) {
        const getWarehouseList = WarehouseStore.getList()
        if(getWarehouseList.length === 1) {
          this.setState({
            message: T('delete-the-last-warehouse')
          })
          return;
        }
        confirmAlert({
          title: T('confirm-delete'),
          message: T('are-you-sure-to-delete-this-warehouse'),
          buttons: [
            {
              label: T('delete'),
              onClick: () => Actions.deleteWarehouse(wid)
            },
            {
              label: T('no-delete'),
              onClick: () => {}
            }
          ]
        });
    }

    onChangeRadio(evt) {
      const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        this.setState({
          [evt.target.name]: value,
        });

    }
    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }
  
    getMessage() {
      return (
              <Notification color="danger">
                    {this.state.message}
                    <Button remove onClick={() => this.setState({message: null})} />
              </Notification>
        )
    }

    render() {
        return (
          <>
            {this.state.message ? this.getMessage() : null}
            <Box>
                <Field>
                  <Control>
                    <Label>{T('warehouse-name')}</Label>
                    <Input  size="large" 
                          name="name" type="text" 
                          placeholder={T('warehouse-name-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.name.error) ? "danger" : "primary"}
                          value={this.state.name.value}
                          />
                  </Control>
                </Field>
              <Field>
                <Control>
                  <Label>{T('position')}</Label>
                  <NumberInput
                    name='position'
                    onChange={this.onChange}
                    value={this.state.position.value}
                  />
                </Control>
              </Field>
                <Field className="radio-button">
                    <Label>{T('warehouse-type')}</Label>
                    <Control>
                      <Radio onChange={this.onChangeRadio} checked={this.state.warehouseType === "1"} value={"1"} name="warehouseType">
                        {T('residue-control')}
                      </Radio>  <br />
                      <Radio onChange={this.onChangeRadio} checked={this.state.warehouseType === "3"} value={"3"} name="warehouseType">
                        {T('residue-control-negative')}
                      </Radio> <br />
                      <Radio onChange={this.onChangeRadio} checked={this.state.warehouseType === "2"} value={"2"} name="warehouseType" >
                        {T('excluding-balances')}
                      </Radio>
                    </Control>
                </Field>
                <Button.Group>
                  <Button size="large" rounded color="primary"
                    onClick={this.submit}
              >{T(this.state.edit ? 'edit-warehouse-btn-label' : 'add-warehouse-btn-label')}</Button>
              {this.state.edit ? <Button size="large" rounded color="danger" icon={<FontAwesomeIcon icon="trash-alt" size="1x" />}
                        onClick={() => this.delete(this.state.wid)}
                      >{T('delete')}</Button> : null}
                  <Button size="large" rounded onClick={this.props.onClose}>{T('close')}</Button>
                </Button.Group>
              {this.state.close ? redirectTo(`/products${this.state.newWhId && "/"+this.state.newWhId}`) : null}
            </Box>
          </>
        );
    }

}

export default connectToStores(AddWarehouseModal, { _: WarehouseStore });
