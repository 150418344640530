import React, {useEffect, useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import { Button, Heading } from "react-bulma-components";
import T from "../../../components/i18n";
import { Checkbox, Field } from "react-bulma-components/lib/components/form";
import UserStore from "../../../user/user-store";
import SubmitButton from "../../../components/submit-button";
import ErrorMessageControlHook
  from "../../../components/error-message-block/error-message-control.hook";
import UserActions from "../../../user/user-actions";
import {errorHandler} from "../../../tools/error-handler";

const NotificationSettingModal = ({settingsUrl ,onClose }) => {
  const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()
  const [settings, setSettings] = useState(UserStore.getMoreSetting(settingsUrl || 'tg_sub_setting') || {
    orders: false,
    sales: false,
    expenses: false,
    send_old_messages: false,
    "3days_delivery": false,
    new_chat: false,
  });
  const [waiting, setWaiting] = useState(false)

  const handleChangeCheckbox = ({ target: { name, checked } }) => {
    setSettings((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    setWaiting(true)
    UserStore.setMoreSetting(settings, settingsUrl || 'tg_sub_setting')
  }

  useEffect(() => {
    const addSettings = UserActions.updateSettings.completed.listen(() => {
      onClose()
    })
    const addSettingsFail = UserActions.updateSettings.failed.listen((res) => {
      setWaiting(false)
      const message = errorHandler(res)
      handleAutoRemoveError(message)
    })

    return () => {
      addSettings()
      addSettingsFail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
      <Box>
        <Heading size={3}>{T("setting-notifications")}</Heading>
        <div className="display-flex-column">
          {UserStore.getModuleEnable('orders') && <Field>
            <Checkbox
              name="orders"
              onChange={handleChangeCheckbox}
              checked={settings["orders"]}
            >
              {T("notification-orders")}
            </Checkbox>
          </Field>}
          {UserStore.getModuleEnable('sales') && <Field>
            <Checkbox
              name="sales"
              onChange={handleChangeCheckbox}
              checked={settings["sales"]}
            >
              {T("notification-sales")}
            </Checkbox>
          </Field>}
          {UserStore.getModuleEnable('payments') && UserStore.getModuleEnable('transactions') && <Field>
            <Checkbox
              name="expenses"
              onChange={handleChangeCheckbox}
              checked={settings["expenses"]}
            >
              {T("notification-expenses")}
            </Checkbox>
          </Field>}
          {UserStore.getModuleEnable("chats") && <>
            <Field>
              <Checkbox
                name="new_chat"
                onChange={handleChangeCheckbox}
                checked={settings["new_chat"]}
              >
                {T("notification-new-chat")}
              </Checkbox>
            </Field>
            <Field>
              <Checkbox
                name="send_old_messages"
                onChange={handleChangeCheckbox}
                checked={settings["send_old_messages"]}
              >
                {T("notification-send-old-messages")}
              </Checkbox>
            </Field>
          </>}
          {UserStore.getModuleEnable('sales') && <Field>
            <Checkbox
              name="3days_delivery"
              onChange={handleChangeCheckbox}
              checked={settings["3days_delivery"]}
            >
              {T("notification-3-days-delivery")}
            </Checkbox>
          </Field>}
        </div>
        <div
          className="display-flex-row margin-top-10"
          style={{ justifyContent: "flex-end" }}
        >
          <SubmitButton
            text='save-btn-label'
            size='medium'
            fullwidth={false}
            rounded color='success'
            submit={handleSubmit}
            waiting={waiting}
          />
          <Button onClick={onClose} size='medium' rounded>{T("close")}</Button>
        </div>
      </Box>
    </>
  );
};

export default NotificationSettingModal;
