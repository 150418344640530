import React from "react";
import T from "../../components/i18n";
import Level from "react-bulma-components/lib/components/level";
import EditButton from "./buttons/edit-button";
import RevertButton from "./buttons/revert-button";
import DeleteButton from "./buttons/delete-button";
import RegistrationButton from "./buttons/registration-button";
import PrintButton from "./buttons/print-button";
import {getWriteOffName} from "./utils/getWriteOffName";
import WarehouseStore from "../warehouse/warehouse-store";
import User from "../../user/user-store";
import WriteOffStore from "./write-off-store";
import ExpensesStore from "../expenses/expenses-store";

const WriteOffRowDesktop = ({data, onDelete, num}) => {
	const {id, from_marketplace_id, responsible_user_id, created_at, status, comment, category_id } = data

	const handleDelete = () => {
		onDelete(data)
	}

	let className = " is-one-";
	className += num % 2 === 0 ? "even" : "odd";

	return (
		<tr className={`is-size-7 ${className}`}>
			<td align="left" width="30%">
					{getWriteOffName({id, created_at})}<br />
					<b>{T("from-product-marketplace")}: </b>{WarehouseStore.getWarehouseName(from_marketplace_id)}<br />
					{comment && <><b>{T("comment")}:</b> {comment}<br/></>}
			</td>
			<td>
				{WriteOffStore.getNameWriteOffCategoryById(category_id)}<br/>
			</td>
			<td>
				<span className={data['status'] === 'completed' ? 'text-success' : 'text-warning'}><b>{T(data['status'])}</b><br /></span>
				<b>{T('write-off-label')} </b> {data['quantity']} {T('items-e')}<br />
				<b>{T('on-amount')}:</b> {data["amount"] || 0} {ExpensesStore.getAccountCurrency()}
		</td>
			<td>
					{User.getResponsebleName(responsible_user_id)}
			</td>
			<td align="right">
				<Level className="margin-top-5">
					<Level.Side align="left">
							<RegistrationButton status={status} data={data}/>
							{status === 'pending' ? (
									<>
										<EditButton data={data}/>
										<DeleteButton onClick={handleDelete}/>
									</>
								) : <RevertButton data={data}/>
							}
							<PrintButton id={id}/>
					</Level.Side>
				</Level>
			</td>
		</tr>)
};

export default WriteOffRowDesktop;