import React, { Component } from 'react';
import T from "components/i18n";
import Section from 'react-bulma-components/lib/components/section';
import Button from 'react-bulma-components/lib/components/button';
import User from 'user/user-store';
import Money from "components/money";
 import DOM from "react-dom-factories";
import OpenInsideModal from "components/modal/open-inside-modal";
import VideoHelpModal from "public/info/wl-name/video/";
import { Link } from "react-router-dom";
import OpenModalButton from "components/modal/open-modal-button";
import AddWarehouseModal from 'modules/warehouse/add-warehouse-modal';
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";
import WarehouseStore from "../../warehouse/warehouse-store";
import AppStore from "../../../app-store";
import AddExpensesModal from "../../expenses/add-expenses-modal";


class OnBoardingBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	
        };
    }

    getClassName(step) {
        const current_step = User.getOnBoaringStep();
        let className = "";
        if (current_step >= step) {
            className += " active"
        } else if (current_step === (step-1)) {
            className  = " next_step";
        }

        return className;
    }

    renderDesktop() {
        const step = User.getOnBoaringStep();
        return (
                <ul className="progressbar">
                      <li className={this.getClassName(1)} >
                        <OpenInsideModal size="small" renderAs="a" color="danger" text={T('see-video')}>
                            <VideoHelpModal video="https://www.youtube.com/embed/cV-65bc1Ogk" />
                        </OpenInsideModal>
                        <br />
                        <b> {T('bonus')}: <Money amount={User.getOnBoaringStepCurrency(1)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b>
                      </li>
                <li className={this.getClassName(2)} >
                    {/* <Link to="/products/add-warehouse">{T('change-warehouse')}</Link> */}
                     <OpenInsideModal size="small" renderAs="a" text={T('change-name-store')}>
                            <AddWarehouseModal id={WarehouseStore.getDefaultWarehouse()}/>
                    </OpenInsideModal>
                    <br /> <b> <Money amount={User.getOnBoaringStepCurrency(2)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b></li>
                      <li className={this.getClassName(3)} ><Link to="/products">{T('add-new-product-btn')} </Link> <br /><b> <Money amount={User.getOnBoaringStepCurrency(3)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b></li>
                      <li className={this.getClassName(4)}><Link to="/sales">{T('add-sale-btn')}</Link> <br /> <b> <Money amount={User.getOnBoaringStepCurrency(4)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /> </b></li>
                      <li className={this.getClassName(5)}><Button style={{fontSize: 'inherit', color: 'currentColor'}} onClick={() => AppStore.openModal(<AddExpensesModal className='modal-start'/>)} className="button-link-without-style">{T('add-expenses-btn')}</Button> <br /> <b> <Money amount={User.getOnBoaringStepCurrency(5)} currency={User.getPaymentCurrency()}  wrapper={DOM.span} /></b></li>
                      <li>
                        <OpenModalButton size="small" text={T('get-bonus')} rounded link="/settings/subscribe" disabled={step !== 5 ? true : false} color="success" />
                                                   <br /><br />
                      </li>
                </ul>
            );
    }

    renderMobile() {   
       const step = User.getOnBoaringStep();

      let button_text = T('get-bonus')+" "+User.getOnBoaringStepCurrency(5)+User.getCurrency(User.getPaymentCurrency());

      button_text += ". "+T('step')+" "+step+"/6";

    	return (
            <Section className="onbording">
                <Button.Group align="center">
                    <OpenModalButton size="small" link="/start" color="warning" text={T('how-to-start')} /> 
                    <OpenModalButton size="small" link="/start/onboarding" color="success" text={button_text} /> 
                </Button.Group>
            </Section>
    		);

    }

                // <ul className="progressbar">
                        // <OpenInsideModal size="small" className={step >= 1 ? "active" : ""} renderAs="li" color="danger" text={User.getOnBoaringStepCurrency(1)} icon={['fab', 'youtube']}>
                        //     <VideoHelpModal video="https://youtu.be/eY6HRe5utBc" />
                        // </OpenInsideModal>
                //       <Link to="/warehouse"><li className={step >= 2 ? "active" : ""} >{User.getOnBoaringStepCurrency(2)}</li></Link>
                //       <Link to="/products"><li className={step >= 3 ? "active" : ""} >{User.getOnBoaringStepCurrency(3)}</li> </Link> 
                //       <Link to="/sales"><li className={step >= 4 ? "active" : ""} >{User.getOnBoaringStepCurrency(4)}</li></Link> 
                //       <Link to="/expenses"><li className={step >= 5 ? "active" : ""} >{User.getOnBoaringStepCurrency(5)}</li></Link> 
                //       <li>
                //         <OpenModalButton size="small" text={T('get-bonus')} rounded link="/settings" disabled={step != 5 ? true : false} color="success" />
                //       </li>
                // </ul>

    render() {
        if (!User.isOnBoarding()) {
            return null;
        }

        return this.props.isMobileSize ? this.renderMobile() : this.renderDesktop();
    }

}

export default withIsMobileSize(OnBoardingBar);

