import React from "react";
import { LocalIcon } from "whitelables/wl-name/icons";
import { connectToStores } from "../../../tools/reflux-tools";
import NotificationStore from "../notification-store";
import { useHistory } from "react-router-dom";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import Badge from "../../../components/Badge";

const NotificationIcon = ({list, style, size}) => {
  const {isMobileSize} = useIsMobile()
  const history = useHistory();
  const isReadMessageLength = list?.filter(
    (item) => !item?.is_read
  )?.length;

  const handleNavigateNotification = () => {
    if (isMobileSize) {
      history.push("notification");
    }
  };
  return (
    <Badge label={isReadMessageLength} hidden={!isReadMessageLength}>
      <span onClick={handleNavigateNotification} style={style}>
        <LocalIcon icon="bell-notification" size={size || "large"} />
      </span>
    </Badge>
  );
};

export default connectToStores(NotificationIcon, { _: NotificationStore });
