import React from "react";
import { Link } from "react-router-dom";
import T from "../../../components/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import AddFundsModal from "../../expenses/add-funds-modal";
import AddExpensesModal from "../../expenses/add-expenses-modal";
import Actions from "../../expenses/expenses-actions";
import { Button } from "react-bulma-components";
import AppStore from "../../../app-store";
import User from "../../../user/user-store";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const ActionButtons = ({ expense, isDeleted }) => {
  const {isMobileSize} = useIsMobile()
  const handleInfoDelete = () => {
    confirmAlert({
      title: "",
      message: T("you-can-delete"),
      buttons: [
        {
          label: T("understand"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleEditFunds = (data) => {
    AppStore.openModal(
      <AddFundsModal
        className='modal-start'
        fund_id={data["id"]}
        category_id={data["category_id"]}
        comment={data["name"]}
        amount={data["amount"]}
        account={data["account_id"]}
        date={data["created_at"]}
      />
    );
  };

  const handleEditExpenses = (data) => {
    AppStore.openModal(
      <AddExpensesModal
        className='modal-start'
        expense_id={data["id"]}
        category_id={data["category_id"]}
        shipment_id={data["shipment_id"]}
        comment={data["name"]}
        amount={data["amount"]}
        type={data["types"].toString()}
        account={data["account_id"]}
        date={data["created_at"]}
      />
    );
  };

  const handleDeleteExpenses = (_id) => {
    confirmAlert({
      title: T("confirm-delete"),
      message: T("are-you-sure-to-delete-this-expenses"),
      buttons: [
        {
          label: T("delete"),
          onClick: () => Actions.deleteExpenses(_id),
        },
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteExpensesFee = (_id) => {
    confirmAlert({
      title: T("confirm-delete"),
      message: T("confirm-edit-delete-expense-fee"),
      buttons: [
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  };

  if(expense && expense?.name === "channel_fee_sale") {
    return <div align={isMobileSize ? "right" : "left"}>
      <Link to="#" onClick={handleDeleteExpensesFee} title={T("delete")}>
        <FontAwesomeIcon icon="trash-alt" size={isMobileSize ? "2x" : "1x"}/>
      </Link>
    </div>
  }

  const handleDeleteExpensesCategory2 = () => {
    confirmAlert({
      title: T("confirm-delete"),
      message: T("confirm-edit-delete-expense-category-delivery"),
      buttons: [
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  };

  if(expense?.category_id === 2) {
    return <div align={isMobileSize ? "right" : "left"}>
      <Link to="#" onClick={handleDeleteExpensesCategory2} title={T("delete")}>
        <FontAwesomeIcon icon="trash-alt" size={isMobileSize ? "2x" : "1x"}/>
      </Link>
    </div>
  }

  return expense && User.getModuleEnable("payments") ? (
    <div
      className="display-flex-row"
      style={isMobileSize ? {justifyContent: "space-evenly"} : {}}
    >
      <Button
        title={T('edit-btn')}
        className="button-link-without-style"
        onClick={() => {
          expense["is_receipt"]
            ? handleEditFunds(expense)
            : handleEditExpenses(expense);
        }}
      >
        <FontAwesomeIcon icon="edit" size={isMobileSize ? "2x" : "1x"} />
      </Button>
      <Button
        title={T('delete')}
        className="button-link-without-style"
        onClick={() => handleDeleteExpenses(expense["id"])}
      >
        <FontAwesomeIcon icon="trash-alt" size={isMobileSize ? "2x" : "1x"} />
      </Button>
    </div>
  ) : !isDeleted ? (
    <div align={isMobileSize ? "right" : "left"}>
      <Link to="#" onClick={handleInfoDelete} title={T("delete")}>
        <FontAwesomeIcon icon="trash-alt" size={isMobileSize ? "2x" : "1x"} />
      </Link>
    </div>
  ) : null;
};

export default ActionButtons;
