import React, {useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import {
	Control,
	Field,
	Input,
	Label, Radio
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import ActionsModal from "./actions-modal";
import Icon from "react-bulma-components/lib/components/icon";
import ErrorMessageBlock
	from "../../../../components/error-message-block/error-message-block";
import NumberInput from "../../../../components/number-input/number-input";

const PriceTagSettingPrintModal = ({data, onSubmit, onClose}) => {
	const [format, setFormat] = useState(data?.format ? data.format : 'termo')
	const [sizePrint, setSizePrint] = useState(data?.size ? data.size : {
		width: null,
		height: null
	})
	const [quantity, setQuantity] = useState(data.quantity === 0 ? 0 : 1)
	const [margin, setMargin] = useState(data.margin || 0)
	const [errorMessage, setErrorMessage] = useState(null)
	const handleAutoRemoveErrorMessage = (message) => {
		setTimeout(() => setErrorMessage(null), 4000)
		setErrorMessage(message)
	}
	const handleChangeQuantity = (evt) => {
		const value = evt.target.value
		setQuantity(value)
	}

	const handlerErrorMessage = () => {
		let errorMessage

		if(sizePrint.width >= 10 || sizePrint.height >=10) {
			errorMessage = T('price-tag-error-size')
		}
		if(!sizePrint.width || !sizePrint.height) {
				errorMessage = T('not all fields required')
		}

		if(errorMessage) {
			handleAutoRemoveErrorMessage(errorMessage)
			return false
		}
		return true
	}

	const handleChangeFormat = (evt) => {
		setFormat(evt.target.value)
	}

	const handleChangeInput = (evt) => {
		const name = evt.target.name
		const value = evt.target.value

		setSizePrint(prevState => ({
			...prevState,
			[name]: Math.abs(value)
		}))
	}
	const handleChangeMargin = ({target: {value}}) => {
		setMargin(value)
	}

	const handleSubmit = () => {
		const data = {
			page: {
				format,
				size: sizePrint,
				quantity: Number(quantity),
			},
		}

		if(format === "A4") {
			data.page.margin = margin || 0
		}

		if(handlerErrorMessage()) {
			if(onSubmit) onSubmit(data)
			if(onClose) onClose()
		}

	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading>{T('price-tag-setting-print')}</Heading>
				<Field>
					<Label size='large'>{T('size-pricetag')}</Label>
					<Field>
						<Label size='small'>{T('width')}</Label>
						<Control iconRight>
							<Input size="large"
										 name="width"
										 autoComplete="nope"
										 type="number"
										 min="0"
										 onChange={handleChangeInput}
										 value={sizePrint.width || ''}
							/>
							<Icon align="right" style={{top: 0}}>
								<span className="small-text">{T('cm-short')}</span>
							</Icon>
						</Control>
					</Field>
					<Field>
						<Label size='small'>{T('heigth')}</Label>
						<Control iconRight>
							<Input size="large"
										 name="height"
										 autoComplete="nope"
										 type="number"
										 min="0"
										 onChange={handleChangeInput}
										 value={sizePrint.height || ''}
							/>
							<Icon align="right" style={{top: 0}}>
								<span className="small-text">{T('cm-short')}</span>
							</Icon>
						</Control>
					</Field>
				</Field>
				<Field>
					<Label size='large'>{T('price-tag-format-print')}</Label>
					<Field className='display-flex-row'>
							<Control>
								<Radio
									name='format'
									className="setting-additional-checkbox"
									value='termo'
									onChange={handleChangeFormat}
									checked={format === 'termo'}
								>
									{T('thermal-printing')}
								</Radio>
							</Control>
							<Control>
								<Radio
									name='format'
									className="setting-additional-checkbox"
									value='A4'
									onChange={handleChangeFormat}
									checked={format === 'A4'}
								>
									A4
								</Radio>
							</Control>
					</Field>
					{format === 'A4' && <>
						<Field>
							<Label>{T('a4-margin-2sm')}</Label>
							<Control iconRight>
								<NumberInput
									max={2}
									name='margin'
									onChange={handleChangeMargin}
									value={margin}
								/>
								<Icon align="right" style={{top: 0}}>
									<span className="small-text">{T('cm-short')}</span>
								</Icon>
							</Control>

						</Field>
					</>}
				</Field>
				<Field>
					<Label>{T('pricetags-quantity')}</Label>
					<small>{T('pricetags-quantity-desk')}</small>
					<Control>
						<Input size="large"
									 name="quantity"
									 autoComplete="nope"
									 type="number"
									 min="0"
									 onChange={handleChangeQuantity}
									 value={quantity}
						/>
					</Control>
				</Field>
				<ActionsModal onSubmit={handleSubmit} onClose={onClose}/>
			</Box>
		</>
	);
};

export default PriceTagSettingPrintModal;