import React, { useEffect } from "react";
import PaginationBlock from "../../components/pagination";
import Actions from "./transactions-actions";
import TransactionsStore from "./transactions-store";
import Reports from "../../components/reports";
import TransactionsRowMobile from "./mobile/transactions-row-mobile";
import TransactionsRowDesktop from "./desktop/transactions-row-desktop";
import EmptyMessage from "./common/empty-message";
import { Box } from "react-bulma-components";
import FilterPanel from "./common/filter-panel";
import { connectToStores } from "../../tools/reflux-tools";
import TransactionsActions from "./transactions-actions";
import TransactionTabs from "./common/transaction-tabs";
import ActionButtons from "./common/action-buttons";
import ExpensesStore from "../expenses/expenses-store";
import ExpensesActions from "../expenses/expenses-actions";
import ErrorMessageControlHook from "../../components/error-message-block/error-message-control.hook";
import User from "../../user/user-store";
import { redirectTo } from "../../tools/redirect-to";
import Account from "./common/account/account";
import TransactionsStatistics from "./transactions-statistics";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";
let COLUMNS = [
  "date",
  "transactions-type",
  "account",
  "amount",
  "balance",
  "commission",
  "comment",
  "reports-actions",
];

const Transactions = (props) => {
  const {isMobileSize} = useIsMobile()
  const transactions = TransactionsStore.getTransactions();
  const transactionsForStatistics = TransactionsStore.getList();
  const {
    errorMessage,
    handleAutoRemoveError,
    setErrorMessage,
    ErrorMessageBlock,
  } = ErrorMessageControlHook();

  useEffect(() => {
    TransactionsActions.load();
    ExpensesActions.load();

    return () => {
      TransactionsStore.clearFilter();
    };
  }, []);

  const statisticsAccount = {};
  transactionsForStatistics
    .filter(TransactionsStore.applyFilter)
    .forEach((item) => {
      const accountId = item.account_id;
      const expense = item.amount <= 0 ? item.amount : 0;
      const coming = item.amount >= 0 ? item.amount : 0;
      const orderID = item.order_id;
      if (item.expenses_id) {
        const getExpenses = ExpensesStore.getExpensesById(item.expenses_id);
        if (!getExpenses) return;
      }


      if (!statisticsAccount[accountId]) {
        if (orderID) {
          statisticsAccount[accountId] = {
            period_last: item.balance,
            period_first: item.balance,
            expense: 0,
            coming: item.amount,
            count: 1,
            commission: item.commission,
          };
        } else {
          statisticsAccount[accountId] = {
            period_last: item.balance,
            period_first: item.balance,
            expense,
            coming,
            count: 1,
            commission: item.commission,
          };
        }
      } else {
        if (orderID) {
          statisticsAccount[accountId].period_first = item.balance;
          statisticsAccount[accountId].coming += item.amount;
          statisticsAccount[accountId].count += 1;
          statisticsAccount[accountId].commission += item.commission;
        } else {
          statisticsAccount[accountId].period_first = item.balance;
          statisticsAccount[accountId].expense += expense;
          statisticsAccount[accountId].coming += coming;
          statisticsAccount[accountId].count += 1;
          statisticsAccount[accountId].commission += item.commission;
        }
      }
    });


  const statisticsSumAccount = JSON.parse(
    JSON.stringify(Object.entries(statisticsAccount))
  ).reduce((acc, [keys, value]) => {
    const getAccount = ExpensesStore.getAccountById(keys);

    if (acc[getAccount.currency_id]) {
      acc[getAccount.currency_id].period_first += value.period_first;
      acc[getAccount.currency_id].period_last += value.period_last;
      acc[getAccount.currency_id].expense += value.expense;
      acc[getAccount.currency_id].coming += value.coming;
      acc[getAccount.currency_id].count += value.count;
      acc[getAccount.currency_id].commission += value.commission;
    } else {
      acc[getAccount.currency_id] = value;
    }
    return acc;
  }, {});

  if (!User.getPermissionEnable("showbalance")) {
    COLUMNS = COLUMNS.filter((item) => item !== "balance");
  }

  if (!User.getPermissionEnable("show-cashflow")) {
    COLUMNS = COLUMNS.filter((item) => item !== "amount");
  }

  return User.getModuleEnable("transactions") ? (
    <div className="transactions-report">
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      {!isMobileSize && <TransactionTabs/>}
      {!isMobileSize && <FilterPanel />}
      <Account
        onErrorMessage={handleAutoRemoveError}
        statisticsAccount={statisticsAccount}
      />
      <TransactionsStatistics statistics={statisticsSumAccount} />
      <div className='display-flex-row-gap flex-wrap' style={{alignItems: 'center'}}>
        <DocumentationButton sectionID='transactions'/>
        {User.getModuleEnable("payments") && <ActionButtons />}
      </div>
      {isMobileSize && <TransactionTabs/>}
      <Box style={{ overflow: "auto" }} className='border-not-top'>
        {isMobileSize && <FilterPanel />}
        <PaginationBlock
          setPage={Actions.setPage}
          totalPage={TransactionsStore.get("count_page")}
          currentPage={TransactionsStore.get("page")}
        />

        <Reports
          {...props}
          load={Actions.load}
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          rows={transactions}
          isLoading={TransactionsStore.get("isLoaded")}
          isSortingColumns={[
            true,
            false,
            false,
            true,
            true,
            User.getPermissionEnable("show-cashflow"),
            false,
            false,
          ]}
          sortActions={(value) => TransactionsStore.onFilter("sort", value)}
          minWidthList={COLUMNS.length * 100}
        >
          {isMobileSize ? (
            <TransactionsRowMobile role="row" />
          ) : (
            <TransactionsRowDesktop role="row" />
          )}
        </Reports>

        <PaginationBlock
          setPage={Actions.setPage}
          totalPage={TransactionsStore.get("count_page")}
          currentPage={TransactionsStore.get("page")}
        />
      </Box>
    </div>
  ) : (
    redirectTo("/access-denied")
  );
};

export default connectToStores(Transactions, {
  _: TransactionsStore,
  exp: ExpensesStore,
});
