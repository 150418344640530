import React, {useEffect, isValidElement} from "react";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import UserStore from "../../../user/user-store";
import NotificationActions from "../notification-actions";
import {confirmDelete} from "../../../tools/confirm-delete";
import {parseJSON} from "../../../tools/error-handler";
import AppStore from "../../../app-store";
import SaleViewModal from "../../sales/modal/sale-view/sale-view-modal";
import NotificationStore from "../notification-store";
import StockMarketplacesViewModal
  from "../../history/stock-marketplaces/stock-marketplaces-view-modal";

const NotificationModal = ({ name, message, type, source, id, isRead, onClose }) => {
  let sourceName = null

  const sourceData =  source?.split("-");
  if (sourceData && sourceData[0] === "integration") {
    let integration = UserStore.getIntegrationNameById(sourceData[1]);
    if(integration['integration_id']) {
      sourceName = T('integration-'+integration['integration_id']);
    }
  }

  const handleDelete = () => {
    confirmDelete(()=> NotificationActions.removeMessage(id))
  }

  useEffect(() => {
    const removeMessage = NotificationActions.removeMessage.completed.listen(() => {
      onClose()
    })

    if(!isRead) {
      if(type === 'news') {
        NotificationStore.setReadNew(id)
      } else {
        NotificationActions.readMessage(id)
      }
    }

    return () => {
      removeMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  let messageText = T(message)

  const messageParse = parseJSON(message);

  if(typeof messageParse === 'object') {
    if(messageParse.type === 'sale') {
       messageText = <div>{T('paid-storage-coming-soon')}: {messageParse.id.map((item, index) => <React.Fragment key={item.id}><Button onClick={() => AppStore.openModal(<SaleViewModal saleID={item.id}/>)} className='button-link-without-style'>{item.on}</Button>{index < messageParse.id.length - 1 && ', '}</React.Fragment>)}</div>
    } else if(messageParse.type === 'history-stock') {
      messageText = <div>ID: <Button className='button-link-without-style' onClick={() => AppStore.openModal(<StockMarketplacesViewModal id={messageParse.id}/>)}>{messageParse.id}</Button></div>
    }
  }

  return (
    <Box>
      <Heading>{T(name)}</Heading>
      <Heading subtitle>{T(sourceName)}</Heading>
      {isValidElement(messageText) ? messageText : (
        <div dangerouslySetInnerHTML={{__html: messageText}}
             style={{whiteSpace: 'pre-wrap'}}/>
      )}
      <Button.Group position="right">
        <Button color="light" onClick={onClose}>
        {T("close")}
        </Button>
        {type !== 'news' && <Button color="danger" onClick={handleDelete}>{T("delete")}</Button>}
      </Button.Group>
    </Box>
  );
};

export default NotificationModal;
