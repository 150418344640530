import React from "react";
import { Field, Label } from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import SelectComponent from "../../../../tools/select-component";
import RadioButtonsListControl from "../../../../components/radio-buttons-list/radio-button-list-control";

const list = [
  { id: "name", name: "name" },
  { id: "category", name: "product-category" },
  { id: "brand", name: "product-brand" },
  { id: "date", name: "date" },
  { id: "price", name: "product-info-price" },
  { id: "instock", name: "on-availability" },
];

const sortList = [
  { title: "ASC", value: "asc" },
  { title: "DESC", value: "desc" },
];

const SortingFilter = ({
  valueRadio,
  selectValue,
  onChangeSelect,
  onChangeRadio,
}) => {
  return (
    <Field>
      <Label>{T("sorting-field-to")}</Label>
      <SelectComponent
        value={selectValue}
        list={() => list}
        onChange={onChangeSelect}
        empty="select-sorting-field"
      />
      {!!selectValue.value && (
        <RadioButtonsListControl
          propsWrapper={{
            className: 'disabled-margin-radio'
          }}
          list={sortList}
          name="sort"
          onChange={onChangeRadio}
          valueRadio={valueRadio}
        />
      )}
    </Field>
  );
};

export default SortingFilter;
