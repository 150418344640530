import React from 'react'
import List from "react-bulma-components/lib/components/list";
import T from "../../components/i18n";
import InfoWrapper from "../../components/info-wrapper";
import Money from "../../components/money";
import DOM from "react-dom-factories";

const ListMonth = ({title, info, items}) => {
	return (
		<List>
			<List.Item className='bold' style={{textAlign: 'left'}}>
				{info ? (
					<InfoWrapper info={info}>
						<span style={{fontSize: 14}}>{T(title)}</span>
					</InfoWrapper>
				) : (
					<span style={{fontSize: 14}}>{T(title)}</span>
				)}
			</List.Item>
			{items.map((item, index) => !item.disabled && (
				<List.Item key={`${index}-${item.title}`}>
					<div className='display-flex-row' style={{alignItems: 'center'}}>
						<span style={{flex: 1, textAlign: 'left'}} className='silver'>
							{T(item.title)}
						</span>
						{item.info ? (
							<InfoWrapper
								info={T(item.info)}>
								<b style={{fontSize: 14}}>
									{item.type === 'text' ? item.amount : <Money amount={item.amount || 0} wrapper={DOM.span} className={item.className}/>}
								</b>
							</InfoWrapper>
						) : (
							<b style={{fontSize: 14}}>
								{item.type === 'text' ? item.amount : <Money amount={item.amount || 0} wrapper={DOM.span} className={item.className}/>}
							</b>
						)}
					</div>
				</List.Item>
			))}

		</List>
	);
};

export default ListMonth;