import React from 'react'
import {Box, Button} from "react-bulma-components";
import T from "../../../components/i18n";
import AppStore from "../../../app-store";
import NotificationSettingModal from "./notification-setting-modal";

const NotificationSubscribe = () => {
	return (
		<Box>
			<div className='display-flex-center'>
				<Button color='info' rounded onClick={() => AppStore.openModal(<NotificationSettingModal settingsUrl='push_sub_setting'/>)} >{T('setting-push-notifications')}</Button>
			</div>
		</Box>
	);
};

export default NotificationSubscribe;