import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";

import ClientsStore from './clients-store'
import AddClientModal from "./modal/add-client-modal/add-client-modal";

import OpenModal from 'components/modal/modal';
import { redirectTo } from 'tools/redirect-to'

class EditClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {        
        if (!this.props.location.state) {
            this.setState({
                close: true
            })
           return false;
        }
        const {cid} = this.props.location.state;



        let client;
        if (cid) {
            client = ClientsStore.getClientById(cid);
            if(!client) {
              return redirectTo("/clients")
            }
        } else {
          return redirectTo("/clients")
        }

        if (!client) {
            this.setState({
                close: true
            })
           return false;
        }

        let backModal = e => {
          window.history.back();
        }

        return (
        <>
          <OpenModal modal={{ closeOnBlur: false }} close={backModal}>
              <AddClientModal
                className='modal-start'
              	edit={true}
              	data={client}
                client_id={cid}
              	/>
          </OpenModal>
          {this.state.close ? redirectTo("/clients") : ""}
          </>
        );
    }

}


export default connectToStores(EditClientModal, { 
    clietns: ClientsStore 
  });