import Actions from './write-off-actions';
import { createStore } from "../../tools/reflux-tools";
import yajax from "yajax";
import UserStore from "../../user/user-store";

const WriteOffStore = createStore({
	listenables: Actions,
	_prefix: '/bproducts/',
	state: {
		write_off: null,
		write_off_category: null,
		isLoadingWriteOff: false,
		isLoadingCategory: false
	},

	get(field) {
		return this.state[field];
	},

	getWriteOffList() {
		const writeOff = this.get('write_off')

		if(writeOff) {
			return writeOff
		} else {
			Actions.load()
		}
	},

	onAdd(data) {
		console.log("start add write off")

		yajax.post(this._prefix+"write_off", JSON.stringify(data)).then(
			Actions.add.completed, Actions.add.failed
		);
	},
	onAddCompleted(data) {
		Actions.load()
		console.log("result add write off: ",data)
	},
	onAddFailed() {
		console.log('result failed add write off')
	},

	onDelete(id) {
		const params = {
			method: "DELETE",
			url: this._prefix+"write_off",
			data: {type: 'write_off', wr_id: id}
		};

		yajax(params).then(
			Actions.delete.completed, Actions.delete.failed
		);
	},
	onDeleteCompleted() {
		Actions.load()
		console.log('result delete write off')
	},
	onDeleteFailed() {
		console.log('result delete write off failed')
	},

	getCategoryList() {
		const categoryList = this.get('write_off_category')

		if(categoryList) {
			return categoryList
		} else {
			Actions.loadCategory()
		}
	},

	getWriteOffCategoryForSelect() {
		const data = this.get('write_off_category')
		return data?.map(item => ({id: item.id, name: item.name}))
	},

	getNameWriteOffCategoryById(id) {
		const category = this.get('write_off_category')

		if(category) {
			return category.find(item => item.id === id)?.name
		} else {
			this.setState({
				write_off_category: []
			})
			Actions.loadCategory()
		}
	},

	onAddCategory({name, id}) {
		this.setState({
			isLoadedAddCategory: true
		})
		const data = {name}
		if(id) data.id = id

		yajax.post(this._prefix+'write_off/category', data).then(
			Actions.addCategory.completed, Actions.addCategory.failed
		)

	},

	onAddCategoryCompleted() {
		console.log('add category completed')
		Actions.loadCategory()
	},

	onAddCategoryFailed() {
		console.log('add category failed')
	},

	onDeleteCategory(data) {
		const params = {
			method: "DELETE",
			url: this._prefix+"write_off/category",
			data
		}
		yajax(params).then(
			Actions.deleteCategory.completed, Actions.deleteCategory.failed
		)
	},
	onDeleteCategoryCompleted() {
		console.log("delete write off category completed")
		Actions.loadCategory()
	},
	onDeleteCategoryFailed(res) {
		this.errorHandler(res)
		console.log('delete write off category failed')
	},


	onLoadCategory(){
		if(!this.state.isLoadingCategory) {
			yajax.get(this._prefix+'write_off/category').then(
				Actions.loadCategory.completed, Actions.loadCategory.failed
			)
			this.setState({
				isLoadingCategory: true
			})
		}

	},
	onLoadCategoryCompleted(data) {
		this.setState({
			write_off_category: data.data,
			isLoadingCategory: false
		})
	},
	onLoadCategoryFailed() {
		this.setState({
			isLoadingCategory: false
		})
		console.log('load write off  category failed')
	},

	onLoad() {
		if(!this.state.isLoadingWriteOff) {
			yajax.get(this._prefix+"write_off").then(
				Actions.load.completed, Actions.load.failed
			);
			this.setState({
				isLoadingWriteOff: true
			})
		}

	},
	onLoadCompleted(data) {
		const permissionWarehouse = UserStore.getPermissionWarehouse()

		const filterWithWarehouse = data['data'].filter(item => {
			let result = true
			if(permissionWarehouse?.length) {
				result = result && permissionWarehouse.some(permissionID => permissionID === item?.from_marketplace_id)
			}

			return result
		})


		this.setState({
			write_off: filterWithWarehouse,
			isLoadingWriteOff: false
		})
	},
	onLoadFailed() {
		this.setState({
			isLoadingWriteOff: false
		})
		console.log('load list write off failed')
	},
})

export default WriteOffStore