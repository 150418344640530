import React, { useEffect } from "react";
import s from "./styles.module.scss";
import { Button } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import ChatsStore from "../../chats-store";
import MessageList from "../message/message-list";
import ChatsActions from "../../chats-actions";
import ButtonArrow from "../common/button-arrow";
import ClientsStore from "../../../clients/clients-store";
import {useHistory} from "react-router-dom";

const ActiveChat = ({
  id,
  onIsActiveClient,
  onIsActiveUsers,
  isActiveUsers,
}) => {
  const [userChat] = ChatsStore.getChatsById(id);
  const history = useHistory()
  const name =
    userChat && userChat?.name !== "  "
      ? userChat?.name
      : userChat?.remote_client_name;

  const getClient = userChat?.client_id && ClientsStore.getClientById(userChat.client_id)

  useEffect(() => {
    if (id) {
      ChatsActions.loadMessages(id);
    }
    return () => {
      ChatsStore.clearMessage();
    };
  }, [id]);

  return (
    <div className={s.active_chat_wrapper}>
      <div className={s.header_chat}>
        <div className="display-flex-row" style={{gap: 10}}>
          <ButtonArrow
            onClick={onIsActiveUsers}
            orientation={isActiveUsers ? "left" : "right"}
          />
          <p
            className="bold font-size-120 uppercase">{getClient ? getClient.simple_name : name}</p>
        </div>
        <div className='display-flex-row' style={{gap: 10}}>
          <Button className="button-link-icon" onClick={onIsActiveClient}>
            <LocalIcon icon="client-info" size='large'/>
          </Button>
          <Button className='button-link-icon' style={{color: 'red'}}
                  onClick={() => history.push("/chats")}>
            <LocalIcon icon="close-fill" size='large'/>
          </Button>
        </div>
      </div>
      <MessageList
        remoteChatId={userChat?.remote_chat_id}
        userId={userChat?.user_id}
        activeChatId={id}
        integration_id={userChat?.integration_id}
      />
    </div>
  );
};

export default ActiveChat;
