import React from 'react'
import FacebookLogin from "react-facebook-login";
import AppStore from "../../../../../app-store";
import UserActions from "../../../../../user/user-actions";
import Facebook from "./facebook";
import UserStore from "../../../../../user/user-store";
import {createMobileAppLink} from "../../../../../tools/create-mobile-app-link";
import T from '../../../../../components/i18n'

const FacebookButton = ({wooID, id, buttonName}) => {
	const responseFacebook = (response) => {
		if (response.accessToken) {
			let data = {
				integration_id: wooID,
				api_key: response.accessToken,
				userID: response.userID,
				ident: JSON.stringify({
					userID: response.userID,
				}),
			};

			if(id) data.id = id

			UserActions.updateIntegrations(data);
		}
	};

	const handleOpenModal = () => {
		if(!id) {
			AppStore.openModal(<Facebook wooID={wooID} modal='integrations'/>)
		}
	}

	const handleClickButtonMobile = (evt) => {
			createMobileAppLink(`/facebook-connect?token=${decodeURIComponent(UserStore.getToken())}`, evt)
	}

	return (
		<>
			{AppStore.isMobileApp() ? (
				<>
					<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
				<a
					className='my-facebook-button-class'
					onClick={handleClickButtonMobile}
					href={`/facebook-connect?token=${decodeURIComponent(UserStore.getToken())}`}>
					<i className='fa fa-facebook'/>
					{buttonName || "Login with Facebook"}
				</a>
				</>
				) : (
				<FacebookLogin
				appId={AppStore.isEnableForWhiteLable("facebook-app")}
				autoLoad={false}
				fields="name,email,picture"
				cssClass="my-facebook-button-class"
				scope="business_management,instagram_basic,pages_read_engagement,pages_show_list,pages_messaging,pages_manage_metadata,instagram_manage_messages,instagram_manage_comments"
				callback={responseFacebook}
				icon="fa-facebook"
				onClick={handleOpenModal}
				textButton={buttonName && T(buttonName)}
		/>
	)
}

</>
)
	;
};

export default FacebookButton;