import React, {useEffect, useState} from 'react'
import HistoryStore from "../history-store";
import {connectToStores} from "../../../tools/reflux-tools";
import {Box, Button, Heading, List} from "react-bulma-components";
import Loader from "react-loader-spinner";
import Tag from "../../../components/tag";
import T from '../../../components/i18n'
import ChannelBlock from "../../../components/channel-block/channel-block";
import SalesStore from "../../sales/sales-store";
import {parseJSON} from "../../../tools/error-handler";
import StockMarketplaceProductRow from "./stock-marketplace-product-row";
import usePaginationHook from "../../../tools/pagination-hook";
import HistoryActions from "../history-actions";
import {
	isMoreThanXMinutesOld
} from "../../../tools/is-more-than-minutes-old/is-more-than-minutes-old";
import SubmitButton from "../../../components/submit-button";

const StockMarketplacesViewModal = ({id, onClose}) => {
	const [data, setData] = useState(null)
	const {end,start,page,total,PaginationBlock,setPage} = usePaginationHook({listLength: data ? data.stock.length : []})
	const [waiting, setWaiting] = useState(false)

	const handleRepeatDownload = () => {
		setWaiting(true)
		HistoryActions.repeatStockMarketplaces(data.id)
	}

	useEffect(()=>{
		HistoryActions.loadStockMarketplacesById(id)

		const load = HistoryActions.loadStockMarketplacesById.completed.listen(res => {
			if(res && Array.isArray(res.data)) {
				setData(res.data[0])
			}
		})

		const loadRepeat = HistoryActions.repeatStockMarketplaces.completed.listen(() => {
			onClose()
		})

		return () => {
			load()
			loadRepeat()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[id])

	if(!data) {
		return <div className='display-flex-center'><Loader type="Rings" color="#00BFFF" height={80} width={80} /></div>
	}

	const statusInfo = HistoryStore.getStatusMarketplace(data.status)
	const channel_id = SalesStore.foundChannelsByIntegrationId(data?.integration_id)

	const parseErrors = parseJSON(data.errors || [])
	const globalErrors = parseErrors.filter(item => !item.pid)

	return data && (
		<Box style={{position: 'relative'}}>
			<div className='display-flex-row-gap'>
				<Heading>ID: {data.id}</Heading>
				<Tag name={statusInfo.name} color={statusInfo.background}/>
			</div>
			<List>
				<List.Item><span className='silver'>{T('reports-date')}: </span> <b>{data.created_at}</b></List.Item>
				<List.Item><span className='silver'>{T('update-label')}: </span> <b>{data.updated_at}</b></List.Item>
				<List.Item><span className='silver'>{T('channel')}: </span><ChannelBlock channel_id={channel_id}/></List.Item>
				<List.Item><span className='silver'>{T('products-request')}: </span><b>{(data.stock.length || data.count_products) ? (data.stock.length || data.count_products) : '-'}</b></List.Item>
				{!!globalErrors && !!globalErrors.length && <List.Item>
					<div className='display-flex-column has-text-danger' style={{padding: '10px'}}>
					{globalErrors.map((item, index) => <div
						key={`global-errors-${index}`}>{T(item.error)}</div>)}
					</div>
				</List.Item>}
			</List>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
			{data.stock.slice(start, end).map((item, index) => <StockMarketplaceProductRow key={`product-${item.product_id}-${index}`}
																													data={item} errors={data?.errors}/>)}
			<br/>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
			<div className='display-flex-row' style={{position: "sticky",bottom: 0, background: '#fafafa', justifyContent: 'flex-end', padding: '10px'}}>
				<Button onClick={onClose} size='medium' rounded>{T('close')}</Button>
				{(data.status === 2 || (data.status === 0 && isMoreThanXMinutesOld(data.created_at, 5))) && <SubmitButton size='medium' fullwidth={false} waiting={waiting} text='retry-upload-label' submit={handleRepeatDownload}/>}
			</div>
		</Box>
	);
};

export default connectToStores(StockMarketplacesViewModal, {HistoryStore});