import React, { useEffect, useState } from "react";
import { parsePhoneNumber } from "../../../../tools/parse-phone-number";
import AppStore from "../../../../app-store";
import UserStore from "../../../../user/user-store";
import ChatsStore from "../../../chats/chats-store";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import { Box, Button, Heading } from "react-bulma-components";
import T from "../../../../components/i18n";
import SelectComponents from "../../../../tools/select-component";
import SubmitButton from "../../../../components/submit-button";
import Loader from "react-loader-spinner";

const RingostatModal = ({ phone, onClose }) => {
  // {value: workers[0]?.id, label: workers[0]?.name}

  const [workers, setWorkers] = useState([]);
  const [loginWorker, setLoginWorker] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [waitingLoadWorkers, setWaitingLoadWorkers] = useState(true);

  const { setErrorMessage, ErrorMessageBlock, errorMessage } =
    ErrorMessageControlHook();

  const handleChangeWorkers = (selectValue) => {
    setLoginWorker(selectValue);
  };

  const handleSubmit = () => {
    const authMessage = {
      action: "createCall",
      workerLogin: loginWorker.value,
      phone: phone,
    };
    if (phone) {
      setWaiting(true);
      ChatsStore.socketRingostat.send(JSON.stringify(authMessage));
    }
  };

  useEffect(() => {
    const listener = (event) => {
      const message = JSON.parse(event.data);

      if (message.forAction === "callAnswer") {
        onClose();
      }
      if (message.forAction === "callStop") {
        setWaiting(false);
      }
      if (message.forAction === "getOnlineUsers") {
        const workersList = UserStore.getRingostatWorkersBySelectByRid(
          UserStore.get("realId")
        );
        const onlineWorkers = workersList.filter((item) =>
          message.online_workers.includes(item.workerID)
        );
        if(onlineWorkers.length > 0) {
          setWorkers(onlineWorkers)
          setLoginWorker({value: onlineWorkers[0].id, label: onlineWorkers[0].name})
        }
        setWaitingLoadWorkers(false);
      }
    };

    if (ChatsStore.socketRingostat) {
      ChatsStore.socketRingostat.addEventListener("message", listener);

      ChatsStore.socketRingostat.send(
        JSON.stringify({ action: "getOnlineUsers" })
      );
    }
    return () => {
      ChatsStore.socketRingostat.removeEventListener("message", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading size={3}>{T("creating-a-call")}</Heading>
        {!waitingLoadWorkers && !!workers.length && (
          <SelectComponents
            onChange={handleChangeWorkers}
            value={loginWorker}
            list={() => workers}
            label="operator"
            placeholder="select-an-operator"
          />
        )}
        {waitingLoadWorkers && (
          <div className='display-flex-center'>
            <Loader
              type="Circles"
              className="button_loader"
              color="#00BFFF"
              height={30}
              width={30}
            />
          </div>
        )}
        {!waitingLoadWorkers && !workers.length && (
          <Box>
            <Heading align="center">{T("no-operators-available")}</Heading>
          </Box>
        )}
        <div
          className="display-flex-row"
          style={{ justifyContent: "flex-end" }}
        >
          <SubmitButton
            text="create-call"
            color="info"
            size="medium"
            waiting={waiting}
            fullwidth={false}
            submit={handleSubmit}
          />
          <Button onClick={onClose} size="medium">
            {T("close")}
          </Button>
        </div>
      </Box>
    </>
  );
};

const RingostatCreateCall = ({ phone }) => {
  const parsePhone = parsePhoneNumber(phone);

  return (
    <img
      className="cursor-pointer"
      src="/img/integrations/ringostat-phone.svg"
      style={{ width: "20px", height: "20px" }}
      alt={`ringostat-${parsePhone}`}
      onClick={() =>
        AppStore.openModal(
          <RingostatModal phone={parsePhone} modal="integrations" />
        )
      }
    />
  );
};

export default RingostatCreateCall;
