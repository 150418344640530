import React, { useState } from "react";
import FinanceCategoryFilter from "./finance-category-filter";
import StatisticsBlock from "../../../components/statistics-block";
import Money from "../../../components/money";
import ExpensesStore from "../../expenses/expenses-store";
import Reports from "../../../components/reports";
import FinanceCategoryRowDesktop from "./finance-category-row-desktop";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../components/i18n";
import { connectToStores } from "../../../tools/reflux-tools";
import User from "../../../user/user-store";
import ActionButtons from "./actions-button";
import { filterSearch } from "../../../tools/filters-helper";
import ExpensesActions from "../../expenses/expenses-actions";
import FinanceCategoryRowMobile from "./finance-category-row-mobile";
import usePaginationHook from "../../../tools/pagination-hook";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DynamicTabList
  from "../../../components/dynamic-tab-list/dynamic-tab-list";

const emptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

let COLUMNS = [
  "name",
  "Expenses",
  "receipts",
  "count-transaction",
  "reports-actions",
];

const FinanceCategory = ({defaultTab,onChangeTab,listTabs}) => {
  const {isMobileSize} = useIsMobile()
  const [searchValue, setSearchValue] = useState("");

  const listCategory = ExpensesStore.getAllCategory(true);
  const getExpenses = ExpensesStore.getExpenses();

  const list = Object.values(listCategory).filter((item) => {
    const data = {
      name: T(item.name),
    };
    return filterSearch(searchValue, ["name", "id"], data);
  });

  const expensesWithCategory = getExpenses.filter((item) => {
    return list.find((category) => category.id === item.category_id);
  });
  const statistics =
    ExpensesStore.getCalcStatisticsExpenses(expensesWithCategory);

  const { end, start, total, page, setPage, PaginationBlock } =
    usePaginationHook({ listLength: list.length });

  const listStatistics = [
    {
      value: Object.entries(statistics.sumExpensesByCurrency).length
        ? Object.entries(statistics.sumExpensesByCurrency).map(
            ([currency, value]) => (
              <Money
                key={`${currency}-expenses-all`}
                amount={value}
                currency={currency}
              />
            )
          )
        : "-",
      name: "Expenses",
    },
    {
      value: Object.entries(statistics.sumReceiptsByCurrency).length
        ? Object.entries(statistics.sumReceiptsByCurrency).map(
            ([currency, value]) => (
              <Money
                key={`${currency}-receipts-all`}
                amount={value}
                currency={currency}
              />
            )
          )
        : "-",
      name: "receipts",
    },
    { value: statistics.count, name: "count-transaction" },
  ];

  const handleSort = (value) => {
    ExpensesActions.filter("sortCategory", value);
  };

  list.sort((a, b) => {
    const [sort] = Object.entries(ExpensesStore.getFilter("sortCategory"));
    if (sort) {
      const [key, value] = sort;
      if (value === 2) return 0;
      if (key === "name") {
        if (value === 1) {
          if (T(a.name) > T(b.name)) {
            return 1;
          } else {
            return -1;
          }
        }
        if (value === 0) {
          if (T(a.name) > T(b.name)) {
            return -1;
          } else {
            return 1;
          }
        }
      }
      const sortNumber = (a, b) => {
        if (value === 1) {
          return b - a;
        } else if (value === 0) {
          return a - b;
        }
      };

      if (key === "Expenses") {
        return sortNumber(
          a.sumExpensesByCurrency[User.get("currency_id")] || 0,
          b.sumExpensesByCurrency[User.get("currency_id")] || 0
        );
      }
      if (key === "receipts") {
        return sortNumber(
          a.sumReceiptsByCurrency[User.get("currency_id")] || 0,
          b.sumReceiptsByCurrency[User.get("currency_id")] || 0
        );
      }

      return 0;
    } else {
      return 0;
    }
  });

  return (
    <div>
      {!isMobileSize && (
        <FinanceCategoryFilter
          search={searchValue}
          setSearch={setSearchValue}
        />
      )}
      <StatisticsBlock list={listStatistics} />
      {User.getModuleEnable("payments") && <ActionButtons />}
      {isMobileSize && <DynamicTabList
        list={listTabs}
        onChangeTab={onChangeTab}
        defaultTab={defaultTab}
      />}
      <Box className='border-not-top'>
        {isMobileSize && (
          <FinanceCategoryFilter
            search={searchValue}
            setSearch={setSearchValue}
          />
        )}
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
        <Reports
          emptyMessage={emptyMessage}
          rows={list.slice(start, end)}
          columns={COLUMNS}
          isSortingColumns={[true, true, true]}
          sortActions={handleSort}
          isLoading={ExpensesStore.get('isStartLoaded')}
        >
          {isMobileSize ? (
            <FinanceCategoryRowMobile role="row" />
          ) : (
            <FinanceCategoryRowDesktop role="row" />
          )}
        </Reports>
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </Box>
    </div>
  );
};

export default connectToStores(FinanceCategory, { _: ExpensesStore });
