import React, { useState } from "react";
import { Button, List } from "react-bulma-components";
import T from "../../../components/i18n";
import ShipmentsStore from "../shipments-store";
import SupplierShipmentsList from "./supplier-shipments-list";
import AppStore from "../../../app-store";
import AddSupplierModal from "../add-supplier-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductsActions from "../../products/products-actions";
import {Label} from "react-bulma-components/lib/components/form";

const SupplierRowMobile = ({ data, num }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenShipments, setIsOpenShipments] = useState(false);
  const statistics = ShipmentsStore.getShipmentsStatisticsBySupplierId(data.id);
  const shipment = ShipmentsStore.getShipmentBySupplierId(data.id);


  const actionsButton = <div className='display-flex-row margin-bottom-10 margin-top-10'
                             style={{justifyContent: 'space-around'}}>
    <Button className='button-link-icon' onClick={(evt) => {
      evt.stopPropagation();
      AppStore.openModal(<AddSupplierModal id={data.id}/>
      )
    }}>
      <FontAwesomeIcon icon='edit' size='2x'/>
    </Button>
    <Button className='button-link-icon' onClick={(evt) => {
      evt.stopPropagation()
      ProductsActions.deleteCatalog('suppliers', data.id)
    }}>
      <FontAwesomeIcon icon='trash-alt' size='2x'/>
    </Button>
  </div>

  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";

  return (
      <tr className={className}>
        <td>
        <div style={{padding:'10px'}}>
          <div
            className='display-flex-row-gap'
            onClick={() => setIsOpen(!isOpen)}
          >
            <div align='left'>
              <Label size='large' className='margin-bottom-0'><b>{data.name}</b></Label>
              {data.phone && !isOpen && <span>+{data.phone}</span>}
            </div>
            {!isOpen && <div>
              <b>{T("supply")}</b>: {statistics.pendingCount}
              <br/>
              <b>{T("quantity-res-mob")}</b>: {statistics.completeCount}
            </div>}
          </div>
          {isOpen && (
            <div>
              <List align="left">
                <List.Item>
                  <b>{T("phone")}: </b> {data.phone ? `+${data.phone}` : "-"}
                </List.Item>
                <List.Item>
                  <b>{T("address")}: </b> {data.address ? data.address : "-"}
                </List.Item>
                <List.Item>
                  <b>{T("supply")}</b>: {statistics.pendingCount}
                </List.Item>
                <List.Item>
                  <b>{T("quantity-res-mob")}</b>: {statistics.completeCount}
                </List.Item>
                <List.Item>
                  <b>{T("comment")}: </b> {data.comment ? data.comment : "-"}
                </List.Item>
                <List.Item className="display-flex-row">
                  <b>{T("amount")}: </b>
                  <div className="display-flex-column">
                    {Object.entries(statistics.sumAmountByCurrency).map(
                      ([key, value]) => (
                        <span key={`amount-currency-${key}`}>
                          {value} {T(`currency-${key}`)}
                        </span>
                      )
                    )}
                    {!Object.entries(statistics.sumAmountByCurrency).length &&
                      "-"}
                  </div>
                </List.Item>
                <List.Item className="display-flex-row">
                  <b>{T("not-paid")}: </b>
                  <div className="display-flex-column">
                    {Object.entries(statistics.sumExpensesAmountByCurrency).map(
                      ([key, value]) => {
                        const calcNotPaid =
                          statistics.sumAmountByCurrency[key] - value;
                        return (
                          !!calcNotPaid && (
                            <span key={`expenses-amount-currency-${key}`}>
                              {statistics.sumAmountByCurrency[key] - value}{" "}
                              {T(`currency-${key}`)}
                            </span>
                          )
                        );
                      }
                    )}
                    {!Object.entries(statistics.sumExpensesAmountByCurrency)
                      .length && "-"}
                  </div>
                </List.Item>
              </List>
            </div>
          )}
          {isOpenShipments && isOpen && actionsButton}
          {isOpen && !!shipment.length && <div align='right'>
            <Button className='button-link-without-style'
                    onClick={() => setIsOpenShipments(!isOpenShipments)}>
              {isOpenShipments ? T("hide-more-filter") : T('view-shipments')}
            </Button>
          </div>}
          {isOpenShipments && isOpen &&
            <SupplierShipmentsList list={shipment}/>}
          {!isOpenShipments && isOpen && actionsButton}
        </div>
        </td>
      </tr>
  );
};

export default SupplierRowMobile;
