import React, { Component } from 'react';

import T from 'components/i18n';
// import Dropdown from 'react-bulma-components/lib/components/dropdown';
import DOM from "react-dom-factories";
import { connectToStores } from "tools/reflux-tools";
import OrdersStore from 'modules/orders/orders-store'
import UserStore from 'user/user-store';
import UserActions from 'user/user-actions';
import SalesActions from 'modules/sales/sales-actions';
import Money from "components/money";
import Navbar from 'react-bulma-components/lib/components/navbar';
import Button from 'react-bulma-components/lib/components/button';
import {
	NavLink, withRouter
} from "react-router-dom";
import Level from 'react-bulma-components/lib/components/level';
import AddExpensesModal from "modules/expenses/add-expenses-modal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OnBoardingBar  from 'modules/start/onboarding'
import AppStore from "app-store";
import { LocalIcon } from 'whitelables/wl-name/icons'
import ChatsStore from 'modules/chats/chats-store'
import NotificationIcon
	from "../../modules/notification/common/notification-icon";
import NotificationStore from "../../modules/notification/notification-store";
import {
	withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import Dropdown from "react-bulma-components/lib/components/dropdown";

class AccountInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {}
		this.UserLogout = this.UserLogout.bind(this);

	}
	getInfoBalance() {
		if (!UserStore.getPermissionEnable("showbalance")) return null;
		return (
			<strong>{T('balance')}: <Money amount={UserStore.getBalance() || 0} wrapper={DOM.span}/></strong>
		)
	}

	UserLogout(event) {
		UserActions.logout();

		event.preventDefault();
		event.stopPropagation();

		this.props.history.push('/')
		return false;
	}

	render() {
		if (window.innerWidth === 1024) return "";

		return (
			<div className="has-background-info-dark info-bar-mobile display-flex-row-gap" style={{alignItems: "center"}}>
					<Link to="/transactions">
						{this.getInfoBalance()}
					</Link>
				<div className="display-flex-row">
					{UserStore.isMainUser() && (
						<Level.Item className="start" style={{margin: 0}}>
							<Link to="/start">
								<LocalIcon icon="start-fill"/>
							</Link>
						</Level.Item>
						)
					}
					<Link to='/voting'>
						<LocalIcon icon="vote" size={"large"}/>
					</Link>
					<Link to="/notification">
						<NotificationIcon list={NotificationStore.get('messages')} />
					</Link>
					{AppStore.isEnableForWhiteLable('contacts') && <>
					{this.props.isMobileSize && AppStore.getWlName() === 'hugeprofit'
						? (
							<Dropdown onClick={e => e.stopPropagation()} className='disabled-default' right style={{marginTop: '-6px'}} label={<FontAwesomeIcon icon="comments" size="2x" />}>
								<Dropdown.Item value={T('contact-link')}>
									<Link onClick={this.props.toggleMenu} to={AppStore.getLink("public_contacts")} style={{padding: '10px',display: 'block' ,fontSize: '15px', width: '100%', color: 'currentColor'}}>
										{T('contact-link')}
									</Link>
								</Dropdown.Item>
								<Dropdown.Item value={T('online-chat')}>
									<div id='contact-us' onClick={this.props.toggleMenu} style={{padding: '10px', fontSize: '15px', width: '100%'}}>
										{T('online-chat')}
									</div>
								</Dropdown.Item>
							</Dropdown>
						) : (
							<Link to="/contacts">
								<FontAwesomeIcon icon="comments" size="2x" />
							</Link>)
					}
					</>}

					{AppStore.isMobileApp() ? (
							<FontAwesomeIcon
								onClick={() => UserStore.postMessage("open-url::"+AppStore.getDocsLink('docs'))}
								icon="question-circle"
								size="2x"
							/>)
						: (
							<Link target="_blank" to={{
								pathname: AppStore.getDocsLink('docs')
							}}>
								<FontAwesomeIcon icon="question-circle" size="2x" />
							</Link>
						)
					}
					<Link to="/settings">
						<FontAwesomeIcon icon="cog" size="2x" />
					</Link>
					<Link to="/" onClick={this.UserLogout}>
						<FontAwesomeIcon icon="power-off" size="2x" onClick={this.UserLogout}/>
					</Link>
				</div>
			</div>
		)
	}
}
class MobileTopBar extends Component {
	// 'warehouse', 'start',
	module_1 = ['dashboard', 'sales', 'orders', 'chats', 'clients'];
	module_2 = ['products', 'shipments', 'moving', 'write-off', 'inventory', 'production'];
	module_3 = ['transactions', 'reports', 'history'];
	module_4 = ['cashier-panel'];

	getMenuItem(name) {
		const link = "/" + name;

		let count = null;

		// if (name === "start" && !UserStore.isMainUser()) {
		//   return null;
		// }

		if (name === "reports" && !UserStore.getModuleEnable('reports')) {
			return null;
		}

		if (name === "orders" && !UserStore.isIntegrationEnable()) {
			return null;
		}

		if (name === "production" && !AppStore.isEnableForWhiteLable('production')) {
			return null;
		}

		if (name === "orders") {
			let statuces = OrdersStore.get("statuses");
			if (typeof(statuces['processing']) !== "undefined") {
				count = "("+statuces['processing']['count']+")";
			}
		}

		if (name === "chats" && !UserStore.isIntegrationChats()) {
			return null;
		}

		if (name === "cashier-panel" && (!UserStore.getModuleEnable('sales') || UserStore.isWarehouseMan() || AppStore.getWlName() === 'navkolo')) {
			return null;
		}

		let viewBox;
		if (name === "chats") {
			let not_read = ChatsStore.get("not_read");

			if (not_read > 0) {
				count = "("+not_read+")";
			}

			viewBox = "0 0 20 370";
		}

		let text = "";
		if (name === "transactions") {
			text = 'cash-flow'
		}

		return (
			UserStore.getModuleEnable(name) ?
				<Navbar.Item renderAs="span" key={name} className="grid-item">
					<NavLink activeClassName="sidebar-active" strict to={link}>
						<LocalIcon icon={name} width="20px" height="20px" viewBox={name === "chats" ? viewBox : false} size={name === 'cashier-panel' ? 'large' : ''}/>
						<p>{name === "transactions" ? T(text) : T(name)}&nbsp;{count}</p>
					</NavLink>
				</Navbar.Item>
				: ""
		);
	}

	getGroup(module) {
		let links = [];
		for (let name in module) {
			links.push(this.getMenuItem(module[name]));
		}
		return links
	}

	toggleMenu(e) {
		// if (e.target && e.target.className && e.target.className.baseVal.indexOf("fa-power-off") > -1) {
		//   return false;
		// }
		this.setState({
			navbar: !this.state.navbar
		})
	}

	getBalance() {
		return UserStore.getBalance()
	}

	getInfoBalance() {
		return (
			<strong>{T('balance')}: <Money amount={this.getBalance() || 0} wrapper={DOM.span}/></strong>
		)
	}

	logoClick() {
		if (AppStore.isMobileApp()) {
			console.log("CLICK APP ")
			window.location.reload()
			// window.location.href= AppStore.getLink("/");
		} else {
			window.location.href="/";
			setTimeout(() => window.location.reload(), 100);
		}
	}

	render() {
		let links_1 = this.getGroup(this.module_1);
		let links_2 = this.getGroup(this.module_2);
		let links_3 = this.getGroup(this.module_3);
		let links_4 = this.getGroup(this.module_4);

		// links.push(
		//     <Navbar.Item renderAs="span" key="account-info">
		//          <AccountInfo />
		//     </Navbar.Item>
		//   )

		const handleAddExpenses = () => {
			AppStore.openModal(<AddExpensesModal className='modal-start'/>);
		};

		return (
			<>
				<Navbar
					fixed="top"
					active={this.props.navbar}
					transparent={false}
				>
					<Navbar.Brand>
						<Navbar.Item renderAs="span" onClick={()=>this.logoClick()}>
							<img src={AppStore.getImagesPath("mobile_logo.png")} alt={AppStore.getAppName()} width="32" />
						</Navbar.Item>

						<Navbar.Item renderAs="span" className="">
							<Button.Group className='top-mobile-button-group'>
								{UserStore.getModuleEnable("sales") ?
									<Button size="small" rounded className={UserStore.getOnBoaringStep() === 3 ? "button_blink" : null} color="primary" onClick={() => SalesActions.openSaleModal()}>{T('add-sale-btn')}</Button>
									: null}

								{UserStore.getModuleEnable("payments") ?
									<Button size="small" rounded className={UserStore.getOnBoaringStep() === 4 ? "button_blink" : null} onClick={handleAddExpenses} color="danger">{T('add-expenses-btn')} </Button>

									: null}
							</Button.Group>
						</Navbar.Item>
						<Navbar.Burger onClick={this.props.toggleMenu}/>
					</Navbar.Brand>

					<Navbar.Menu  onClick={this.props.toggleMenu}>
						<Navbar.Container>
							<Navbar.Item renderAs="span" key="account-info">
								<AccountInfo {...this.props}/>
							</Navbar.Item>
						</Navbar.Container>
						<Navbar.Container>
							{links_1.find(item => !!item) && <Level className='navbar-level'>
								{links_1}
							</Level>}
							{links_2.find(item => !!item) && <Level className='navbar-level'>
								{links_2}
							</Level>}
							{links_3.find(item => !!item) && <Level className='navbar-level'>
								{links_3}
							</Level>}
							{links_4.find(item => !!item) && <Level className='navbar-level'>
								{links_4}
							</Level>}
						</Navbar.Container>
						<Navbar.Container position="end">

						</Navbar.Container>
					</Navbar.Menu>
				</Navbar>
				{this.props.location.pathname.indexOf("chats") === -1 && <OnBoardingBar />}
			</>
		)
	}
}

export default connectToStores(withIsMobileSize(withRouter(MobileTopBar)), {
	orders: OrdersStore
});
