import React, { useEffect, useRef, useState } from "react";
import s from "./styles.module.scss";
import { formatDateChat, getNamePhoto } from "../../utils";
import ChatsStore from "../../chats-store";
import { LocalIcon } from "whitelables/wl-name/icons";
import MessageItemImage from "./message-item-image";
import MessageItemDocument from "./message-item-document";
import MessageItemVideo from "./ message-item-video";
import {parseJSON} from "../../../../tools/error-handler";
import T from '../../../../components/i18n'
import Loader from "react-loader-spinner";
// import {Button} from "react-bulma-components";

const imageSource = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".webp",
  ".svg",
  ".tiff",
  ".bmp",
];

const videoSource = ['.mp4', '.avi', '.mkv', '.flv', '.wmv', '.webm', '.mpeg', '.mpg']

const urlModification = (url) => {
  if (typeof url === "string") {
    const extension = url.split(".").pop().toLowerCase();
    const splitUrl = url.split("/").pop();
    const isImage = imageSource.includes(`.${extension}`);
    const isVideo = videoSource.includes(`.${extension}`)

    if (isImage) {
      return {
        type: "image",
        url,
        name: splitUrl,
      };
    } else if(isVideo) {
      return {
        type: 'video',
        url,
        name: splitUrl,
      }
    } else {
      return {
        type: "document",
        url,
        name: splitUrl,
      };
    }
  }
};


const loc = window.location;
let url = "";

if (loc.host === "localhost:3000") {
  url = "https://ac1.h-profit.com/";
}

const MessageItem = ({ data, userId, remoteChatId, registerRef, scrollToMessage, loadMessage }) => {
  let message = data.message;
  const refDate = useRef();
  const [widthDate, setWidthDate] = useState(54);

  useEffect(() => {
    const updateSize = () => {
      if (refDate.current) {
        const rect = refDate.current.getBoundingClientRect();
        const width = rect.width;
        setWidthDate(width);
      }
    };

    updateSize();

    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  if (message.indexOf("<photo") > -1) {
    const [type, url] = getNamePhoto(data.message);

    if (url) {
      message = (
        <img
          style={{ maxWidth: "400px", width: "100%" }}
          alt={url}
          src={
            type === "id" ? ChatsStore.getImage(userId, remoteChatId, url) : url
          }
          border="0"
        />
      );
    }
  }

  const getArrayFilesWithInfo = JSON.parse(data.files || "[]").map((item) =>
    urlModification(item)
  );

  const filterListImage = getArrayFilesWithInfo.filter(
    (item) => item.type === "image"
  );
  const filterListDocument = getArrayFilesWithInfo.filter(
    (item) => item.type === "document"
  );
  const filterListVideo = getArrayFilesWithInfo.filter(
    (item) => item.type === "video"
  );

  const sourceParse = parseJSON(data.source)

  const ref = useRef(null);

  useEffect(() => {
    if (registerRef) {
      registerRef(data.id, ref);
    }
  }, [data, registerRef]);
  return (
    <>
    <div
      ref={ref}
      id={data.id}
      className={`${s.wrapper_item} ${!!data.is_own ? s.wrapper_owner : ""}`}
    >
      {/*<Button onClick={() => scrollToMessage(21787)}>TEST</Button>*/}
      {!data.is_own && (
        <img
          className={s.avatar}
          src={ChatsStore.getAvatar(userId, remoteChatId)}
          alt={userId}
        />
      )}
      <div className={s.with_comment}>
        {sourceParse && sourceParse?.type === 'comments' && sourceParse?.post?.url && !data.is_own && <a target='_blank' rel="noopener noreferrer"  className={s.comment} href={sourceParse.post.url}>
          <LocalIcon icon='link' size='small'/>
          {" "}
          {T('comment-on-the-post')}{sourceParse?.post?.name ? `: ${sourceParse?.post?.name}` : ''}
        </a>}
        {sourceParse && sourceParse?.type === 'comments' && !sourceParse?.post?.url && !data.is_own && <span className={s.comment}>
          {T('comment-on-the-post')}{sourceParse?.post?.name ? `: ${sourceParse?.post?.name}` : ''}
        </span>}
      <div className={`${s.item} ${!!data.is_own ? s.owner : ""} ${data?.error ? s.error : ''} ${data.status === 'deleted' ? s.deleted_status : ''}`}>
        {sourceParse && sourceParse?.reply_to && sourceParse?.reply_to?.reply_id && (
          <button className={s.reply_to} onClick={() => scrollToMessage(sourceParse?.reply_to)}>
            <div className={s.reply_to_content}>
              {sourceParse?.reply_to?.files && <img style={{width: 30, height: 30}}
                    src={`${url}${parseJSON(sourceParse?.reply_to?.files)[0]}`} alt={parseJSON(sourceParse?.reply_to?.files)[0]}/>}
              <span className={s.reply_text}>{sourceParse?.reply_to?.txt}</span>
            </div>
            {loadMessage && <Loader type="Rings" color="#00BFFF" height={20} width={20}/>}
          </button>)}
        <div className={s.message}>
          <div
            style={{
              width: `calc(100% - ${widthDate}px)`,
            }}
            className={s.message_wrapper}
          >
            {!!filterListImage.length && (
              <div className={s.message_image_wrapper}>
                {filterListImage?.map(
                  (item) =>
                    item.type === "image" && <MessageItemImage key={item.name} data={item} />
                )}
              </div>
            )}
            {!!filterListDocument.length && (
              <div className="display-flex-column-gap">
                {filterListDocument?.map(
                  (item) =>
                    item.type === "document" && (
                      <MessageItemDocument key={item.name} data={item} />
                    )
                )}
              </div>
            )}
            {!!filterListVideo.length && (
              <div className="display-flex-column-gap">
                {filterListVideo?.map(
                  (item) =>
                    item.type === "video" && (
                      <MessageItemVideo key={item.name} data={item} />
                    )
                )}
              </div>
            )}
            {message}
          </div>
          <div className={`${s.date} ${data?.error ? s.error : ''}`} ref={refDate}>
            <span className="nowrap">{formatDateChat(data.dt)}</span>
            {!data.is_own && !data?.error && (
              <span className={s.is_check}>
                {data.is_read ? (
                  <LocalIcon icon="double-check" size="middle" />
                ) : (
                  <LocalIcon icon="one-check" size="middle" />
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>

      {!!data?.error && <div className={`${s.error_block} ${data.is_own ? s.own : ''}`}>{data?.error}</div>}
  </>
  );
};

export default MessageItem;
