import React, { useState } from "react";
import { connectToStores } from "tools/reflux-tools";
import ProductionStore from "./production-store";
import Template from "./template/template";
import History from "./history/history";
import GeneralTabs from "./common/general-tabs";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";

const Production = () => {
  const [generalTab, setGeneralTab] = useState("production");
  const {isMobileSize} = useIsMobile()
  const handleChangeTab = (tab) => {
    setGeneralTab(tab);
  };


  return (
    <>
      {!isMobileSize && <GeneralTabs tabs={generalTab} onChange={handleChangeTab}/>}
      {generalTab === "production" && <Template onChangeTabs={handleChangeTab} isActiveTab={generalTab} />}
      {generalTab === "history" && <History onChangeTabs={handleChangeTab} isActiveTab={generalTab}/>}
      {generalTab === "production-deleted" && <Template isDeleted onChangeTabs={handleChangeTab} isActiveTab={generalTab}/>}
    </>
  );
};

export default connectToStores(Production, { _: ProductionStore });
