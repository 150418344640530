import { createStore } from "../../tools/reflux-tools";
import Actions from "./cashier-panel-actions";
import ProductsStore from "../products/products-store";
import {filterSearch} from "../../tools/filters-helper";
import WarehouseStore from "../warehouse/warehouse-store";
import UserStore from "../../user/user-store";
import calcMargin from "../../tools/calc-margin";
import T from "../../components/i18n";
import {parseJSON} from "../../tools/error-handler";
import {isMobile} from "react-device-detect";

const CLEAR_FILTER = () => ({
  warehouse: 0,
  category: {value: 0},
  shipment: {'value': 0, label: T('all')},
  brand: {'value': 0, label: T('all')},
  mid: {'value': 0, label: T('all')},
  instock: window.localStorage.getItem("instock") || '0',
  withoutbarcode: false,
  withoutprice: false,
  search: "",
  margin_from: "",
  margin_to: "",
  price_from: "",
  price_to: "",
  sort: {},
  attr: {},
  reserv: {value: 0, label: T('all')},
  integration: {value: 0, label: T('all')},
  minStock: {value: 0, label: T('all')},
  addFields: {value: 0, label: T('all')},
  expirationDate: {value: 0, label: T('all')},
  type_product: {value: 0, label: T('all')}
})

const CashierPanelStore = createStore({
  listenables: Actions,
  state: {
    rows: [],
    cart: {},
    isOpen: false,
    _filter: CLEAR_FILTER()
  },

  setField(field, value) {
    this.setState({ [field]: value });
  },

  onFilter(name, value) {
    let filters = {};

    for (let i in this.state['_filter']) {
      filters[i] = this.state['_filter'][i];
    }

    filters[name] = value;

    this.setState({
      _filter: filters,
    });

    return Actions.filter.completed()
  },

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  },

  getFilter(field) {
    return this.state["_filter"][field];
  },

  onAddToCart(ident) {
    const cart = JSON.parse(JSON.stringify(this.state.cart))

    if(cart[ident]) {
      let currentValue = cart[ident].count + 1
      currentValue = Math.round(currentValue * 100) / 100;
      cart[ident].count = currentValue;
    } else {
      cart[ident] = {
        ident,
        count: 1
      }
    }
    const data = {
      cart
    }

    if(!Object.keys(this.state.cart).length && !isMobile) {
      data.isOpen = true
    }

    this.setState(data);

    Actions.addToCart.completed(cart[ident])
  },

  onDeleteToCart(ident, isDelete) {
    const cart = JSON.parse(JSON.stringify(this.state.cart));

    if(isDelete && cart[ident]) {
      delete cart[ident]
    } else {
      if(cart[ident]) {
        if(cart[ident].count <= 1) {
          delete cart[ident]
        } else {
          let currentValue = cart[ident].count - 1
          currentValue = Math.round(currentValue * 100) / 100;
          cart[ident].count = currentValue;
        }
      }
    }

    this.setState({
      cart
    })

    Actions.deleteToCart.completed(cart[ident], ident)
  },

  changeCountByIdent(ident, count) {
    const cart = JSON.parse(JSON.stringify(this.state.cart))

    if(cart[ident]) {
      cart[ident].count = parseFloat(count) || '';
    }

    this.setState({
      cart
    })
  },

  isInCart(ident) {
    const cart = this.state.cart;

    return cart[ident] || false;
  },

  getLengthCart() {
    const cart = this.state.cart;

    return Object.keys(cart).length;
  },

  clearMid(product) {
    const filter = this.get('_filter')

    const copyProduct = JSON.parse(JSON.stringify(product))

    const stock = Object.values(copyProduct.stock)

    let result = true

    if(filter.warehouse) {
      if(copyProduct.stock[filter.warehouse]) {
        copyProduct.stock = {[filter.warehouse]: copyProduct.stock[filter.warehouse]};
      } else {
        result = result && false
      }
    }
    if (filter['instock'] !== "0") {
      stock.forEach(item => {
        if(filter['instock'] === '1' && parseFloat(item.instock) <= 0 && !WarehouseStore.isDropshipping(item.marketplace_id) && !ProductsStore.isServicesProduct(item.product_id)) {
          delete copyProduct.stock[item.marketplace_id]
        } else if(filter['instock'] === '2' && (parseFloat(item.instock) > 0 || WarehouseStore.isDropshipping(item.marketplace_id) || ProductsStore.isServicesProduct(item.product_id))) {
          delete copyProduct.stock[item.marketplace_id]
        }
      })
    }

    if(filter['reserv']['value']) {
      const reservFilter = filter['reserv']['value']

      stock.forEach(item => {
        if(reservFilter === 'reserv' && item.quantity === item.instock) {
          delete copyProduct.stock[item.marketplace_id]
        } else if(reservFilter === 'notreserv' && item.quantity !== item.instock) {
          delete copyProduct.stock[item.marketplace_id]
        }
      })
    }

    if(filter['minStock']["value"]) {
      const filterMinStock = filter['minStock']['value']

      stock.forEach(item => {
        const isRequired = UserStore.isMinimalStock({mid: item?.marketplace_id, pid: product['id'], instock: item['instock']})
        if(!isRequired && filterMinStock === 'required') {
          delete copyProduct.stock[item?.marketplace_id]
        } else if(isRequired && filterMinStock === 'no-required') {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }

    if (filter['shipment']['value']) {
      stock.forEach(item => {
        const isShipment = parseInt(filter['shipment']['value']) === parseInt(item['current_sid'])
        if(!isShipment) {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }

    if (filter['price_from']) {
      stock.forEach(item => {
        const isPrice = parseFloat(item['price']) >= parseFloat(filter['price_from'])
        if(!isPrice) {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }

    if (filter['price_to']) {
      stock.forEach(item => {
        const isPrice = parseFloat(item['price']) <= parseFloat(filter['price_to'])
        if(!isPrice) {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }

    if (filter['margin_from']) {
      stock.forEach(item => {
        const margin = calcMargin(
          item["net_price"],
          item["price"],
          item["sale_price"]
        );
        const isMargin = parseFloat(margin) >= parseFloat(filter['margin_from'])
        if(!isMargin) {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }

    if (filter['margin_from']) {
      stock.forEach(item => {
        const margin = calcMargin(
          item["net_price"],
          item["price"],
          item["sale_price"]
        );
        const isMargin = parseFloat(margin) <= parseFloat(filter['margin_to'])
        if(!isMargin) {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }

    if (filter['withoutprice']) {
      stock.forEach(item => {
        const isWithoutPrice = stock['net_price'] === 0 || stock['price'] === 0
        if(!isWithoutPrice) {
          delete copyProduct.stock[item?.marketplace_id]
        }
      })
    }


    if(!Object.keys(copyProduct.stock).length) {
      result = result && false
    }

    if(result) {
      return copyProduct
    }

    return false
  },

  applyFilter(product) {
    const filter = this.get('_filter')

    let sku = [];
    for (let i in product['stock']) {
      let stock = product['stock'][i];
      sku.push(stock['sku'])
      break;
    }

    let result = true
    if(filter['search']) {
      const dataSearch = {
        id: product.id,
        name: product.name,
        brand: product.brand,
        asin: product.asin,
        asin2: product.asin2,
        sku: sku.join(' '),
        af: Object.values(JSON.parse(product.af)).join(', '),
        attr_dict: product.attr_dict,
      }

      result = filterSearch(filter['search'], ['id', 'name', 'brand', 'asin', 'asin2', 'sku', 'af', 'attr_dict'], dataSearch);
    }

    if (filter['withoutbarcode']) {
      result = result && product['asin'] === "";
    }

    let categoryFilter = filter['category']['value'];

    if (categoryFilter !== 0) {
      let childs_category = ProductsStore.get("categoryChild")[categoryFilter]
      if (typeof(childs_category) === "undefined") {
        childs_category = [categoryFilter];
      }
      result = result && (childs_category.indexOf(product['category_id']) > -1);
    }

    if (filter['brand']['value'] !== 0) {
      if(filter['brand']['value'] === 'unbranded') {
        result = result && !product['brand_id']
      } else {
        result = result && (parseInt(filter['brand']['value']) === product['brand_id']);
      }
    }

    if(filter['integration']["value"] !== 0) {
      if(filter['integration']['value'] !== 'no-integration') {
        result = result && product?.mp.some(item => item === filter['integration']['value'])
      } else {
        result = result && !product?.mp?.length
      }
    }

    if(filter['type_product']['value']) {
      result = result && product.type_product === parseInt(filter['type_product']['value'])
    }

    if(filter['expirationDate'] && filter['expirationDate']['value']) {
      const expiratedDate = ProductsStore.theExpirationDateByPid(product.id)
      result = result && expiratedDate === filter['expirationDate']['value']
    }

    if (filter['addFields']['value']) {
      const parseAf = parseJSON(product.af)
      result = result && !!parseAf[filter['addFields']['value']]
    }

    if(Object.keys(filter['attr']).length > 0) {
      result = result && Object.entries(filter['attr']).every(([key, filterItem]) => {
        const { value } = filterItem;
        const parse = parseJSON(product['attr_desc']);
        return value === 0 || (parse && parse[key] === value);
      });
    }


    if(filter['reserv']['value']) {
      const stockKey = Object.keys(product.stock)

      if(filter['reserv']['value'] === 'reserv') {
        result = result && stockKey.some(item => product.stock[item].quantity !== product.stock[item].instock)
      } else if(filter['reserv']['value'] === 'notreserv') {
        result = result && stockKey.some(item => product.stock[item].quantity === product.stock[item].instock)
      }
    }

    if(filter['minStock']["value"]) {
      const stock = Object.values(product.stock)
      const filterMinStock = filter['minStock']['value']
      if(filterMinStock === 'required') {
        result = result && stock.some(item => UserStore.isMinimalStock({mid: item?.marketplace_id || item.mid, pid: product['id'], instock: item['instock']}))
      } else if(filterMinStock === 'no-required') {
        result = result && stock.some(item => !UserStore.isMinimalStock({mid: item?.marketplace_id || item.mid, pid: product['id'], instock: item['instock']}))
      }
    }

    return result
  },



  getProductList() {
    const data = ProductsStore.get('loadListDict');

    const result = Object.values(data || {})?.reduce((acc, cur) => {
      if(Array.isArray(cur)) {
        const variableWithFilter = cur.reduce((filterAcc,filterCur) => {
          if(Object.keys(filterCur).length === 0) return filterAcc
          const newItem = this.clearMid(filterCur)
          if(!newItem) return filterAcc

          const itemWithFilter = this.applyFilter(newItem)
          if(!itemWithFilter) return filterAcc

          filterAcc.push(newItem)
          return filterAcc
        },[])

        if(!variableWithFilter.length) return acc

        acc.push(variableWithFilter)

      } else {
        if(Object.keys(cur.stock).length === 0) return acc
        const curValueWithFilter = this.clearMid(cur)
        if(!curValueWithFilter) return acc

        const isFilter = this.applyFilter(cur)

        if(!isFilter) return acc

        acc.push(curValueWithFilter)
      }

      return acc;
    }, [])

    return result.reverse()
  }
});

export default CashierPanelStore;
