import React from 'react'
import T from "../../../../components/i18n";
import s from './styles.module.scss'

const HelperActions = ({onChangeDiscount, onChangePrepaid, onChangeSurrender, active, type}) => {
	return (
		<div className={s.surrender_wrapper}>
			<span className={`${s.surrender} ${active.discount ? s.active : ''}`} onClick={onChangeDiscount}>{T('sales-discount')}</span>
			{type !== "reserve_sale" && <span className={`${s.surrender} ${active.prepaid ? s.active : ''}`}
						 onClick={onChangePrepaid}>{T('prepaid')}</span>}
			{type === "main_sale" && <span className={`${s.surrender} ${active.surrender ? s.active : ''}`}
						 onClick={onChangeSurrender}>{T('surrender')}</span>}
		</div>
	);
};

export default HelperActions;