import React from "react";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import { AVAILABLE_LANGS } from "../../configs/wl-name/api";
import {useHistory, useLocation} from "react-router-dom";
import UserStore from "../../user/user-store";
import {setLang} from "../../components/i18n";
import {Button} from "react-bulma-components";

const SelectLang = () => {
  const keysLang = Object.keys(AVAILABLE_LANGS);

  let lang_text = (
    <span className="uppercase bold-text">{UserStore.getLang()}</span>
  );

  const location = useLocation()
  const history = useHistory()

  const handleChangeLang = (lang) => {
    window.localStorage.setItem('lang', lang)
    setLang(lang);
    UserStore._onSetLang(lang);
    document.documentElement.lang = lang === 'ua' ? 'uk' : lang;
    const pathname = location.pathname
    history.push(`${pathname.replace(/(ua|en|ru)/, lang)}${location.hash}`)
  }

  return (
    <Dropdown hoverable right label={lang_text} className='disabled-background'>
      {keysLang.map((lang) => (
        <Dropdown.Item key={lang} value={lang}>
          <Button
            className='button_link'
            onClick={() => handleChangeLang(lang)}
          >
            {AVAILABLE_LANGS[lang]["full"]}
          </Button>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default SelectLang;
