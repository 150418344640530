import React, {useEffect, useState} from 'react'
import ClientsStore from "../../modules/clients/clients-store";
import AppStore from "../../app-store";
import ClientViewModal from "../../modules/clients/modal/client-view/client-view-modal";
import ClientsActions from "../../modules/clients/clients-actions";
import T from '../i18n'
import Tag from "../tag";
import {connectToStores} from "../../tools/reflux-tools";

const ClientInfoRow = ({clientID, withoutPhone, withTags}) => {
		const client = ClientsStore.getClientById(clientID);

		const clientCount = ClientsStore.getClientCountById(clientID)
		const [countClient, setCountClient] = useState(clientCount)

		useEffect(() => {
			if(clientID) {
				ClientsStore.queueClientsCount(clientID)
			}

			const loadCount = ClientsActions.loadClientsCount.completed.listen(() => {
				const getClientCount = ClientsStore.getClientCountById(clientID)
				setCountClient(getClientCount)
			})

			return () => {
				loadCount()
			}
		}, [clientID]);

		if (!client) {
			return null
		}

		const tagsGroup = ClientsStore.getGroupClientTagByClientId(clientID);

		return !!clientID && (
			<div className='button-link-without-style display-inline'
					 style={{userSelect: 'text'}} onClick={(e) => {
				e.stopPropagation();
				AppStore.openModal(<ClientViewModal clientID={clientID}/>)
			}}
			>
          <span>
            {client.simple_name} {withoutPhone ? '' : client.phone && `(+${client.phone})`} {client && !!countClient.non_delete_orders_count &&
						<b className='text-success'
							 title={T('total-sales-report')}>({countClient.non_delete_orders_count})</b>}
          </span>
				{withTags && !!tagsGroup?.length && <div className='display-flex-row flex-wrap'>
					{tagsGroup.map((item) => (
								<Tag
									key={`tag-${item.id}`}
									color={item.color}
									name={item.name}
								/>
							))}
					</div>}
			</div>
		);
};

export default connectToStores(ClientInfoRow, {ClientsStore});