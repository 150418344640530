import React from "react";
import {Box, Button, Heading} from "react-bulma-components";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import AppStore from "../../../app-store";
import AddSalesModal from "../add-sales-modal";

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-sales")}</Heading>
      {User.getModuleEnable("sales") && !User.isWarehouseMan() ? (
        <Button color='info' onClick={() => AppStore.openModal(<AddSalesModal modal='sale-modal'/>)}>
          {T("add-first-sale-btn")}
        </Button>
      ) : null}
    </Box>
  );
};

export default EmptyMessage;
