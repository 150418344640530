import React, { useEffect, useState } from "react";
import SalesStore from "../../../sales/sales-store";
import T from "../../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import { connectToStores } from "../../../../tools/reflux-tools";
import Box from "react-bulma-components/lib/components/box";
import SalesActions from "../../../sales/sales-actions";
import Heading from "react-bulma-components/lib/components/heading";
import SalesReportItem from "./sales-report-item";
import usePaginationHook from "../../../../tools/pagination-hook";
import useDateRangeHook from "../../../../tools/date-range-hook";
import {getDatePeriod} from "../../../../tools/date-range";
import {Button} from "react-bulma-components";

const SalesReportClient = ({ clientID }) => {
  const [sales, setSales] = useState([]);
  const [triggerUnfoldReport, setTriggerUnfoldReport] = useState(false)

  const {dateEnd, dateStart, DateRange, onChangeDate} = useDateRangeHook({defaultDateStart: getDatePeriod(90)})


  const salesWithFilter = sales.filter(item => {
    const createDate = new Date(item.purchase_date * 1000)

    dateStart.setHours(0, 0, 0, 0);
    dateEnd.setHours(23, 59, 59, 999);
    if (createDate < dateStart || createDate > dateEnd) {
      return false;
    }

    return item
  })

  const { total, page, start, end, PaginationBlock, setPage } =
    usePaginationHook({ listLength: salesWithFilter.length || 0, perPage: 10 });

  useEffect(() => {
    const loadSalesClient = SalesActions.getSalesByClientId.completed.listen(
      () => {
        setSales(SalesStore.getSalesByClientId(clientID));
      }
    );

    return () => {
      loadSalesClient();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SalesActions.getSalesByClientId(clientID, dateStart, dateEnd);
  }, [dateEnd, dateStart, clientID]);




  return (
    <Box className='sales-report-client'>
      <Heading renderAs="h2" size={4}>
        {T("customer-purchases")}
      </Heading>
      <div className='filter-date'>
        <DateRange date_start={dateStart} date_end={dateEnd}
                   onChange={onChangeDate} range={['today', 'yesterday', 'week', 'this-month', 'last-month', '3-month', 'all-period']}/>
      </div>
      <div align='right'>
        <Button className='button-link-without-style'
                onClick={() => setTriggerUnfoldReport(prev => !prev)}>{triggerUnfoldReport ? T('hide-more-filter') : T('unfold')}</Button>
      </div>
      <PaginationBlock setPage={setPage} totalPage={total} currentPage={page}/>
      <List className="view-list-scroll-400">
        {salesWithFilter.slice(start, end).map((item) => (
          <SalesReportItem key={item.oid} data={item} triggerOpen={triggerUnfoldReport}/>
        ))}
        {!salesWithFilter.length && (
          <List.Item align="center" key="no-sale">
            <b>{T("not-have-sales")}</b>
          </List.Item>
        )}
      </List>
    </Box>
  );
};

export default connectToStores(SalesReportClient, {_: SalesStore});
