import React, {useEffect, useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from '../../../../components/i18n'
import NumberInput from "../../../../components/number-input/number-input";
import SubmitButton from "../../../../components/submit-button";
import ProductsActions from "../../products-actions";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../../tools/error-handler";

const EditSalePrice = ({pid, mid,id, amount, onClose}) => {
	const [amountState, setAmountState] = useState(amount)
	const [waiting, setWaiting] = useState(false)
	const {errorMessage,ErrorMessageBlock,handleAutoRemoveError,setErrorMessage} = ErrorMessageControlHook()


	const handleSubmit = (e) => {
		setWaiting(true)
		ProductsActions.setPrice('price', id, amountState, pid, mid);
	}

	const handleChangeInput = ({target: {value}}) => {
		setAmountState(value)
	}

	useEffect(() => {
		const priceAdd = ProductsActions.setPrice.completed.listen(() => {
			ProductsActions.loadByPid([pid])
			onClose()
		});

		const priceAddFail = ProductsActions.setPrice.failed.listen((res) => {
			const error = errorHandler(res)
			handleAutoRemoveError(error)
			onClose()
		});

		return () => {
			priceAdd()
			priceAddFail()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading>{T('history-action-51')}</Heading>
				<NumberInput value={amountState} onChange={handleChangeInput} />
				<div className='display-flex-row margin-top-10' style={{justifyContent:'flex-end'}}>
					<Button onClick={onClose} size='medium' rounded>{T('close')}</Button>
					<SubmitButton text='btn-edit' size='medium' submit={handleSubmit} fullwidth={false} waiting={waiting}/>
				</div>
			</Box>
		</>
	);
};

export default EditSalePrice;