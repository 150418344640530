import React from 'react'
import ChatsActions from "./chats-actions";
import SelectComponent from "../../tools/select-component";
import ChatsStore from "./chats-store";
import ClientsStore from "../clients/clients-store";
import UserStore from "../../user/user-store";
import T from '../../components/i18n'

const sourceList = [
	{
		name: 'Telegram',
		id: 13
	},
	{
		name: "Viber",
		id: 17
	},
	{
		name: 'Olx',
		id: 19
	},
	{
		name: 'Instagram',
		id: 'instagram',
	},
	{
		name: 'Facebook',
		id: 14,
	},
]

const ChatsFilters = () => {
	const handleChangeSelect = (value, {name}) => {
		if(name) {
			ChatsActions.filter(name, value)
		}

		if(name === 'source') {
			ChatsActions.filter("integrationName", {value: 0, label: T('all')})
		}
	}

	const sourceChats = UserStore.getIntegrationChatsListByIID(ChatsStore.getFilter('source').value)

	return (
		<>
			<SelectComponent
				name='source'
				empty='all'
				label='history-data-integration_id'
				value={ChatsStore.getFilter('source')}
				list={() => sourceList}
				onChange={handleChangeSelect}
			/>
			{sourceChats.length > 1 && <SelectComponent
				name='integrationName'
				empty='all'
				label='integration'
				value={ChatsStore.getFilter('integrationName')}
				list={() => sourceChats}
				onChange={handleChangeSelect}
			/>}
			{ClientsStore.getClientTagList()?.length && <SelectComponent
				label='clients-tags'
				name='tags'
				empty='all'
				onChange={handleChangeSelect}
				value={ChatsStore.getFilter('tags')}
				list={ClientsStore.getClientTagList}
			/>}
		</>
	);
};

export default ChatsFilters;