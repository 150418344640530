import React from "react";
import User from "../../../../../user/user-store";
import ProductsStore from "../../../../products/products-store";
import List from "react-bulma-components/lib/components/list";
import Image from "react-bulma-components/lib/components/image";
import OpenInsideModal from "../../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../../../products/edit-products-modal";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import NumberInput from "../../../../../components/number-input/number-input";
import Icon from "react-bulma-components/lib/components/icon";
import Money from "../../../../../components/money";
import DOM from "react-dom-factories";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { instockMobileText, productNameMobile } from "../utils";
import {Button} from "react-bulma-components";
import AppStore from "../../../../../app-store";
import ChangeIndexModal
  from "../../../../../components/draggable-list/change-index-modal";
import UserStore from "../../../../../user/user-store";

const ProductLineMobile = ({
  prod,
  step,
  countName,
  received,
  mid,
  currency,
  productData,
  currencyRate,
  refresh,
  moveQuantity,
  onChangeCount,
  onChangeProductData,
  onChangeRetailPrice,
  deleteShipmentItem,
  onChangeIndexItem,
  index,
}) => {
  const pid = prod.id;
  const name = productNameMobile(prod);
  const instock = instockMobileText(prod, step);
  const count_name = countName;
  const img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";
  const quantity = received ? "quantity-res-mob" : "quantity-ord-mob";
  const getProduct = ProductsStore.getProductByMid(pid, mid);

  const shCurr = currency;
  const retailCurr = User.getCurrency();

  let sumCostAmount =
    productData[pid][count_name] * (productData[pid]["raw_net_price"] || 0);
  const currRate = currencyRate;

  if (shCurr !== retailCurr) {
    sumCostAmount =
      productData[pid][count_name] *
      ((productData[pid]["raw_net_price"] || 0) * currRate);
  }

  const handleChangeItems = () => {
    AppStore.openModal(<ChangeIndexModal modal='changeIndex' index={index + 1} onChangeIndex={(newIndex) => onChangeIndexItem(newIndex, index)}/>)
  }

  return (
    <List.Item key={pid} align="left">
      <div className='display-flex-row'>
        <Button className='button-link-without-style' onClick={handleChangeItems}>
          {index + 1}
        </Button>
        <b>
          <Image src={img} size={32} className="align-left" key={refresh}/>
          {User.getPermissionEnable("editproducts") ? (
            <OpenInsideModal size="small" text={name} renderAs="b">
              <EditProductsModal
                pid={getProduct.parent_id || getProduct.id}
                step={0}
                className='modal-start'
              />
            </OpenInsideModal>
          ) : (
            name
          )}
        </b>
      </div>
      <hr/>
      <Columns className="is-mobile">
        <Columns.Column className="is-half">
          <Label>
            {T("product-info-warehouse-one")}:<span> {instock}</span>
            <br/>
            {received ? (
              <span>
                {T("expect")}:{" "}
                <span className="like-link" onClick={() => moveQuantity(pid)}>
                  {" "}
                  {ProductsStore.getStockUnits(
                    productData[pid]["quantity_shipped"],
                    productData[pid]["units"]
                  )}
                </span>
              </span>
            ) : (
              <span className="opacity0">. &nbsp;</span>
            )}
          </Label>
        </Columns.Column>
        <Columns.Column className="is-half">
          <Field horizontal>
            <Label>{T(quantity)}:</Label>
            <Control iconRight>
              <NumberInput
                key={"count-pid-" + pid}
                name={"count-pid-" + pid}
                type="text"
                className="for-small-icon"
                onChange={onChangeCount}
                value={productData[pid][count_name]}
              />
              <Icon align="right">
                <span className="small-text">
                  {ProductsStore.getUnits(productData[pid]["units"])}
                </span>
              </Icon>
            </Control>
          </Field>
        </Columns.Column>
        {UserStore.getPermissionEnable('ship-rawcost') && <Columns.Column className="is-half">
          <Field horizontal>
            <Label>{T("shipments-price-item")}:</Label>
            <Control iconRight>
              <NumberInput
                key={"pid-" + pid}
                name={"pid-" + pid}
                className="for-small-icon"
                type="text"
                onChange={(evt) => onChangeProductData(evt, "raw_net_price")}
                value={productData[pid]["raw_net_price"]}
              />
              <Icon align="right">
                <span className="small-text">{currency}</span>
              </Icon>
            </Control>
          </Field>
        </Columns.Column>}
        <Columns.Column className="is-half">
          <Field horizontal>
            <Label>{T("products-price")}:</Label>
            <Control iconRight>
              <NumberInput
                key={"retail_price-" + pid}
                name={"retail_price-" + pid}
                className="for-small-icon"
                onChange={onChangeRetailPrice}
                value={productData[pid]["retail_price"]}
              />
              <Icon align="right">
                <span className="small-text">{User.getCurrency()}</span>
              </Icon>
            </Control>
          </Field>
          {UserStore.getPermissionEnable('ship-rawcost') && <div align="right" style={{marginTop: "-5px"}}>
            <b className="display-inline">{T("cost-of-sales")}: </b>
            <Money wrapper={DOM.span} amount={sumCostAmount}/>
          </div>}
        </Columns.Column>
      </Columns>
      <div align="right">
        <Link
          to="#"
          onClick={() => deleteShipmentItem(pid)}
          title={T("delete")}
        >
          <FontAwesomeIcon icon="trash-alt" size="1x" /> {T("delete-from-list")}
        </Link>
      </div>
    </List.Item>
  );
};

export default ProductLineMobile;
