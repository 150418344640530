import React from 'react';
import {Dropdown} from "react-bulma-components";
import {LocalIcon} from 'whitelables/wl-name/icons'
import T from '../i18n'
import AppStore from "../../app-store";
import DocumentModal from "./document-modal";

const DropdownDocument = ({list, heading}) => {

	return (
		<Dropdown right={false} label={<LocalIcon icon="download" size="large" />}>
			<Dropdown.Item
				target="_blank"
				title={T("download-report-pdf")}
				value="item"
			>
				<div onClick={() => AppStore.openModal(<DocumentModal printDocument={list} format='pdf' heading={heading}/>)}>
					{T("download-report-pdf")}
				</div>
			</Dropdown.Item>
			<Dropdown.Item
				title={T("download-report-excel")}
				value="item"
			>
				<div onClick={() => AppStore.openModal(<DocumentModal printDocument={list} format='xls' heading={heading}/>)}>
					{T("download-report-excel")}
				</div>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default DropdownDocument;