//It's a very important to have variable 'messages'
//because our "lang compiler" expects such format

import React from "react";

const messages = {
    "registration-accept-terms": {
        ru: (
            <span>Я подтверждаю корректность указанных мною данных, а также свое полное и безоговорочное согласие с
            условиями, определенными договорными в <b className="link link--secondary" data-href="agreement">Договоре на участие в играх и пари</b> и
            в <b className="link link--default" data-href="rules">Правилах приема ставок</b>
            </span>
        ),
        en: (
            <span>I confirm that all the information I have entered is accurate and that I fully and unconditionally
            agree to the terms and conditions set forth in the <b className="link link--secondary" data-href="agreement">Game Participation</b> and
            <b className="link link--default" data-href="rules"> Betting Agreement</b>
            </span>
        )
    },
    "phone-busy": {
        ru: "Этот номер телефона уже используется. Попробуйте другой",
        ua: "Цей номер телефону вже використовується. Спробуйте інший",
        en: "This phone number is already in use. Try another one"
    },
    "username-busy": {
        ru: "Этот логин уже используется. Попробуйте другой",
        en: "This login is taken. Try another one"
    },
    "go-to-site": {
        ru: "Перейти на сайт",
        en: "Go to site"
    },
    "registration-cancel": {
        ru: "Отмена",
        en: "Cancel"
    },
    sex: {
        ru: "Пол",
        en: "Sex"
    },
    "sex-male": {
        ru: "Мужской",
        en: "Male"
    },
    "sex-female": {
        ru: "Женский",
        en: "Female"
    },
    birthday: {
        ru: "Дата рождения",
        en: "Date of Birth"
    },
    country: {
        ru: "Страна",
        en: "Country"
    },
    region: {
        ru: "Регион",
        en: "Region"
    },
    city: {
        ru: "Город",
        ua: "Місто",
        en: "City/Town"
    },
    address: {
        ru: "Адрес",
        en: "Address",
        ua: "Адреса"
    },
    zipcode: {
        ru: "Почтовый индекс",
        en: "ZIP/Postal Code",
        ua: "Поштовий індекс"
    },
    login: {
        ru: "Логин",
        ua: "Логін",
        en: "Login"
    },
    phone: {
        ru: "Телефон",
        ua: "Телефон",
        en: "Phone"
    },
    language: {
        ru: "Язык",
        en: "Language",
        ua: "Мова"
    },
    "time-zone": {
        ru: "Часовой пояс",
        en: "Time Zone"
    },
    password: {
        ru: "Пароль",
        ua: "Пароль",
        en: "Password"
    },
    "wrong-password": {
        ru: "Введите Пароль",
        ua: "Введіть Пароль",
        en: "Enter Password"
    },
    "password-strength": {
        ru: "Надёжность пароля",
        en: "Password strength"
    },
    example: {
        ru: "Пример",
        en: "Example"
    },
    registration: {
        ru: "Регистрация",
        ua: "Реєстрація",
        en: "Registration"
    },
    "email-placeholder": {
        ru: "Введите Ваш E-mail",
        ua: "Введіть Ваш E-mail",
        en: "Enter your E-mail"
    },
    "your-email": {
        ru: "Ваш E-mail",
        ua: "Ваш E-mail",
        en: "Your E-mail"
    },
    "phone-placeholder": {
        ru: "Введите Ваш Телефон",
        ua: "Введіть Ваш Телефон",
        en: "Enter your Phone Number"
    },
    "login-with-facebook": {
        ru: "Вход с помощью Facebook",
        en: "Login with Facebook",
        ua: "Вхід за допомогою Facebook"
    }
};

export default messages;
