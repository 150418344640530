import React from "react";
import Reports from "../../../components/reports";
import Actions from "../production-actions";
import HistoryRow from "./history-row";
import EmptyMessage from "../common/empty-message";
import ProductionStore from "../production-store";
import { Box } from "react-bulma-components";
import ActionButton from "../common/action-button";
import BaseFilterLayout from "../../../components/base-filter-layout";
import useFilterHook from "../../../tools/use-filter-hook/use-filter-hook";
import T from "../../../components/i18n";
import { getDatePeriod } from "../../../tools/date-range";
import { applyFilter } from "../utils";
import PaginationHook from "../../../tools/pagination-hook";
import TemplateFilter from "../template/template-filter";
import ProductionActions from "../production-actions";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import GeneralTabs from "../common/general-tabs";

const COLUMNS = [
  "ID",
  "date",
  "products",
  "production-quantity",
  "production-netcost",
  "amount-on-producations",
  "conponent-on-amount",
  "expenses-productions",
  'reports-actions'
];

const History = ({isActiveTab,onChangeTabs}) => {
  const {isMobileSize} = useIsMobile()
  const {
    filters,
    onChangeFilter,
    onChangeSearch,
    onChangeDate,
    onClearFilter,
  } = useFilterHook({
    defaultValues: {
      category: { value: 0, label: T("all") },
      search: "",
      date_start: getDatePeriod(30),
      date_end: new Date(),
      mid: { value: 0, label: T("all-warehouse") },
    },
  });
  const data = ProductionStore.getHistory();

  const withFilter = data?.filter((item) => applyFilter(item, filters, "unix"));
  const { page, total, start, end, PaginationBlock, setPage } = PaginationHook({
    listLength: withFilter?.length || 0,
  });

  const handleRefresh = () => {
    onClearFilter();
    ProductionActions.loadHistory();
  };

  const filterPanel = (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: onChangeSearch,
        defaultValue: filters.search,
      }}
      refresh={{
        onChange: handleRefresh,
      }}
      dateRangeSetting={{
        onChange: onChangeDate,
        date_start: filters.date_start,
        date_end: filters.date_end,
      }}
      hiddenFilter={
        <TemplateFilter filters={filters} onChangeFilters={onChangeFilter} />
      }
    />
  );

  return (
    <>
      {!isMobileSize && filterPanel}
      <ActionButton />
      {isMobileSize && <GeneralTabs tabs={isActiveTab} onChange={onChangeTabs}/>}
      <Box className="production-report border-not-top">
        {isMobileSize && filterPanel}
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
        <Reports
          load={Actions.loadHistory}
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          rows={withFilter ? withFilter.slice(start, end) : []}
        >
          <HistoryRow role="row" />
        </Reports>
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
      </Box>
    </>
  );
};

export default History;
