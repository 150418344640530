import ClientsStore from "../clients/clients-store";
import {filterSearch} from "../../tools/filters-helper";
import ChatsStore from "./chats-store";

export const getSystemId = (chat) => {
  if (chat["extra"]) {
    let extra = JSON.parse(chat["extra"]);
    if (typeof extra["object"] !== "undefined") {
      if(extra["object"] === 'facebook' || extra["object"] === 'instagram') {
        return extra["object"];
      } else if (chat.system_id) {
        return chat.system_id;
      }
      return extra["object"];
    }
  }

  if(chat.system_id) {
    return chat.system_id;
  }
};

export const formatDate = (date) => {
  date = new Date(date * 1000);
  const now = new Date();
  const diff = (now - date) / 1000; // разница в секундах
  // console.log("date ", date, now, diff)
  if (diff < 60) {
    return Math.floor(diff) + " сек.";
  } else if (diff < 3600) {
    return Math.floor(diff / 60) + " мин.";
  } else if (diff < 86400) {
    return Math.floor(diff / 3600) + " ч.";
  } else {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${day}.${month}`;
  }
};

export const formatDateChat = (date) => {
  date = new Date(date * 1000);
  const now = new Date();

  const hours = date.getHours().toString().padStart(2, "0");
  const min = date.getMinutes().toString().padStart(2, "0");

  if (now.getDay() === date.getDay() && now.getMonth() === date.getMonth()) {
    return `${hours}:${min}`;
  } else {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${day}-${month} ${hours}:${min}`;
  }
};

export const getNamePhoto = (mess) => {
  const matchID = mess.match(/id=(\w+\.\w+)/);

  if (matchID && matchID[1]) {
    return ['id', matchID[1]];
  } else {
    const matchURL = mess.match(/url='(.*?)'/);
    if(matchURL && matchURL[1]) {
      return ['url', matchURL[1]]
    } else {
      console.log('we couldn\'t pull the link')
    }
  }
  return [];
};


export const filterChats = (chats, filter) => {
    if(!chats) return []

  return chats.filter((item) => {
    let result = true
    if(filter.search) {
      if(item.client_id) {
        const getClient = ClientsStore.getClientById(item.client_id)

        result = filterSearch(filter.search, ['name', 'phone', 'first_name', 'last_name', 'simple_name'], getClient)
      } else {
        result = filterSearch(filter.search, ['name', 'remote_client_name'], item)
      }
    }

    if(filter?.tags && filter?.tags.value) {
      if(item.client_id) {
        const getClient = ClientsStore.getClientById(item.client_id)
        if(getClient) {
          result = result && getClient.tags?.some(item => item === filter["tags"]['value'])
        } else {
          result = false
        }
      } else {
        result = false
      }
    }


    if(filter?.date_start && filter?.date_end) {
      const date = item.last_date * 1000

      filter['date_start'].setHours(0,0,0,0);
      filter['date_end'].setHours(23,59,59,999);

      if (date < filter['date_start'] || date > filter['date_end']) {
        return false;
      }

    }


    if(filter.status) {
      if(filter.status === 'created') {
        const isStatus = ChatsStore.getChatsStatusName(item.status)
        result = result && !isStatus
      } else {
        result = result && filter.status === parseFloat(item.status)
      }
    }

    if(filter.source && filter.source.value) {
      const system_id = getSystemId(item)
      result = result && system_id === filter.source.value
    }

    if(filter.integrationName && filter.integrationName.value) {
      result = result && parseFloat(filter.integrationName.value) === parseFloat(item.integration_id)
    }


    return result
  })
}