import React from 'react'
import Image from "react-bulma-components/lib/components/image";
import ProductsStore from "../../products/products-store";
import s from './styles.module.scss'
import T from '../../../components/i18n'
import {Button} from "react-bulma-components";
import AppStore from "../../../app-store";
import RestoreModal from "./modal/restore-modal";
import WarehouseStore from "../../warehouse/warehouse-store";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import SelectStore from "../../sales/select-store";

const RowDesktop = ({data, isCheckbox, num}) => {
	const category = ProductsStore.getCategoryName(data.category_id)
	const brand = ProductsStore.getBrandName(data.brand_id)

	const getWarehousesName = Object.keys(data.stock).map(item => {
		const name = WarehouseStore.getWarehouseName(item)
		if(name) {
			return {
				name,
				id: item
			}
		} else {
			return {
				name: T('warehouse-deleted'),
				id: item,
				isDeleted: true
			}
		}
	})

	const midsWithoutDeleted = getWarehousesName.filter(item => !item.isDeleted)


	const handleRestore = () => {
		AppStore.openModal(<RestoreModal mids={midsWithoutDeleted} pid={data.id} modal='without-scroll'/>)
	}

	const handleChangeCheckbox = (id) => {
		SelectStore.onSelectGroup(id);
	}

	let className = " is-one-";
	className += num % 2 === 0 ? "even" : "odd";

	return (
		<tr className={className}>
			<td>
				<div className={s.wrapper_name}>
					<div className='display-flex-column'>
						{isCheckbox && midsWithoutDeleted.length >= 1 && <Field style={{margin: 0, marginBottom: '-5px'}}>
							<Control>
								<Checkbox
									name="is_products"
									onChange={() => handleChangeCheckbox(data.id)}
									onClick={(evt) => evt.stopPropagation()}
									checked={SelectStore.getIsSelectById(data.id)}
								/>
							</Control>
						</Field>}
						<Image src={ProductsStore.makeImageUrl(data.id, 0, 64)} size={64} style={{height: 'auto'}}/>
					</div>
					<div>
						<strong>ID: {data.id}. {data.name}</strong>
						<br/>
						{category && <span>{T('product-category')}: {category} {(brand || data.sku) &&	" | "}</span>}
						{brand && <span>{T('product-brand')}: {brand} {data.sku && " | "}</span>}
						{data.sku && <span>{T('product-vendor-code')}: {data.sku}</span>}
					</div>
				</div>
			</td>
			<td>
				{data.updated_at}
			</td>
			<td>
				<div className={s.wrapper_mid}>
					{getWarehousesName.map(item => <span key={item.id}
																							 className={item?.isDeleted ? 'text-danger' : ''}>{item.name} {item?.isDeleted && `ID: ${item.id}`}</span>)}
				</div>
			</td>
			<td>
				{midsWithoutDeleted.length >= 1 && <Button color='info' onClick={handleRestore}>
					{T('restore-label')}
				</Button>}
			</td>
		</tr>
	);
};

export default RowDesktop;