import React from "react";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import T from "../../../components/i18n";
import AddProductions from "../add-production";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";

const ActionButton = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div
      className="display-flex-row margin-bottom-10 margin-top-10 top-button"
      style={{ justifyContent: "space-between" }}
    >
      <DocumentationButton sectionID='production'/>
      <OpenInsideModal text={T("create-production")} icon="plus-circle" size={isMobileSize ? 'small' : 'medium'}>
        <AddProductions />
      </OpenInsideModal>
    </div>
  );
};

export default ActionButton;
