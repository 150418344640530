import React, { useState } from "react";
import ExpensesStore from "../../expenses/expenses-store";
import TabList from "../../../components/tab-list";
import TransactionsActions from "../transactions-actions";
import TransactionsStore from "../transactions-store";
import ScrollToTopOnMount from "../../../tools/scroll-top";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const TransactionTabs = () => {
  const list = ExpensesStore.getActionsList();
  const actionTypeNumberAndCount = TransactionsStore.get('actionTypeNumberAndCount')
  const {isMobileSize} = useIsMobile()

  const filterWithActiveAction = list.filter(item => Object.keys(actionTypeNumberAndCount).some(action => item.id === action))


  const moveAmountAccount = filterWithActiveAction.reduce((acc, cur) => {
    if(cur.id === "6" || cur.id === "7"){
      if(acc["6"] || acc['7']) {
        return acc
      } else {
        acc[cur.id] = {
          id: 'move-found',
          name: 'transfers',
          count: (actionTypeNumberAndCount['6'] || 0) + (actionTypeNumberAndCount['7'] || 0)
        };
      }

    } else {
      acc[cur.id] = cur
    }

    return acc
  }, {})

  const refactoringList = Object.values(moveAmountAccount).map((item) => ({
    tab: item.id,
    label: item.name,
    count: item?.count || actionTypeNumberAndCount[item.id] || ''
  }));



  const [isActiveTab, setIsActiveTab] = useState(0);

  const handleChangeTab = (value) => {
    if(value === 'move-found') {
      TransactionsActions.filter('action_id', {value: 0})
      TransactionsActions.filter('multiTypeAction', [{value: "6"}, {value: "7"}])
    } else {
      TransactionsActions.filter('multiTypeAction', {value: 0})
      TransactionsActions.filter('action_id', {value})
    }
    setIsActiveTab(value);
    TransactionsStore.setState({
      tab: value
    })
  };

  return !!refactoringList.length && (
    <>
      {isMobileSize && <ScrollToTopOnMount scrollSettings={{block: 'center'}}/>}
      <TabList
        list={[{ tab: 0, label: "all" }, ...refactoringList]}
        isActive={isActiveTab}
        onChange={handleChangeTab}
      />
    </>

  );
};

export default TransactionTabs;
