import React, {useEffect, useState} from 'react'
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {Box, Button, Columns, Heading} from "react-bulma-components";
import {parseJSON} from "../../../tools/error-handler";
import ProductRow from "./product-row";
import usePaginationHook from "../../../tools/pagination-hook";
import WarehouseStore from "../../warehouse/warehouse-store";
import SalesStore from "../../sales/sales-store";
import T from '../../../components/i18n'
import IntegrationBindingActions from "./integration-binding-actions";
import IntegrationBindingStore from "./integration-binding-store";
import {connectToStores} from "../../../tools/reflux-tools";
import RadioButtonsListControl
	from "../../../components/radio-buttons-list/radio-button-list-control";
import OrdersActions from "../../orders/orders-actions";
import SearchString from "../../../components/base-filter-layout/search-string";
import {filterSearch} from "../../../tools/filters-helper";
import SelectProductStore
	from "../../../components/select-products/select-product-store";
import SelectProductActions
	from "../../../components/select-products/select-product-actions";
import {confirmDelete} from "../../../tools/confirm-delete";

const listRadio = [
	{
		title: 'all',
		value: 'all'
	},
	{
		title: 'binding-label',
		value: 'binding',
	},
	{
		title: 'not-binding-label',
		value: 'not-binding'
	}
]

const MassBinding = () => {
	const location = useLocation()
	const history = useHistory()

	const data = location.state

	const parseIdent = parseJSON((data && data?.ident) || '{}')

	const [isBinding, setIsBinding] = useState('all')
	const [search, setSearch] = useState('')



	const handleChangeValueIsBinding = ({value}) => {
		setIsBinding(value)
	}

	useEffect(() => {
		SelectProductActions.filter('mid', {value: parseIdent?.mid})
	}, [parseIdent.mid]);

	const productList = SelectProductStore.getProductList()

	const productWithFilter = productList.filter(item => {
		let result = true
		if(isBinding === 'binding') {
			result = result && IntegrationBindingStore.isBindingProductByPid(item?.id)
		} else if(isBinding === 'not-binding') {
			result = result && !IntegrationBindingStore.isBindingProductByPid(item?.id)
		}
		result = result &&  filterSearch(search, ['full_name', 'id', 'asin', 'cat_name', 'brand', 'name', 'sku'], item)
		return result
	})
	const {start,end,page,total,setPage,PaginationBlock} = usePaginationHook({
		perPage: 20,
		listLength: productWithFilter?.length
	})

	const handleChangeSearch = ({value, name}) => {
		if(name) {
			setSearch(value)
			setPage(0)
		}
	}

	const findChannelId = SalesStore.foundChannelsByIntegrationId(data?.id)
	const channelName = SalesStore.getChannelsName(findChannelId)

	useEffect(() => {
		IntegrationBindingActions.remoteProductsByIid(data?.id)

		return () => {
			OrdersActions.refreshOrders()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getRemoteProducts = IntegrationBindingStore.getListForSelectWithoutBindingProduct(data?.id)

	const handleDeleteMassBinding = () => {
		confirmDelete(() => {
			IntegrationBindingActions.deleteMassBinding({iid: data?.id})
		},'delete-bulk-bindings')
	}

	if (!location.state) {
		return <Redirect to='/settings/integrations' />;
	}



	return (
		<Box>
			<div className='display-flex-row-gap'>
				<Button size='small' rounded color='info' onClick={() => history.goBack()}>{T('back')}</Button>
				<Button size='small' rounded color='danger' onClick={handleDeleteMassBinding}>{T('delete-bulk-bindings')}</Button>
			</div>
			<Heading>{T('integration')}: {channelName}</Heading>
			<Heading subtitle>{T('Warehouse')}: {WarehouseStore.getWarehouseName(parseIdent.mid)}</Heading>
			<Columns>
				<Columns.Column>
					<SearchString onChange={handleChangeSearch} defaultValue={search}/>
				</Columns.Column>
				<Columns.Column>
					<RadioButtonsListControl name='is_binding' list={listRadio} valueRadio={isBinding} onChange={handleChangeValueIsBinding}/>
				</Columns.Column>
				<Columns.Column/>
			</Columns>
			<div className='display-flex-column-gap'>
				<PaginationBlock setPage={setPage} totalPage={total} currentPage={page}/>
				<div>
					<div style={{padding: '10px', borderBottom: "1px solid gray"}}>
						<Columns>
							<Columns.Column size="two-fifths">
						<span className='font-size-110 bold'>
							{T('products-on', {name: "CRM"})}
						</span>
							</Columns.Column>
							<Columns.Column>
						<span className='font-size-110 bold'>
							{T('products-on', {name: channelName})}
						</span>
							</Columns.Column>
						</Columns>
					</div>
					{productWithFilter.slice(start, end).map(item => <ProductRow
						iid={data.id} key={item.id} data={item}
						remoteProducts={getRemoteProducts}/>)}
				</div>
				<PaginationBlock setPage={setPage} totalPage={total}
												 currentPage={page}/>
			</div>
		</Box>
	);
};

export default connectToStores(MassBinding, {_: IntegrationBindingStore});