import { useEffect, useState } from "react";
import PaginationBlock from "../../components/pagination";

const usePaginationHook = ({ perPage, listLength }) => {
  const [page, setPage] = useState(0);
  const [perPageState,setPerPageState] = useState(perPage || 20);
  const [total, setTotal] = useState(Math.ceil(listLength / perPageState));
  const [start, setStart] = useState(page * perPageState);
  const [end, setEnd] = useState(page * perPageState + perPageState);

  useEffect(() => {
    setTotal(Math.ceil(listLength / perPageState));
    setPage(0);
  }, [listLength, perPageState]);

  useEffect(() => {
    setStart(page * perPageState);
    setEnd(page * perPageState + perPageState);
  }, [page, perPageState]);

  const handleSetPage = (value) => {
    setPage(value);
  };

  useEffect(() => {
    if(perPage) {
      setPerPageState(perPage);
    }
  }, [perPage]);

  const handleCountPerPage = (value) => {
    setPerPageState(value);
  }

  return {
    page,
    setPage: handleSetPage,
    total,
    PaginationBlock,
    start,
    end,
    perPageState,
    handleCountPerPage
  };
};

export default usePaginationHook;
