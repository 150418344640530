import React, { Component } from 'react';

import AddSaleModal from "./add-sales-modal";
import SalesStore from './sales-store';

import OpenModal from 'components/modal/modal';

class AddSalesModalLink extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    renderSelectProduct(products) {
        return (
          <AddSaleModal
            		className='modal-start'
                modal="sale-modal"
                select_product={products}
                />
        );
    }

    render() {
        const products = this.props.location.state;

        let data = null;

        if (products && typeof(products.oid) !== "undefined") {
            const sales = SalesStore.getSalesByOid(products.oid);
            data = <AddSaleModal className='modal-start' modal='sale-modal' {...sales} type_sale="delivery"/>
        } else {
            data = this.renderSelectProduct(products)
        }

        return (
          <OpenModal modal={{ closeOnBlur: false }}>
                {data}
          </OpenModal>
        );
       
    }
}


export default AddSalesModalLink;