import React, { Component } from 'react';
import T from "components/i18n";
import ScrollToTopOnMount from 'tools/scroll-top'
import Notification from 'react-bulma-components/lib/components/notification';
import DOM from "react-dom-factories";
import Money from "components/money";
import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Input, 
    Label, 
    Textarea, 
} from 'react-bulma-components/lib/components/form';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationBlock from "components/pagination";
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Icon from 'react-bulma-components/lib/components/icon';
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import InfoBlock from 'components/info-block';
import InventoryStore from './inventory-store'
import Actions from './inventory-actions'
import ProductsStore from '../products/products-store'
import WarehouseStore from '../warehouse/warehouse-store'
import WarehouseActions from '../warehouse/warehouse-actions'
import ExpensesStore from '../expenses/expenses-store'
import SelectComponent from 'tools/select-component'
import confirm from 'tools/confirm'
import SelectProducts from 'components/select-products';
import User from 'user/user-store';
import "react-datepicker/dist/react-datepicker.css";
import SubmitButton from 'components/submit-button';
import OpenInsideModal from "components/modal/open-inside-modal";
import AddExpensesModal from "modules/expenses/add-expenses-modal";
import UserStore from "user/user-store";
import InventoryActions from "./inventory-actions";
import {errorHandler, parseJSON} from "../../tools/error-handler";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import RadioButtonListControl
  from "../../components/radio-buttons-list/radio-button-list-control";

class AddInventory extends Component {
    constructor(props) {
        super(props);
        let state = {}, 
            product_data = {},
            close = false;
        if (typeof(props.location.state) != "undefined") {
           state = props.location.state;
        }

        const data = state.data || {},
              only_show = state.only_show || false,
              step = state.step || 0;
        
        this.changing_products = [];
        const items = data['items'] || {};
        if (Object.keys(items).length > 0) {
           product_data = this.prepareItems(items, data.marketplace_id, step)
        }

        this.product_data_list = [];
        this.forgot_products = [];
        this.approv_forgot_products = false;

        for (let i in items) {
          this.product_data_list.push(parseInt(items[i]['product_id']));
        }

        let result = this.calcSaleAmount(product_data);
        let count_per_page = 50;
        this.state = {
          date: data.created_at || new Date(),
          marketplace_id: {'value': data.marketplace_id || WarehouseStore.getDefaultWarehouse()},
          id: data.id || null,
          comment: data.comment || "",
          result: result,
          status: data.status || 'pending',
          product_data: product_data, 
          products_select: data.select_product || [],
          data: data, //amount after received items
          currency: {'value': User.get("currency_id")},
          step: data.status === "completed" ? 4 : step,
          only_show: only_show,
          page: 0,
          count_per_page: count_per_page,
          count_page: Math.ceil(this.product_data_list.length/count_per_page),
          item_length: 0,
          close: close,
          waiting: null,
          message: null,
          addAllWaiting: false,
          isButtonAll: true,
          isErrorById: {},
          filterProduct: 'all',
          errorProducts: JSON.parse(JSON.stringify(this.changing_products))
        };



        this.onChange = this.onChange.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.backStep = this.backStep.bind(this);
        this.addForgot = this.addForgot.bind(this);
        this.confirmForgot = this.confirmForgot.bind(this);
        this.putRow = this.putRow.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeProductData = this.onChangeProductData.bind(this);
        this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepTwo = this.renderStepTwo.bind(this);
        this.renderStepThird = this.renderStepThird.bind(this);
        this.renderStepFinish = this.renderStepFinish.bind(this);
        this.renderStepReport = this.renderStepReport.bind(this);
        this.deleteInventoryItem = this.deleteInventoryItem.bind(this);
        this.tryAgainPutRow = this.tryAgainPutRow.bind(this);
        this.filterAllAndError = this.filterAllAndError.bind(this);

        this.listenAdd = null;
        this.delayTimeById = {};
    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenAddFailed) == "function") {
        this.listenAddFailed();
      }
      if (typeof(this.listenPut) == "function") {
        this.listenPut();
      } 
      if (typeof(this.listenPutFailed) == "function") {
        this.listenPutFailed();
      }
      if (typeof(this.listenOnAddAll) == "function") {
        this.listenOnAddAll();
      }
      if (typeof(this.listenOnLoad) == "function") {
        this.listenOnLoad();
      }
      if (typeof(this.listOnAddAllFailed) == "function") {
        this.listOnAddAllFailed();
      }
      if (typeof(this.listenOnLoadFailed) == "function") {
        this.listenOnLoadFailed();
      }
      if (typeof(this.listenDeleteProduct) == "function") {
        this.listenDeleteProduct();
      }
    }

    componentDidMount() {
        Actions.load();

        const {select_product} = this.props;

        if (select_product) {
            this.onChangeSelectProduct(select_product);
        }

        ProductsStore.closeModal();
        
        this.listenAdd = Actions.add.completed.listen((data) => {
            console.log("!!!! LISTEN createInventory"); 
            this.setState({
              "step": data['step'],
              'waiting': false,
              "id": data['id']
            });
        });

        this.listenAddFailed = Actions.add.failed.listen((res) => {
            console.log("!!!! LISTEN Failed Inventory", res); 
            let data = {'error': 'internal-service-error'};
            
            if (res && typeof(res['response']) !== "undefined") {
              data = JSON.parse(res['response']);
            }

            
            this.setState({
              "message": T(data['error']),
              'waiting': false,
            });
        });

        this.listenPut = Actions.put.completed.listen((data, ids) => {
            console.log("PUT Inventory, ", data); 
            let product_datas = {};

            const isNotError = ids.reduce((acc,cur) => {
              acc[parseInt(cur)] = false
              return acc
            }, {})

            this.setState(prev => ({
              isErrorById: {
                ...prev.isErrorById,
                ...isNotError
              }
            }))

            let d = this.state.data;
            Object.assign(d, data['total']);

            if (data.is_batch) {
                Actions.load();
                product_datas = this.prepareItems(data['data'], this.getMid())
                this.product_data_list = [];
                this.forgot_products = [];

                for (let i in product_datas) {
                  this.product_data_list.push(parseInt(product_datas[i]['id']));
                }

            } else {
                if (data['success'] && data['data']) {
                  data = data['data']
                }

                let pid = data['product_id'];

                product_datas = this.state.product_data;
                let product_data = product_datas[pid];

                if (!product_data) {
                    product_data = {};
                }

                Object.assign(product_data, data);

                product_datas[pid] = product_data;
            }

            this.setState({
              "product_data": product_datas,
              "data": d
            });
        });

        this.listenDeleteProduct = Actions.delete.completed.listen((res) => {
          if (res['success']) {
            let d = this.state.data;
            Object.assign(d, res['total']);
            this.setState({
              data: d
            })
          }
        })

        this.listenPutFailed = Actions.put.failed.listen((res, ids) => {
            console.log("!!!! LISTEN Put Failed Inventory", res);
            let data = {'error': 'internal-service-error'};
            
            if (res && typeof(res['response']) !== "undefined") {
              data = parseJSON(res['response']);
            }

            const isError = ids.reduce((acc,cur) => {
              acc[parseInt(cur)] = true
              return acc
            }, {})

          this.setState(prev => ({
            isErrorById: {
              ...prev.isErrorById,
              ...isError
            }
          }))



            this.setState({
              "message": T(data['error']),
            });
        });

        this.listenOnAddAll = Actions.addAllProduct.completed.listen((res) => {
          this.listenOnLoad = Actions.load.completed.listen((data) => {
            const getInventoryById = InventoryStore.getInventoryById(this.state.id)
            const productData = this.prepareItems(getInventoryById.items, getInventoryById.marketplace_id, this.state.step)
            this.product_data_list = getInventoryById.items.map(item => parseInt(item.product_id));
            this.updateData(productData)
            this.setState({
              addAllWaiting: false,
              isButtonAll: false
            })
          });
          this.listenOnLoadFailed = Actions.load.failed.listen((res) => {
            this.setState({
              addAllWaiting: false
            })
          })
        })
        this.listOnAddAllFailed = Actions.addAllProduct.failed.listen((res) => {
          const error = errorHandler(res)
          this.setState({
            "message": error,
            addAllWaiting: false
          });
        })
    }

  getMid() {
      return this.state.marketplace_id.value || this.state.marketplace_id;
    }

    getExpensesRow(expenses_id) {
        let expenses = ExpensesStore.getExpensesById(expenses_id);
        if (!expenses) {
            return;
        }
        return(
            <div key={"k1-"+expenses_id}>
                <b className="has-text-success" > {T('paid')}: <Money aid={expenses['account_id']} amount={expenses['amount']}  className="has-text-success font-size-120" wrapper={DOM.span}/></b> 
                {" "+T('from_account')} <b>{T(ExpensesStore.getAccountName(expenses['account_id']))}</b><br />
            </div>
          )
    }


    prepareItems(items, mid, step) { 
      let product_data = {}

     for (let key in items) {
        const pr = items[key],
              pid = pr['product_id'].toString();
        let data_info = ProductsStore.getProductByMid(pid, mid);
        if (data_info) {
          product_data[pid] = this.formattingProductInfo(data_info, mid, pr, step);
        } else {
          product_data[pid] = this.formattingProductInfoRemoveProduct(pid);
        }
      }
      return product_data;
    }



    getMessage() {
        setTimeout(() => {
          this.setState({
            "message": null
          })
        }, 4000);
        return (
              <Notification color="danger">
                    <ScrollToTopOnMount />
                    {this.state.message}
                    <Button remove onClick={() => this.setState({message: null})} />
              </Notification>
        )
    }

    nextStep() {
        let step = this.state.step + 1, error_message;

        if (this.state.status === "completed") {
          this.setState({"step": 2});
          return;
        }
        
        if (step === 1) {
          let data = {
            marketplace_id: this.state.marketplace_id.value,
            comment: this.state.comment,
            step: 0
          };

          Actions.add(data);

        }
        else if (step === 2) {
            if (Object.keys(this.state.product_data).length < 1) {
                error_message = "select-min-one-item-inventory";
            } 

            if (this.changing_products.length > 0) {
                error_message = "changed-inventory";
            }

            if (error_message) {
              this.setState({
                "message": T(error_message)
              })
              return;
            }

            let products_by_mid = ProductsStore.getProductsByMid(this.getMid());
            let forgot_products = products_by_mid.filter((pr) => this.product_data_list.indexOf(parseInt(pr['id'])) === -1);

            this.forgot_products = []

            for (let forgot of forgot_products) {
              if(forgot.type_product === 3) {
                continue;
              }
              let pid = forgot['id'];
              let d = ProductsStore.getProductByMid(pid, this.getMid());
              if (d && d['quantity'] > 0) {
                  let product_info = this.formattingProductInfo(d, this.getMid());
                  product_info['quantity_fact'] = 0; 
                  this.forgot_products.push(product_info);
              }
            }

            this.setState({"step": step});
        } else if (step === 3) {
          if (this.forgot_products.length > 0 && !this.approv_forgot_products) {
              confirm('confirm-text-inventory', T('confirm-text-inventory-text',[this.forgot_products.length]), this.confirmForgot, T('continue-btn'))
          } else {
            this.setState({"waiting": true});
            Actions.add({"inventory_id": this.state.id, "step": step});
          }
        } else {
          this.setState({"step": step});
        }

        // useEffect(() => {
          window.scrollTo(0, 0);
        // }, [])
    }

    backStep() {
        this.setState({"step": this.state.step - 1});
    }

    confirmForgot() {
        this.approv_forgot_products = true;
        this.nextStep();
    }

    deleteInventoryItem(pid) {
      let product_data = this.state.product_data;
      // const state = this.state;
      if (typeof(product_data[pid]) != "undefined") {
        Actions.delete({
            'mov_id': this.state.id,'iid': product_data[pid]['iid'], 'type': 'item'
        });

        delete product_data[pid];

        let new_list = this.product_data_list.filter((a) => parseInt(a) !== parseInt(pid));


        // for (let i in this.product_data_list) {
        //     if (parseInt(this.product_data_list[i]) !== parseInt(pid)) {
        //       new_list.push(this.product_data_list[i]);
        //     }
        // }

        this.product_data_list = new_list;

        this.updateData(product_data);

        // let data = {
        //     "pid": pid,
        //     "sid": state.id
        // }          
      }
    }

    updateData(product_data, active_pid) {
        let result = this.calcSaleAmount(product_data),
           count_page = Math.ceil(this.product_data_list.length/this.state.count_per_page);
        this.setState({
            'result': result,
            'product_data': product_data,
            'count_page': count_page,
            'page': count_page >= (this.state.page+1) ? this.state.page : count_page,
            'active_pid': active_pid ? active_pid : false
        });


        if (active_pid) {
          setTimeout(()=>{this.setState({'active_pid': false})}, 2000)
        }
    }

    calcSaleAmount(products) {
      let should = 0,
          fact = 0,
          defi = 0;
      for (let i in products) {
        const prod = products[i];
        // amount += parseFloat(prod['raw_net_price']*count_item);
        should += parseFloat(prod['quantity_should']);
        fact += parseFloat(prod['quantity_fact']);
        defi += parseFloat(prod['quantity_deficit']);
      }


      return {should, fact, defi};
    }

    formattingProductInfo(data, mid, info, step) {
      let name = data['name'],
          instock = data['quantity'], inventory_id,
          fact = 0, defic = 0, should=0, iid = 0,
          amount_fact = 0, amount_defic = 0, net_price = 0;

      if (typeof(data['stock']) !== "undefined" && mid &&
        typeof(data['stock'][mid]) !== "undefined") {
        instock = data['stock'][mid]['quantity'];
        net_price = data['stock'][mid]['raw_net_price'];
      }


      if (info) {
        fact = info['quantity_fact'];
        defic = info['quantity_deficit'];
        should = info['quantity_should'];
        inventory_id = info['inventory_id'];
        amount_fact = info['amount_fact'];
        amount_defic = info['amount_deficit'];
        iid = info['id'];

        if (should !== instock && (step && step === 1)) {
          if (this.changing_products.indexOf(parseInt(data['id'])) === -1) {
            this.changing_products.push(parseInt(data['id']));
          }
          should = instock;
        }
      } else {
          defic = 0 - instock;
          should = instock;
          fact = 0;
          inventory_id = this.state.id;
          amount_defic = defic * net_price;
      }

      return {
                'id': data['id'].toString(),
                'iid': iid,
                'name': name,
                'brand': data['brand'] || "",
                'raw_net_price': data['netprice'] || 0,
                'sku': data['sku'],
                'asin': data['asin'],
                'instock': instock || 0,
                "inventory_id": inventory_id,
                "quantity_should": should,
                "quantity_fact": fact ,
                "quantity_deficit": defic,
                "amount_fact": amount_fact ,
                "amount_deficit": amount_defic,
                "product_id": data['id'],
                'wid': data['wid']
              }
    }

    formattingProductInfoRemoveProduct(pid) {
      return {
            'id': pid,
            'remove': true,
            'name': T('product-remove'),
            'brand': "",
            'amount_fact': 0,
            'amount_deficit': 0,
            'raw_net_price':  0,
            'quantity_should': 0,
            'quantity_fact': 0,
            'quantity_deficit': 0,
            "product_id": pid,
            'sku': "",
            'asin': "",
            'instock': "",
            'quantity': "",
            'wid': ""
          }
    }

    onChangeSelectProduct(selectedOption, need_delete) {
      let product_data = this.state.product_data, product_info;;
      for (let p in selectedOption) {
          let pid = selectedOption[p]['value'].split("_")[0],
              data = ProductsStore.getProductByMid(pid, this.getMid());

              if (data) {
                if (need_delete) {
                  this.deleteInventoryItem(pid);
                } else {
                  let index_elem = this.product_data_list.indexOf(parseInt(pid))
                  if (index_elem > -1 ) {
                    var targetElement = this.product_data_list.splice(index_elem, 1)[0]; 
                    this.product_data_list.unshift(targetElement);

                    this.onChangeCount(false, pid)
                    return false;
                  }

                  product_info = this.formattingProductInfo(data, this.getMid());
                  this.product_data_list.unshift(parseInt(pid))
                  product_info['quantity_fact'] = 0; 
                  product_data[pid] = product_info;
                  setTimeout(()=> this.onChangeCount(false, pid), 100);
              }
            }
      } 

      if (product_info) {
          console.log("change product");
          // this.putRow(product_info)
      }

      // this.listenAdd = Actions.add.completed.listen(() => {
      //     this.setState({"step": 2});
      // });

      this.updateData(product_data);
    }

    tryAgainPutRow(pid) {
      const productByPid = this.state.product_data[pid]

      this.putRow(productByPid)
    }

    putRow(data) {

      if (this.delayTimeById[data.id]) {
        clearTimeout(this.delayTimeById[data.id]);
      }

      function formatData(product_info, inventory_id) {
        let pr = {
          "inventory_id": inventory_id,
          "quantity_should": product_info['quantity_should'],
          "quantity_fact": product_info['quantity_fact'],
          "quantity_deficit": product_info['quantity_deficit'],
          "product_id": product_info['product_id'],
          "amount_fact": product_info['amount_fact'],
          "amount_deficit": product_info['amount_deficit'],
        };

        if (typeof(product_info['iid']) !== "undefined") {
          pr['iid'] = product_info['iid'];
        }

        return pr;
      }

      let d = [];
      if (Array.isArray(data) && data.length > 0) {
        for (let product of data) {
          d.push(formatData(product, this.state.id));
        }
      } else {
        d = [formatData(data, this.state.id)];
      }

      this.delayTimeById[data.id] = setTimeout(() => {
        Actions.put({ 'data': d });
      }, 1000);
    }


    onChangeSelect(selectedOption, action) {
      let product_data = this.state.product_data;
      this.setState({
        [action.name]: selectedOption,
        "product_data": product_data
      });
    }


    onChangeDate(value) {
        this.setState({
            'date': value
        });
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
            [name]: value
        });
    }

    onChangeProductData(evt, name) {
      const value = evt.target.value,
            pid = evt.target.name.split("-")[1];

        let product_data = this.state.product_data;
        product_data[pid][name] = value;

        this.updateData(product_data)
    }


    onChangeCount(evt, _pid) {
        let value, pid;
        if (evt) {
            value = evt.target.value;
            pid = evt.target.name.split("-")[2];
        }

        let product_data = {...this.state.product_data};


        if (_pid) {
          pid = _pid;
          value = product_data[pid]['quantity_fact'] + 1;
        }

        if(this.state.isErrorById[pid]) {
          return
        }

        product_data[pid]['quantity_fact'] = value !== "" ? parseFloat(value) : value ;
        // if (product_data[pid]['quantity_should'] < 0) {
        //   product_data[pid]['quantity_deficit'] = (product_data[pid]['quantity_should'] + product_data[pid]['quantity_fact'])
        // } else {
          product_data[pid]['quantity_deficit'] = (product_data[pid]['quantity_should'] - product_data[pid]['quantity_fact']) * -1;
        // }
        product_data[pid]['amount_fact'] = product_data[pid]['raw_net_price'] * product_data[pid]['quantity_fact'];
        product_data[pid]['amount_deficit'] = product_data[pid]['raw_net_price'] * product_data[pid]['quantity_deficit'];
        this.putRow(product_data[pid]);
        this.updateData(product_data, pid);

        for (let i in this.changing_products){
          if (parseInt(this.changing_products[i]) === parseInt(pid)) {
            this.changing_products.splice(i, 1);
          }
        }
    }

    getProductName(prod) {
      const pid = prod.id;

      let name = "";

      // if (prod.asin && prod.asin !== "") {
      //     name += prod.asin+". ";
      // }

      if (prod.sku  && prod.sku !== "") {
          name += prod.sku+". ";
      }

      if (prod.brand && prod.brand !== "") {
          name += prod.brand+". ";
      }

      name += prod.name;

      return  ( 
          <span>ID: {pid}. {name}</span>
      );
      
    }

    productLine(prod) { 
      return this.props.isMobileSize ? this.productLineMobile(prod) : this.productLineDesktop(prod);
    }
    
    productLineMobile(prod) { 
        const pid = prod.id,
              name = this.getProductName(prod);

        let className = "font-size-80";
        if (this.changing_products.indexOf(parseInt(pid)) > -1) {
          className += " has-background-light-warning";
        }

        if (this.state.active_pid === pid) {
          className=" highlight"
        }

        const isErrorLine = this.state.isErrorById[pid]


        return (
          <List.Item key={pid} className={className} style={isErrorLine ? {background: 'red', color: 'white'} : {}}>
                <Heading style={isErrorLine ? {color: 'white'} : {}} size={6} className="has-text-info">{name}</Heading>

                <Columns className="is-mobile">
                  <Columns.Column>
                <Field horizontal>
                        <Label style={isErrorLine ? {color: 'white'} : {}} >{T('should-inventory')}:</Label>
                        <b>{prod.quantity_should} {T('items-p')}</b>
                 </Field>
                 </Columns.Column>
                 <Columns.Column>
                <Field horizontal>
                      <Label style={isErrorLine ? {color: 'white'} : {}}>{T('In-stock')}:</Label>
                      <Control iconRight>
                          <Input
                              key={"count-pid-"+pid} 
                              name={"count-pid-"+pid} 
                              type="number" 
                              className="for-slim-icon "
                              disabled={isErrorLine}
                              onChange={this.onChangeCount} 
                              value={this.state.product_data[pid]['quantity_fact']}
                              />
                              <Icon align="right" className="for-slim-icon ">
                                  <span className="small-text">{T('items-p')}</span>
                              </Icon>
                          </Control>
                </Field>
                </Columns.Column>
                <Columns.Column>
                      <Label style={isErrorLine ? {color: 'white'} : {}}>{T('report_result')}:</Label>
                      <b>{this.state.product_data[pid]["quantity_deficit"]} {T('items-p')}</b>
                    </Columns.Column>
                </Columns>
                <div>
                  {isErrorLine && <Button size='small' rounded onClick={() => this.tryAgainPutRow(pid)}>
                    {T('try-again')}
                  </Button>}
                  {!isErrorLine && <Link to="#" onClick={() => this.deleteInventoryItem(pid)}
                         title={T('delete')}>
                    <FontAwesomeIcon icon="trash-alt"
                                     size="1x"/> {T('delete-from-list')}
                  </Link>}
                </div>
          </List.Item>
        )

    }

    productLineDesktop(prod) { 
        const pid = prod.id,
              name = this.getProductName(prod);

        let className = "font-size-80";
        if (this.changing_products.indexOf(parseInt(pid)) > -1) {
          className += " has-background-light-warning";
        }

        if (this.state.active_pid === pid) {
          className=" highlight"
        }

        const isErrorLine = this.state.isErrorById[pid]

        return (
          <List.Item key={pid} className={className} style={isErrorLine ? {background: 'red', color: 'white'} : {}}>
              <Columns  style={{alignItems:'center'}}>
                <Columns.Column size='two-fifths'>
                    {name}
                </Columns.Column>
                <Columns.Column>
                  <Columns className={this.props.isMobileSize ? "is-mobile" : ""} style={{alignItems:'center'}}>
                      <Columns.Column >
                         <b>{prod.quantity_should} {T('items-p')}</b>
                      </Columns.Column >
                      <Columns.Column >
                        <Control iconRight>
                          <Input
                              key={"count-pid-"+pid} 
                              name={"count-pid-"+pid} 
                              type="number" 
                              className="for-slim-icon " 
                              min="1"
                              disabled={isErrorLine}
                              onChange={this.onChangeCount} 
                              value={this.state.product_data[pid]['quantity_fact']}
                              />
                              <Icon align="right" className="for-slim-icon ">
                                  <span className="small-text">{T('items-p')}</span>
                              </Icon>
                          </Control>
                    </Columns.Column>
                    <Columns.Column>
                      <b>{this.state.product_data[pid]["quantity_deficit"]} {T('items-p')}</b>
                    </Columns.Column>
                    {UserStore.getPermissionEnable('incomeinformation') && <Columns.Column>
                      <Money amount={prod['amount_deficit']}
                             wrapper={DOM.span}/>
                    </Columns.Column>}
                    <Columns.Column>
                      {isErrorLine && <Button size='small' rounded onClick={() => this.tryAgainPutRow(pid)}>
                        {T('try-again')}
                      </Button>}
                      {!isErrorLine && <Link to="#"
                             onClick={() => this.deleteInventoryItem(pid)}
                             title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="1x"/>
                      </Link>}
                    </Columns.Column>
                  </Columns>
              </Columns.Column>
            </Columns>
          </List.Item>
        )
    }



    getProductLineTitle(withAction) {
      return (
        <List.Item className="font-size-80 bold has-background-light nowrap">
              <Columns>
                <Columns.Column size={"two-fifths"}>
                    {T('product-name')}
                </Columns.Column>
                <Columns.Column>
                  <Columns>
                    <Columns.Column >
                        {T('should-inventory')}
                    </Columns.Column>
                    <Columns.Column>
                        {T('In-stock')}
                    </Columns.Column> 
                    <Columns.Column>
                        {T('inventory-result')}
                    </Columns.Column>
                    {UserStore.getPermissionEnable('incomeinformation') && <Columns.Column>
                      {T('expected_goods_worth_short')}
                    </Columns.Column>}
                    {withAction && <Columns.Column>
                      {T('reports-actions')}
                    </Columns.Column>}
                  </Columns>
              </Columns.Column>
            </Columns>
          </List.Item>
        );
    }

    getInventoryList() {
      return this.props.isMobileSize ? this.getInventoryListMobile() : this.getInventoryListDesktop();
    }

    getInventoryListMobile() {
        

      let empty_list = (
              <List.Item key="s-1" className="font-size-80"><Heading size={6} className="has-text-danger ">{T('select-min-one-item-inventory')}</Heading></List.Item>
          );

      let inventory_list = this.getList();

      return (
        <>
          {this.filterAllAndError()}
          {this.getPaginationBlock()}
            <List hoverable className="inventory-order-list">
                {inventory_list.length > 0 ? inventory_list : empty_list}
            </List>
          {this.getPaginationBlock()}
        </>
      );
    }

    getPaginationBlock() {
        return (
          <div style={{margin: 10}}>
            <PaginationBlock
                    setPage={(page)=>{this.setState({page: parseInt(page)})}}
                    totalPage={this.state.count_page}
                    currentPage={this.state.page}
            />
          </div>
        );
    }

    getList() {
      const sp = this.state.product_data;



      let start = this.state.page*this.state.count_per_page,
          end = start + this.state.count_per_page,
          inventory_list = [];


          const product_data_list = JSON.parse(JSON.stringify(this.product_data_list))

          const productDataListWithFilter = product_data_list.filter(pid => {
            const filter = this.state.filterProduct

            if(filter === 'error') {
              const changingProduct = this.state.errorProducts
              return changingProduct.includes(pid)
            }

            return true
          })

          if(this.state.count_page !== Math.ceil(productDataListWithFilter.length/this.state.count_per_page)) {
            this.setState({count_page: Math.ceil(productDataListWithFilter.length/this.state.count_per_page)})
          }

          const for_show = productDataListWithFilter.slice(start,end)
          for (let i in for_show) {
            const pid = for_show[i];
            let product = sp[pid];


            if (product) {
              inventory_list.push(this.productLine(product));
            }
        }
        return inventory_list;
    }

    getInventoryListDesktop() {
      let empty_list = (
            <List.Item key="s-1" className="font-size-80"><Heading size={5} className="has-text-danger ">{T('inventory-title-step-2-desc')}</Heading></List.Item>
          );

      let inventory_list = this.getList();

      return (
        <>
          {this.getPaginationBlock()}
            <List hoverable className="inventory-order-list">
                {this.getProductLineTitle(true)}
                {inventory_list.length > 0 ? inventory_list : empty_list}
            </List>
          {this.getPaginationBlock()}
        </>
        );
    }

    getCurrency(aid) {
      if (aid && aid.value) {
        return ExpensesStore.getAccountCurrency(aid.value);
      }

      return User.getCurrency(this.state.currency.value);
    }

    renderInfoBlock() {
      return (
          <Box className="margin-top-10">
              <Columns>
                <Columns.Column>
                    <Heading size={5}>{T('should-inventory')}</Heading>
                    <div><b>{this.state.result.should} {T('items-e')} | {this.product_data_list.length } {T('items-short')}</b></div>
                </Columns.Column>
                <Columns.Column>
                    <Heading size={5}>{T('In-stock')}</Heading>
                     <div><b> {this.state.data.quantity || 0} {T('items-e')} </b>  <br />
                       {UserStore.getPermissionEnable('incomeinformation') && (
                         <>
                           <b>{T('on-amount')}: </b>
                           <Money amount={this.state.data['amount']} wrapper={DOM.span}/>
                         </>)}
                     </div>
                </Columns.Column> 
                <Columns.Column>
                    <Heading size={5}>{T('report_result')}</Heading>
                    <div>
                      <b>{T('shortage')}: </b> {this.state.data['deficit_quantity'] }{T('items-e')}
                      {UserStore.getPermissionEnable('incomeinformation') && (
                        <>
                          <b>{T('on-amount')}: </b> <Money
                          amount={this.state.data['deficit_amount']}
                          wrapper={DOM.span}/>
                        </>
                      )}
                      <br/>
                      {this.state.data['expenses_id'] && this.getExpensesRow(this.state.data['expenses_id'])}
                      <b>{T('surplus')}: </b> {this.state.data['surplus_quantity']} {T('items-e')}
                      {UserStore.getPermissionEnable('incomeinformation') && (
                        <>
                          <b>{T('on-amount')}: </b> <Money
                          amount={this.state.data['surplus_amount']}
                          wrapper={DOM.span}/>
                        </>
                      )}
                      <br />
                      {UserStore.getPermissionEnable('incomeinformation') && (
                        <>
                          <b>{T('difference')}: </b> <Money
                          amount={this.state.data['deficit_amount'] + this.state.data['surplus_amount']}
                          wrapper={DOM.span}/>
                        </>
                      )}
                    </div>
                  {UserStore.getPermissionEnable('incomeinformation') && (
                    <>
                      {this.state.data['deficit_amount'] !== 0 && this.state.step > 3 && !this.state.data['expenses_id'] ? this.getExpensesButton() : null}
                    </>
                  )}
                </Columns.Column>
                      
              </Columns>
          </Box>
        )
    }

    getExpensesButton() {
        return (
          <OpenInsideModal size="small" text={T('add-inventory-expenses')}>
              <AddExpensesModal amount={Math.abs(this.state.data['deficit_amount'])} comment={T('inventory-expenses-comment', [Math.abs(this.state.data['deficit_quantity']), this.state.data['created_at'].split(" ")[0]])} inventory_id={this.state.id} className='modal-start'/>
          </OpenInsideModal>
          )
    }
   
    renderStepOne() {
      return (
          <Box>
            <InfoBlock title={'inventory-title-step-1'} text="" />
            <Columns>
              <Columns.Column>
                    <SelectComponent 
                        name="marketplace_id"
                        label="history-data-marketplace_id"
                        onChange={this.onChangeSelect} 
                        value={this.state.marketplace_id} 
                        list={WarehouseStore.getWarehouseShipmentList}
                        load={WarehouseActions.load}
                        creatable={false}/>
                </Columns.Column>
            </Columns>
            <Field>
              <Label>{T('comment')}</Label>
              <Control>
                <Textarea  size="small" 
                      name="comment" 
                      type="text" 
                      placeholder={T('inventory-comment-placeholder')}
                      onChange={this.onChange} 
                      value={this.state.comment}
                      />
              </Control>
            </Field>
            <Button.Group position="centered">
              <Button  size="large"  rounded color="primary"
                onClick={this.nextStep}
                >{T('next-btn')}
              </Button>
              <Button  size="large" rounded color="light"
                onClick={() => {Actions.load(); this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    filterAllAndError() {
      const list = [
        {
          title: 'all',
          value: 'all'
        },
        {
          title: 'products-with-error',
          value: 'error'
        }
      ]

      const handleChange = ({value}) => {
        this.setState({
          filterProduct: value,
          errorProducts: JSON.parse(JSON.stringify(this.changing_products))
        })
      }

      return <RadioButtonListControl
        name='filterProduct'
        valueRadio={this.state.filterProduct}
        propsWrapper={this.props.isMobileSize ? {} :  {style: {position: 'absolute', left: 0}}}
        list={list}
        onChange={handleChange}
      />
    }

    renderStepTwo() {
      const handleAddAll = () => {
        this.setState({
          addAllWaiting: true
        })
        InventoryActions.addAllProduct(this.state.id);
      }

      return (
          <Box>
            <InfoBlock title={'inventory-title-step-2'} text={T('inventory-title-step-2-desc')} />
            <div style={{position: "relative"}}>
              {!this.props.isMobileSize && this.filterAllAndError()}
              {this.state.isButtonAll && <SubmitButton
                text='add-all-products'
                fullwidth={false}
                size='small'
                style={{position: 'absolute', right: 0}}
                submit={handleAddAll}
                waiting={this.state.addAllWaiting}
                color='info'
              />}
              <SelectProducts
                onChange={this.onChangeSelectProduct}
                value={this.state.products_select}
                quantity={0}
                selected={() => {return this.product_data_list}}
                // pids={this.product_data_list}
                source="inventory"
                mid={this.getMid()} />
            </div>

            <div key={this.state.refresh}>
              {this.getInventoryList()}
            </div>

            {this.renderInfoBlock()}
            <Button.Group position="centered">
              <Button  size="large"  rounded color="primary"
                onClick={this.nextStep}
                >{T('summarize-btn')}
              </Button>
              <Button  size="large" rounded color="light"
                onClick={() => {Actions.load(); this.setState({close: true})}}
                >{T('continue-later')}</Button>
            </Button.Group>
        </Box>
      );
    }

    addForgot() {
      this.putRow(this.forgot_products);
    }

    renderStepThird() {
      let surplus_list = [];
      let shortage_list = [];
      let forgot_list = [];

      const sp = this.state.product_data;
      for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = sp[pid];
          if (product['quantity_deficit'] > 0) {
            surplus_list.push(this.productLineConfirm(product));
          } else if (product['quantity_deficit'] < 0) {
            shortage_list.push(this.productLineConfirm(product));
          }
      }

      let forgot_result = {'deficit_quantity': 0, 'deficit_amount': 0};

      for (let pr of this.forgot_products) {
        forgot_list.push(this.productLineConfirm(pr))
        forgot_result['deficit_quantity'] += pr['quantity_deficit']
        forgot_result['deficit_amount'] += pr['amount_deficit']
      }

      return (
          <Box>
            {shortage_list.length > 0 ?
            <>
            <Heading>{T('shortage')}</Heading>
            <List hoverable className="inventory-order-list">
                  {this.props.isMobileSize ? null : this.getProductLineTitle()}
                  {shortage_list}
            </List>
            <div align="right">
                <b>{T('shortage')}: </b> {this.state.data['deficit_quantity']} {T('items-e')}
              {UserStore.getPermissionEnable('incomeinformation') && <>
                <b>{T('on-amount')}</b> <Money
                amount={this.state.data['deficit_amount']}
                wrapper={DOM.span}/>
              </>}
              <br/>
            </div>
            </>
              : null}

            {surplus_list.length > 0 ? 
            <>
            <Heading className="margin-top-20">{T('surplus')}</Heading>
            <List hoverable className="inventory-order-list">
                  {this.props.isMobileSize ? null : this.getProductLineTitle()}
                  {surplus_list}
            </List>
            <div align="right">
                <b>{T('surplus')}: </b> {this.state.data['surplus_quantity']} {T('items-e')} <b>{T('on-amount')}</b> <Money amount={this.state.data['surplus_amount']} wrapper={DOM.span} />
            </div>
            </> : null}

            {forgot_list.length > 0 ?
              <>
                <Heading className="margin-top-20">{T('not-add-to-list')}</Heading>
                <InfoBlock title='' color="warning" text="not-add-to-list-desc" />
                <List hoverable className="inventory-order-list">
                  {this.props.isMobileSize ? null : this.getProductLineTitle()}
                  {forgot_list}
                </List>
                <div align="right">
                  <Button rounded color="primary" onClick={()=>{confirm('add-to-list', T('add-to-list-count',[forgot_list.length]), this.addForgot)}}>{T('add-to-list')}</Button> <br />
                  <b>{T('shortage')}: </b> {forgot_result['deficit_quantity']} {T('items-e')} <b>{T('on-amount')}</b> <Money amount={forgot_result['deficit_amount']} wrapper={DOM.span} /> <br />
                </div>
              </>
              : null}

            {this.renderInfoBlock()}
            <Button.Group position="centered">
              <Button  size="large"  rounded color="primary"
                onClick={this.backStep}
                >{T('back-btn')}
              </Button>
              <SubmitButton text='complete-inventory-btn' fullwidth={false} waiting={this.state.waiting} submit={this.nextStep}/>

              <Button  size="large" rounded color="light"
                onClick={() => {Actions.load(); this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    productLineConfirmMobile(prod) {
      const pid = prod.id,
              name = this.getProductName(prod);
        return (
            <List.Item key={pid} className="">
                  <b className="has-text-info">{name}</b>
                  <Columns className="is-mobile">
                      <Columns.Column>
                          {T('should-inventory')}: <b>{prod.quantity_should} {T('items-p')} </b><br />
                          {T('In-stock')}: <b>{prod.quantity_fact} {T('items-p')}</b>
                      </Columns.Column>
                      <Columns.Column>
                          {T('report_result')}: <b>{prod["quantity_deficit"]} {T('items-p')}</b> <br />
                        {UserStore.getPermissionEnable('incomeinformation') && <>{T('on-amount')}: <b><Money
                          amount={prod['amount_deficit']}
                          wrapper={DOM.span}/></b></>}
                      </Columns.Column>
                  </Columns>
            </List.Item>
          )
    }

    productLineConfirm(prod) { 
        if (this.props.isMobileSize) return this.productLineConfirmMobile(prod);

        const pid = prod.id,
              name = this.getProductName(prod);
        return (
            <List.Item key={pid} className="font-size-80">
              <Columns >
                <Columns.Column size={"two-fifths"}>
                    {name}
                </Columns.Column>
                <Columns.Column>
                  <Columns >
                      <Columns.Column >
                         <b>{prod.quantity_should} {T('items-p')}</b>
                      </Columns.Column >
                      <Columns.Column >
                          <b>{prod['quantity_fact']} {T('items-p')}</b>
                    </Columns.Column>
                    <Columns.Column>
                      <b>{prod["quantity_deficit"]} {T('items-p')}</b>
                    </Columns.Column>
                    {UserStore.getPermissionEnable('incomeinformation') && <Columns.Column>
                      <Money amount={prod['amount_deficit']}
                             wrapper={DOM.span}/>
                    </Columns.Column>}
                  </Columns>
              </Columns.Column>
            </Columns>
          </List.Item>
        )
    }


    renderStepReport() {
      
      let surplus_list = [];
      let shortage_list = [];

      const sp = this.state.product_data;
      for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = sp[pid];
          if (product['quantity_deficit'] > 0) {
            surplus_list.push(this.productLineConfirm(product));
          } else if (product['quantity_deficit'] < 0) {
            shortage_list.push(this.productLineConfirm(product));
          }
      }

      return (
          <Box>
            <Heading>{T('inventory')} {this.state.date.split(" ")[0]}</Heading>

            {this.renderInfoBlock()}
            <Heading>{T('shortage')}</Heading>
            <List hoverable className="inventory-order-list">
                  {this.props.isMobileSize ? null : this.getProductLineTitle()}
                  {shortage_list}
            </List>
            <div align="right">
                <b>{T('shortage')}: </b> {this.state.data['deficit_quantity']} {T('items-e')}
              {UserStore.getPermissionEnable('incomeinformation') && <>
                <b>{T('on-amount')}</b> <Money
                amount={this.state.data['deficit_amount']} wrapper={DOM.span}/>
                <br/>
              </>}
            </div>

            <Heading className="margin-top-20">{T('surplus')}</Heading>
            <List hoverable className="inventory-order-list">
                  {this.props.isMobileSize ? null : this.getProductLineTitle()}
                  {surplus_list}
            </List>
            <div align="right">
                <b>{T('surplus')}: </b> {this.state.data['surplus_quantity']} {T('items-e')}
              {UserStore.getPermissionEnable('incomeinformation') && <>
                <b>{T('on-amount')}</b> <Money
                amount={this.state.data['surplus_amount']}
                wrapper={DOM.span}/>
              </>

              }
            </div>
            <Button.Group position="centered" className="margin-top-20">
              <Button  size="large" rounded color="light"
                onClick={() => {Actions.load(); this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    renderStepFinish() {
      return (
        <Box>
          <h1 className="title">{T('inventory-finished')}</h1>
          <Button.Group>
              <Button fullwidth size="large"  rounded color="primary"
                onClick={() => {Actions.load(); this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() { 
        const stepNaviganor = [this.renderStepOne, this.renderStepTwo, this.renderStepThird, this.renderStepFinish, this.renderStepReport],
              currentStep = stepNaviganor[this.state.step];
      return (
              <div>
                {this.state.message ? this.getMessage() : ""}
                {currentStep()}
                {this.state.close ? redirectTo("/inventory") : ""}
              </div>
        );
    }

}

//<Heading>Оформление поставки товара</Heading>

export default connectToStores(withIsMobileSize(AddInventory), {
    products: ProductsStore,
    inventory: InventoryStore 
  });
