import React, {useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import T from "../../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import ModalItsOK from "../../../../../components/modal-its-ok";
import UserActions from "../../../../../user/user-actions";
import ActionsModal from "../common/actions-modal";
import ApiKeyInputWithGenerate
	from "../common/api-key-input-with-generate";
import IntegrationListenerHook from "../common/integration-listener.hook";
import {handleDeleteIntegration} from "../utils";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import {generateKey} from "../../../../../tools/genarete-key";
import IntegrationControlHook from "../common/integration-control.hook";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import SelectComponent from "../../../../../tools/select-component";
import WarehouseActions from "../../../../warehouse/warehouse-actions";
import {parseJSON} from "../../../../../tools/error-handler";
import SalesStore from "../../../../sales/sales-store";
import AppStore from "../../../../../app-store";
import AddChannelModal from "../../../add-channel-modal";

const ApiModal = ({data, wooID, onClose,}) => {
	const {id, api_key, is_active, ident} = data
	const identParse = parseJSON(ident || {})

	const [waiting, setWaiting] = useState(false)
	const [apiKey, setApiKey] = useState(api_key || generateKey( 32))


	let midsAccess = null;

	if(identParse.midsAccess === null) {
		midsAccess = [{value: 0, label: T('all')}]

	} else if(Array.isArray(identParse.midsAccess)) {
		midsAccess = identParse.midsAccess.map(item => ({value: item, label: WarehouseStore.getWarehouseName(item)}))
	}

	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		selectValue,
		handleChangeSelect
	} = IntegrationControlHook({
		selectDefaultValue: {
			midsAccess,
			channel_id: identParse.channel_id ? {value: identParse.channel_id} : false,
			midReserv: identParse.midReserv ? {value: identParse.midReserv} : false,
		}
	})
	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleSubmit = ({deactivate}) => {
		const identData = {}

		if(!selectValue.midsAccess || !selectValue.midReserv || !selectValue.channel_id){
			handleAutoRemoveError('not all fields required')
			return
		}

		identData.midReserv = selectValue.midReserv.value
		identData.channel_id = selectValue.channel_id.value

		identData.midsAccess = selectValue.midsAccess.value || null

		if(selectValue.midsAccess[0].value === 0) {
			identData.midsAccess = null
		} else {
			identData.midsAccess = selectValue.midsAccess.map(item => item.value)
		}

		const data = {
			integration_id: 15,
			api_key: apiKey,
			is_active: 1,
			ident: JSON.stringify(identData)
		}
		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(!!apiKey && apiKey.length === 32) {
			UserActions.updateIntegrations(data)
		} else {
			handleAutoRemoveError('not all fields required')
		}
	}

	const handleChangeSelectMid = (value, meta) => {
		if(meta.option?.value === 0) {
			handleChangeSelect([{value: 0, label: T('all')}], meta)
		} else if(value) {
			const filterWithoutAll = value.filter(item => item.value !== 0)
			handleChangeSelect(filterWithoutAll, meta)
		} else {
			handleChangeSelect(value, meta)
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T(`integration-${wooID}`)])}</Heading>
				<ApiKeyInputWithGenerate value={apiKey} label='api-key-integration-label' onClickGenerateKey={setApiKey}  />
				<SelectComponent
					name="channel_id"
					label='sale-channel'
					placeholder='channel'
					onChange={handleChangeSelect}
					value={selectValue.channel_id}
					list={() => SalesStore.getChannelListWithoutIntegration(identParse.channel_id)}
					addItem={({name}) => AppStore.openModal(<AddChannelModal name={name} />)}
					addItemModal={() => AppStore.openModal(<AddChannelModal/>)}
					creatable
				/>
				<SelectComponent
					name="midsAccess"
					empty='all'
					label='access-warehouses'
					onChange={handleChangeSelectMid}
					value={selectValue.midsAccess}
					list={WarehouseStore.getWarehouseList}
					load={WarehouseActions.load}
					placeholder='select-warehouse-label'
					className='full-size-select'
					isMulti
				/>
				<SelectComponent
					name="midReserv"
					label='reservation-warehouse'
					onChange={handleChangeSelect}
					value={selectValue.midReserv}
					list={WarehouseStore.getWarehouseList}
					load={WarehouseActions.load}
					placeholder='select-warehouse-label'
				/>
				<ActionsModal
					onClose={onClose}
					waiting={waiting}
					isEdit={id}
					isActive={is_active}
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
				/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage}/>}
		</>

	);
};

export default ApiModal;