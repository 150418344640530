import React from "react";
import Reports from "../../../../components/reports";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../../components/i18n";
import GeneralSubReportRowDesktop from "./general-sub-report-row-desktop";
import { useIsMobile } from "../../../../components/is-mobile-context/is-mobile-context";
import GeneralSubReportRowMobile from "./general-sub-report-row-mobile";
import usePaginationHook from "../../../../tools/pagination-hook";

const EmptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

const GeneralSubReports = ({ data, activeFilter, setSortValue}) => {
  let COLUMNS = [
    "name",
    "sales-amounts",
    "dashboard-sales-profit",
    "sales",
    "average-check",
    "many-margin",
  ];

  let sortColumns = [true, true, true, true, true, true]

  const { isMobileSize } = useIsMobile();
  const { start, end, page, setPage, total, PaginationBlock } =
    usePaginationHook({
      perPage: isMobileSize ? 5 : 10,
      listLength: data.length,
    });

  if (activeFilter === "mid") {
    COLUMNS.push("Expenses");
  }

  if(activeFilter === "clients") {
    COLUMNS = [
      ...COLUMNS.slice(0, 1),
      'phone',
      ...COLUMNS.slice(1)
    ];

    sortColumns = [
      ...sortColumns.slice(0, 1),
      false,
      ...sortColumns.slice(1)
    ];
  }

  return (
    <>
      <PaginationBlock setPage={setPage} totalPage={total} currentPage={page} />
      <Reports
        emptyMessage={EmptyMessage}
        columns={COLUMNS}
        rows={data.slice(start, end)}
        isSortingColumns={sortColumns}
        sortActions={(value) => setSortValue(value)}
      >
        {isMobileSize ? (
          <GeneralSubReportRowMobile role="row" activeFilter={activeFilter} />
        ) : (
          <GeneralSubReportRowDesktop role="row" activeFilter={activeFilter} />
        )}
      </Reports>
      <PaginationBlock setPage={setPage} totalPage={total} currentPage={page} />
    </>
  );
};

export default GeneralSubReports;
