import { createActions } from "tools/reflux-tools";


const IntegrationBindingActions = createActions({
	remoteProductsByIid: { asyncResult: true },
	deleteBinding: {asyncResult: true},
	binding: {asyncResult: true},
	deleteMassBinding: {asyncResult: true}
});


export default IntegrationBindingActions;