import React, {useEffect, useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import { Label } from "react-bulma-components/lib/components/form";
import T from "../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import { LocalIcon } from "whitelables/wl-name/icons";
import Heading from "react-bulma-components/lib/components/heading";
import {Link, useLocation} from "react-router-dom";
import Button from "react-bulma-components/lib/components/button";
import OrdersStore from "../orders/orders-store";
import OrdersActions from "../orders/orders-actions";
import { connectToStores } from "../../tools/reflux-tools";
import ProductsActions from "./products-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductsStore from "./products-store";
import {confirmDelete} from "../../tools/confirm-delete";
import WarehouseStore from "../warehouse/warehouse-store";
import AppStore from "../../app-store";

const CheckReservShipModal = ({ pid, reservLength, realQuantity, mid, onClose }) => {
  const [reserveOrdersList, setReserveOrdersList] = useState([])
  const [reserveSalesList, setReserveSalesList] = useState([])
  const [waitingShipmentList, setWaitingShipmentList] = useState([])

  const [isUndefinedReserv, setIsUndefinedReserv] = useState(0)
  const [realReservLength, setRealReservLength] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const location = useLocation()

  useEffect(() => {
    OrdersActions.loadReserveWaiting();

    const deleteSuperfluous = OrdersActions.deleteSuperfluousReserv.completed.listen(() => {
      setIsUndefinedReserv(realReservLength)
      ProductsActions.load()
    })

    const loadReserveWaiting = OrdersActions.loadReserveWaiting.completed.listen(() => {
      setIsLoading(false)
      setReserveOrdersList(OrdersStore.getReserveWaitingByPid(
        "reserve",
        "orders",
        pid,
        mid
      ))
      setReserveSalesList(OrdersStore.getReserveWaitingByPid(
        "reserve",
        "sales",
        pid,
        mid
      ))
      setWaitingShipmentList(OrdersStore.getReserveWaitingByPid(
        "waiting",
        "shipment",
        pid
      ))
    })

    const deleteReserv = OrdersActions.deleteReserve.completed.listen(() => {
      onClose()
    })

    return () => {
      deleteSuperfluous()
      loadReserveWaiting()
      deleteReserv()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const realReservLengthSale = reserveSalesList.reduce((acc, cur) => acc + cur?.quantity || 0 ,0)
    const realReservLengthOrder = reserveOrdersList.reduce((acc, cur) => acc + cur?.quantity || 0 ,0)
    const realReservLengthCalc = realReservLengthSale + realReservLengthOrder

    setRealReservLength(realReservLengthCalc)
    setIsUndefinedReserv(reservLength - realReservLengthCalc)
  }, [reserveSalesList, reserveOrdersList, reservLength]);

  const handleRemoveSuperfluousReserv = () => {
    OrdersActions.deleteSuperfluousReserv({pid, mid: mid || null, instock: realQuantity - realReservLength})
  }

  const handleDeleteReserveProduct = ({product_id, quantity, marketplace_id, oid}) => {
    const message = <div>{ProductsStore.getProductName(product_id, true)} <b>{quantity} {T("items-p")}</b></div>
    confirmDelete(() => OrdersActions.deleteReserve([{pid: product_id, mid: marketplace_id, roid: oid }]), 'confirm-delete-reserve', message, 'confirm-order-shift', 'cancel-label')
  }

  const handleChangeOrderFilter = ({created_at,order_id}) => {
      OrdersActions.filter('date_start', new Date(created_at))
      OrdersActions.filter('search', order_id)
      AppStore.closeAllModal()
  }

  return (
    <Box>
      <Heading size={3}>{T("detail-info-reserv-shipment")}</Heading>
      {!!reserveSalesList?.length && (
        <>
          <Label>{T("reserv-list-sale")}</Label>
          <List className="view-list-scroll-400">
            {reserveSalesList.map((item) => (
              <List.Item
                className="display-flex-row-gap"
                key={item["order_id"]}
                style={{ alignItems: "center" }}
              >
                <div className="display-flex-row-gap">
                  <span>{item["created_at"]}</span>
                  <span>#{item["order_id"]}</span>
                  <span className="silver">
                    {T("items-count", { count: item["quantity"] })}
                  </span>
                  {(item.mid || item.marketplace_id) &&  <span className="silver">( {WarehouseStore.getWarehouseName(item.mid || item.marketplace_id)} )</span>}
                </div>
                <Link
                  to={{
                    pathname: "/sales/reserv",
                    state: {
                      status: "reserve_sale",
                      search: `${item["order_id"]}`,
                      tab: "reserve_sale",
                      period: new Date(item.created_at),
                    },
                  }}
                  onClick={onClose}
                >
                  <LocalIcon icon={"search"} />
                </Link>
              </List.Item>
            ))}
          </List>
        </>
      )}
      {!!reserveOrdersList?.length && (
        <>
          <Label>{T("reserv-list-order")}</Label>
          <List className="view-list-scroll-400">
            {reserveOrdersList.map((item) => (
              <List.Item
                key={item["order_id"]}
                className="display-flex-row-gap"
                style={{ alignItems: "center" }}
              >
                <div className="display-flex-row-gap">
                  <span>{item["created_at"]}</span>
                  <span>{item["order_text"] ? item["order_text"] : `#${item["order_id"]}`}</span>
                  <span className="silver">
                    {T("items-count", { count: item["quantity"] })}
                  </span>
                  {(item.mid || item.marketplace_id) &&  <span className="silver">( {WarehouseStore.getWarehouseName(item.mid || item.marketplace_id)} )</span>}
                </div>
                <div className='display-flex-row'>
                  {location.pathname.includes('orders') ? (
                    <span onClick={() => handleChangeOrderFilter(item)}>
                      <LocalIcon icon={"search"} className="cursor-pointer"/>
                    </span>
                  ) : <Link
                    to={{
                      pathname: "/orders",
                      state: {
                        search: `${item["order_text"] || item["order_id"]}`,
                        date_start: new Date(item["created_at"]),
                      },
                    }}
                    onClick={onClose}
                  >
                    <LocalIcon icon={"search"}/>
                  </Link>}
                  <Button className='button-link-icon' onClick={() => handleDeleteReserveProduct(item)}>
                    <FontAwesomeIcon icon='undo' />
                  </Button>
                </div>
              </List.Item>
            ))}
          </List>
        </>
      )}
      {!!isUndefinedReserv && !isLoading && <List>
        <List.Item className='display-flex-row-gap'>
          {isUndefinedReserv < 0 ? T('not-enough-reserves') :T('unknown-reserves')} {Math.abs(isUndefinedReserv)} {T('items-p')}
          <Button onClick={handleRemoveSuperfluousReserv} rounded size='small' color='success'>
            {isUndefinedReserv < 0 ? T('reserve') :T('release-reserves-btn')}
          </Button>
        </List.Item>
      </List>}
      {!!waitingShipmentList?.length && (
        <>
          <Label>{T("reserv-shipment-list")}</Label>
          <List className="view-list-scroll-400">
            {waitingShipmentList.map((item) => (
              <List.Item
                key={item["shipment_id"]}
                className="display-flex-row-gap"
                style={{ alignItems: "center" }}
              >
                <div className="display-flex-row-gap">
                  <span>{item["created_at"]}</span>
                  <span>#{item["shipment_id"]}</span>
                  <span className="silver">
                    {T("items-count", { count: item["quantity"] })}
                  </span>
                  {(item.mid || item.marketplace_id) &&  <span className="silver">( {WarehouseStore.getWarehouseName(item.mid || item.marketplace_id)} )</span>}
                </div>
                <Link
                  to={{
                    pathname: "/shipments",
                    state: {
                      search: `${item["shipment_id"]}`,
                      period: new Date(item.created_at),
                    },
                  }}
                  onClick={onClose}
                >
                  <LocalIcon icon={"search"} />
                </Link>
              </List.Item>
            ))}
          </List>
        </>
      )}
      {!reserveSalesList?.length && !reserveOrdersList?.length && !waitingShipmentList?.length && (
        <List>
          <List.Item>{T('clear-list')}</List.Item>
        </List>)}
      <Button.Group position="right">
        <Button color="light" rounded onClick={onClose}>
          {T("close")}
        </Button>
      </Button.Group>
    </Box>
  );
};

export default connectToStores(CheckReservShipModal, { _: OrdersStore, product: ProductsStore });
