import React, { Component } from "react";
import Button from 'react-bulma-components/lib/components/button';
import { Link } from "react-router-dom";
import T from 'components/i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalIcon } from 'whitelables/wl-name/icons'
import AppStore from 'app-store';
import {withIsMobileSize} from "../../is-mobile-context/is-mobile-context";


class OpenInsideModal extends Component {

    render() {
        const props = this.props;
        let size = props.size || (this.props.isMobileSize ? "small" : "large"), button;

        let className = ""
        let style = null;

        if (props.className) {
          className += " "+props.className;
        }

        if(props.style) {
          style = props.style;
        }

        if (props.renderAs === "b") {
           className += " click_element has-text-info";
           button = (
            <b
              size={size} 
              className={className}
              style={style}
              onClick={(evt) => {evt.stopPropagation(); AppStore.openModal(props.children); this.props.onClick && this.props.onClick()}}
              disabled={props.disabled || false}
            >
            {(props.icon) ?  <span><FontAwesomeIcon icon={props.icon} size="1x" />&nbsp;</span> : ""}{T(props.text)}
            {(props.img) ? props.img : null}
            </b>
          );
        } else if (props.renderAs === "p") {
           className += " click_element"
           button = (
            <p
              size={size}
              style={style}
              className={className}
              onClick={(evt) => {evt.stopPropagation(); AppStore.openModal(props.children); this.props.onClick && this.props.onClick()}}
            >
               {(props.icon) ? <span><FontAwesomeIcon icon={props.icon} size="1x" />&nbsp;</span> : (props.local_icon) ? <LocalIcon icon={props.local_icon} width="20px" height="25px" fill={props.iconFill ? props.iconFill : false} />: ""}{T(props.text)}
            </p>
          );
        } else if (props.renderAs === "icon") {
            button = (
            <Link to="#" style={style} className={className} onClick={(evt) => {evt.stopPropagation(); AppStore.openModal(props.children); this.props.onClick && this.props.onClick()}} title={T(props.text)}>
              {props.icon && <FontAwesomeIcon icon={props.icon}
                                size={props.size ? props.size : "1x"}
                                title={T(props.text)}/>}
              {props.local_icon && <LocalIcon icon={props.local_icon} size={props.size ? props.size : "small"} />}
            </Link> 
            )
        } else {
           className += props.renderAs === "a" ? " button_link" : ""

           button = (
            <Button
              style={style}
              size={size} className={className} 
              rounded color={props.color || "info"} renderAs={props.renderAs || "button"}
              onClick={(evt) => {evt.stopPropagation(); AppStore.openModal(props.children); this.props.onClick && this.props.onClick()}}
              disabled={props.disabled || false}
            >
               {(props.icon) ? <span><FontAwesomeIcon icon={props.icon} size={props.iconSize ? props.iconSize : "1x" }/>&nbsp;</span> : ""}{T(props.text)}
            </Button>
          );
       }

        return button;
    }
}

export default withIsMobileSize(OpenInsideModal);