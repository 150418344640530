import React from 'react'
import {Box} from "react-bulma-components";
import T from "../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import {Label} from "react-bulma-components/lib/components/form";
import Image from "react-bulma-components/lib/components/image";

const InfoOlx = ({data}) => {
	return data && (
		<Box className="margin-top-10">
			<Heading size={5}>{T("info-order-chats")}</Heading>
			<div className='display-flex-row' style={{ alignItems: "flex-start" }}>
				{data.images && <Image size={64} src={data.images[0]?.url}/>}
				<div className='display-flex-column' style={{textAlign: "left"}}>
					<Label>{data.title}</Label>
					<p>{T('history-data-price')}: <span className='has-text-success'>{data.price.value} {data.price.currency}</span></p>
				</div>
			</div>
			<a className='button is-success is-rounded is-small' target='_blank' rel="noopener noreferrer" href={data.url}>{T('go-to-order')}</a>
		</Box>
	);
};

export default InfoOlx;