import React, { useState } from "react";
import { Label } from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import { Button, List } from "react-bulma-components";
import GeneralSubReports from "../common/general-sub-reports";
import RadioButtonListControl from "../../../../components/radio-buttons-list/radio-button-list-control";

const list = [
  {
    title: "fiscalize-warehouse",
    value: "mid",
  },
  {
    title: "clients",
    value: "clients",
  },
  {
    title: "sale-channel",
    value: "channels",
  },
];

const GeneralRowMobile = ({ data, groupDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState("mid");

  let labelDate = data.label;

  if (groupDate === "days") {
    labelDate = new Date(parseInt(data.label)).toLocaleDateString();
  }

  const handleToggleOpen = (evt) => {
    evt.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleToggleOpenCategory = () => {
    setIsOpenCategory((prev) => !prev);
  };

  const handleChangeActiveFilter = ({ value }) => {
    setIsActiveFilter(value);
  };

  const rows = Object.values(data[isActiveFilter] || {});

  return (
    <tr>
      <td style={{ padding: 10 }}>
        <div className="cursor-pointer" onClick={handleToggleOpen}>
          <Label size="large">{labelDate}</Label>
          {!isOpen && (
            <div>
              <div>
                <span className="silver">
                  {T("sales-amounts")}({T("sales-amounts-revenue")}):{" "}
                </span>
                <b>
                  <Money
                    signClassname
                    amount={data?.amount_sale || 0}
                    wrapper={DOM.span}
                  />
                </b>
              </div>
              <div>
                <span className="silver">
                  {T("dashboard-sales-profit")} ({T("gross-profit")}):{" "}
                </span>
                <b>
                  <Money
                    signClassname
                    amount={(data?.profit || 0) - (data?.expensesProfit || 0)}
                    wrapper={DOM.span}
                  />
                </b>
              </div>
            </div>
          )}
        </div>
        {isOpen && (
          <div>
            <Label>{T("sales-all")}</Label>
            <List>
              <List.Item>
                <span className="silver">
                  {T("sales-amounts")}({T("sales-amounts-revenue")}):{" "}
                </span>
                <b>
                  <Money
                    signClassname
                    amount={data?.amount_sale || 0}
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">
                  {T("dashboard-sales-profit")} ({T("gross-profit")}):{" "}
                </span>
                <b>
                  <Money
                    signClassname
                    amount={(data?.profit || 0) - (data?.expensesProfit || 0)}
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("other_replenish")}: </span>
                <b>
                  <Money
                    amount={data.receipt}
                    wrapper={DOM.span}
                    signClassname
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("total-sales")}: </span>
                <b>{data?.count || 0 }</b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("sold-units")}: </span>
                <b>
                  {data?.quantity || 0} {T("items-e")}
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("average-check")}: </span>
                <b>
                  <Money
                    signClassName
                    amount={data?.averageCheck || 0}
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("average-margin")}: </span>
                <b>{data?.margin || 0}%</b>
              </List.Item>
            </List>
            <Label>{T("Expenses")}</Label>
            <List>
              <List.Item>
                <span className="silver">{T("expenses-shipment")}: </span>
                <b>
                  <Money
                    amount={data?.shipmentExpenses || 0}
                    wrapper={DOM.span}
                    className="has-text-danger"
                  />
                </b>
              </List.Item>
              {!!data?.expensesProfit && (
                <List.Item>
                  <span className="silver">{T("expenses-profit-sales")}: </span>
                  <b>
                    <Money
                      amount={data?.expensesProfit || 0}
                      wrapper={DOM.span}
                      className="has-text-danger"
                    />
                  </b>
                </List.Item>
              )}
              <List.Item>
                <span className="silver">
                  {T("expenses-without-shipment")}:{" "}
                </span>
                <b>
                  <Money
                    amount={data?.amountExpenses || 0}
                    wrapper={DOM.span}
                    className="has-text-danger"
                  />
                </b>
              </List.Item>
              {!!data?.notCalcExpenses && (
                <List.Item>
                  <span className="silver">{T("expenses-not-deducted")}: </span>
                  <b>
                    <Money
                      amount={data?.notCalcExpenses || 0}
                      wrapper={DOM.span}
                      className="h as-text-danger"
                    />
                  </b>
                </List.Item>
              )}
            </List>
            <Label>{T("shipment")}</Label>
            <List>
              <List.Item>
                <span className="silver">{T("expected_goods_worth")}: </span>
                <b>
                  <Money amount={data.pendingAmount || 0} wrapper={DOM.span} />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("received_goods_worth")}: </span>
                <b>
                  <Money
                    className={"has-text-success"}
                    amount={data.completeAmount || 0}
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("not-paid")}: </span>
                <b>
                  <Money
                    className="has-text-danger"
                    amount={
                      (data.pendingAmount || 0) +
                      (data.completeAmount || 0) -
                      (data.paidAmount || 0)
                    }
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
            </List>
            <Label>{T("report_result")}</Label>
            <List>
              <List.Item>
                <span className="silver">{T("total-funds-received")}: </span>
                <b>
                  <Money
                    amount={(data.amount_sale || 0) + (data.receipt || 0)}
                    signClassname
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("sales-profit")}: </span>
                <b>
                  <Money
                    amount={
                      (data.profit || 0) +
                      (data.receipt || 0) -
                      (data.expensesProfit || 0) -
                      (data.amountExpenses || 0)
                    }
                    signClassname
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
              <List.Item>
                <span className="silver">{T("operating-profit")}: </span>
                <b>
                  <Money
                    amount={
                      (data.amount_sale || 0) +
                      (data.receipt || 0) -
                      (data.expensesProfit || 0) -
                      (data.amountExpenses || 0) -
                      (data.shipmentExpenses || 0) -
                      (data.expensesDeliveryShipment || 0)
                    }
                    signClassname
                    wrapper={DOM.span}
                  />
                </b>
              </List.Item>
            </List>
            <div align="right">
              <Button color="info" onClick={handleToggleOpenCategory}>
                {T(isOpenCategory ? "hide-more-filter" : "unfold")}
              </Button>
            </div>
            {isOpenCategory && (
              <div>
                <RadioButtonListControl
                  name={`show-on-${data.label}`}
                  label="show-on"
                  valueRadio={isActiveFilter}
                  list={list}
                  onChange={handleChangeActiveFilter}
                />
                <GeneralSubReports
                  data={rows}
                  activeFilter={isActiveFilter}
                />
              </div>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default GeneralRowMobile;
