import React, { useState } from "react";
import GeneralRowContent from "./general-row-content";
import GeneralSubReports from "../common/general-sub-reports";
import RadioButtonListControl from "../../../../components/radio-buttons-list/radio-button-list-control";
import SelectComponent from "../../../../tools/select-component";
import ClientsStore from "../../../clients/clients-store";
import SalesStore from "../../../sales/sales-store";
import T from "../../../../components/i18n";
import WarehouseStore from "../../../warehouse/warehouse-store";
import {uaSort} from "../../../../tools/sort-by-key/ua-sort";
import DropdownDocument from "../common/dropdown-document";

const list = [
  {
    title: "fiscalize-warehouse",
    value: "mid",
  },
  {
    title: "clients",
    value: "clients",
  },
  {
    title: "sale-channel",
    value: "channels",
  },
];

const GeneralRowDesktop = ({ data, groupDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState("mid");
  const [filter, setFilter] = useState({
    mid: [{ value: 0, label: T("all") }],
    clients: [{ value: 0, label: T("all") }],
    channels: [{ value: 0, label: T("all") }],
  });

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChangeActiveFilter = ({ value }) => {
    setIsActiveFilter(value);
  };

  const clientsListForSelect = Object.values(data.clients || {}).map((item) => {
    const client = ClientsStore.getClientById(item.name);

    return {
      name: client.name,
      id: item.name,
    };
  });
  const channelsListForSelect = Object.values(data.channels || {}).map(
    (item) => {
      const channelName = SalesStore.getChannelsName(item.name);
      return {
        name: T(channelName),
        id: item.name,
      };
    }
  );
  const midsListForSelect = Object.values(data.mid || {}).map((item) => {
    const midName = WarehouseStore.getWarehouseName(item.name);
    return {
      name: midName,
      id: item.name,
    };
  });

  const handleChangeFilter = (selectedOption, action) => {
    if (!selectedOption) {
      setFilter((prev) => ({
        ...prev,
        [action.name]: [{ value: 0, label: T("all") }],
      }));
    } else if (action.option?.value === 0) {
      setFilter((prev) => ({
        ...prev,
        [action.name]: [action.option],
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        [action.name]: selectedOption.filter((item) => item?.value !== 0),
      }));
    }
  };

  const [sortValue, setSortValue] = useState(null)

  const rows = Object.values(data[isActiveFilter] || {});

  const rowsWithFilter = rows.filter((item) => {
    if (isActiveFilter === "mid" && filter && !!filter.mid[0].value) {
      return filter.mid.some(
        (filter) => parseInt(filter.value) === parseInt(item.name)
      );
    } else if (isActiveFilter === "clients" && filter && !!filter.clients[0].value) {
      return filter.clients.some(
        (filter) => parseInt(filter.value) === parseInt(item.name)
      );
    } else if (isActiveFilter === "channels" && filter && !!filter.channels[0].value) {
      return filter.channels.some(
        (filter) => parseInt(filter.value) === parseInt(item.name)
      );
    }
    return item;
  });

  rowsWithFilter.sort((a, b) => {
    if(sortValue) {
      const keyValues = Object.entries(sortValue || {})
      const name = keyValues[0][0]
      const direction = keyValues[0][1];

      if(name === 'name') {
        let nameA = "";
        let nameB = "";

        if(isActiveFilter === 'clients') {
          nameA = ClientsStore.getClientById(a.name)?.simple_name
          nameB = ClientsStore.getClientById(b.name)?.simple_name
        }
        if(isActiveFilter === 'mid') {
          nameA = WarehouseStore.getWarehouseName(a.name)
          nameB = WarehouseStore.getWarehouseName(b.name)
        }
        if(isActiveFilter === 'channels') {
          nameA = SalesStore.getChannelsName(a.name)
          nameB = SalesStore.getChannelsName(b.name)
        }

        if(direction === 1) {
          return uaSort(nameA, nameB)
        } else if(direction === 0) {
          return uaSort(nameB, nameA)
        }
      } else {
        let valueA = 0;
        let valueB = 0;

        if(name === 'sales-amounts') {
          valueA = a?.amount_sale || 0
          valueB = b?.amount_sale || 0
        } else if(name === 'dashboard-sales-profit') {
          valueA = (a?.profit || 0) - (a?.expensesProfit || 0)
          valueB = (b?.profit || 0) - (b?.expensesProfit || 0)
        } else if(name === 'sales') {
          valueA = a?.count || 0
          valueB = b?.count || 0
        } else if(name === 'average-check') {
          valueA = a?.averageCheck || 0
          valueB = b?.averageCheck || 0
        } else if(name === 'many-margin') {
          valueA = a?.margin || 0
          valueB = b?.margin || 0
        }

        if (direction === 1 ) {
          if (valueA < valueB) return 1;
          if (valueA > valueB) return -1;
        } else if (direction === 0) {
          if (valueA > valueB) return 1;
          if (valueA < valueB) return -1;
        }
      }
    }

    return 0
  })


  let labelDate = data.label;

  if (groupDate === "days") {
    labelDate = new Date(parseInt(data.label)).toLocaleDateString();
  }


  return (
    <>
      <GeneralRowContent
        data={data}
        groupDate={groupDate}
        onToggleOpen={handleToggleOpen}
        isOpen={isOpen}
      />
      {isOpen && (
        <tr className={isOpen ? "selected-border" : ""}>
          <td colSpan={6}>
            <div className='display-flex-row-gap' style={{alignItems: 'center'}}>
              <RadioButtonListControl
                name={`show-on-${data.label}`}
                label="show-on"
                valueRadio={isActiveFilter}
                list={list}
                onChange={handleChangeActiveFilter}
              />
              <DropdownDocument data={rowsWithFilter} activeFilter={isActiveFilter} labelDate={data?.name || labelDate}/>
            </div>
            {isActiveFilter === "mid" && midsListForSelect.length > 1 && (
              <SelectComponent
                name="mid"
                empty="all"
                className="full-size-select"
                list={() => midsListForSelect}
                onChange={handleChangeFilter}
                value={filter.mid}
                isMulti
              />
            )}
            {isActiveFilter === "clients" &&
              clientsListForSelect.length > 1 && (
                <SelectComponent
                  name="clients"
                  empty="all"
                  className="full-size-select"
                  list={() => clientsListForSelect}
                  onChange={handleChangeFilter}
                  value={filter.clients}
                  isMulti
                />
              )}
            {isActiveFilter === "channels" &&
              channelsListForSelect.length > 1 && (
                <SelectComponent
                  name="channels"
                  empty="all"
                  className="full-size-select"
                  list={() => channelsListForSelect}
                  onChange={handleChangeFilter}
                  value={filter.channels}
                  isMulti
                />
              )}
          </td>
        </tr>
      )}
      {isOpen && (
        <tr className={isOpen ? "selected-border" : ""}>
          <td colSpan={6}>
            <GeneralSubReports
              data={rowsWithFilter}
              activeFilter={isActiveFilter}
              setSortValue={setSortValue}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default GeneralRowDesktop;
