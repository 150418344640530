import React, { useEffect, useRef, useState } from "react";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import { Button } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import Title from "../../common/title/title";

const ReviewItem = ({ avatar, name, review }) => {
  return (
    <article className={s.item}>
      <span className={s.wrapper_img}>
        <img src={avatar} alt={T(name)} />
      </span>
      <div className={s.name}>{T(name)}</div>
      <p>{T(review)}</p>
    </article>
  );
};

const ReviewBlock = ({ moreReviewLink, listReview }) => {
  const [translate, setTranslate] = useState(0);
  const ref = useRef();
  const refContainer = useRef();
  const [currentLength, setCurrentLength] = useState(3);
  const [currentWidth, setCurrentWidth] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setCurrentLength(Math.round(ref.current.offsetWidth / 350) || 1);
    }

    if (refContainer.current) {
      setCurrentWidth(refContainer.current.getBoundingClientRect().width);
    }
  }, []);

  const handleClickPrev = () => {
    setTranslate((prev) => {
      if (prev + (300 + 50) >= 0) {
        return 0;
      } else {
        return prev + (300 + 50);
      }
    });
  };
  const handleClickNext = () => {
    setTranslate((prev) => {
      if (Math.abs(prev - 350) >= currentWidth - 350) {
        return -(currentWidth - (350 * currentLength - 50));
      } else {
        return prev - (300 + 50);
      }
    });
  };

  return (
    <Container
      id="review"
      classNameContainer={s.section}
      classNameWrapper={s.wrapper}
    >
      <Title>
        {T('say-about-us')}
      </Title>
      <section className={s.wrapper_slider} ref={ref}>
        {!!translate && (
          <Button
            onClick={handleClickPrev}
            size="large"
            className={`button-icon ${s.button_icon} ${s.left}`}
            color="info"
          >
            <LocalIcon
              icon="arrow-left"
              size="small"
              className={`${s.icon} ${s.prev}`}
            />
          </Button>
        )}
        <section
          ref={refContainer}
          className={s.review}
          style={{ transform: `translateX(${translate}px)` }}
        >
          {listReview?.map((item) => (
            <ReviewItem key={item.name} {...item} />
          ))}
        </section>
        {Math.abs(translate) + 350 * currentLength <= currentWidth && (
          <Button
            onClick={handleClickNext}
            size="large"
            className={`button-icon ${s.button_icon} ${s.right}`}
            color="info"
          >
            <LocalIcon icon="arrow-left" size="small" className={s.icon} />
          </Button>
        )}
      </section>
      <a href={moreReviewLink}>{T("more-reviews")}</a>
    </Container>
  );
};

export default ReviewBlock;
