import React, {useEffect, useState} from 'react'
import Button from "react-bulma-components/lib/components/button";
import { LocalIcon } from 'whitelables/wl-name/icons'
import T from '../i18n'
import ProductsStore from "../../modules/products/products-store";

const FilterViewNow = ({filterView}) => {
	const [renderState, setRenderState] = useState([])

	const handleRenderView = (arrView, arrList) => {
		return arrView?.reduce((acc, curKey) => {
			if((!!arrList[curKey] && arrList[curKey]?.value !== 0) && curKey !== 'attr') {
				const value = {value: arrList[curKey]?.value || arrList[curKey], label: arrList[curKey]?.label || arrList[curKey]}
				acc.push({filterName: curKey,...value})
			}
			if(!!arrList[curKey] && curKey === 'attr') {
				Object.entries(arrList[curKey] || {}).forEach(item => {
					const key = item[0]
					const value = item[1]
					if(value?.value !== 0) {
						acc.push({globalName: curKey, filterName: key, ...value})
					}
				})

			}
			return acc
		}, [])
	}

	useEffect(() => {
			setRenderState(handleRenderView(filterView?.view, filterView?.list))
	}, [filterView]);

	return !!renderState?.length && (
		<Button.Group className='margin-top-10'>
			{renderState.map(item => {
				if(item.globalName === 'attr') {
					return (
						<Button
							key={item.filterName}
							size='small'
							rounded
							onClick={() => filterView?.onDelete(item)}
							className='display-flex-row-gap'
						>
								{ProductsStore.getAttrName(+item?.filterName)}: {T(item.label)}
								<LocalIcon icon='close' size='small'/>
						</Button>)
				} else {
					return (
						<Button
							key={item.filterName}
							size='small'
							rounded
							className='display-flex-row-gap'
							onClick={() => filterView?.onDelete(item)}
						>
							{T(item?.filterName)}: {T(item.label.value || item.label)}
							<LocalIcon icon='close' size='small'/>
						</Button>)
				}})}


		</Button.Group>
	);
};

export default FilterViewNow;