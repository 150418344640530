import React, { useState } from "react";
import { Button, List } from "react-bulma-components";
import { Link } from "react-router-dom";
import T from "../../../components/i18n";
import ExpensesStore from "../../expenses/expenses-store";
import { confirmDelete } from "../../../tools/confirm-delete";
import ProductsActions from "../../products/products-actions";
import AppStore from "../../../app-store";
import AddExpensesCategoryModal from "../../expenses/add-expenses-category-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label } from "react-bulma-components/lib/components/form";
import Money from "../../../components/money";
import UserStore from "../../../user/user-store";

const FinanceCategoryRowMobile = ({ data, num }) => {
  const [isActive, setIsActive] = useState(false);
  const getExpensesByCategoryId = ExpensesStore.getExpensesByCategoryId(
    data.id
  );
  const calcStatistics = ExpensesStore.getCalcStatisticsExpenses(
    getExpensesByCategoryId
  );

  const handleDelete = () => {
    confirmDelete(ProductsActions.deleteCatalog("payments-category", data.id));
  };

  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";

  return (
    <tr className={className} style={{ padding: 5 }} onClick={() => setIsActive((prev) => !prev)}>
      <td>
        <div style={{ padding: 10 }}>
          <div className="display-flex-row-gap">
            <Label size="large" className="margin-bottom0">
              <Link
                to={{
                  pathname: "/transactions",
                  state: { expenses_category: data.id },
                }}
              >
                {T(data.name)}
              </Link>
            </Label>
            {!isActive && (
              <>
                {calcStatistics.sumExpensesByCurrency[
                  UserStore.get("currency_id")
                ] && (
                  <Label size="large" className="margin-bottom0">
                    <Money
                      precision={0}
                      className={"has-text-danger"}
                      amount={
                        calcStatistics.sumExpensesByCurrency[
                          UserStore.get("currency_id")
                        ]
                      }
                    />
                  </Label>
                )}
                {!!calcStatistics.sumReceiptsByCurrency[
                  UserStore.get("currency_id")
                ] && (
                  <Label size="large" className="margin-bottom0">
                    <Money
                      precision={0}
                      className={"has-text-success"}
                      amount={
                        calcStatistics.sumReceiptsByCurrency[
                          UserStore.get("currency_id")
                        ]
                      }
                    />
                  </Label>
                )}
              </>
            )}
          </div>
          {isActive && (
            <>
              <Label>{T("Expenses")}</Label>
              <List>
                {Object.entries(calcStatistics.sumExpensesByCurrency).map(
                  ([currency, value]) => (
                    <List.Item key={`${currency}-${data.id}-expenses`}>
                      <Money amount={value} currency={currency} />
                    </List.Item>
                  )
                )}
                {!Object.entries(calcStatistics.sumExpensesByCurrency)
                  .length && <List.Item>-</List.Item>}
              </List>
              <Label>{T("receipts")}</Label>
              <List>
                {Object.entries(calcStatistics.sumReceiptsByCurrency).map(
                  ([currency, value]) => (
                    <List.Item key={`${currency}-${data.id}-receipts`}>
                      <Money amount={value} currency={currency} />
                    </List.Item>
                  )
                )}
                {!Object.entries(calcStatistics.sumReceiptsByCurrency)
                  .length && <List.Item>-</List.Item>}
              </List>
              {!data.is_global && (
                <div
                  className="display-flex-row"
                  style={{ justifyContent: "space-around" }}
                >
                  <Button
                    className="button-link-icon"
                    onClick={() =>
                      AppStore.openModal(
                        <AddExpensesCategoryModal {...data} isEdit />
                      )
                    }
                  >
                    <FontAwesomeIcon icon="edit" size="2x" />
                  </Button>
                  <Button className="button-link-icon" onClick={handleDelete}>
                    <FontAwesomeIcon icon="trash-alt" size="2x" />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default FinanceCategoryRowMobile;
