import React from 'react'
import {Button} from "react-bulma-components";
import T from '../../../../components/i18n'
import AppStore from "../../../../app-store";
import DocumentationModal from "./documentation-modal";

const DocumentationButton = ({sectionID, buttonTitle}) => {
	return (
		<Button color='warning' size='small' rounded onClick={() => AppStore.openModal(<DocumentationModal sectionID={sectionID}/>)}>
			{T(buttonTitle || 'how-work-section?')}
		</Button>
	);
};

export default DocumentationButton;