import React from "react";
import ChatUserInfo from "../common/chat-user-info";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import Money from "../../../components/money";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confirm from "../../../tools/confirm";
import ChatsActions from "../chats-actions";
import { Button } from "react-bulma-components";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import StatusButton from "../common/status-button";

const ChatRowDesktop = ({ data, num }) => {
  const {isMobileSize} = useIsMobile()
  const history = useHistory();
  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    confirm(
      "confirm-delete",
      T("are-you-sure-to-delete-this-chat"),
      () => ChatsActions.delete({ chat_id: data?.id, type: "chats" }),
      T("delete")
    );
  };

  const handleOpenChat = () => {
    history.push(`/chats/${data?.id}`);
  };

  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";

  return (
    <tr onClick={handleOpenChat} className={`cursor-pointer ${className}`}>
      <td width="40%">
        <ChatUserInfo data={data} />
      </td>
      {!isMobileSize && (
        <>
          <td>
            <StatusButton name={data.status} chatId={data?.id}/>
          </td>
          <td>{data.rid ? User.getResponsebleName(data["rid"]) : "--"}</td>
          <td>{data?.active_orders}</td>
          <td>{data?.all_orders}</td>
          <td>
            <Money amount={data?.total_amount} />
          </td>
          <td>
            <Button onClick={handleDelete} className="button-link-icon">
              <FontAwesomeIcon icon="trash-alt" size="1x" />
            </Button>
          </td>
        </>
      )}
    </tr>
  );
};

export default ChatRowDesktop;
