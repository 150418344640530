import React from 'react'
import DynamicTabList
	from "../../../components/dynamic-tab-list/dynamic-tab-list";

const GeneralTabs = ({tabs, onChange}) => {
	const list = [
		{
			label: "production-template",
			value: "production",
		},
		{
			label: "history-production",
			value: "history",
		},
		{
			label: "production-template-deleted",
			value: "production-deleted",
		},
	];

	return (
		<DynamicTabList list={list} defaultTab={tabs} onChangeTab={onChange} />
	);
};

export default GeneralTabs;