import React, {useEffect, useState} from 'react'
import List from "react-bulma-components/lib/components/list";
import T from "../../../components/i18n";
import formatDate from "../../../tools/format-date";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Money from "../../../components/money";
import DebtLink from "./debt-link";
import ActionsRow from "./actions-row";
import ClientsStore from "../clients-store";
import Tag from "../../../components/tag";
import UserStore from "../../../user/user-store";
import CommunicationClient from "../../../components/communication-client";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../app-store";
import ClientViewModal from "../modal/client-view/client-view-modal";
import DOM from "react-dom-factories";

const ReportRowMobile = ({data, triggerIsOpen}) => {
	const [isOpen, setIsOpen] = useState(false)
	const tagsGroup = ClientsStore.getGroupClientTagByClientId(data['id'])

	useEffect(() => {
		setIsOpen(triggerIsOpen)
	},[triggerIsOpen])

	return (
		<List.Item className='list-bg-fa' onClick={() => setIsOpen(prev => !prev)}>

			<div className='display-flex-column'>
				<div className='display-flex-row-gap margin-bottom-10' style={{display: 'inline-flex', width: '100%'}}>
					<Button className='button-link-without-style font-size-120 bold'
									style={{fontSize: '16px'}}
									onClick={() => AppStore.openModal(<ClientViewModal clientID={data.id}/>)}>
						{data['name']}
					</Button>
					<CommunicationClient phone={data.phone} nickname={data.nickname} style={{width: 'fit-content'}}/>
				</div>
				{!isOpen && <div className='display-flex-row'>
					<span>
						<span className='silver'>{T("clients-total_amount")}: </span>
						<b>
							<Money amount={data['total_amount'] || 0} wrapper={DOM.span}/>
						</b>
					</span>
					<span>
						<span className='silver'>{T("clients-discount")}: </span>
						<b>
							{data['discount'] || 0}%
						</b>
					</span>
				</div>}
			</div>
			{isOpen && <>
				<List align='left'>
					<List.Item>
						<b>{T("clients-date")}</b>: {formatDate(data['created_at'])}
					</List.Item>
					<List.Item>
						<b>{T("clients-discount")}</b>: {data['discount'] || 0}%
					</List.Item>
					<List.Item>
						<b>{T("balance")}</b>: {data['balance']} {UserStore.getCurrency()}
					</List.Item>
					{!!tagsGroup?.length && (
						<List.Item className='tag-group'>
							<b>{T("clients-tags")}</b>: {tagsGroup.map(item => <Tag
							key={`tag-${item.id}`} color={item.color} name={item.name}/>)}
						</List.Item>)}
				</List>
				<Columns className="is-mobile product-mobile-block">
					{UserStore.getPermissionEnable('show-cashflow') &&
						<Columns.Column size="half">
							<Box className="small-number-box">
								<b>{T("clients-total_amount")}</b>: <Money
								amount={data['total_amount'] || 0}/>
							</Box>
						</Columns.Column>}
					<Columns.Column size="half">
						<Box className="small-number-box">
							<b>{T("clients-debt")}</b>: {data['debt'] > 0 ? DebtLink(data) :
							<Money amount={data['debt'] || 0}/>}
						</Box>
					</Columns.Column>
				</Columns>
				<ActionsRow id={data['id']}/>
			</>}
		</List.Item>
	);
};

export default ReportRowMobile
