import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";
import {
    Field, 
    Control, 
    Label, 
} from 'react-bulma-components/lib/components/form';
import T from "components/i18n";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import createOptionSelect from 'tools/create-option-select';
import {colourStyles} from 'tools/select-tools';
import AsyncSelect from 'react-select/async';
import ProductsStore from 'modules/products/products-store'
import ExpensesStore from 'modules/expenses/expenses-store'
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class SelectComponents extends Component {

  constructor(props) {
      super(props);

      // this.selectRef = SelectInstance<ColourOption>;
      this.state = {
        inputValue: false
      }

      this.limitShow = props.showLimit || 100;

      this.onChange = this.onChange.bind(this);
  }

  static defaultProps = {
        readOnly: false
  }

  componentWillUnmount() {
      if (this.listener) {
           this.listener();
      }

      if (this.listen_add) {
           this.listen_add();
      }

      if (this.listenerInner ) {
           this.listenerInner();
      }
  }

  // componentWillReceiveProps() {
  //   console.log("component will reciive props");

  //   setTimeout(()=>{this.checkDefaultValue()}, 50);
  // }

  componentDidMount() {
      const {load, list, addItem, name, onChange} = this.props;
      const select_list = list();
      if (select_list?.length < 1 && load) {
        this.listener = load.completed.listen((data) => {
            // this.setDefVal();
            setTimeout(()=>{this.checkDefaultValue()}, 50);
        });
      } else {
        this.checkDefaultValue()
      }
      if (addItem) {
        if (typeof(addItem.completed) !== "undefined") {
            this.listen_add = addItem.completed.listen((data) => {
                let newSelect =  {value: data['id'], label: data['label']};
                if (newSelect.value) {
                  onChange(newSelect, {"name": name});
                } 

                this.listenerInner = load.completed.listen(() => {
                    onChange(newSelect, {"name": name});
                });
                
            });
        }
      }
  }

  checkDefaultValue() {
    const {value, emptyValue, autoSelect, isMulti} = this.props;

    if (value === null && !emptyValue) {
      this.setDefVal();
    } 

    if (value === null && autoSelect) {
      const res = this.getList(value);
      if (res[0].length === 2) {
          this.setDefVal();
      }
    }

    if (isMulti && value) {
      if (typeof(value[0]) !== "undefined" && value[0].value !== 0 && !value[0].label) {
        this.setDefVal();
      }
    }
  }

  setDefVal() {
    const {value, onChange, name} = this.props,
          res = this.getList(value),
          defValue = res[1];

    if (!defValue || defValue.length === 0) {
      return;
    }

    let option = defValue;

    if (defValue.length === 1) {
      option = defValue[0]
    }

      // console.log("setDefVal ", name, option);
      // if (empty && option.value === 0) {
      //   return;
      // }

    setTimeout(() => {
      onChange(option, {"name": name, "action": 'select-option'});
    }, 10);
    
  }

  getList(val) {
    const {list, empty, emptyValue, isMulti, customCreate, autoSelect} = this.props;
      const select_list = list();
      let ct = [],
          defaultVal = [];

      if (empty) {
        ct.push({'id': customCreate ? "_create" : 0, 'name': T(empty)});
      }

      for (let i in select_list) {
        let cat = select_list[i],
            option = {'id': cat['id'], 'name': T(cat['name']) || `ID:${cat['id']} - ${T('no-name')}`};

        ct.push(option);

        if (val && val.length > 0) {
          for (let k in val) {
            const v = val[k];

            if (v && (parseInt(cat['id']) === parseInt(v.value) || cat['id'] === v.value )) {
              defaultVal.push(option);
            }
          }
        } else {
          if (val && (parseInt(cat['id']) === parseInt(val.value) || cat['id'] === val.value )) {
              defaultVal.push(option);
          }
        }
      }

      if (defaultVal.length === 0 && ct.length > 0 && !isMulti) {
          if (empty && ct.length === 2 && (!emptyValue || autoSelect)) {
            defaultVal.push(ct[1]);
          } else {
            defaultVal.push(ct[0]);
          }
      }

      return [createOptionSelect(ct), createOptionSelect(defaultVal)];
  } 

  onChange(newValue, actionMeta){
      const {onChange, addItem, name, isMulti, addItemModal} = this.props;
      let ref = this.selectRef;
      if (actionMeta.action === "create-option") {
          this.setState({"inputValue": false});
          if (isMulti) {
            addItem({"name": newValue[newValue.length-1].value});
          } else {
            addItem({"name": newValue.value});
          }
      } else if (["select-option", "remove-value"].indexOf(actionMeta.action) > -1) {
          if (!newValue) {
            onChange(newValue, {"name": name});
          } else {
            let value = newValue.value;
            if (isMulti && newValue[newValue.length-1]) {
              value = newValue[newValue.length-1].value;
            }
            
            if (value === "create_new") {
              if (typeof(addItemModal) === "function") {
                addItemModal();
              } else {
                  this.setState({"inputValue": true});
                  ref.blur();

                  setTimeout(() => {
                    ref.focus();
                  }, 50);
              }
              
            } else {
              this.setState({"inputValue": false});
              onChange(newValue, {"name": name});
            } 
          }
      }
  }

  render() {
    const {value, onChange, label, creatable, filterOption, 
      name, readOnly, isOptionDisabled, autoSelect,
      async, _filters, promice, empty, isMulti, className, placeholder} = this.props,
    res = this.getList(value);

    let list = res[0], defaultVal = res[1];

    // this.checkDefaultValue()

    let set_value = value;

    if (set_value  && !set_value.label) {
      if (set_value.value === 0) {
        if (defaultVal.length > 0 && defaultVal[0].value !== 0) {
          this.setDefVal();
        } else {
          set_value['label'] = T(empty);
        }
      } else {
        if (!isMulti) {
          this.setDefVal();
        }
      }
    } 

    if (set_value === null && autoSelect && list.length === 2) {
        this.setDefVal();
    }

    const defaultFilterOption = (candidate, input) => {
        if (candidate.value === 0) {
            return true;
        }

        if (candidate.label === undefined) {
          return false;
        }

        if(!candidate.label) {
          return false
        }

        return candidate.data.__isNew__ || candidate.label.toLowerCase().includes(input.toLowerCase());
    };

    let select, _className = "";

    if (!this.props.isMobileSize) {
      _className = "mySelect";
    }

    if (className) {
      _className += " "+className
    }

    const filterData = (inputValue) => {
        if (_filters) {
          return _filters(inputValue, list)
        }
        if (inputValue.length < 1) return [];

        let result = list.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        let data = result.slice(0, this.limitShow);

        return data;
    };

    const promiseOptions = inputValue =>
      new Promise(resolve => {
            resolve(filterData(inputValue));
        });

    let default_data = list.slice(0,this.limitShow);
    default_data.push({'id': 0, 'name': T(empty)});
    if (creatable) {
        let create_data = {"value": 'create_new', color: '#36B37E', 'label': " + " + T('create')}
        list.push(create_data);
        default_data.push(create_data);
        let additional_props = {};
        if (this.state.inputValue) {
          additional_props = {
            "defaultInputValue": " ",
            "placeholder": T('create_new'),
            // "inputValue": " "
          };
          list = [];
          set_value = null;
          // setTimeout(() => this.selectRef.focus(), 0)
        }

        select = (
          <AsyncCreatableSelect 
                ref={ref => {
                  this.selectRef = ref;
                }}
                loadOptions={promice || promiseOptions}
                defaultOptions={default_data} 
                onChange={this.onChange} 
                styles={colourStyles}
                openMenuOnFocus
                formatCreateLabel={userInput => `${T('create')}: ${userInput}`}
                // placeholder={T('create')}
                isDisabled={readOnly}
                // defaultInputValue={this.state.inputValue || null}
                // inputValue={""}
                placeholder={T(placeholder)}
                filterOption={filterOption || defaultFilterOption}
                isMulti={isMulti}
                isOptionDisabled={isOptionDisabled}
                createOptionPosition="first"
                classNamePrefix={_className}
                name={name}
                value={set_value}
                {...additional_props}
                />
        );
    } else if (async) {
      select = (
        <AsyncSelect 
                defaultOptions={default_data} 
                loadOptions={promice || promiseOptions}
                defaultValue={0}
                placeholder={T(placeholder)}
                isDisabled={readOnly}
                onChange={this.onChange} 
                classNamePrefix={_className}
                name={name}
                value={value} />
        )
    } else {
       select = (
        <Select 
                options={list} 
                onChange={onChange} 
                isDisabled={readOnly}
                placeholder={T(placeholder)}
                classNamePrefix={_className}
                isOptionDisabled={isOptionDisabled}
                isMulti={isMulti}
                name={name}
                value={value} />

        );
    }
     
    return (
        <Field>
            {label && label !== "" ? <Label align="left">{T(label)}</Label> : null}
            <Control>
              {select}
            </Control>
        </Field>
      );
  }
};



export default connectToStores(withIsMobileSize(SelectComponents),
  { 
    expenses: ExpensesStore, 
    products: ProductsStore 
  });
