import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";


// import Notification from 'react-bulma-components/lib/components/notification';
// import Actions from './notification-actions' 
// import NotificationStore from './notification-store' 
import ExpensesStore from '../expenses/expenses-store' 
import ExpensesActions from '../expenses/expenses-actions' 

import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';
import Progress from 'react-bulma-components/lib/components/progress';
import Columns from 'react-bulma-components/lib/components/columns';

import User from 'user/user-store';

import { 
    Select 
} from 'react-bulma-components/lib/components/form';

import T from "components/i18n";
import Money from "components/money";
import { LocalIcon } from 'whitelables/wl-name/icons'
import {Button} from "react-bulma-components";
import AppStore from "../../app-store";
import CustomizeColumnsModal
  from "../../components/customize-columns/modal/customize-columns-modal";
import UserStore from "user/user-store";
import AddExpensesCategoryModal from "../expenses/add-expenses-category-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import AddExpensesModal from "../expenses/add-expenses-modal";


const colors = ['danger', 'warning', 'success'];

class ExpensesCategory extends Component {
  constructor(props) {
        super(props);
        
        this.state = {
            "period": "month-current",
            "max": 0,
            "data": []
      }

      this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
      this.listenLoadCompleted = ExpensesActions.load.completed.listen(() => {
            this.updateData(this.state.period);   
      });

      this.updateData(this.state.period);    
  }

  componentWillUnmount() {
      if (typeof(this.listenLoadCompleted) == "function") {
          this.listenLoadCompleted();
      }

  }

  onChange(evt) {
    const value = evt.target.value;
    this.updateData(value);      
  }

  updateData(period) {
      const [start, end] = this.props.makeDate(period),
            data = ExpensesStore.getExpensesByCategory(start, end);

            //            max = Math.max(...Object.values(data));

      let maxs = []
      for (let i in data) {
          maxs.push(data[i][1]);
      }

      this.setState({
          "period": period,
          "max": Math.max(...maxs),
          "data": data
      })
  }

  getLine(i, _id, amount) {
    return (
          <Columns key={_id} className="margin-top-10 is-mobile">
                  <Columns.Column align="left" size="one-third">
                        <Money amount={amount} className="middle-money"/>
                        <Button className='button-link-without-style' renderAs={Link} to={{
                          pathname: `/transactions`,
                          state: {expenses_category: _id}
                        }}>
                          <div
                            className="is-size-7">{ExpensesStore.getCategoryName(_id)}</div>
                        </Button>
                  </Columns.Column>
            <Columns.Column>
              <Progress max={this.state.max || 100} value={amount} color={colors[i]} size="large" />
                  </Columns.Column>
          </Columns>

      );
  }


  renderView() {  
        let full_amount = 0;
        const getActiveColumn = UserStore.getCustomColumns("pr-expenses-category")

        const arrayActiveColumn = Object.values(getActiveColumn || {})

        const categoryList = this.state.data?.reduce((acc, [categoryID, amount], index) => {
          if(Array.isArray(arrayActiveColumn) && !!arrayActiveColumn.length) {
            const findCategory = arrayActiveColumn.find(category => parseInt(category.id) === parseInt(categoryID));
            if(findCategory && findCategory.is_active) {
              full_amount += amount;
              acc.push({categoryID, amount, position: findCategory.position });
            }
          } else {
            full_amount += amount;
            acc.push({categoryID, amount, position: index + 1});
          }
          return acc
        },[]).sort((a, b) => a?.position - b?.position)

        const getExpensesCategory = ExpensesStore.getCategoryForCustomizing("expenses_category")
        return (
            <Box className="fullwidth">
              <Columns>
                  <Columns.Column  align="left">
                        <Heading size={5}>{T('expenses-for-category')}</Heading>
                  </Columns.Column >
                  <Columns.Column  align="right">
                    <div className="display-flex-row">
                      <Select onChange={this.onChange} name="period" value={this.state.period}>
                        <option value="month-last">{T('month-last')}</option>
                        <option value="month-current">{T('month-current')}</option>
                        <option value="7days">{T('7days')}</option>
                        <option value="30days">{T('30days')}</option>
                        <option value="this-year">{T('this-year')}</option>
                        <option value="last-year">{T('last-year')}</option>
                      </Select>
                      <Button className='button-link-icon'
                              onClick={() => AppStore.openModal(<CustomizeColumnsModal
                                                                            customizeColumnsActive={getActiveColumn}
                                                                            columnsName="pr-expenses-category"
                                                                            modal='customize-columns'
                                                                            customizeColumns={getExpensesCategory}
                                                                            withButtonReset
                              />)}
                      >
                        <LocalIcon icon='gear' size='small'/>
                      </Button>
                    </div>
                  </Columns.Column >
              </Columns>
              <Content align="left" className="widgetExpensesCategory">
                  <Money amount={full_amount*-1} signClassname={true} className="good-money" tooltip="Tooltip Text"/>
                  <div className="is-size-7 margin-bottom-15">{T('expenses-for')} {T(this.state.period)}</div>
                  {categoryList.map(({categoryID, amount}, index) => this.getLine(index, categoryID, amount))}
              </Content>
              <div className='display-flex-row flex-wrap' style={{ justifyContent: 'center' }}>
                <Button className='display-flex-row' size='small' color='info' rounded onClick={() => AppStore.openModal(<AddExpensesCategoryModal defaultValue={"0"} modal="add-expenses-category" />)}>
                  <FontAwesomeIcon icon='plus-circle'/>
                  {T('add-category-btn-label')}
                </Button>
                <Button className='display-flex-row' size='small' color='success' rounded onClick={() => AppStore.openModal(<AddExpensesModal className='modal-start'/>)}>
                  <FontAwesomeIcon icon='plus-circle'/>
                  {T('add-expenses-btn')}
                </Button>
              </div>
            </Box>
        );
  }
  render() {
      return !User.getModuleEnable("payments") ? null : this.renderView();
  }
}

// export default ExpensesCategory;
export default connectToStores(ExpensesCategory, { _: ExpensesStore });

