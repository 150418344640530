import React from 'react';
import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Messages from 'components/messages';
import { Field} from "react-bulma-components/lib/components/form";
import SelectComponent from "../../tools/select-component";
import UserStore from 'user/user-store';
import BaseForm from "../../components/modules/base-form";
import ProductsStore from "./products-store";
import SelectProducts from "../../components/select-products";
import ProductsActions from "./products-actions";
import OrdersActions from "../orders/orders-actions";
import Spinner from "../../templates/spinner";
import {
	withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import SubmitButton from "../../components/submit-button";
import ModalItsOk from "../../components/modal-its-ok";

class BindingIntegrationProductsModal extends BaseForm {
	constructor(props) {
		super(props);

		let name = null;
		if(props.product_id && props.product_mid) {
			name = ProductsStore.generateSelectSingleOption(this.props.product_id,this.props.product_mid, true)
		}


		let waiting = false
		if(props.integration_id) {
			waiting = true
			ProductsActions.changeSelectRemote(props.integration_id)
		}

		if(!this.props.isDefaultValue) {
			ProductsStore.onRemoteProductByPid(this.props.integration_id, this.props.product_id)
		}

		this.state = {
			product_name: name,
			message: null,
			waiting: waiting,
			close: false,
			integration_remote_product_list: null,
			isCreate: !!props.isCreate,
			integration_id: {
				value: props.integration_id || '',
				error: ""
			},
			remote_product: null,
			putIsFinish: false,
		};
		this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
		this.onChangeSelectIntegration = this.onChangeSelectIntegration.bind(this);
		this.delete = this.delete.bind(this)
		this.submit = this.submit.bind(this)
		this.onPutProductTo = this.onPutProductTo.bind(this)
	}

	componentWillUnmount() {
		this.setState({ isMounted: false })
		if (typeof(this.deleteBinding) === "function") {
			this.deleteBinding();
		}

		if (typeof(this.deleteBindingFail) === "function") {
			this.deleteBindingFail();
		}

		if (typeof(this.binding) === "function") {
			this.binding();
		}

		if (typeof(this.bindingFail) === "function") {
			this.bindingFail();
		}
		if(typeof(this.remoteProductID) === "function") {
			this.remoteProductID();
		}

		if(typeof(this.remoteProductIDFail) === "function") {
			this.remoteProductIDFail();
		}

		if(typeof(this.putProduct) === "function") {
			this.putProduct();
		}
		if(typeof(this.putFailed) === "function") {
			this.putFailed();
		}
		if(typeof(this.loadRemoteProduct) === "function") {
			this.loadRemoteProduct();
		}

	}

	componentDidMount() {
		const {onClose} = this.props;
		ProductsActions.remoteProducts()
		this.remoteProductID = ProductsActions.remoteProductByPid.completed.listen(data => {
			this.loadRemoteProduct = ProductsActions.remoteProducts.completed.listen(() => {
				const dataProduct = ProductsStore.getRemoteForSelectProducts()
				const findProduct = data.find(item => parseFloat(item?.integration_id) === parseFloat(this.props?.integration_id))
				const checkID = dataProduct?.some(item => item?.id ===  findProduct?.remote_id?.toString())

				this.setState({
					integration_remote_product_list: data.map(item => item?.integration_id),
					remote_id: findProduct?.remote_id || ''
				})

				if(!!data.length && checkID && findProduct && this.props.integration_id) {
					this.setState({
						remote_product: {value: findProduct?.remote_id?.toString()},
						remote_id: findProduct?.remote_id?.toString()
					})
				}
				setTimeout(() =>  this.setState({waiting: false}) , 20)
			})
		})

		this.remoteProductIDFail = ProductsActions.remoteProductByPid.failed.listen(() => {
			this.setState({waiting: false})
		})

		this.deleteBinding = OrdersActions.deleteBinding.completed.listen((data) => {
			if (typeof(onClose) == "function") {
				onClose();
			}
		})

		this.deleteBindingFail = OrdersActions.deleteBinding.failed.listen((res) => {
			const data = JSON.parse(res['response']);
			this.setState({
				'message': {
					'msg': data
				},
				waiting: false
			})
		});

		this.binding = OrdersActions.binding.completed.listen((data) => {
			if (typeof(onClose) == "function") {
				onClose();
			}
		})

		this.bindingFail = OrdersActions.binding.failed.listen((res) => {
			const data = JSON.parse(res['response']);
			this.setState({
				'message': {
					'msg': T(data['error'])
				},
				waiting: false
			})
		});

		this.putProduct = OrdersActions.manualPutProduct.completed.listen(() => {
			this.setState({
				putIsFinish: true
			})
		})
		this.putFailed = OrdersActions.manualPutProduct.failed.listen((res) => {
			const data = JSON.parse(res['response']);
			this.setState({
				'message': {
					'msg': T(data['error'])
				},
				waiting: false
			})
		})
	}

	onChangeSelectIntegration (selectedOption) {
			if(selectedOption.value !== this.state.integration_id.value) {
				ProductsActions.changeSelectRemote(selectedOption.value)
			}

			this.setState({
				integration_id: selectedOption,
			})

			if(selectedOption.value !== this.props.integration_id) {
				this.setState({
					remote_product: {
						value: 0
					},
				})
			}
	}

	onPutProductTo() {
		const data = {
			mid: this.props.product_mid,
			pid: this.props.product_id,
			iid: this.state.integration_id.value,
		}
		this.setState({
			waiting: true
		})
		OrdersActions.manualPutProduct(data)
	}

	onChangeSelectProduct(selectedOption) {
			const pid_ident = selectedOption[0]['value'],
				name = selectedOption[0]['label'];

			this.setState({
				products: pid_ident.split("_")[0],
				product_name: name
			});
	}


	submit() {
			let pid = this.state.products || this.props.product_id,
				iid = this.state.integration_id?.value,
				rid = this.state.remote_product?.value;

			console.log("pid: ", pid, iid, rid)
			if(!(pid && iid && rid)) {
				this.setState({
					'message': {
						'msg': T('not all fields required')
					}
				})
				return false
		}

		this.setState({
			waiting: true
		})
		OrdersActions.binding({pid, iid,rid}, this.props.disabledUpdateOrder)
	}

	delete() {
			let pid = this.state.products || this.props.product_id,
				iid = this.state.integration_id?.value,
				rid = this.state.remote_product?.value;

			if(!(pid && iid)) {
				this.setState({
					'message': {
						'msg': T('not all fields required')
					}
				})
				return false
			}

			this.setState({
				waiting: true
			})

		OrdersActions.deleteBinding({pid, iid,rid})
	}

	listRemoteIntegration() {
			if(this.props.isCreate && this.state.integration_remote_product_list) {
				const integrationList = UserStore.getIntegrationListWithChannelName(this.props.product_mid)
				const remote_id_integration_active = this.state.integration_remote_product_list

				return integrationList.filter(item => !remote_id_integration_active.some(remoteItem => remoteItem === item.id));
			} else {
				return UserStore.getIntegrationListWithChannelName(this.props.product_mid)
			}
	}

	render() {
		const integration = UserStore.getIntegrationById(this.state.integration_id.value)

		const isPutProduct = [UserStore.WOO_ID,UserStore.OC_ID,UserStore.HS_ID, UserStore.SH_EX].indexOf(integration?.integration_id) > -1

		return (
			<>
				<Messages message={this.state.message} close={() => this.setState({message: null})} />
				{!this.state.putIsFinish && <Box>
					<Heading>
						{this.props.isCreate ? T('binding-products') : T('linked-product')}
					</Heading>
					<Box>
						{this.state.product_name}
					</Box>
					{!this.props.product_id && (
						<SelectProducts
							name='products'
							onChange={this.onChangeSelectProduct}
							value={this.state.products}
							single
						/>)}
					<center><Heading>{T('with')}</Heading></center>

					<Field>
						<SelectComponent
							name="integration_id"
							label={this.props.isCreate ? "active-integration-list" : 'integration'}
							empty={this.props.isCreate ? 'select-active-integration' : ''}
							onChange={this.onChangeSelectIntegration}
							value={this.state.integration_id}
							list={() => this.listRemoteIntegration()}
							readOnly={!!this.props.integration_id}
							creatable={false}
						/>
					</Field>
					{this.state.waiting ?
						<div className='select-wrapper-load'><Spinner/></div> : (
							<Field>
								<SelectComponent
									name="remote_product"
									label={this.props.isCreate ? "list-products-integration" : `${T('product-from-integration')}${this.state.remote_id ? ` ID: ${this.state.remote_id}` : ''}`}
									onChange={this.onChangeSelect}
									value={this.state.remote_product}
									list={ProductsStore.getRemoteForSelectProducts}
									empty='select-product-list'
									readOnly={!!this.props.integration_id}
								/>
							</Field>)
					}

					<Button.Group className="margin-top-10" position='centered'>
						{this.state.isCreate && <>
							{isPutProduct && (!this.props.disabledPutProduct || integration?.integration_id === UserStore.WOO_ID) && (
								<SubmitButton text='unload-product'
															size={this.props.isMobileSize ? "medium" : "large"}
															rounded
															fullwidth={false}
															submit={this.onPutProductTo}
															waiting={this.state.waiting}
															color="info"
								/>
							)}
							<SubmitButton
								size={this.props.isMobileSize ? "medium" : "large"}
								rounded
								color="primary"
								fullwidth={false}
								submit={this.submit}
								waiting={this.state.waiting}
								text='binding-products'/>
						</>}
						{!this.state.isCreate && <SubmitButton
							size={this.props.isMobileSize ? "medium" : "large"}
							rounded
							color="danger"
							submit={this.delete}
							fullwidth={false}
							waiting={this.state.waiting}
							text='remove-binding'
						/>}
						<Button
							size={this.props.isMobileSize ? "medium" : "large"}
							rounded
							color="light"
							onClick={this.props.onClose}
						>
							{T('close')}
						</Button>
					</Button.Group>
				</Box>}
				{this.state.putIsFinish && <ModalItsOk onClose={this.props.onClose} title='finish-put-product'/>}
			</>
		);

	}
}

export default withIsMobileSize(BindingIntegrationProductsModal)