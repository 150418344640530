import React, { useEffect, useRef, useState } from "react";
import s from "./styles.module.scss";
import ChatsStore from "../../chats-store";
import MessageItem from "./message-item";
import Loader from "react-loader-spinner";
import ChatsActions from "../../chats-actions";
import { LocalIcon } from "whitelables/wl-name/icons";
import { Button } from "react-bulma-components";
import SendField from "./send-field";

const MessageList = ({ remoteChatId, userId, activeChatId, integration_id }) => {
  const messages = ChatsStore.get("messages");
  const messageListRef = useRef(null);
  const [disabledScrollDown, setDisabledScrollDown] = useState(false);
  const [disabledLoad, setDisabledLoad] = useState(false);
  const [isActiveButtonScroll, setIsActiveButtonScroll] = useState(false);
  const [loadMessage, setLoadMessage] = useState(false);

  const messageRefs = useRef({})

  const registerRef = (id, ref) => {
    messageRefs.current[id] = ref.current;
  };

  useEffect(() => {
    if (messageListRef.current && messages && messages.length > 0) {
      if (!disabledScrollDown && !isActiveButtonScroll) {
        messageListRef.current.style.scrollBehavior = "auto";
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      } else {
        messageListRef.current.style.scrollBehavior = "smooth";
        setDisabledScrollDown(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    let timeout;
    if (messages && activeChatId) {
      timeout = setTimeout(() => {
        messages.forEach((item) => {
          if (!item.is_read) {
            item.is_read = 1;
            ChatsStore.markAsRead(item, activeChatId);
          }
        });
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [messages, activeChatId]);

  useEffect(() => {
    ChatsStore.setActiveChat(activeChatId);

    return () => {
      ChatsStore.setActiveChat(false);
      setDisabledScrollDown(false);
      setDisabledLoad(false);
    };
  }, [activeChatId]);

  useEffect(() => {
    const loadMessage = ChatsActions.loadMessages.completed.listen((res) => {
      if (!res.data.length) setDisabledLoad(true);
    });

    return () => {
      loadMessage();
    };
  }, []);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    if (distanceFromBottom > 100) {
      setIsActiveButtonScroll(true);
    } else {
      setIsActiveButtonScroll(false);
    }

    if (!disabledLoad) {
      if (scrollTop === 0) {
        ChatsActions.loadMessages(activeChatId);
        e.currentTarget.scrollTo(0, 50);
        setDisabledScrollDown(true);
      }
    }
  };

  const handleScrollDown = () => {
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  };

  const scrollToMessage = ({reply_id, offset}) => {
    const element = messageRefs.current[reply_id];

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const currentLimit = 25 - offset % 25 + offset - ChatsStore.state.offset
      ChatsActions.loadMessages(activeChatId, currentLimit);
      setLoadMessage(true);
      const loadMessageByReply = ChatsActions.loadMessages.completed.listen(() => {
        scrollToMessage({reply_id, offset})
        setLoadMessage(false);
        loadMessageByReply()
      })
    }
  };

  return (
    <>
      <div className={s.wrapper} ref={messageListRef} onScroll={handleScroll}>
        {ChatsStore.get("messagesLoading") && (
          <div className="display-flex-center">
            <Loader type="Rings" color="#00BFFF" height={80} width={80} />
          </div>
        )}
        {messages?.map((item) => (
          <MessageItem
            key={`${item.dt}_${item.id}_${item.remote_message_id}`}
            data={item}
            remoteChatId={remoteChatId}
            userId={userId}
            registerRef={registerRef}
            scrollToMessage={scrollToMessage}
            loadMessage={loadMessage}
          />
        ))}
        {isActiveButtonScroll && (
          <div className={s.scroll_down}>
            <Button
              onClick={handleScrollDown}
              color="info"
              style={{ padding: "0 2px" }}
            >
              <LocalIcon icon="chevron-down" size="large" />
            </Button>
          </div>
        )}
      </div>
      <SendField
        key={activeChatId}
        chatId={activeChatId}
        triggerScrollDown={handleScrollDown}
        integration_id={integration_id}
      />
    </>
  );
};

export default MessageList;
