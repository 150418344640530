import React, {Component} from 'react';
// import Reflux from 'reflux';
import T from "../../components/i18n";
import UserActions from '../user-actions';
import { redirectTo } from 'tools/redirect-to'

import { Field, Control, Input, Label } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';

import AppStore from 'app-store';
import Messages from 'components/messages';

class RecoveryChangePassForm extends Component {
  constructor(props) {
        super(props);
        this.state = {
          code: {
                error: "",
                readonly: false,
                value: ""
          },
          new_password: {
                error: "",
                value: ""
          },
          message: null,
          error: null,
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.recoveryPassCodeComplete = UserActions.recoveryPassCode.completed.listen((data) => {
        if (data['status'] === "OK") {
          this.setState({
            message: T('password-success-change')
          });

          setTimeout(()=> {
            this.setState({
                redirect: true
            });
          }, 3000);
        }
     });

    this.recoveryPassCodeFailed = UserActions.recoveryPassCode.failed.listen((data) => {
          this.setState({
            'error':T(data['response'])
          });
     });

    const code  = window.location.pathname.split("/")

    let codeValue = ''

    if(code[code.length-1] !== "recovery-change-pass"){
      codeValue = code[code.length-1]
    }

    if (codeValue) {
      this.setState({
        'code': {'value': codeValue, 'readonly': true}
      });
    }


  }

  componentWillUnmount() {
    if (typeof(this.recoveryPassCodeComplete) == "function") {
          this.recoveryPassCodeComplete();
    }

    if (typeof(this.recoveryPassCodeFailed) == "function") {
          this.recoveryPassCodeFailed();
    }
  }

  submit() {
    const code = this.state.code.value,
          pass = this.state.new_password.value;

    console.log("start recovery pass...");
    UserActions.recoveryPassCode(code, pass)
  }


  onChange(evt) {
      const value = evt.target.value,
            name = evt.target.name;

      this.setState({
        [name]: {
          'value': value
        }
      });
  }

  render () {
        return (
              <Box>
                <Messages message={this.state.message || this.state.error} color={this.state.error ? 'danger' : 'success'} close={() => this.setState({message: null, error: null})} />
                <div align="center"><Heading>{T('change-password')}</Heading></div>
                <Field>
                  <Control>
                    <Label>{T('recovery-code-text')}</Label>
                    <Input size="large" 
                          name="code" type="text" 
                          placeholder={T("recovery-code")}
                          autoComplete="new-code"
                          readOnly={this.state.code.readonly}
                          onChange={this.onChange} 
                          color={(this.state.code.error) ? "danger" : "primary"}
                          value={this.state.code.value}
                          />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('new-password-placeholder')}</Label>
                    <Input  size="large" 
                          name="new_password" type="password" 
                          placeholder={T('new-password-placeholder')}
                          autoComplete="new-password"
                          onChange={this.onChange} 
                          color={(this.state.new_password.error) ? "danger" : "primary"}
                          value={this.state.new_password.value}
                          />
                  </Control>
                </Field>
                <Button.Group>
                  <Button fullwidth size="large"  rounded color="primary"
                    onClick={this.submit}
                    >{T('change-password')}</Button>
                </Button.Group>
                 {this.state.redirect ? redirectTo(AppStore.getLink("login")) : null}
              </Box>
        );
    }
};

export default RecoveryChangePassForm;