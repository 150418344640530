import React from "react";
import OpenModalButton from "../../../components/modal/open-modal-button";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import { Link } from "react-router-dom";
import { createMobileAppLink } from "../../../tools/create-mobile-app-link";
import ClientsStore from "../clients-store";
import Button from "react-bulma-components/lib/components/button";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import DiscountSettingsModal from "../discount-settings/discount-settings-modal";
import AddClientModal from "../modal/add-client-modal/add-client-modal";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";

const ActionButtons = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div className="ex-open-modal-wrapper display-flex-row-gap" style={{ flexWrap: "wrap", alignItems: 'center' }}>
      <DocumentationButton sectionID='clients'/>
      <div className="display-flex-row flex-wrap">
        <OpenInsideModal text='add-clients-btn' icon='plus-circle' size={isMobileSize ? "small" : "medium"}>
          <AddClientModal className='modal-start'/>
        </OpenInsideModal>
        <OpenModalButton
          link="/clients/import-clients"
          text={T("load-from-excel")}
          icon="plus-circle"
          size={isMobileSize ? "small" : "medium"}
        />
        {!User.getPermissionEnable("deleteproducts") ? null : (
          <Link
            onClick={() => createMobileAppLink(ClientsStore.getExportUrl())}
            to={ClientsStore.getExportUrl()}
            target="_blank"
            title={T("export-excel")}
          >
            <Button size={isMobileSize ? "small" : "medium"} rounded color="info">
              {T("export-excel")}
            </Button>
          </Link>
        )}
        <OpenInsideModal
          text={T("adjust-discounts-btn")}
          size={isMobileSize ? "small" : "medium"}
        >
          <DiscountSettingsModal />
        </OpenInsideModal>
      </div>
    </div>
  );
};

export default ActionButtons;
