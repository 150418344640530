import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";
import T from "components/i18n";
import Money from "components/money";
import DOM from "react-dom-factories";
import { redirectTo } from 'tools/redirect-to'
import Notification from 'react-bulma-components/lib/components/notification';
import ScrollToTopOnMount from 'tools/scroll-top'
import Image from 'react-bulma-components/lib/components/image';
import Media from 'react-bulma-components/lib/components/media';
import User from 'user/user-store';
import {
    Field, 
    Control, 
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import SalesActions from 'modules/sales/sales-actions'
import ChangeAccount from 'modules/sales/change-account'
import ExpensesActions from 'modules/expenses/expenses-actions'
import ExpensesStore from 'modules/expenses/expenses-store'
import ClientsStore from 'modules/clients/clients-store'
import ProductsStore from 'modules/products/products-store'
import ProductActions from 'modules/products/products-actions'
import SelectComponent from 'tools/select-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SalesStore from './sales-store';
import List from 'react-bulma-components/lib/components/list';
import Barcode from "react-barcode";
import OpenInsideModal from 'components/modal/open-inside-modal';
import NotEnableNPModal from './delivery/not-enable-np';
import CreateNovaposhta from './delivery/create-novaposhta';
import CreateUkrposhta from './delivery/create-ukrposhta';
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

const ACTIVE_TTN = [User.NP_ID, User.UP_ID];

class ProcessDeliveryModal extends Component {
    constructor(props) {
        super(props);
      this.data = SalesStore.getSalesByOid(props.oid);
      const { delivery_info } = this.data;

      let integration_id = null;
        // if (delivery_info && delivery_info.indexOf(":") > -1) {
        //   integration_id =  parseInt(this.data.delivery_info.split(":")[0]);
        // }
        if (delivery_info) {
          integration_id =  parseInt(delivery_info);
        }

      let {product_data} = this.preparingProductData();
      
      this.state = {
          oid: props.oid,
          amount: props.total_order - props.prepaid,
          saleAccount: props.aid,
          fromAccount: {'value': props.aid},
          prepaid: props.prepaid,
          tracking_number: this.data.tracking_number || "",
          close: false,
          change_account: false,
          message: null,
          step: 0,
          integration_id: integration_id ? { 'value': integration_id } : null,
          client: this.data.client_id > 0 ? { 'value': this.data.client_id } : null,
          amount_sale: this.data.amount_sale || 0,
          postoffice: this.deliveryDetails,
          product_data: product_data
        };
        // ClientsActions.loadDelivery(this.data['client_id'])

        this.onChange = this.onChange.bind(this);
        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);

    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenAddFailed) == "function") {
        this.listenAddFailed();
      }

      if (typeof(this.listenDelete) == "function") {
          this.listenDelete();
      }

      if (typeof(this.listenDeleteFailed) == "function") {
        this.listenDeleteFailed();
      }      

      if (typeof(this.listenAddTrackingFinish) == "function") {
        this.listenAddTrackingFinish();
      } 

      if (typeof(this.finishParcelListen) == "function") {
        this.finishParcelListen();
      } 

      if (typeof(this.tranferFundListen) == "function") {
        this.tranferFundListen();
      }
      if (typeof(this.listenFinish) == "function") {
        this.listenFinish();
      }
      if (typeof(this.listenFinishFailed) == "function") {
        this.listenFinishFailed();
      }
    }

  componentDidMount() {
        // if (Object.keys(this.data).length === 0) {
        //   this.setState({close: true});
        //   return;
        // }

        const oid = this.data['oid'];

        this.listenFinish = SalesActions.finishReserv.completed.listen((data) => {
          if (data['success']) {
            this.setState({step: 1, 'finish_message': 'reserv-success'});
          }
        }); 

        this.listenAddTrackingFinish = SalesActions.addTrackingNumber.completed.listen((data) => {
          if (data['success']) {
            this.setState({step: 1, 'finish_message': 'parcel-send-success'});
          }
        });

        this.listenFinishFailed = SalesActions.finishReserv.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "message": T(data['error']),
            });
        });

        this.listenDelete = SalesActions.deleteSale.completed.listen((data) => {
          if (data['success']) {
            this.setState({step: 1, 'finish_message': 'sales-success-delete'});
          }
        });

        this.listenDeleteFailed = SalesActions.deleteSale.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "message": T(data['error']),
            });
        });

        this.tranferFundListen = ExpensesActions.transferFunds.completed.listen(() => {
          console.log("listen on proccess delivery ", oid)
           SalesActions.finishParcel(oid);
        });

        this.finishParcelListen = SalesActions.finishParcel.completed.listen(() => {
            this.setState({
              'step': 1,
              'finish_message': T('parsel-success-finish')
            })

        });
  }
  sortingProducts(pr_data) {
        this.product_data_list = [];

        for (let pid_ident in pr_data) {
            this.product_data_list.unshift(pid_ident);
        }

        this.product_data_list = [...new Set(this.product_data_list)];

  }
  
  prepareProductData(pid, mid, data, pr) {
      return {
                'count': data.count || 1,
                'mid': mid,
                'name': data['product_deleted'] === 1 ? T('product_deleted') : data['name'],
                'product_deleted': data['product_deleted'],
                'sku': data['sku'],
                'size': data['size'],
                'pid': pid,
                'parent_id': data['parent_id'],
                'instock': data['instock'] || 0,
                'units': data['units'],
                'ident': ProductsStore.getIdent(pid, mid),
                'finish_price': pr
          }
    }

  preparingProductData() {
    const { items } = this.data;
        let product_data = {};
        if (this.props.oid) {
          for (let i in items) {
            let item = items[i],
                pid = item['product_id'],
                mid = item['mid'],
                pid_ident = ProductsStore.getIdent(pid, mid),
                data = ProductsStore.getProductByMid(pid, mid) || item;

            let price = this.props.total_order;
            data['count'] = item['quantity'];
            product_data[pid_ident] = this.prepareProductData(pid, mid, data, price);
          }
        } 
    this.sortingProducts(product_data)
        return {product_data};
    }
  
  getCreateTTNButton() {
      if (ACTIVE_TTN.indexOf(this.state.integration_id.value) === -1) return null;

      let buttons = {
          1: (<OpenInsideModal size="small" text={T('create-novaposhta-ttn')}>
                      {User.isNpCreateTTNEnable() ?
                        <CreateNovaposhta
                          className='modal-start'
                          modal='pochta'
                          client={this.state.client} 
                          amount={this.state.amount} 
                          postoffice={this.state.postoffice} 
                          money={this.state.fromAccount && ExpensesStore.isCOD(this.state.fromAccount.value) ? "2" : false}
                          product={this.state.product_data} 
                          onCompleteCreate={(tracking_number) => {
                            this.setState({tracking_number: tracking_number}); 
              }}
            />
                      : <NotEnableNPModal /> }
                  </OpenInsideModal>
                  ),
          3: (
                  <OpenInsideModal size="small" text={T('create-ukrposhta-ttn')}>
                      {User.isUpCreateTTNEnable() ?
                        <CreateUkrposhta
                          className='modal-start'
                          modal='pochta'
                          client={this.state.client}
                          amount={this.state.amount} 
                          postoffice={this.state.postoffice} 
                          money={this.state.fromAccount && ExpensesStore.isCOD(this.state.fromAccount.value) ? "2" : false}
                          product={this.state.product_data} 
                          onCompleteCreate={(tracking_number) => {
                            this.setState({tracking_number: tracking_number}); 
                          }}/>
                      : <NotEnableNPModal delivery_id={3}/> }
                  </OpenInsideModal>
          )
      }

      const activeButton = buttons[this.state.integration_id.value]

      return (
        <div className='create-ttn-btn-box align-center'>
          {activeButton}
        </div>
      )
  }

    getMessage() {
        setTimeout(() => {
          this.setState({
            "message": null
          })
        }, 4000);
        return (
              <Notification color="danger">
                    <ScrollToTopOnMount />
                    {this.state.message}
                    <Button remove onClick={() => this.setState({message: null})} />
              </Notification>
        )
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
          [name]: value
        });
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

         this.setState({
          [name]: value
         })
    }


    onChangeAccount(selectedOption, actions) {
      if (!selectedOption) return;
     
      this.setState({
        [actions.name]: selectedOption
      });

      setTimeout(this.calcCommission, 10);
    }

    deleteSale(oid) {
        confirmAlert({
          title: T('confirm-delete'),
          message: T('are-you-sure-to-delete-this-delivery'),
          buttons: [
            {
              label: T('delete'),
              onClick: () => SalesActions.deleteSale(this.state.oid, 1)
            },
            {
              label: T('no-delete'),
              onClick: () => {}
            }
          ]
        });
    }

    checkFinishParcel() {
      const aid = this.data['account_id'];

      if (ExpensesStore.isCOD(aid)) {
        this.setState({
          "change_account": true
        })
      } else {
        SalesActions.finishParcel(this.data['oid']);
      }
    }

    finishParcel(oid) {
        confirmAlert({
          title: T('confirm-finish'),
          message: T('are-you-sure-to-finish-this-parsel'),
          buttons: [
            {
              label: T('parsel-delivered'),
              onClick: () => this.checkFinishParcel()
            },
            {
              label: T('no-delivery'),
              onClick: () => {}
            }
          ]
        });
    }


    submit(type) {
      switch (type) {
            case 'remove':
              this.deleteSale(this.state.oid);
              break;
            case 'send':
              const len = this.state.tracking_number.length;
              if (len !== 0 && len !== 14 && len !== 13) {
                this.setState({
                  "message": T('not-correct-tracking-number'),
                });
                return
              }
              SalesActions.addTrackingNumber(this.state.oid, 1, this.state.tracking_number);
              break;
            case 'finish':
              SalesActions.finishReserv({
                "oid": this.state.oid, 
                "aid": this.state.fromAccount.value, 
                "amount": this.state.amount,
              });
              break;
            case 'finish-parcel':
              this.finishParcel({
                "oid": this.state.oid, 
                "aid": this.state.fromAccount.value, 
                "amount": this.state.amount,
              });
              break;
            case 'reserve_sale':
            default:
              break;
      }

        
    }

    getProductLine() {
      let response = [];

      for (let i in this.data['items']) {
          let item = this.data['items'][i];
          const pid = item['product_id'],
                pr_d = ProductsStore.getProduct(pid),
                product_name = pr_d['name'],
                img = "/bimages/get/64-"+User.get("id")+"-"+pid+"-0.jpg";
          response.push(
              <List.Item key={i} className="cursor-pointer">
                <Media>
                      <Media.Item position="left">
                          <Image src={img} size={64} />
                      </Media.Item>
                      <Media.Item  onClick={() => ProductActions.openProductModal(pid)}>
                            <span className="underline">{product_name} </span> <br />
                            <p>{ProductsStore.makeInfoBar(pid)}</p>
                    </Media.Item>
                  </Media>
              </List.Item>
          )
      }

      return (
        <List>
          {response}
        </List>
        )
    }

    renderStepOne() {
      // const {name} = this.props;
      let comment = T('no');
      if (this.data['comment'])  {
          comment = (
            <span><b>{T('comment')}</b>: {this.data['comment']}</span>
          );
      } 


      const delivery_address = ClientsStore.getClientDeliveryById(this.data['client_id'], this.data['clients_delivery_id']),
            is_delivery = true,
            medium_size = this.props.isMobileSize ? "small" : "medium",
            balance_text = this.state.prepaid && this.state.prepaid ? 'need-balance' : "paid",
            not_paid = ExpensesStore.isDebt(this.state.saleAccount) || ExpensesStore.isCOD(this.state.saleAccount);

      return (
          <Box>
              <Heading size={4}>
                  {T('process-delivery-title')}
              </Heading>
              
              <Columns className="info-block">
                  <Columns.Column>
                        <div className="margin-bottom-10"> <span className="silver">{T('order')}</span> <br/>{this.data['order_number_text']} </div>
                        {this.state.prepaid && this.state.prepaid > 0 ? 
                        <div className="margin-bottom-10"> 
                          <span className="silver">{T('prepaid')}:</span> <br /><b><Money amount={this.state.prepaid}  wrapper={DOM.span}/> </b> 
                          {T('to-account')}: <b>{T(ExpensesStore.getAccountName(this.data['prapaid_account_id']))}</b>
                          </div>
                      : null}
                      {not_paid ? <div className="margin-bottom-10">
                          <span className="silver">{T('subscribe')}:</span><br /> 
                         <b className="has-text-danger">{T(ExpensesStore.getAccountName(this.state.saleAccount))}</b> <br/><Money amount={this.state.amount}  wrapper={DOM.span}/> 
                      </div> :
                        <div className="margin-bottom-10">
                          <span className="silver">{T(balance_text)}:</span><br /> <b><Money amount={this.state.amount}  wrapper={DOM.span}/> </b> 
                          {T('to-account')}: <b>{T(ExpensesStore.getAccountName(this.state.saleAccount))}</b>
                        </div>
                      }
                  </Columns.Column>
                  <Columns.Column>
                        <div className="margin-bottom-10"><span className="silver">{T('products-price')}</span> <br/><Money amount={this.data['amount_sale']} /></div>
                        <div className="margin-bottom-10"><span className="silver">{T('comment')}</span> <br/>{comment}</div>
                        {this.data['clients_delivery_id'] ? 
                        <div className="margin-bottom-10"><span className="silver">{T('delivery-delivery')}</span> <br/>
                           {ClientsStore.getClientDeliveryNane(delivery_address, User.getLang())}
                        </div>
                        : null }
                  </Columns.Column>
              </Columns>
          {this.getProductLine()}
          <Columns>
            <Columns.Column>
              <Field>
                  <Control>
                    <Label>{T('tracking-number')}</Label>
                    <Input  size="large" 
                          name="tracking_number"
                          type="number"
                          onChange={this.onChange} 
                          value={this.state.tracking_number}
                          />
                  </Control>
              </Field> 
            </Columns.Column>
            {this.state.integration_id && ACTIVE_TTN.indexOf(this.state.integration_id.value) > -1 && (User.isNpCreateTTNEnable() || User.isUpCreateTTNEnable()) ? 
              <Columns.Column>
                  {this.getCreateTTNButton()}
              </Columns.Column>
              : null}
          </Columns>
              
              {this.state.tracking_number && this.state.tracking_number.length === 14 ? 
              <div align="center"> 
               <Barcode value={this.state.tracking_number} />
              </div>
              : null}
              { true ? "" :   
              <Box>
                <Field>
                    <Control>
                      <Label>{T('need-balance')}</Label>
                      <Input  size="large" 
                            name="amount"
                            type="number"
                            onChange={this.onChange} 
                            value={this.state.amount}
                            />
                    </Control>
                </Field>
                <Field>
                    <Control>
                      <SelectComponent 
                          name="fromAccount"
                          label="to-account"
                          onChange={this.onChangeAccount} 
                          value={this.state.fromAccount} 
                          list={ExpensesStore.getAccountsList}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                  </Control>
                </Field> 
              </Box>
              }
              <Button.Group position="centered">
                    {is_delivery?
                    <Button  fullwidth size="large" rounded color="success"
                      onClick={() => this.submit('send')}>{T('send-parcel')}/{T('add-ttn')}</Button>
                    : null }

                    <Button  size={medium_size} rounded color={is_delivery ? "primary" : "success"}
                      onClick={() => this.submit('finish-parcel')}>{T('finish-parcel')}</Button>

                    <Button  size={medium_size} rounded color="warning"
                      onClick={() => this.submit('remove')}>{T('delete')}</Button>

                    <Button  size={medium_size} rounded color="light"
                      onClick={this.props.onClose}>{T('close')}</Button>
          
              </Button.Group>
          </Box>
      );
    }

    renderStepSecond() {
      return (
        <Box>
          <Heading className="title">{T(this.state.finish_message)}</Heading>
          <Button.Group>
              <Button fullwidth size="large"  rounded color="primary"
                onClick={this.props.onClose}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
              currentStep = stepNaviganor[this.state.step],
              params = {
                          fromAccount: {'value': this.data['account_id']},
                          amount: this.state.amount,
                          oid: this.state.oid,
                          onClose: this.props.onClose
                        };

        return (
              <div>
                {this.state.message ? this.getMessage() : ""}
                {this.state.change_account ?
                <ChangeAccount {...params} /> : 
                currentStep()
                }
                {this.state.close ? redirectTo("/sales") : ""}
              </div>
        );

    }
       
}

export default connectToStores(withIsMobileSize(ProcessDeliveryModal), {
    clients: ClientsStore
  });

