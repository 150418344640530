import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import getQueryParams from "tools/query-params";
import {
    Field, 
    Control, 
    Input,
    // Radio,
    Label
} from 'react-bulma-components/lib/components/form';
import Messages from 'components/messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from 'react-bulma-components/lib/components/box';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';
import ExpensesStore from './expenses-store'
import ExpensesActions from './expenses-actions'
import ProductsStore from '../products/products-store'
import ShipmentsStore from '../shipments/shipments-store'
import ShipmentsActions from '../shipments/shipments-actions'
import SelectComponent from 'tools/select-component';
import AppStore from 'app-store';
import SubmitButton from 'components/submit-button';
import trackEvent from 'tools/track-events';
import User from 'user/user-store';
import AddExpensesCategoryModal from "./add-expenses-category-modal";
import {errorHandler} from "../../tools/error-handler";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import WarehouseStore from "../warehouse/warehouse-store";
import Select from "../../components/Select";
import ErrorMessageBlock
  from "../../components/error-message-block/error-message-block";
import NumberInput from "../../components/number-input/number-input";

class AddExpensesModal extends Component {
    constructor(props) {
        super(props);
        let category = null
        if (this.props.category_id) {
          category = {
              'value': this.props.category_id
          };
        } 

        let shipment = null
        let shipmentInfo = null
        if (this.props.shipment_id) {
          shipment = {
              'value': this.props.shipment_id
          };

          const getShipment = ShipmentsStore.getShipmentById(this.props.shipment_id)

          if(getShipment) {
            shipmentInfo = getShipment
          }
        }

        let account = null;
        if (this.props.account) {
          account = {
              'value': this.props.account,
              'label': "",
          };
        }
      
        let date = new Date();
        if (this.props.date) {
          date = new Date(this.props.date * 1000)
        }

        let mid = this.props.mid || null

        if(this.props.expense_id) {
          const expense = ExpensesStore.getExpensesById(this.props.expense_id)
          if(expense.marketplace_id) {
            mid = expense.marketplace_id
          }
        }

        let accountList = null
        if(shipment) {
          accountList = ExpensesStore.getAccountsList([1,4], shipmentInfo?.currency, true)
        } else {
          accountList = ExpensesStore.getPersonalAccounts()
        }

        let errorAccount = null

        if(!accountList) {
          errorAccount = T('are-no-accounts-available')
        }


        this.state = {
          comment: {
                error: "",
                value: this.props.comment || ""
          },
          amount: {
                error: "",
                value: this.props.amount || ""
          },
          types: {
                error: "",
                value: this.props.type || "1"
          },
          mid: mid ? {
            error: '',
            value: mid
          } : null,
          account: account,
          date: date,
          category: category,
          shipment: shipment,
          expense_id: this.props.expense_id || null,
          message: null,
          waiting: null,
          close: false,
          shipmentInfo: shipmentInfo,
          errorAccount
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeDateSaled = this.onChangeDateSaled.bind(this);
        this.getAccountList = this.getAccountList.bind(this);
        this.onChangeSelectShipment = this.onChangeSelectShipment.bind(this);
        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        let product_id = getQueryParams("product");
        if (product_id) {
          this.setState({"product": {"value": product_id, "error": ""}});
        }

        this.listenAddFaild = ExpensesActions.addExpenses.failed.listen((res) => {
          const errorMessage = errorHandler(res)
            this.setState({
              'waiting': false,
              'message': {
                'msg': errorMessage
              }
            })
        });

        this.listenAddCompleted = ExpensesActions.addExpenses.completed.listen(() => {
            setTimeout(() => {
              AppStore.closeModal();
            }, 100);
        });
    }

    componentWillUnmount() {
        if (typeof(this.listenAddFaild) == "function") {
            this.listenAddFaild();
        }
        if (typeof(this.listenAddCompleted) == "function") {
            this.listenAddCompleted();
        }
    }

    submit() {
        if (!this.state.account || this.state.account.value === 0) {
            this.setState({
              'message': {
                'msg': T('not all fields required')
              }
          })

            return false;
        }
        const account = this.state.account.value,
              comment = this.state.comment.value,
              types = this.state.types.value,
              date =  this.state.date,
              category = this.state.category.value,
              shipment = this.state.shipment && this.state.shipment.value ? this.state.shipment.value : 0,
              amount = this.state.amount.value || 0,
              id = this.state.expense_id,
              mid = !!this.state.mid.value && this.state.mid.value

        if (comment.trim() === "" || amount <= 0) {
          this.setState({
              'message': {
                'msg': T('not all fields required')
              }
          })

          return false;
        }

        let inventory_id = 0;
        if (this.props.inventory_id) {
          inventory_id = this.props.inventory_id;
        }

        this.setState({"waiting": true});

        ExpensesActions.addExpenses(account, amount, comment, types, category, shipment, date, inventory_id, id, mid);
        trackEvent('users', 'working', 'add-expenses');
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
        [name]: {
          'value': value
        }
        });
    }


    onChangeDateSaled(date) {
        this.setState({
            "date": date
        });
    }

    onChangeSelect(selectedOption, actionMeta) {
      const dataSelect = {
        [actionMeta.name]: selectedOption
      }

      if(actionMeta.name === 'category') {
          dataSelect.shipment = null
      }
        this.setState(dataSelect)
    }

    onChangeSelectShipment(selectedOption, actionMeta) {
      const getShipment = ShipmentsStore.getShipmentById(selectedOption.value)

      const accounts = ExpensesStore.getAccountsList([1,4], getShipment?.currency, true)

      const data = {
        [actionMeta.name]: selectedOption,
        shipmentInfo: getShipment,
        account: {value: accounts[0].id},
      }

      if(!this.props.expense_id) {
        data.mid = {value: getShipment.marketplace_id}
      }

      this.setState(data)
    }

    getAccountList() {
      if(this.state.shipment) {
        return ExpensesStore.getAccountsList([1,4], this.state.shipmentInfo?.currency, true)
      } else {
        return ExpensesStore.getPersonalAccounts()
      }
    }

    onChangeAccount({value, name}){
      this.setState({
        [name]: value
      })
    }

    render() {
        const mess = (this.state.message && this.state.message['msg']) ? this.state.message['msg'] : null;
        return (
          <div className='add-expenses'>
              {this.state.errorAccount && <ErrorMessageBlock message={this.state.errorAccount}/>}
              <Messages message={mess} close={() => this.setState({message: null})} />
              <Box>
                <Field>
                  <Control>
                    <Label>{T('expenses-amount')}</Label>
                    <NumberInput
                      size="large"
                      name="amount"
                      min="0.01"
                      placeholder={T('expenses-amount-placeholder')}
                      onChange={this.onChange}
                      color={(this.state.amount.error) ? "danger" : null}
                      value={this.state.amount.value}
                    />
                  </Control>
                </Field>

                <Field>
                  <Control>
                    <Label>{T('expenses-name')}</Label>
                    <Input  size="large" 
                          name="comment" 
                          type="text" 
                          placeholder={T('expenses-comment-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.comment.error) ? "danger" : null}
                          value={this.state.comment.value}
                          />
                  </Control>
                </Field>

                <Field>
                     <SelectComponent 
                          name="category"
                          label="expenses-category"
                          onChange={this.onChangeSelect} 
                          value={this.state.category}
                          readOnly={parseInt(this.props.category_id) === ExpensesStore.SHIPMENT_CATEGORY}
                          list={() => ExpensesStore.getCategoryListWithoutCategory(2)}
                          addItem={(value) => AppStore.openModal(<AddExpensesCategoryModal modal="add-expenses-category" defaultValue='0' isDisabledReceipt name={value.name}/>)}
                          addItemModal={() => {AppStore.openModal(<AddExpensesCategoryModal modal="add-expenses-category" defaultValue='0' isDisabledReceipt/>)}}
                          load={ExpensesActions.loadCategory}
                          creatable={true}/>
                </Field>
                <Field>
                  <SelectComponent
                    empty='all'
                    name="mid"
                    label='history-data-marketplace_id'
                    onChange={this.onChangeSelect}
                    value={this.state.mid}
                    list={WarehouseStore.getWarehouseList}
                  />
                </Field>
                {this.state.category && this.state.category.value === 11 ? 
                <Field>
                     <SelectComponent 
                          name="shipment"
                          label="select-shipment"
                          onChange={this.onChangeSelectShipment}
                          value={this.state.shipment} 
                          list={() => ShipmentsStore.getList()}
                          load={ShipmentsActions.load}
                          creatable={false}/>
                </Field>
                : null}
                {/*<Field>*/}
                {/*  <Label>{T("expenses-type")}</Label>*/}
                {/*    <Control>*/}
                {/*        <Radio*/}
                {/*            onChange={this.onChange} */}
                {/*            checked={this.state.types.value === '1'} */}
                {/*            value="1" */}
                {/*            size="large"*/}
                {/*            name="types">*/}
                {/*          {ExpensesStore.getNameTypeExpenses(1)}*/}
                {/*        </Radio>*/}
                {/*        <Radio */}
                {/*            onChange={this.onChange} */}
                {/*            checked={this.state.types.value === '2'}*/}
                {/*            value="2" */}
                {/*            size="large"*/}
                {/*            name="types" >*/}
                {/*          {ExpensesStore.getNameTypeExpenses(2)}*/}
                {/*        </Radio>*/}
                {/*    </Control>*/}
                {/*</Field> */}
                <Columns className={this.props.isMobileSize ? "is-mobile": ""}>
                <Columns.Column>
                    <Field>
                      <Select list={this.getAccountList()} label="debit-the-account" name='account' value={this.state.account} onChange={this.onChangeAccount}/>
                    </Field>
                </Columns.Column>      
                <Columns.Column>
                    <Field>
                        <Label>{T("sale-date")}</Label>
                        <Control>
                           <DatePicker
                              selected={this.state.date}
                              onChange={this.onChangeDateSaled}
                              className="input"
                              dateFormat="dd-MM-yyyy"
                              popperPlacement="top-left"
                              showTimeInput
                              locale={User.getLang()}
                              maxDate={new Date()}
                              timeFormat="HH:mm"
                            />
                        </Control>
                    </Field>
                </Columns.Column>
                </Columns>

                <Button.Group>
                  {!this.state.errorAccount && <SubmitButton
                    text={this.state.expense_id ? "edit-expenses-btn" : "add-expenses-btn"}
                    waiting={this.state.waiting} submit={this.submit}/>}
                </Button.Group>
              </Box>
        </div>
        );
    }

}


export default connectToStores(withIsMobileSize(AddExpensesModal), {
    expenses: ExpensesStore,
    products: ProductsStore 
  });
