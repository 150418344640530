// import ReactGA from 'react-ga4';
// import AppStore from "app-store";
import TagManager from 'react-gtm-module'
// import UserStore from 'user/user-store';

// ReactGA.pageview(

const trackEvent = (category, actions, label, value, currency) => {
	// if (!AppStore.isEnableForWhiteLable("ga")) {
	// 	return
	// }
	//sign_up
	console.log("track event", actions, category, label);
    // ReactGA.initialize('UA-174991079-1');

    // ReactGA.pageview('/about/contact-us');
    // let data = {
	//   category: category,
	//   action: actions,
	//   label: label
	// };

	let event = 'Button Click';
	if (actions === "payment") {
		if (actions === "payment" && label === "start") {
			event = 'Add to Cart';
		} else {
			event = 'Purchase';
		}
	} else if (actions === "registration") {
		event = 'sign_up';
	}

	let eventData = {
		  event: event,
		  eventCategory: category,
		  eventAction: actions,
		  eventLabel: label,
	};

	if (value) {
		eventData['eventValue'] = value;
	}

	// if (eventData['event'] === 'sign_up') {
	// 	eventData['userId'] = UserStore.getUserId();
	// }

  	TagManager.dataLayer({ dataLayer: eventData });

  if (window.fbq && typeof(window.fbq) == "function") {

  	let fb = {'value': value}
  	if (currency) {
  		fb['value'] = parseFloat(fb['value'])
  		fb['currency'] = currency
  	}
  	console.log("send track to Facebook ", event, fb)
  	window.fbq('track', event, fb);
	}

    // ReactGA.event(data);

}

export default trackEvent;