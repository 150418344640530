import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import WarehouseStore from "modules/warehouse/warehouse-store";
import Level from 'react-bulma-components/lib/components/level';
import OpenModalButton from "components/modal/open-modal-button";
import User from 'user/user-store';
import DOM from "react-dom-factories";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Actions from './inventory-actions'
import List from 'react-bulma-components/lib/components/list';
import Heading from 'react-bulma-components/lib/components/heading';
import OpenInsideModal from "components/modal/open-inside-modal";
import AddExpensesModal from "modules/expenses/add-expenses-modal";
import ExpensesStore from '../expenses/expenses-store'
import ExportDocumentDropdown from "./common/export-document-dropdown";
import UserStore from "user/user-store";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import formatDate from "../../tools/format-date";

class InventoryRow extends Component {
    getContinueButton() {
        const {data} = this.props;

        return (
            <Level.Item key="regBut" style={this.props.isMobileSize ? {margin: 0} : {}}>
                <OpenModalButton size="small"  color="success" link={{
                              pathname: '/inventory/edit',
                              state: {'step': 1, 'edit': true, 'data': data }
                            }} text={T('finish-inventory-btn')} />
            </Level.Item>

        );
    }

    getEditButton() {
        const {data} = this.props;

        return (
            <Level.Item key='edit-btn'>
                 <Link to={{
                  pathname: '/inventory/edit',
                  state: {'edit': true, 'step': 0, 'data': data }
                }} title={T('delete')}>
                        <FontAwesomeIcon icon="edit" size="1x" />
                </Link>
              </Level.Item>
        );

    }

    getDeleteButton() {
        const {data} = this.props;  

        return (
            <Level.Item key="delBut">
                <Link to="#" onClick={() => this.deleteInventory(data['id'])} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size={this.props.isMobileSize ? '2x' :"1x"} />
                </Link>
            </Level.Item>

        );
    } 

    getRevertButton() {
        const {data} = this.props;  

        return (
            <Level.Item key="delBut">
                <Link to="#" onClick={() => this.revertInventory(data['id'])} title={T('revert-inventory')}>
                        <FontAwesomeIcon icon="undo" size={this.props.isMobileSize ? '2x' : "1x"} />
                </Link>
            </Level.Item>

        );
    }

    getPendingButton() {
        let response = [
            this.getContinueButton(),
            // this.getEditButton(),
            this.getDeleteButton()
        ]

        return response;
    }

    getPriceButton() {
        const {data} = this.props;

        return (
            <Level.Item style={this.props.isMobileSize ? {margin: 0} : {}}>
                <OpenModalButton size="small"  color="info" link={{
                              pathname: '/inventory/edit',
                              state: {'step': 5, 'only_show': true, 'data': data }
                            }} text={T('product-info-report')} />
            </Level.Item>

        );
    }

    getPrintButton() {
        return null;
        // const {data} = this.props;

        // return (
        //     <Level.Item>
        //         <Link to={this.getPrintUrl(data.id)} target="_blank" title={T('print')}>
        //               <FontAwesomeIcon icon="print" size="1x" title={T('print')}/>
        //           </Link>    
        //     </Level.Item>

        // );
    }

    deleteInventory(sid) {
        confirmAlert({
          title: T('confirm-delete'),
          message: '',
          buttons: [
            {
              label: T('delete'),
              onClick: () => {
                Actions.delete({
                'mov_id': sid, 'type': 'inventory'
              });


              
             }
            },
            {
              label: T('no-delete'),
              onClick: () => {}
            }
          ]
        });
    }

    revertInventory(sid) {
        confirmAlert({
          title: T('revert-inventory'),
          message: T('revert-inventory-desc'),
          buttons: [
            {
              label: T('revert-inventory'),
              onClick: () => {
                Actions.add({"inventory_id": sid, "step": 4})
                setTimeout(Actions.load, 1000);
                setTimeout(Actions.load, 5000);
              }
            },
            {
              label: T('close'),
              onClick: () => {}
            }
          ]
        });
    }

    getPrintUrl(sid) {
        return "/bprint/inventory?mid="+sid+"&uid="+User.get("id")+"&token="+encodeURIComponent(User.getToken())
    }

    getExpensesButton(amount, comment, id, status) {
        if (amount <= 0 || status !== "completed") return null;
        return (
        <div>
          <OpenInsideModal size="small" text={T('add-inventory-expenses')}>
              <AddExpensesModal amount={amount} comment={comment} inventory_id={id} mid={this.props?.data?.marketplace_id} className='modal-start'/>
          </OpenInsideModal>
        </div>
          )
    }

    getExpensesRow(expenses_id) {
        let expenses = ExpensesStore.getExpensesById(expenses_id);
        if (!expenses) {
            return;
        }
        return(
            <div key={"k1-"+expenses_id}>
                <b className="has-text-success" > {T('paid')}: <Money aid={expenses['account_id']} amount={expenses['amount']}  className="has-text-success font-size-120" wrapper={DOM.span}/></b> 
                {" "+T('from_account')} <b>{T(ExpensesStore.getAccountName(expenses['account_id']))}</b><br />
            </div>
          )
    }

    mobileRender() {
        const {data} = this.props;

        let comment = "";
        if (data['comment'] && data['comment'] !== "") {
            comment = (
                <> <b>{T("comment")}:</b> {data['comment']} </>
                );
        }

        let row_comment = T('inventory-expenses-comment', [Math.abs(data['deficit_quantity']), formatDate(data['created_at'])])

        let className = " is-one-";
        className += this.props.num % 2 === 0 ? "even" : "odd";

        return (
            <tr className={className}>
                <td colSpan="5">   
                  <Heading size={5} className="margin-top-10">ID: {data['id']}. {T('inventory')} <span className='nowrap'>{formatDate(data['created_at'])}</span> <b className="has-text-success nowrap">({T(data['status'])}</b>)
                        </Heading>                        
                            
                        <List>
                            <List.Item>
                                <b>{T("history-data-marketplace_id")}:</b> {T(WarehouseStore.getWarehouseName(data['marketplace_id']))} 
                            </List.Item>
                            <List.Item>
                                <b>{T("responseble")}:</b> {User.getResponsebleName(data['responsible_user_id'])} 
                            </List.Item>

                        </List>
                                <hr />
                        <List>
                            <List.Item>
                                <b>{T('added-product')}:</b> {data['quantity']} {T('items-e')}
                                {UserStore.getPermissionEnable('incomeinformation') && <>
                                  <b>{T('on-amount')}</b> <Money
                                  amount={data['amount']}
                                  wrapper={DOM.span}/>
                                </>}
                                <br/>
                            </List.Item>
                            <List.Item>
                                <b>{T('shortage')}: </b> {data['deficit_quantity']} {T('items-e')}

                              {UserStore.getPermissionEnable('incomeinformation') && <>
                                <b>{T('on-amount')}</b> <Money
                                amount={data['deficit_amount']}
                                wrapper={DOM.span}/>
                              </>}
                              <br/>
                              {data['expenses_id'] ? this.getExpensesRow(data['expenses_id']) : this.getExpensesButton(Math.abs(data['deficit_amount']), row_comment, data['id'], data['status'])}
                            </List.Item>
                            <List.Item>
                                <b>{T('surplus')}: </b> {data['surplus_quantity']} {T('items-e')}
                              {UserStore.getPermissionEnable('incomeinformation') && <>
                                <b>{T('on-amount')}</b> <Money
                                amount={data['surplus_amount']}
                                wrapper={DOM.span}/>
                              </>}
                            </List.Item>
                        </List>

                  <div>{comment}</div>
                  <div className="display-flex-row flex-wrap" style={{justifyContent: "space-between", padding: "10px"}}>
                    {(data['status'] === "pending") ? this.getPendingButton() : ""}
                    {(data['status'] === "completed") && <>
                      <ExportDocumentDropdown inventoryId={data.id}/>
                      {this.getPriceButton()}
                      {this.getRevertButton()}
                    </>}
                  </div>
                        {/*<Level renderAs="nav" className="is-mobile margin-bottom-5">*/}
                        {/*    <Level.Side align="left">*/}

                        {/*      <Level.Item>*/}
                        {/*      </Level.Item>*/}
                        {/*    </Level.Side>*/}
                        {/*</Level>                    */}
                </td>
            </tr>
        );
    }

    desktopRender() {
        const {data} = this.props;
        let comment = "";
        if (data['comment'] && data['comment'] !== "") {
            comment = (
                <> <b>{T("comment")}:</b> {data['comment']} </>
                );
        }

        let row_comment = T('inventory-expenses-comment', [Math.abs(data['deficit_quantity']), formatDate(data['created_at'])])

        let className = " is-one-";
        className += this.props.num % 2 === 0 ? "even" : "odd";

        return (
            <tr className={`is-size-7 ${className}`}>
                <td align="left" width="30%">ID: {data['id']}. {T('inventory')} {formatDate(data['created_at'])}<br />
                    <b>{T("history-data-marketplace_id")}:</b> {T(WarehouseStore.getWarehouseName(data['marketplace_id']))} <br />
                    <b>{T("responseble")}:</b> {User.getResponsebleName(data['responsible_user_id'])} <br />
                    {comment}
                </td>
                <td>
                  <span className={data['status'] === 'completed' ? 'text-success' : 'text-warning'}>
                    {T(data['status'])}
                  </span>
                     <br />
                    <b>{T('added-product')}:</b> {data['quantity']} {T('items-e')}
                  {UserStore.getPermissionEnable('incomeinformation') && <>
                    <b>{T('on-amount')}</b> <Money amount={data['amount']}
                                                   wrapper={DOM.span}/>
                  </>}
                  <br/>
                  <b>{T('shortage')}: </b> {data['deficit_quantity']} {T('items-e')}
                  {UserStore.getPermissionEnable('incomeinformation') && <>
                    <b>{T('on-amount')}</b> <Money
                    amount={data['deficit_amount']} wrapper={DOM.span}/>
                  </>}
                  <br/>
                  {data['expenses_id'] ? this.getExpensesRow(data['expenses_id']) : this.getExpensesButton(Math.abs(data['deficit_amount']), row_comment, data['id'], data['status'])}
                    <b>{T('surplus')}: </b> {data['surplus_quantity']} {T('items-e')}
                  {UserStore.getPermissionEnable('incomeinformation') && <>
                    <b>{T('on-amount')}</b> <Money
                    amount={data['surplus_amount']} wrapper={DOM.span}/>
                  </>}
                </td>
              <td align="right">
                <Level className="margin-top-5">
                        <Level.Side align="left">
                          {(data['status'] === "pending") ? this.getPendingButton() : ""}      
                          {(data['status'] === "completed") && <>
                            {this.getPriceButton()}
                            {this.getRevertButton()}
                            <ExportDocumentDropdown inventoryId={data.id}/>
                            </>}
                        </Level.Side>
                    </Level>  
                </td>
            </tr>
        );
    }   

    render() {
        return this.props.isMobileSize ? this.mobileRender() : this.desktopRender();
    }
}

export default withIsMobileSize(InventoryRow);
