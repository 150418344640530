export const extractNumber = (value) =>  {
	if(!value) return null

	if(typeof value === 'number') return  value

	const replaceValue = value.replace(/\s/g, "")

	const replaceDot = replaceValue.replace(',', '.')

	return parseFloat(replaceDot)
}