import React, { Component } from 'react';

import AddExpensesModal from "./add-expenses-modal";

import OpenModal from 'components/modal/modal';

class AddExpensesModalLink extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const state = this.props.location.state;
        return (
          <OpenModal className="modal-without-scroll" modal={{ closeOnBlur: false }}>
              <AddExpensesModal
                className='modal-start'
              	state={state}
              	/>
          </OpenModal>
        );
    }
}


export default AddExpensesModalLink;