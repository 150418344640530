import React, {useEffect} from "react";
import { Box } from "react-bulma-components";
import { connectToStores } from "../../tools/reflux-tools";
import ChatsStore from "./chats-store";
import ChatsActions from "./chats-actions";
import ClientsActions from "../clients/clients-actions";
import ChatRowDesktop from "./desktop/chat-row-desktop";
import Reports from "../../components/reports";
import EmptyMessage from "./common/empty-message";
import usePaginationHook from "../../tools/pagination-hook";
import {filterChats} from "./utils";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import AddChatsStatus from "./modal/add-chats-status";
import FilterPanel from "./filter-panel";
import {useLocation} from "react-router-dom";

const COLUMNS = [
  "client",
  "status",
  "responseble",
  "active-orders",
  "all-orders",
  "expected_goods_worth_short",
  "reports-actions",
];

const Chats = () => {
  const {isMobileSize} = useIsMobile()
  const location = useLocation()

  useEffect(() => {
    ChatsActions.load();
    ClientsActions.load();

    return () => {
      if(location?.pathname !== '/chats')
      ChatsStore.clearFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChangeStatus = (tab) => {
    ChatsActions.filter('status', tab)
  }


  const list = ChatsStore.getChats();
  const listWithFilter = filterChats(list, ChatsStore.get("_filter"))

  const { end, start, page, setPage, total, PaginationBlock } =
    usePaginationHook({
      listLength: listWithFilter?.length || 0,
    });


  const listWithPagination = listWithFilter && listWithFilter?.length && listWithFilter.slice(start, end);

  return (
    <>
    <DynamicTabList
      list={ChatsStore.getChatTabsStatus()}
      defaultTab={ChatsStore.getFilter("status")}
      onChangeTab={handleChangeStatus}
      propsAddModal={{modal: 'without-scroll'}}
      addModal={AddChatsStatus}
      propsSettingModal={{modal: 'without-scroll',  isEdit: true}}
      settingModal={AddChatsStatus}
    />
    {!isMobileSize  && <FilterPanel/>}
    <Box style={{overflow: "auto"}} className='border-not-top'>
      {isMobileSize  && <FilterPanel/>}
      <Reports
        load={ChatsActions.load}
        emptyMessage={EmptyMessage}
        columns={COLUMNS}
        hideFooterOnEmpty
        rows={listWithPagination}
        minWidthList={980}
      >
        <ChatRowDesktop role="row"/>
      </Reports>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
    </Box>
    </>
  );
};

export default connectToStores(Chats, {_: ChatsStore});
