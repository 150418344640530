import React, { useState } from "react";
import ShipmentsStore from "../shipments-store";
import T from "../../../components/i18n";
import SupplierShipmentsList from "./supplier-shipments-list";
import { LocalIcon } from "whitelables/wl-name/icons";
import AddSupplierModal from "../add-supplier-modal";
import {Button} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppStore from "../../../app-store";
import ProductsActions from "../../products/products-actions";

const SupplierRowDesktop = ({ data, num}) => {
  const [isOpen, setIsOpen] = useState(false);
  const shipment = ShipmentsStore.getShipmentBySupplierId(data.id);

  const statistics = ShipmentsStore.getShipmentsStatisticsBySupplierId(data.id);
  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";
  return (
    <>
      <tr
        onClick={() => !!shipment.length && setIsOpen(!isOpen)}
        className={`${isOpen && !!shipment.length ? "selected-border" : ""} ${className}`}
        style={shipment.length ? {cursor: "pointer"} : {}}
      >
        <td>
          {!!shipment.length && (
            <div
              className={`display-flex-center black icon-open ${
                isOpen ? "active" : ""
              }`}
            >
              <LocalIcon icon="arrow-left" size="small"/>
            </div>
          )}
        </td>
        <td>
          <b>{data.name}</b>
        </td>
        <td>
          {data.phone || data.address ? (
            <div
              className="display-flex-column"
              style={{justifyContent: "center"}}
            >
              {data.phone && <span>+{data.phone}</span>}
              {data.address && <span>{data.address}</span>}
            </div>
          ) : (
            "-"
          )}
        </td>
        <td>{data.comment || "-"}</td>
        <td>
          <b>{T("supply")}</b>: {statistics.pendingCount}
          <br/>
          <b>{T("quantity-res-mob")}</b>: {statistics.completeCount}
        </td>
        <td>
          <div className="display-flex-column">
            {Object.entries(statistics.sumAmountByCurrency).map(
              ([key, value]) => (
                <span key={`amount-currency-${key}`}>
                  {value} {T(`currency-${key}`)}
                </span>
              )
            )}
            {!Object.entries(statistics.sumAmountByCurrency).length && "-"}
          </div>
        </td>
        <td>
          <div className="display-flex-column">
            {Object.entries(statistics.sumExpensesAmountByCurrency).map(
              ([key, value]) => {
                const calcNotPaid = statistics.sumAmountByCurrency[key] - value;
                return !!calcNotPaid && (
                    <span key={`expenses-amount-currency-${key}`}>
                      {calcNotPaid}{" "}
                      {T(`currency-${key}`)}
                    </span>
                );
              }
            )}
            {!Object.entries(statistics.sumExpensesAmountByCurrency).length &&
              "-"}
          </div>
        </td>
        <td>
          <div className="display-flex-row">
            <Button className='button-link-icon' onClick={(evt) => {
              evt.stopPropagation();
              AppStore.openModal(<AddSupplierModal id={data.id}/>
              )}}>
              <FontAwesomeIcon icon='edit'/>
            </Button>
            <Button className='button-link-icon' onClick={(evt) => {
                evt.stopPropagation()
                ProductsActions.deleteCatalog('suppliers', data.id)
              }}>
              <FontAwesomeIcon icon='trash-alt'/>
            </Button>
          </div>
        </td>
      </tr>
      {isOpen && !!shipment.length && (
        <tr className="selected-border">
          <td colSpan={8}>
            <SupplierShipmentsList list={shipment}/>
          </td>
        </tr>
      )}

    </>
  );
};

export default SupplierRowDesktop;
