import React from "react";
import T from "components/i18n";

import ScrollToTopOnMount from "tools/scroll-top";
import Notification from "react-bulma-components/lib/components/notification";
import SubmitButton from "components/submit-button";

import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from "tools/redirect-to";
import {
  Control,
  Field,
  Label,
} from "react-bulma-components/lib/components/form";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
// import Section from 'react-bulma-components/lib/components/section';
// import Level from 'react-bulma-components/lib/components/level';
import Icon from "react-bulma-components/lib/components/icon";
// import Media from 'react-bulma-components/lib/components/media';
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";

import MovingStore from "./moving-store";
import Actions from "./moving-actions";

import ProductsStore from "../products/products-store";
import WarehouseStore from "../warehouse/warehouse-store";
import WarehouseActions from "../warehouse/warehouse-actions";

import ExpensesStore from "../expenses/expenses-store";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from "react-router-dom";
import SelectComponent from "tools/select-component";

import SelectProducts from "components/select-products";
import BaseForm from "components/modules/base-form";
import roundSignificant from "tools/round-significant";

import User from "user/user-store";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import AppStore from "../../app-store";
import Image from "react-bulma-components/lib/components/image";
import CommentField from "../../components/comment-field";
import InfoBlockQuantityAmount from "../../components/info-block-quantity-amount";
import ProductsActions from "../products/products-actions";
import NumberInput from "../../components/number-input/number-input";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import {confirmDelete} from "../../tools/confirm-delete";
import InfoBlock from "../../components/info-block";

class AddMoving extends BaseForm {
  constructor(props) {
    super(props);
    let state = {},
      product_data = {},
      close = false;
    if (typeof props.location.state != "undefined") {
      state = props.location.state;
    }

    const data = state.data || {},
      only_show = state.only_show || false,
      step = state.step || 0,
      disabledSelectMid = state.disabledSelectMid || false;

    const items = data["items"] || {};
    if (Object.keys(items).length > 0) {
      for (let key in items) {
        const pr = items[key],
          pid = pr["product_id"];
        let data_info = ProductsStore.getProductByMid(
          pid,
          data.from_marketplace_id
        );
        if (data_info) {
          data_info["quantity_moving"] = pr["quantity_shipped"];
          product_data[pid] = this.formattingProductInfo(
            data_info,
            data.from_marketplace_id
          );
        } else {
          product_data[pid] = this.formattingProductInfoRemoveProduct(pid);
        }
      }
    }

    this.is_init = true;
    this.product_data_list = [];

    for (let i in items) {
      this.product_data_list.push(items[i]["product_id"]);
    }

    const count_name = this.getCountName(),
      result = this.calcSaleAmount(product_data, count_name);

    this.group_select = false;
    if (state.group_select) {
      AppStore.closeModal();
      this.group_select = state.group_select.select_product;
    }

    this.state = {
      date: new Date(),
      from_mid: { value: data.from_marketplace_id },
      to_mid: data.to_marketplace_id ? { value: data.to_marketplace_id } : null,
      id: data.id || null,
      status: data.status || "pending",
      comment: data.comment || null,
      product_data: product_data,
      products_select: data.select_product || [],
      amount: result["amount"] || 0, //amount after received items
      quantity: result["count"] || 0, //quantity items after received items
      currency: { value: User.get("currency_id") },
      step: data.status === "completed" && step !== 4 ? 2 : step,
      only_show: only_show,
      close: close,
      waiting: null,
      message: null,
      disabledSelectMid: disabledSelectMid,
    };

    this.item_length = 0;
    this.onChange = this.onChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeCount = this.onChangeCount.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeProductData = this.onChangeProductData.bind(this);
    this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
    this.renderStepOne = this.renderStepOne.bind(this);
    this.renderStepFinish = this.renderStepFinish.bind(this);
    this.renderStepConfirm = this.renderStepConfirm.bind(this);
    this.renderStepRevert = this.renderStepRevert.bind(this);
    this.submit = this.submit.bind(this);
    this.deleteMovingItem = this.deleteMovingItem.bind(this);
    this.onChangeCommentField = this.onChangeCommentField.bind(this);
    this.moveQuantityAll = this.moveQuantityAll.bind(this);
    this.isNotHaveInstock = this.isNotHaveInstock.bind(this);

    this.listenAdd = null;
  }

  componentWillUnmount() {
    if (typeof this.listenAdd == "function") {
      this.listenAdd();
    }

    if (typeof this.listenAddFailed == "function") {
      this.listenAddFailed();
    }
  }

  componentDidMount() {
    // const mid = getQueryParams("mid");
    // if (mid) {
    //   this.setState({"mid": mid});
    // }
    if (this.state.step === 1) {
      // this.updateData()
    }

    const { select_product } = this.props;

    if (select_product) {
      this.onChangeSelectProduct(select_product);
    }

    if (this.group_select) {
      this.onChangeSelectProduct(this.group_select);
    }

    ProductsStore.closeModal();

    this.listenAdd = Actions.add.completed.listen((data) => {
      console.log("!!!! LISTEN createMoving");
      this.setState({
        waiting: false,
        step: data["step"],
        close: data["step"] === 5 ? true : false,
      });

      if ([3, 5].indexOf(data["step"]) > -1) {
        const keysProductData = Object.keys(this.state.product_data || {});
        if (keysProductData && keysProductData.length) {
          ProductsActions.loadByPid(keysProductData);
        }
        // ProductActions.load(true);
      }
    });

    this.listenAddFailed = Actions.add.failed.listen((res) => {
      console.log("!!!! LISTEN Failed Moving", res);
      let data = { error: "internal-service-error" };

      if (res && typeof res["response"] !== "undefined") {
        data = JSON.parse(res["response"]);
      }

      this.setState({
        waiting: false,
        message: T(data["error"]),
      });
    });

    setTimeout(() => {
      this.is_init = false;
    }, 1000);
    // Actions.loadSuppliers();
  }

  getMid() {
    return this.state.from_mid.value || this.state.from_mid;
  }

  checkNegative() {
    return WarehouseStore.isNegativeStock(this.getMid());
  }

  getMessage() {
    setTimeout(() => {
      this.setState({
        message: null,
      });
    }, 4000);
    return (
      <Notification color="danger">
        <ScrollToTopOnMount />
        {this.state.message}
        <Button remove onClick={() => this.setState({ message: null })} />
      </Notification>
    );
  }

  nextStep() {
    let step = this.state.step + 1,
      error_message;

    if (this.state.status === "completed") {
      this.setState({ step: 2 });
      return;
    }

    if (step === 1) {
      if (Object.keys(this.state.product_data).length < 1) {
        error_message = "select-min-one-item-moving";
      } else if (this.state.quantity === 0) {
        error_message = "not-received-product";
      } else if (this.state.to_mid.value === 0) {
        error_message = "select-warehouse";
      }

      if (error_message) {
        this.setState({
          message: T(error_message),
        });
        return;
      }

      this.submit();
    } else if (step === 3) {
      this.submit();
    } else {
      this.setState({ step: step });
    }

    // useEffect(() => {
    window.scrollTo(0, 0);
    // }, [])
  }

  backStep() {
    this.setState({ step: this.state.step - 1 });
  }

  deleteMovingItem(pid) {
    let product_data = this.state.product_data;
    // const state = this.state;

    if (typeof product_data[pid] != "undefined") {
      delete product_data[pid];

      let new_list = [];
      for (let i in this.product_data_list) {
        if (parseInt(this.product_data_list[i]) !== parseInt(pid)) {
          new_list.push(this.product_data_list[i]);
        }
      }

      this.product_data_list = new_list;

      this.updateData(product_data);

      Actions.delete({
        mov_id: this.state.id,
        pid: pid,
        type: "item",
      });

      // let data = {
      //     "pid": pid,
      //     "sid": state.id
      // }
    }
  }

  submit() {
    const state = this.state;

    let send_product = [];
    for (let i in this.product_data_list) {
      let pid = this.product_data_list[i];
      send_product.push(state.product_data[pid]);
    }

    let data = {
      from_mid: state.from_mid.value || state.from_mid,
      to_mid: state.to_mid.value || state.to_mid,
      quantity: state.quantity,
      products: send_product,
      amount: state.amount,
      step: state.step,
      comment: state.comment,
    };

    if (this.state.id) {
      data["id"] = this.state.id;
    }

    this.setState({ waiting: true });
    Actions.add(data);

    // this.listenAdd = Actions.add.completed.listen(() => {
    //     this.setState({"step": 1});
    // });
  }

  updateData(product_data) {
    const count_name = this.getCountName(),
      result = this.calcSaleAmount(product_data, count_name);

    this.setState({
      product_data: product_data,
      amount: result["amount"],
      quantity: result["count"],
    });
  }

  calcSaleAmount(products, count_name) {
    let amount = 0;
    let count = 0;
    for (let i in products) {
      const prod = products[i],
        count_item = parseFloat(prod["quantity_moving"]);
      amount += parseFloat(prod["raw_net_price"] * count_item);
      count += count_item;
    }

    amount = Math.round(amount * 100) / 100;
    count = roundSignificant(count, 3);

    return { amount, count };
  }

  formattingProductInfo(data, mid) {
    let name = data["name"],
      instock = data["instock"];

    if (
      typeof data["stock"] !== "undefined" &&
      mid &&
      typeof data["stock"][mid] !== "undefined"
    ) {
      instock = data["stock"][mid]["instock"];
    }
    return {
      id: data["id"],
      name: name,
      brand: data["brand"] || "",
      retail_price: data["price"] || "",
      finish_price: data["net_price"] || 0,
      raw_net_price: data["raw_net_price"] || 0,
      quantity_moving: data["quantity_moving"] || 0,
      sku: data["sku"],
      asin: data["asin"],
      instock: instock || 0,
      wid: data["wid"],
      units: data["units"],
    };
  }

  formattingProductInfoRemoveProduct(pid) {
    return {
      id: pid,
      remove: true,
      name: T("product-remove"),
      brand: "",
      retail_price: 0,
      finish_price: 0,
      raw_net_price: 0,
      quantity_moving: 0,
      sku: "",
      asin: "",
      instock: "",
      quantity: "",
      wid: "",
    };
  }
  onChangeSelectProduct(selectedOption, need_delete, filterWarehouse) {
    let product_data = this.state.product_data;

    const groupCondition = this.group_select && !!filterWarehouse;
    if (this.group_select && filterWarehouse) {
      this.product_data_list = [];
    }
    for (let p in selectedOption) {
      let pid = selectedOption[p]["value"].split("_")[0],
        data = ProductsStore.getProductByMid(
          pid,
          filterWarehouse || this.getMid()
        ),
        warehouseId = selectedOption[p]["value"].split("_")[1];

      if (groupCondition) {
        if (Number(warehouseId) !== filterWarehouse) {
          if (!data.stock[filterWarehouse]) {
            delete product_data[pid];
          }
          continue;
        }
      }

      if (data) {
        if (need_delete) {
          this.deleteMovingItem(pid);
        } else {
          if (!this.checkNegative() && data["instock"] <= 0) {
            this.setState({
              message: T("not-have-stock"),
            });
            continue;
          }

          if (typeof product_data[pid] != "undefined" && !filterWarehouse) {
            product_data[pid]["quantity_moving"] += 1;
          } else {
            let product_info = this.formattingProductInfo(
              data,
              groupCondition || this.getMid()
            );
            this.product_data_list.unshift(pid);
            product_info["quantity_moving"] = 1;
            product_data[pid] = product_info;
          }
        }
      }
    }

    // Actions.add({
    //   "step": 1,
    //   "sid": this.state.id,
    //   "products": product_data,
    // });

    // this.listenAdd = Actions.add.completed.listen(() => {
    //     this.setState({"step": 2});
    // });

    this.updateData(product_data);
  }

  onChangeSelect(selectedOption, action) {
    if (action.name === "from_mid" && !this.is_init && this.group_select) {
      this.onChangeSelectProduct(
        this.group_select,
        false,
        selectedOption.value
      );
    }

    let product_data = this.state.product_data;

    if (action.name === "from_mid" && !this.is_init && !this.group_select) {
      console.log("clear product_data");
      this.product_data_list = [];
      product_data = {};
    }

    this.setState({
      [action.name]: selectedOption,
      product_data: product_data,
    });
  }

  onChangeDate(value) {
    this.setState({
      date: value,
    });
  }

  onChange(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    this.setState({
      [name]: value,
    });
  }

  onChangeProductData(evt, name) {
    const value = evt.target.value,
      pid = evt.target.name.split("-")[1];

    let product_data = this.state.product_data;

    product_data[pid][name] = value;
    this.updateData(product_data);
  }

  onChangeCount(evt) {
    let value = evt.target.value,
      pid = evt.target.name.split("-")[2],
      count_name = this.getCountName();

    value = this.checkVal(value);

    if (!isNaN(value)) {
      let product_data = this.state.product_data;
      product_data[pid][count_name] = value;
      this.updateData(product_data);
    }
  }

  moveQuantityAll() {
    const product_data = {...this.state.product_data};
    let countInstock = 0
    for (let pid in product_data) {
      product_data[pid]['quantity_moving'] = product_data[pid]['instock'];
      countInstock += parseFloat(product_data[pid]['instock'])
    }
    confirmDelete(() => this.updateData(product_data), 'confirm-action', T('sure-you-want-move', {count: countInstock}), 'confirm-label', 'close')
  }


  getProductName(prod) {
    const pid = prod.id;

    let name = "";

    if (prod.asin && prod.asin !== "") {
      name += prod.asin + ". ";
    }

    if (prod.sku && prod.sku !== "") {
      name += prod.sku + ". ";
    }

    if (prod.brand && prod.brand !== "") {
      name += prod.brand + ". ";
    }

    name += prod.name;

    return (
      <span>
        ID: {pid}. {name}
      </span>
    );
  }

  getCountName() {
    return "quantity_moving";
  }

  productLine(prod) {
    return this.props.isMobileSize
      ? this.productLineMobile(prod)
      : this.productLineDesktop(prod);
  }

  productLineMobile(prod) {
    const pid = prod.id,
      name = this.getProductName(prod),
      count_name = this.getCountName();
    const img = "/bimages/get/34-" + User.get("id") + "-" + pid + "-0.jpg";


    const isStandard = WarehouseStore.isStandardStock(this.state.from_mid.value)
    const isNotHaveInstock = prod.instock < this.state.product_data[pid][count_name]

    const product = ProductsStore.getProduct(pid)

    const style = {}
    const textStyle = {}

    if(isNotHaveInstock && isStandard) {
      style.borderColor = '#f03a5f'
      textStyle.color = '#f03a5f'
    }

    if(!product) {
      style.borderColor = '#f03a5f'
      textStyle.color = '#f03a5f'
    }

    return (
      <List.Item key={pid} align="left" style={style}>
        <b style={textStyle}>
          <Image src={img} size={32} className="align-left" />
          {name}
        </b>
        <hr />
        <Columns className="is-mobile">
          <Columns.Column>
            <Label>
              {T("In-stock")}: <br />
              <div className="text-success big-text margin-top-10">
                {" "}
                {prod.instock}{" "}
                {ProductsStore.getUnits(this.state.product_data[pid]["units"])}
              </div>
              <br />
            </Label>
          </Columns.Column>
          <Columns.Column>
            <Field horizontal>
              <Label>{T("Moving")}:</Label>
              <Control iconRight>
                <NumberInput
                  key={"count-pid-" + pid}
                  name={"count-pid-" + pid}
                  className="for-small-icon "
                  min="1"
                  onChange={this.onChangeCount}
                  value={this.state.product_data[pid][count_name]}
                />
                <Icon align="right">
                  <span className="small-text">
                    {ProductsStore.getUnits(
                      this.state.product_data[pid]["units"]
                    )}
                  </span>
                </Icon>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column
            size="one-fifth"
            style={{ width: "10%", paddingTop: "35px" }}
          >
            <Link
              to="#"
              onClick={() => this.deleteMovingItem(pid)}
              title={T("delete")}
            >
              <FontAwesomeIcon icon="trash-alt" size="2x" />
            </Link>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }

  productLineDesktop(prod) {
    const pid = prod.id,
      name = this.getProductName(prod),
      count_name = this.getCountName();

    const isStandard = WarehouseStore.isStandardStock(this.state.from_mid.value)
    const isNotHaveInstock = prod.instock < this.state.product_data[pid][count_name]

    const product = ProductsStore.getProduct(pid)

    const style = {}

    if(isNotHaveInstock && isStandard) {
      style.color = 'white'
    }

    if(!product) {
      style.background = 'red'
      style.color = 'white'
    }

    return (
      <List.Item key={pid} className={`font-size-80 ${isNotHaveInstock && isStandard ? 'has-background-warning' : ''}`} style={style}>
        <Columns>
          <Columns.Column size={"two-thirds"}>{name}</Columns.Column>
          <Columns.Column>
            <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
              {this.state.status !== "completed" ? (
                <Columns.Column>
                  <b>
                    {prod.instock}{" "}
                    {ProductsStore.getUnits(
                      this.state.product_data[pid]["units"]
                    )}
                  </b>
                </Columns.Column>
              ) : null}
              <Columns.Column>
                {product && <Control iconRight>
                  <NumberInput
                    key={"count-pid-" + pid}
                    name={"count-pid-" + pid}
                    className="for-slim-icon "
                    min="1"
                    onChange={this.onChangeCount}
                    value={this.state.product_data[pid][count_name]}
                  />
                  <Icon align="right" className="for-slim-icon ">
                    <span className="small-text">
                      {ProductsStore.getUnits(
                        this.state.product_data[pid]["units"]
                      )}
                    </span>
                  </Icon>
                </Control>}
              </Columns.Column>
              <Columns.Column>
                <Link
                  to="#"
                  onClick={() => this.deleteMovingItem(pid)}
                  title={T("delete")}
                >
                  <FontAwesomeIcon icon="trash-alt" size="1x" />
                </Link>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }


  getProductLineTitle() {
    return (
      <List.Item
        key="title-product-line"
        className="font-size-80 bold has-background-light nowrap"
      >
        <Columns>
          <Columns.Column size={"two-thirds"}>
            {T("product-name")}
          </Columns.Column>
          <Columns.Column>
            <Columns>
              {this.state.status !== "completed" ? (
                <Columns.Column>{T("In-stock")}</Columns.Column>
              ) : null}
              <Columns.Column>
                {T("Moving")}
                {!!Object.keys(this.state.product_data || {}).length && !this.state.step && <Button className="button_link smll"
                         onClick={() => this.moveQuantityAll()}>({T('all')})</Button>}
              </Columns.Column>
              {this.state.step === 2 ? null : (
                <Columns.Column>{T("del")}</Columns.Column>
              )}
            </Columns>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }

  getMovingList() {
    return this.props.isMobileSize ? this.getMovingListMobile() : this.getMovingListDesktop();
  }

  getMovingListMobile() {
    const sp = this.state.product_data;

    let moving_list = [],
      empty_list = (
        <List.Item key="s-1" className="font-size-80">
          <Heading size={6} className="has-text-danger ">
            {T("select-min-one-item-moving")}
          </Heading>
        </List.Item>
      );

    for (let i in this.product_data_list) {
      const pid = this.product_data_list[i];
      let product = sp[pid];
      if (product) {
        moving_list.push(this.productLine(product));
      }
    }

    this.item_length = moving_list.length || 0;

    return (
      <List hoverable className="shipments-order-list">
        {moving_list.length > 0 ? moving_list : empty_list}
      </List>
    );
  }

  getMovingListDesktop() {
    const sp = this.state.product_data;
    let moving_list = [],
      empty_list = (
        <List.Item key="s-1" className="font-size-80">
          <Heading size={5} className="has-text-danger ">
            {T("select-min-one-item-moving")}
          </Heading>
        </List.Item>
      );
    for (let i in this.product_data_list) {
      const pid = this.product_data_list[i];
      let product = sp[pid];
      if (product) {
        moving_list.push(this.productLine(product));
      }
    }

    this.item_length = moving_list.length || 0;
    return (
      <List hoverable className="moving-order-list">
        {this.getProductLineTitle()}
        {moving_list.length > 0 ? moving_list : empty_list}
      </List>
    );
  }

  onChangeCommentField(value) {
    this.setState({
      comment: value,
    });
  }

  getCurrency(aid) {
    if (aid && aid.value) {
      return ExpensesStore.getAccountCurrency(aid.value);
    }

    return User.getCurrency(this.state.currency.value);
  }

  headingMoving() {
    let title = ''
    let text = ''
    const step = this.state.step
    const status = this.state.status
    const id = this.state.id

    if(step === 1) {
      return null
    } else if(step === 2) {
      if(status === 'pending') {
        title = 'products-moving-btn'
        text = <div>
          {T('history-data-mov_id')}{" "} <b>{id}</b><br/>
          {T("source")}:{" "}
          <b>{T(WarehouseStore.getWarehouseName(this.state.from_mid.value))}</b> <br/>
          {T("destination")}:{" "}
          <b>{T(WarehouseStore.getWarehouseName(this.state.to_mid.value))}</b>
        </div>
      } else {
        title = `${T('history-data-mov_id')} ${id}`
        text = <div>
          {T("source")}:{" "}
          <b>{T(WarehouseStore.getWarehouseName(this.state.from_mid.value))}</b> <br/>
          {T("destination")}:{" "}
          <b>{T(WarehouseStore.getWarehouseName(this.state.to_mid.value))}</b>
        </div>
      }
    } else if(step === 0) {
      if(id) {
        title = 'edit-moving-title'
        text = `${T('history-data-mov_id')} ${id}`
      } else {
        title = 'create-moving-title'
      }
    }

    return <InfoBlock title={title} text={text}/>
  }

  renderStepOne() {

    let disabledSubmit = false

    for (let i in this.product_data_list) {
      const pid = this.product_data_list[i];
      const product = ProductsStore.getProduct(pid)

      if(!product) {
        disabledSubmit = true;
      }
    }

    return (
      <Box>
        {this.headingMoving()}
        <Columns style={{position: 'relative', zIndex: 10}}>
          <Columns.Column>
            <SelectComponent
              name="from_mid"
              label="from-product-marketplace"
              onChange={this.onChangeSelect}
              readOnly={this.state.disabledSelectMid}
              value={this.state.from_mid}
              list={WarehouseStore.getWarehouseShipmentList}
              load={WarehouseActions.load}
              empty="select-warehouse"
              isOptionDisabled={(option) =>
                option.value === this.state.to_mid.value && !!this.state.to_mid.value
              }
              creatable={false}
            />
          </Columns.Column>
          <Columns.Column>
            <SelectComponent
              name="to_mid"
              label="to-product-marketplace"
              onChange={this.onChangeSelect}
              readOnly={this.state.disabledSelectMid}
              value={this.state.to_mid}
              list={WarehouseStore.getWarehouseShipmentList}
              load={WarehouseActions.load}
              empty="select-warehouse"
              isOptionDisabled={(option) =>
                option.value === this.state.from_mid.value && !!this.state.from_mid.value
              }
              creatable={false}
            />
          </Columns.Column>
        </Columns>
        <SelectProducts
          onChange={this.onChangeSelectProduct}
          value={this.state.products_select}
          selected={() => {
            return this.product_data_list;
          }}
          quantity={0}
          source="moving"
          mid={this.getMid()}
        />

        {this.getMovingList()}

        <InfoBlockQuantityAmount
          allQuantity={this.state.quantity}
          lengthProduct={this.item_length}
          amount={this.state.amount}
          title="moving-count-item-added"
        />
        <CommentField
          onChange={this.onChangeCommentField}
          comment={this.state.comment}
          placeholder="moving-comment-placeholder"
        />
        <Button.Group position="centered">
          {!disabledSubmit && <SubmitButton size="large" fullwidth={false} rounded color="primary" submit={this.nextStep} text={this.state.id ? T("btn-edit") : T("btn-save")} waiting={this.state.waiting}/>}
          <Button
            size="large"
            rounded
            color="light"
            onClick={() => {
              Actions.load();
              this.setState({ close: true });
            }}
          >
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    );
  }

  isNotHaveInstock() {
    const isStandard = WarehouseStore.isStandardStock(this.state.from_mid.value)
    if(isStandard) {
      const productData = Object.values(this.state.product_data)

      return productData.some(item => item.instock < item.quantity_moving)
    }

    return false
  }

  isNotHaveInstockMessage() {
    const isNotHaveInstock = this.isNotHaveInstock()
    return isNotHaveInstock && this.state.status !== 'completed' && <Notification color="danger">
      {T('cant-complete-move-dont-have-product')}
    </Notification>
  }

  productLineConfirm(prod) {
    const pid = prod.id,
      name = this.getProductName(prod),
      count_name = this.getCountName();

    const isStandard = WarehouseStore.isStandardStock(this.state.from_mid.value)
    const isNotHaveInstock = prod.instock < this.state.product_data[pid][count_name] && this.state.status !== 'completed'

    const product = ProductsStore.getProduct(pid)

    const style = {}

    if(isNotHaveInstock && isStandard) {
      style.color = 'white'
    }

    if(!product) {
      style.background = 'red'
      style.color = 'white'
    }

    return (
      <List.Item key={pid} className={`font-size-80 ${isNotHaveInstock && isStandard ? 'has-background-warning' : ''}`} style={style}>
        <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
          <Columns.Column size={"two-thirds"}>{name}</Columns.Column>
          {this.state.status !== "completed" ? (
            <Columns.Column>
              <b>
                {prod.instock}{" "}
                {ProductsStore.getUnits(this.state.product_data[pid]["units"])}
              </b>
            </Columns.Column>
          ) : null}
          <Columns.Column>
            <b>
              {this.state.product_data[pid][count_name]}{" "}
              {ProductsStore.getUnits(this.state.product_data[pid]["units"])}
            </b>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }

  productLineConfirmMobile(prod) {
    const pid = prod.id,
      name = this.getProductName(prod),
      count_name = this.getCountName();
    const img = "/bimages/get/34-" + User.get("id") + "-" + pid + "-0.jpg";
    const product = ProductsStore.getProduct(pid)

    const isStandard = WarehouseStore.isStandardStock(this.state.from_mid.value)
    const isNotHaveInstock = prod.instock < this.state.product_data[pid][count_name] && this.state.status !== 'completed'

    const style = {}

    if(isNotHaveInstock && isStandard) {
      style.color = 'white'
    }

    if(!product) {
      style.background = 'red'
      style.color = 'white'
    }

    return (
      <List.Item key={pid} align="left" className={`${isNotHaveInstock && isStandard ? 'has-background-warning' : ''}`} style={style}>
        <b>
          <Image src={img} size={32} className="align-left" />
          {name}
        </b>
        <hr />
        <Columns className="is-mobile" >
          {this.state.status !== "completed" && (
            <Columns.Column>
              <Label style={style}>
                {T("product-info-warehouse-one")}:
                <span className="text-success">
                  {" "}
                  {prod.instock}
                  {T("items-p")}
                </span>
                <br />
              </Label>
            </Columns.Column>
          )}
          <Columns.Column >
            <Label style={style}>
              {T("moving")}:
              <span className="text-success">
                {" "}
                {this.state.product_data[pid][count_name]}
                {T("items-p")}
              </span>
              <br />
            </Label>
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }

  renderStepConfirm() {
    let moving_list = [];
    const sp = this.state.product_data;

    let disabledConfirm = false;

    for (let i in this.product_data_list) {
      const pid = this.product_data_list[i];
      let product = sp[pid];

      const getProduct = ProductsStore.getProduct(pid)

      if(!getProduct) {
        disabledConfirm = true;
      }

      if (product) {
        moving_list.push(
          this.props.isMobileSize
            ? this.productLineConfirmMobile(product)
            : this.productLineConfirm(product)
        );
      }
    }

    return (
      <Box>
        {this.headingMoving()}
        <List hoverable className="moving-order-list">
          {this.props.isMobileSize ? null : this.getProductLineTitle()}
          {moving_list}
        </List>
        <Button.Group position="centered">

          {!this.isNotHaveInstock() && !disabledConfirm && <>
            {this.state.only_show ? null : (
              <SubmitButton
                text="create-moving"
                fullwidth={false}
                size="large"
                color="primary"
                waiting={this.state.waiting}
                submit={this.nextStep}
              />
            )}
          </>}
          <Button
            size="large"
            rounded
            color="light"
            onClick={() => {
              Actions.load();
              this.setState({ close: true });
            }}
          >
            {T("close")}
          </Button>
        </Button.Group>
        <InfoBlockQuantityAmount
          allQuantity={this.state.quantity}
          lengthProduct={moving_list.length}
          amount={this.state.amount}
          title="total-products"
        />
      </Box>
    );
  }

  renderStepRevert() {
    return (
      <Box>
        <h1 className="title">{T("revert-moving")}</h1>
        <div className="big-text">{T("moving-revert-desc")}</div>
        <Button.Group position="centered">
          <SubmitButton
            text="revert-moving"
            fullwidth={false}
            size="large"
            color="primary"
            waiting={this.state.waiting}
            submit={this.submit}
          />
          <Button
            size="large"
            rounded
            color="light"
            onClick={() => {
              this.setState({ close: true });
            }}
          >
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    );
  }

  renderStepFinish() {
    return (
      <Box>
        <h1 className="title">
          {T(this.state.step === 1 ? "moving-created" : "moving-finished")}
        </h1>
        <Button.Group>
          <Button
            fullwidth
            size="large"
            rounded
            color="primary"
            onClick={() => {
              Actions.load()
              this.setState({ close: true });
            }}
          >
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    );
  }

  render() {
    const stepNaviganor = [
        this.renderStepOne,
        this.renderStepFinish,
        this.renderStepConfirm,
        this.renderStepFinish,
        this.renderStepRevert,
        this.renderStepFinish,
      ],
      currentStep = stepNaviganor[this.state.step];
    return (
      <div>
        {this.isNotHaveInstockMessage()}
        {this.state.message ? this.getMessage() : ""}
        {currentStep()}
        {this.state.close ? redirectTo("/moving") : ""}
      </div>
    );
  }
}

//<Heading>Оформление поставки товара</Heading>

export default connectToStores(withIsMobileSize(AddMoving), {
  products: ProductsStore,
  moving: MovingStore,
});
