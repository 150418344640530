import React, { Component } from "react";
import T from "components/i18n";
import ExpensesStore from 'modules/expenses/expenses-store';
import ProductStore from 'modules/products/products-store';
import WarehouseStore from 'modules/warehouse/warehouse-store';
import DOM from "react-dom-factories";
import Money from "components/money";
import formatDate from 'tools/format-date';
import List from 'react-bulma-components/lib/components/list';
import Heading from 'react-bulma-components/lib/components/heading';
import User from 'user/user-store';
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../app-store";
import ViewProductModal
    from "../../products/modal/view-product/view-product-modal";
import {parseJSON} from "../../../tools/error-handler";
import ClientViewModal from "../../clients/modal/client-view/client-view-modal";
import ClientsStore from "../../clients/clients-store";
import {
    withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";
import SaleViewModal from "../../sales/modal/sale-view/sale-view-modal";
import ListInfoProductModal
    from "../../products/modal/list-info-product-modal/list-info-product-modal";
import SalesStore from "../../sales/sales-store";
import OrdersStore from "../../orders/orders-store";

const IMPORTANT_FIELD = ['category_id', 'asin', 'responsible_user_id', 'balance', 'name', 'profit',
 'instock', 'sku', 'net_price', 'price', 'amount', 'marketplace_id', 'new', 'old', 'total', 'comment',
 'integration_id', 'is_reserved', 'reserv', 'quantity', 'from_mid', 'to_mid', 'mov_id', 'source', "mids", 'old_price', 'new_price', 'mass_price', 'changed_to'];
 
class HistoryRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.isUpdatingInternally = false;
    }

    componentDidUpdate(prevProps) {
        if (
          this.props.triggerIsOpen !== prevProps.triggerIsOpen &&
          !this.isUpdatingInternally
        ) {
            this.setState({ isOpen: this.props.triggerIsOpen });
        }
        this.isUpdatingInternally = false;
    }

    parseComment(data) {
        if (data[0] !== "{") {
            return data;
        }
        
        const comments = JSON.parse(data);
        let response = [];
        for (let i in comments) {
            const comment = comments[i];

            if (i === "products") {
                for (let j in comment) {
                    let _comment = comment[j];
                    response.push(T('new-instock', {'count': _comment['instock']}))
                }
            }

            if (IMPORTANT_FIELD.indexOf(i) > -1 && comment !== "") {
                let _string = comment;
                if (['amount', 'balance', 'price', 'net_price', 'profit', 'old_price', 'new_price', 'changed_to'].indexOf(i) > -1) {
                    if (["net_price", "profit"].indexOf(i) > -1 && !User.getPermissionEnable("incomeinformation")) continue;
                    if (["price", "balance", 'old_price', 'new_price'].indexOf(i) > -1 && !User.getPermissionEnable("salesprice")) continue;
                    _string = (<Money amount={comment} wrapper={DOM.span} aid={this.props?.data?.account_id || null}/>)
                } else if (i === "responsible_user_id") {
                    _string = User.getResponsebleName(_string);
                } else if (i === "marketplace_id") {
                    _string = T(WarehouseStore.getWarehouseName(_string))
                } else if (['from_mid', 'to_mid'].indexOf(i) > -1) {
                    _string = T(WarehouseStore.getWarehouseName(_string))
                } else if (i === "is_reserved") {
                    _string = "Order ID: "+_string;
                } else if (i === "source") {
                    _string = T(_string);
                } else if (i === "integration_id") {
                    let integr = User.getIntegrationNameById(_string);
                    _string = T('integration-'+integr['integration_id']);
                } else if(i === 'category_id') {
                    _string = ProductStore.getCategoryName(_string)
                } else if(i === 'name') {
                    _string = T(_string) || _string;
                } else if(i === 'comment') {
                    const regexProduction = /Production/g;
                    const regexUndoProduction = /Undo production/g;

                    _string = _string.replace(regexProduction, T('production'))
                    _string = _string.replace(regexUndoProduction, T('undo-production'))

                    _string = T(_string) || _string;
                } else if( i === 'mids') {
                    if(Array.isArray(_string)){
                        _string = _string.map(item => {
                            const name = WarehouseStore.getWarehouseName(item)
                            if(name) {
                                return name
                            } else {
                                return `${T('warehouse-deleted')} ID: ${item}`
                            }
                        }).join(', ')
                    }
                } else if(i === 'mass_price') {
                    const source = comments.source
                    if(source === 'decrease_update_mass_price') {
                        _string = `-${parseFloat(comment) * 100}%`
                    } else if(source === 'increase_update_mass_price'){
                        _string = `${parseFloat(comment) * 100}%`
                    } else if(source === 'mass_change_price_increase' || source === 'mass_change_price_decrease') {
                        _string = (<Money amount={comment} wrapper={DOM.span} />)
                    } else if(source === 'mass_change_price_decrease') {
                        _string = (<Money amount={comment} wrapper={DOM.span} />)
                    } else if(source === 'mass_change_price_exact') {
                        _string = (<Money amount={comment} wrapper={DOM.span} />)
                        response.push(<div key={i}><b>{T('history-data-new_price')}:</b> {_string}</div>)
                        continue;
                    }
                } else if(i === 'order_status') {
                    _string = SalesStore.isDefaultStatus(_string)
                      ? T("status-" + _string)
                      : OrdersStore.getStatusNameById(_string)
                }
                response.push(<div key={i}><b>{T('history-data-'+i)}:</b> {_string}</div>)
            }
        }

        return response;
    }
    
    renderMobile() {
        const {data} = this.props;
        let account = null, order_number = null, 
        shipment_number = null, expenses_number = null,
        responseble = null, marketplace = null;
        

        if (data['account_id']) {
            account = (
                    <List.Item>
                        <b>{T('account')}:</b> {T(ExpensesStore.getAccountName(data['account_id']))}
                    </List.Item>

                );
        }

        let comments = {}
        if (data['comment'][0] === "{") {
            comments = JSON.parse(data['comment']);
        }

        if (data['order_id'] || comments['order_number']) {

            order_number = (
                <List.Item><b>{T('purchase')}: </b> <Button className='button-link-without-style' onClick={() => AppStore.openModal(<SaleViewModal modal='sale-view-modal' saleID={data['order_id']}/>)}>#{comments['order_number'] || data['order_id']}</Button></List.Item>
                )
        }

        if (data['shipment_id'] && data['shipment_id'] !== "null") {
            if (data['shipment_id'].indexOf("[") > -1) {
                let shipment = JSON.parse(data['shipment_id']);
                shipment_number = [];
                for (let i in shipment) {
                    let sp = shipment[i];
                    shipment_number.push(
                            <List.Item><b>{T('shipment')} #{sp['sid']}</b>. {T('quantity')}: {sp['count']}</List.Item>
                        )
                }
            } else {
            shipment_number = (
                <List.Item><b>{T('shipment')}:</b> #{data['shipment_id']}</List.Item>
                )
            }
        }

        if (data['responsible_user_id']) {
            responseble = (
                <List.Item><b>{T('responseble')}:</b> {User.getResponsebleName(data['responsible_user_id'])}</List.Item>
                )
        }

        if (data['expenses_id']) {
            expenses_number = (
                <List.Item><b>{T('payment')}:</b> #{data['expenses_id']}</List.Item>
                )
        }

        if (data['marketplace_id']) {
            marketplace = (
                <List.Item><b>{T('history-data-marketplace_id')}:</b> {T(WarehouseStore.getWarehouseName(data['marketplace_id']))}</List.Item>
                )
        }
        const parseComment = parseJSON(data['comment']);
        let infoProduct = null

        if((parseComment.pid_mid && typeof parseComment.pid_mid === "string") || (parseComment.pids && Array.isArray(parseComment.pids))) {
            let pid_mid = parseComment.pid_mid;
            if(parseComment.pids && Array.isArray(parseComment.pids)) {
                pid_mid = parseComment.pids.map(item => `${item}_${data.marketplace_id}`).join(',');
            }
            infoProduct = <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ListInfoProductModal modal='listinfoproduct' pid_mid={pid_mid}/>)}>{T('list-products')}</Button>
        }

        let className = " is-one-";
        className += this.props.num % 2 === 0 ? "even" : "odd";

        return (
            <tr key={data['id']} className={`${className} ${this.state.isOpen ? 'selected-border' : ''}`}>
                <td colSpan="5" style={{padding: 10}}>
                    <div onClick={() => this.setState({isOpen: !this.state.isOpen})}>
                        {formatDate(data['created_at'])}
                        <Heading size={5} className="left display-flex-row-gap flex-wrap" style={this.state.isOpen ? {margin: '0 0 10px 0'} : {margin: 0}}>
                            {T('history-action-' + data['action_id'])} <br/>
                            {data['product_id'] ?
                              <Button className='button-link-without-style'
                                      size='large'
                                      onClick={() => AppStore.openModal(
                                        <ViewProductModal
                                          pid={data['product_id']}/>)}>
                                  {ProductStore.getProduct(data['product_id'])['name']}<br/>
                              </Button>
                              : null}
                        </Heading>
                        {data['responsible_user_id'] && !this.state.isOpen && <span
                          className='silver'><b>{T('responseble')}:</b> {User.getResponsebleName(data['responsible_user_id'])}</span>}
                    </div>
                    {this.state.isOpen && <List>
                        {marketplace}
                        {responseble}
                        {account}
                        {order_number}
                        {shipment_number}
                        {expenses_number}
                        {infoProduct && <List.Item>{infoProduct}</List.Item>}
                        <List.Item>
                            {this.parseComment(data['comment'])}
                        </List.Item>
                    </List>}
                </td>
            </tr>
        );
    }

    renderDesktop() {
        const {data} = this.props;
        let account = null, order_number = null,
          shipment_number = null, expenses_number = null,
        expenses_name = null, responseble = null, marketplace = null;
        


        if (data['account_id']) {
            account = (
                    <div>
                        <b>{T('account')}:</b> {T(ExpensesStore.getAccountName(data['account_id']))}
                    </div>

                );
        }

        let comments = {}
        if (data['comment'][0] === "{") {
            comments = JSON.parse(data['comment']);
        }

        if (data['order_id'] || comments['order_number']) {
            if(data.action_id !== 5) {
                order_number = (
                  <div><b>{T('purchase')}: </b><Button className='button-link-without-style' onClick={() => AppStore.openModal(<SaleViewModal modal='sale-view-modal' saleID={data['order_id']}/>)}>#{comments['order_number'] || data['order_id']}</Button></div>
                )
            } else {
                order_number = (
                  <div><b>{T('purchase')}: </b>#{comments['order_number'] || data['order_id']}</div>
                )
            }

        }

         if (data['shipment_id'] && data['shipment_id'] !== "null") {
            if (data['shipment_id'].indexOf("[") > -1) {
                let shipment = JSON.parse(data['shipment_id']);
                shipment_number = [];
                for (let i in shipment) {
                    let sp = shipment[i];
                    shipment_number.push(
                            <div key={`shipnumber-${sp['sid']}`}><b>{T('shipment')} #{sp['sid']}</b>. {T('quantity')}: {sp['count']}</div>
                        )
                }
            } else {
            shipment_number = (
                <div><b>{T('shipment')}:</b> #{data['shipment_id']}</div>
                )
            }
        }

        if (data['responsible_user_id']) {
            responseble = (
                <div><b>{T('responseble')}:</b> {User.getResponsebleName(data['responsible_user_id'])}</div>
                )
        } 

        if (data['marketplace_id']) {
            marketplace = (
                <div><b>{T('history-data-marketplace_id')}:</b> {T(WarehouseStore.getWarehouseName(data['marketplace_id']))}</div>
                )
        }

        if (data['expenses_id']) {
            let comments = {}
            if (data['comment'][0] === "{") {
                comments = JSON.parse(data['comment']);
            }
            expenses_number = (
                <div><b>{T('payment')}:</b> #{data['expenses_id']}</div>
                )

            expenses_name = (
                <div>{T(comments['name']) || comments['name']}</div>
                );
        }
        const parseComment = parseJSON(data['comment']);
        let infoProduct = null

        if((parseComment.pid_mid && typeof parseComment.pid_mid === "string") || (parseComment.pids && Array.isArray(parseComment.pids))) {
            let pid_mid = parseComment.pid_mid;
            if(parseComment.pids && Array.isArray(parseComment.pids)) {
                pid_mid = parseComment.pids.map(item => `${item}_${data.marketplace_id}`).join(',');
            }
            infoProduct = <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ListInfoProductModal modal='listinfoproduct' pid_mid={pid_mid}/>)}>{T('list-products')}</Button>
        }

        const client_id = [44, 45].includes(data.action_id) ? parseComment['id'] : parseComment['cid'] || null;

        const getClient = ClientsStore.getClientById(client_id);

        let className = " is-one-";
        className += this.props.num % 2 === 0 ? "even" : "odd";

        return (
                <tr key={`row-${data['id']}`} className={className}>
                    <td width="20%">
                     {formatDate(data['created_at'])}<br/>
                    <b className="has-text-success">{T('history-action-'+data['action_id'])}</b>
                    {order_number}
                    {shipment_number}
                    {expenses_number}
                    {responseble}
                    </td>
                    <td width="30%">
                        {expenses_name}
                        {infoProduct}
                        {data['product_id'] && <Button className='button-link-without-style'
                                 size='small' onClick={() => AppStore.openModal(
                          <ViewProductModal pid={data['product_id']}/>)}>
                            {ProductStore.getProductName(data['product_id'])}
                        </Button>}
                        {!(parseComment.pids && Array.isArray(parseComment.pids)) && marketplace}
                        {client_id && (
                          <Button className='button-link-without-style' size='small' onClick={() => AppStore.openModal(<ClientViewModal clientID={client_id}/>)}>
                              {getClient?.name}
                          </Button>
                          )}
                    </td>
                    <td>
                    {account}
                    {this.parseComment(data['comment'])}
                    </td>
                </tr>
            );
    }

    render() {
        return this.props.isMobileSize ? this.renderMobile() : this.renderDesktop();
    }
}

export default withIsMobileSize(HistoryRow);
