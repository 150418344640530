import React, {useState} from 'react'
import ReportList from "./report-list";
import {Box} from "react-bulma-components";
import ActionsButton from "./actions-button";
import StatisticsBlock from "../../../components/statistics-block";
import ShipmentsStore from "../shipments-store";
import T from '../../../components/i18n'
import FilterPanel from "./filter-panel";
import DynamicTabList
	from "../../../components/dynamic-tab-list/dynamic-tab-list";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const SupplierComponent = ({isActiveTab,listTabs,onChangeTab}) => {
	const [searchFilter, setSearchFilter] = useState('')
	const {isMobileSize} = useIsMobile()
	const getStatistics = ShipmentsStore.getShipmentStatisticBySupplier()

	const calcSumStatistics = Object.values(getStatistics).reduce((acc, cur) => {
		Object.entries(cur.sumAmountByCurrency).forEach(([currency, amount]) => {
			if(!acc.sumAmountByCurrency[currency]) {
				acc.sumAmountByCurrency[currency] = 0
			}
			acc.sumAmountByCurrency[currency] += amount;
		})

		Object.entries(cur.sumExpensesAmountByCurrency).forEach(([currency, amount]) => {
			if(!acc.sumExpensesAmountByCurrency[currency]) {
				acc.sumExpensesAmountByCurrency[currency] = 0
			}
			acc.sumExpensesAmountByCurrency[currency] += (cur.sumAmountByCurrency[currency] - amount);
		})



		acc.pendingCount += cur.pendingCount
		acc.completeCount += cur.completeCount
		return acc
	},{
		sumAmountByCurrency: {},
		sumExpensesAmountByCurrency: {},
		pendingCount: 0,
		completeCount: 0
	})

	const sumWithCurrencyBlock = (list, type) => {
		const keyValues = Object.entries(list)
		if(keyValues.length) {
			return <div className='display-flex-column'>
				{keyValues.map(([key, values]) => !!values && <span key={`sum-statistics-${key}-${type}`}>{values} {T(`currency-${key}`)}</span>)}
			</div>
		}

		return "-"
	}

	const statisticsList = [
		{
			name: 'supply',
			value: <span>{calcSumStatistics.pendingCount}</span>,
			classNameText: 'title'
		},
		{
			name: 'quantity-res-mob',
			value: <span>{calcSumStatistics.completeCount}</span>,
			classNameText: 'title'
		},
		{
			name: "amount",
			value: sumWithCurrencyBlock(calcSumStatistics.sumAmountByCurrency, 'amount'),
			classNameText: 'title'
		},
		{
			name: "not-paid",
			value: sumWithCurrencyBlock(calcSumStatistics.sumExpensesAmountByCurrency, 'not-paid', ),
			classNameText: 'title has-text-danger'
		}
	]

	const handleChangeSearch = ({name, value}) => {
		if(name) {
			setSearchFilter(value)
		}
	}

	return (
		<>
			{!isMobileSize && <FilterPanel searchFilter={searchFilter}
										onChangeSearch={handleChangeSearch}/>}
			<StatisticsBlock list={statisticsList}/>
			<ActionsButton/>
			{isMobileSize && <DynamicTabList
				list={listTabs}
				onChangeTab={onChangeTab}
				defaultTab={isActiveTab}
			/>}
			<Box className='border-not-top'>
				{isMobileSize && <FilterPanel searchFilter={searchFilter}
											onChangeSearch={handleChangeSearch}/>}
				<ReportList searchFilter={searchFilter}/>
			</Box>
		</>
	);
};

export default SupplierComponent;