import React, { Component } from 'react';

import T from "components/i18n";
import Pagination from './base';
import {withIsMobileSize} from "../is-mobile-context/is-mobile-context";


class PaginationBlock extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }
    onChange(page) { 
        const {setPage} = this.props;
        setPage(page-1);
    }

    render() {
        const {totalPage, currentPage,size, buttonClassName, showPrevNext, delta, listStyle, style, countPerPage, onChangePerPage, stylePerPage, optionsPerPage, centerElement} = this.props;
        return (
            <Pagination
                onChange={this.onChange}
                current={currentPage+1}
                total={totalPage}
                className={size || "is-small"}
                buttonClassName={buttonClassName}
                delta={typeof(delta) !== "undefined" ? delta : this.props.isMobileSize ? 1 : 5}
                showPrevNext={typeof(showPrevNext) !== "undefined" ? showPrevNext : !this.props.isMobileSize}
                showFirstLast
                next={T('page-next')}
                previous={T('page-previous')}
                listStyle={listStyle}
                style={style}
                countPerPage={countPerPage}
                onChangePerPage={onChangePerPage}
                stylePerPage={stylePerPage}
                optionsPerPage={optionsPerPage}
                centerElement={centerElement}
            />
        );

    }
}

export default withIsMobileSize(PaginationBlock);