import React, {useEffect, useState} from "react";
import { connectToStores } from "tools/reflux-tools";
import ShipmentsStore from "./shipments-store";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import ShipmentsComponent from "./shipments-component/shipments-component";
import SupplierComponent from "./supplier-component/supplier-component";
import ShipmentsActions from "./shipments-actions";
import ExpensesActions from "../expenses/expenses-actions";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";

const Shipments = () => {
  const [isActiveTab, setIsActiveTab] = useState("shipments");
  const {isMobileSize} = useIsMobile()
  const list = [
    {
      label: "shipments",
      value: "shipments",
    },
    {
      label: "suppliers",
      value: "suppliers",
    },
  ];

  const handleChangeTab = (tabs) => {
    setIsActiveTab(tabs);
  };


  useEffect(() => {
    ShipmentsActions.load()

    const addExpenses = ExpensesActions.addExpenses.completed.listen(() => {
      ShipmentsActions.load()
    })

    return () => {
      addExpenses()
    }
  }, []);

  return (
    <>
      {!isMobileSize && <DynamicTabList
        list={list}
        onChangeTab={handleChangeTab}
        defaultTab={isActiveTab}
      />}
      {isActiveTab === "shipments" && <ShipmentsComponent listTabs={list} onChangeTab={handleChangeTab} isActiveTab={isActiveTab} />}
      {isActiveTab === "suppliers" && <SupplierComponent listTabs={list} onChangeTab={handleChangeTab} isActiveTab={isActiveTab}/>}
    </>
  );
};

export default connectToStores(Shipments, { _: ShipmentsStore });
