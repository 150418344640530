import React, { Component } from 'react';
import NumberBlock from "components/number-block";
import Actions from '../products-actions';

import { 
    Field, 
    Control, 
} from 'react-bulma-components/lib/components/form';

import User from 'user/user-store'
import NumberInput from "../../../components/number-input/number-input";
import UserStore from "user/user-store";


class CostField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            "netcost": "",
            "price": "",
        };

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onBlur(evt) {
      const value = evt.target.value;
            // name = evt.target.name;
            // _name = "finish_"+name;

      const {price, net_price} = this.props;

      // this.setState({
      //     [_name]: value
      //   });

      if(this.searchTimeout) {
        setTimeout(() => {
          Actions.loadByPid([this.props.pid])
        }, 5000);
      } else {
        if (value !== price && value !== net_price) {
          Actions.loadByPid([this.props.pid])
        }
      }
    }


    onChange(evt) {
        evt.persist()
        clearTimeout(this.searchTimeout);
        const value = evt.target.value,
              name = evt.target.name,
              {wid, pid, mid} = this.props;

        this.setState({
          [name]: value
        });

      this.searchTimeout = setTimeout(() => {
          Actions.setPrice(name, wid, value, pid, mid);
      }, 5000);
    }

    getPriceField() {
        const {price, sale_price} = this.props;
        
        let response = "";

        if (price || sale_price ) {
            let format_price = sale_price || price;

            // if (sale_price) {
            //     format_price = (
            //         <div className="sale-price">
            //             {sale_price} <span>{price}</span>
            //         </div>
            //     )
            // }
            
            response = (
                <NumberBlock style={{minWidth: 130}} top="product-cost" number={format_price} bottom={User.getCurrency()} simple={true} />
            );

        } else if(UserStore.getPermissionEnable('editproducts')) {
            response =  (
               <Field>
                <Control className="table-input-center">
                  <NumberInput
                    size="large"
                    name="price"
                    type="number"
                    min="0"
                    placeholder="0"
                    onChange={this.onChange}
                    onKeyUp={this.onChange}
                    onBlur={this.onBlur}
                    value={this.state.price}
                  />
                </Control>

              </Field>
              );
        } else {
          let format_price = sale_price || price;
          response = (
            <NumberBlock style={{minWidth: 130}} top="product-cost" number={format_price} bottom={User.getCurrency()} simple={true} />
          );
        }

        return response;
    }

    getNetCostField() {
        const {net_price, cur_id, subchild} = this.props;

        let response = (
            <NumberBlock style={{minWidth: 130}} top="netcost" number={net_price} bottom={User.getCurrency(cur_id)} simple={true} subchild={UserStore.getPermissionEnable('rawcost') && subchild}/>
        );

        if (!net_price) {
          response =  (
               <Field style={{position: 'relative'}}>
                <Control className="table-input-center">
                  <NumberInput
                    size="large"
                    name="netcost"
                    type="number"
                    min="0"
                    placeholder="0"
                    onChange={this.onChange}
                    onKeyUp={this.onChange}
                    onBlur={this.onBlur}
                    value={this.state.netcost}
                  />
                </Control>
                 {subchild && UserStore.getPermissionEnable('rawcost') && <div style={{position: 'absolute', left: '13px'}}>
                   {subchild}
                 </div>}
                { /* <div>{T('or')}</div>
                <Link to="/">{T('calculate-netcost')}</Link> */ }
              </Field>
          );
        } 

        return response;
    }

    render() {
        const {net_price} = this.props;

        return typeof(net_price) !== "undefined" ? this.getNetCostField() : this.getPriceField();
    }
}

export default CostField;
