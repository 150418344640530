import React from "react";
import FilterPanel from "./filter-panel";
import ReportList from "./report-list";
import ActionsButton from "./actions-button";
import ShipmentsStore from "../shipments-store";
import ShipmentsTotal from "./shipments-total";
import {Box} from "react-bulma-components";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DynamicTabList
  from "../../../components/dynamic-tab-list/dynamic-tab-list";

const ShipmentsComponent = ({listTabs,onChangeTab,isActiveTab}) => {
  const {isMobileSize} = useIsMobile()
  return (
    <div className="shipment-list" style={isMobileSize ? {marginTop: "20px"} : {}}>
      {!isMobileSize && <FilterPanel/>}
      <ShipmentsTotal shipments={ShipmentsStore.getShipments()} />
      <ActionsButton />
      {isMobileSize && <DynamicTabList
        list={listTabs}
        onChangeTab={onChangeTab}
        defaultTab={isActiveTab}
      />}
      <Box className="shipments-report border-not-top">
        {isMobileSize && <FilterPanel/>}
        <ReportList />
      </Box>
    </div>
  );
};

export default ShipmentsComponent;
