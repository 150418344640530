import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";

import ProductsStore from "./products-store";
import AddProductsModal from "./modal/add-product/add-products-modal";
// import AppStore from "app-store";

// import OpenModal from 'components/modal/modal';
import { redirectTo } from "tools/redirect-to";

class EditProductsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.product = null;
  }

  componentDidMount() {
    // let pid, step;
    // if (this.props.location.state) {
    //     let {pid, step} = this.props.location.state;
    // }  else{
  }

  render() {
    let { pid, step, copy, onClose } = this.props;

    if (this.props.location && this.props.location.state) {
      pid = this.props.location.state["pid"];
    }

    // console.log("pid, ", pid, this.props.location.state )
    // if (pid) {
    //     this.step = step;
    //     this.product = ProductsStore.getProduct(pid);
    // }

    // const {pid, step} = this.props;

    if (pid) {
      this.step = step || 0;
      this.product = ProductsStore.getProduct(pid);
    }

    if (!this.product) {
      return <div>{redirectTo("/products")}</div>;
    }

    let product = this.product;

    // let backModal = e => {
    //   window.location.href = "/products";
    // }

    let variable = null

    if(product["parent_id"] === product["id"]) {
      variable = ProductsStore.getProductByIdInList(product["id"]);
    }

    return (
      <AddProductsModal
        className='modal-start'
        modal="product-modal"
        stock={product["stock"]}
        pid={copy ? null : product["id"]}
        edit={true}
        copy={copy || false}
        category_id={product["category_id"]}
        desc={product["description"]}
        name={product["real_name"]}
        af={product["af"]}
        type_product={product["type_product"]}
        barcode={product["asin"]}
        brand_id={product["brand_id"]}
        vendorCode={product["sku"]}
        units={product["units"]}
        attr={product["attr_dict"] || null}
        variable={variable}
        is_variable={product["parent_id"] === product["id"]}
        step={this.step || 0}
        onClose={onClose}
        disabledAddSelectProduct={this.props.disabledAddSelectProduct}
      />
    );
  }
}

export default connectToStores(EditProductsModal, {
  products: ProductsStore,
});
