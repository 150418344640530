import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";
import formatDate from 'tools/format-date';
import T from "components/i18n";
import Money from "components/money";
import DOM from "react-dom-factories";
import { Link } from "react-router-dom";
import NumberBlock from "components/number-block";
import User from 'user/user-store';
import Columns from 'react-bulma-components/lib/components/columns';
import List from 'react-bulma-components/lib/components/list';
import ExpensesStore from '../expenses/expenses-store';
import ExpensesActions from '../expenses/expenses-actions';
import SalesStore from '../sales/sales-store';
import SalesActions from '../sales/sales-actions';
import ShipmentsStore from '../shipments/shipments-store';
import AddAccountModal from '../transactions/add-account-modal';

import ProfitAndLoss from './widget-profit-and-loss';
import ExpensesCategory from './widget-expenses-category';

import Tile from 'react-bulma-components/lib/components/tile';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Level from 'react-bulma-components/lib/components/level';
import Section from 'react-bulma-components/lib/components/section';
import numberFormat from "tools/number-format";
import {
    Select
} from 'react-bulma-components/lib/components/form';
import OpenModalButton from "components/modal/open-modal-button";
import OpenInsideModal from "components/modal/open-inside-modal";
import { redirectTo } from 'tools/redirect-to'
import Loader from 'react-loader-spinner'
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import ListMonth from "./list-month";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          mobileSalePeriod: 'today',
          isLoadingSales: false,
          listenLoadExpenses: false,
          isLoading: true,
        };
        this.report = {};
        this.shipments_report = {};

        this._makePeriodData = this._makePeriodData.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }

    componentDidMount() {
      this.shipments_report = ShipmentsStore.getReport();
      ExpensesActions.load();
      SalesActions.loadReports();
      SalesActions.load();

      this.listenLoadSales = SalesActions.load.completed.listen((res) => {
          this.setState({
            "isLoadingSales": true
          });

          this.initFinish();
      });

      this.listenLoadExpenses = ExpensesActions.load.completed.listen((res) => {
          this.setState({
            "isLoadingExpenses": true
          });

          this.initFinish();
      });

      User._set("isRedirect", false);
        // this.setState({reports: SalesStore.get("sales_reports")});
    }

    componentWillUnmount() {
      if (typeof(this.listenLoadExpenses) == "function") {
        this.listenLoadExpenses();
      }
      if (typeof(this.listenLoadSales) == "function") {
        this.listenLoadSales();
      }
    }

  initFinish() {
      if (this.state.isLoadingSales && this.state.isLoadingExpenses) {
         this.setState({
            isLoading: false
        });
        console.log("ALL LOAD COMPLETE DASHBOARD!!!")
      }
    }

    onChangeSelect(evt) {
      const value = evt.target.value,
            name = evt.target.name;

      this.setState({
        [name]: value
      })
    }

    getAccountList() {
        let response = [];
        const accounts = ExpensesStore.getSortedAccounts();

        for (let [key, account] of accounts) {
          response.push(
            <List.Item key={key + account['id']} className="display-flex-row-gap" style={{alignItems: "center", padding: "0", paddingLeft: 5}} >
              <b style={{flex: 1,textAlign: 'left', fontSize: 10}} className='silver'>{T(account['name'])}</b>
              <div style={{fontSize: '14px', fontWeight: 'bold'}}>
                <Money
                  amount={account['balance']}
                  aid={account['id']}
                  wrapper={DOM.span}
                />
              </div>
              <div style={{padding: "5px 5px 5px 10px",borderLeft: "1px solid #ededed"}}>
                {!ExpensesStore.isSystemAccount(account['id']) && User.getPermissionEnable("edit-balance") ?
                  <OpenInsideModal renderAs="b" size="small" icon="edit"
                                   text="">
                    <AddAccountModal {...account} />
                  </OpenInsideModal> : (
                    <div style={{width:'18.25px', height: '14.55px'}} />
                  )}
              </div>
              {/*<Columns className="is-mobile">*/}
              {/*  <Columns.Column size="four-fifths" align="left">*/}
              {/*    <b>{T(account['name'])}:</b> <Money*/}
              {/*    amount={account['balance']} aid={account['id']}*/}
              {/*    wrapper={DOM.span}/>*/}
              {/*  </Columns.Column>*/}
              {/*  <Columns.Column align="right">*/}
              {/*    {!ExpensesStore.isSystemAccount(account['id']) && User.getPermissionEnable("edit-balance") ?*/}
              {/*      <OpenInsideModal renderAs="b" size="small" icon="edit"*/}
              {/*                       text="">*/}
              {/*        <AddAccountModal {...account} />*/}
              {/*      </OpenInsideModal>*/}
              {/*      : null}*/}
              {/*  </Columns.Column>*/}
              {/*</Columns>*/}
            </List.Item>
          );
        }

      return response;
    }

  _getMonth(wday) {
    let month = wday.getMonth() + 1;

    if (month < 10) {
        month = "0"+String(month);
      }

      return String(month);
    }

    _getDay(wday) {
      let month = wday.getDate();

      if (month < 10) {
        month = "0"+String(month);
      }

      return String(month);
    }

    _getFormatDate(wday) {
       return String(wday.getFullYear())+this._getMonth(wday)+this._getDay(wday);
    }

    _sumAmountReport(data, period, end_period) {
      const keys = Object.keys(data);
      let response = {},
          _now = new Date(),
          _end_period = this._getFormatDate(_now);

      for(let i in keys) {
          let key = keys[i];

          if (key in ['global', 'products']) continue;
          //if date in report more or equal than our period, make sum
          key = parseInt(key)
          if (typeof(end_period) !== "undefined") {
            _end_period = end_period;
          }

          if (key >= parseInt(period) && key <= parseInt(_end_period)) {
              for(let i in data[key]) {
                if (typeof(response[i]) == "undefined") {
                  response[i] = 0;
                }
                response[i] += data[key][i];
            }
          }
      }

      return response;

    }

    __makePeriodDate(period) {
        let start = new Date(),
            end = new Date();
        if (period === "today") {

        } else if (period === "yesterday") {
          start.setDate(start.getDate() - 1);
          end.setDate(end.getDate() - 1);
        } else if (["week", "7days"].indexOf(period) > -1) {
          start.setDate(start.getDate() - 7);
        } else if (period === "30days") {
          start.setDate(start.getDate() - 30);
        } else if (["month", "current", "month-current"].indexOf(period) > -1) {
          start.setDate(1);
        }
        else if (['last', 'month-last'].indexOf(period) > -1) {
          start.setDate(1);
          start.setMonth(start.getMonth()-1);
          end.setDate(0);
        } else if (period === "this-year") {
          start.setMonth(0);
          start.setDate(1);
        }
        else if (period === "last-year") {
          start.setMonth(0);
          start.setDate(0);
          start.setYear(1900 + (start.getYear()-1))

          end.setMonth(12)
          end.setDate(0);
          end.setYear(1900 + (end.getYear()-1))
        }

        return [start, end];
    }

    _makePeriodData(period) {
        let response = {'expenses': {}};

        const data = this.props.sales.sales_reports || {},
              expenses_report = this.props.expenses.report || {};

        if (typeof(data['products']) == "undefined") {
          return response;
        }

        const [_start, _end] = this.__makePeriodDate(period),
              start = this._getFormatDate(_start),
              end =  this._getFormatDate(_end);

        response =  this._sumAmountReport(data, start, end)
        response['expenses'] = this._sumAmountReport(expenses_report, start, end);

        return response;
    }

    getShipmentsReport(period) {
      const shipments_report = ShipmentsStore.getReport();
        let wday = new Date(),
           fullmonth = String(wday.getFullYear())+this._getMonth(wday);

        if (period === "last") {
            wday.setMonth(wday.getMonth() - 1);
            fullmonth = String(wday.getFullYear())+this._getMonth(wday);
        }

        return shipments_report[fullmonth] || {};

    }

    getSalesInfo(report) {
      let className = "dashboard-block-sales";

      className += this.props.isMobileSize ? " is-mobile" : "";
      return (
          <Columns className={className}>
               <Columns.Column>
                  <NumberBlock top="saled" number={report['gross_income'] || 0} bottom={User.getCurrency()} money={true} />
               </Columns.Column>
               {!User.getPermissionEnable("incomeinformation") ? null :
               <Columns.Column>
                  <NumberBlock top="sales-profit" number={report['sales_profit'] || 0} bottom={User.getCurrency()} money={true} />
               </Columns.Column>
               }
            </Columns>
      )

    }
           // <div>{T('saled')} <strong> {this.report[period]['items_count'] || 0} </strong> {T('units')} </div>
            // <div>{T('sales-amounts')}: <strong> <Money amount={report['gross_income'] || 0} wrapper={DOM.span}/> </strong></div>
            // <div>{T('sales-profit')}: <strong> <Money amount={report['sales_profit'] || 0} wrapper={DOM.span}/> </strong> </div>

    getMobileSaleStatistics() {
      const data = this._makePeriodData(this.state.mobileSalePeriod),
            text = (<span>{T('product-info-sales')} <b>{data['items_count'] || 0} {T('items-e')}</b></span>);

      return (
          <Tile renderAs="article" kind="parent">
            <Box>
              <Level>
                  <Level.Side align="left">
                    <Level.Item>
                        <Heading size={5}>{T('sales')}</Heading>
                    </Level.Item>
                  </Level.Side>
                  <Level.Side align="right">
                      <Level.Item>
                        <Select onChange={this.onChangeSelect} name="mobileSalePeriod" value={this.state.mobileSalePeriod}>
                          <option value="today">{T('today')}</option>
                          <option value="yesterday">{T('yesterday')}</option>
                          <option value="week">{T('week')}</option>
                          <option value="30days">{T('30days')}</option>
                        </Select>
                      </Level.Item>
                  </Level.Side>
              </Level>
              <OpenModalButton size="large" link={{
                                          pathname: '/sales',
                                          state: {period: this.state.mobileSalePeriod}
                                        }} text={text} />

              {this.getSalesInfo(data)}
            </Box>
          </Tile>
        );
    }




    getTileStatisticSale(period) {
      this.report[period] = this._makePeriodData(period);

      return (
          <Tile kind="child" key={period}>
            <Box>
              <Heading size={6}>{T(period)}</Heading>
              <div><Link to={{
              pathname: '/sales',
              state: {
                period: period
              }
            }}>{T('product-info-sales')} <b>{numberFormat(this.report[period]['items_count'] || 0)} {T('items-e')}</b></Link></div>
              <div>{T('dashboard-expenses-on')} <b> <Money amount={this.report[period]['expenses']['amount'] || 0} wrapper={DOM.span}/></b></div>
              {this.getSalesInfo(this.report[period])}
            </Box>
          </Tile>
        );
    }

    getShipmentsList(count) {
      const shipments_report = ShipmentsStore.getList("rows");

      let response = [];

      let c = 0;
      for (let i in shipments_report) {
         const shipment = shipments_report[i];
         let amount = shipment['received_amount'];
         let payment = 0;
         let payments = []
         if ('expenses' in shipment) {
            for (let i in shipment['expenses']) {
              let exp = shipment['expenses'][i]
              payment = exp['amount'];

              if (ExpensesStore.getAccount(exp['account_id'])['currency_id'] !== User.get("currency_id")) {
                  payment = ExpensesStore.getAmountByRate(exp['account_id'], amount);
              }

              payments.push(
                <span key={"a-"+i} className=" text-success"><b>{T('paid')}: <Money amount={payment || 0} wrapper={DOM.span}/></b><br /></span>
              )
            }
         }

         response.push(
            <List.Item key={shipment['id']}>№{shipment['id']}. {T('shipment-from')} {formatDate(shipment['created_at'])} <br />
             <span>{shipment['quantity']} {T('items-e')} {T('product_on')} <Money currency={shipment['currency']} amount={amount || 0} wrapper={DOM.span}/></span> <br />
              {payments}

            </List.Item>
          )

         c += 1;

         if (c >= count) {
            break;
         }
      }

      if(!shipments_report.length) {
        response.push(
          <List.Item>
            <Heading renderAs='p' size={5}>{T('not-have-shipments')}</Heading>
          </List.Item>
        )
      }

      return (
          <div>
            <List>
                {response}
            </List>

            <div align="center" className="margin-top-10">
                  <OpenModalButton link="/shipments/add-shipment-base" text={T('create-product-delivery')} icon="plus-circle" size="small"/>
            </div>
          </div>
        )
    }

    calcCleanProfit(sales) {
        let result = 0;
        if (sales) {

          result = (sales['sales_profit'] || 0) - (sales['expenses']['amount'] || 0)
        }
        return result;
    }

    calcPurchaseProfit(sales, shipments) {
        let result = 0;
        if (sales) {
          result = (sales['gross_income'] || 0) - (sales['expenses']['amount'] || 0)
        }

        return result;
    }

    calcAvailableMoneyPurchase(shipments, period) {
        let result = 0;
        const sales = this._makePeriodData(period)
        if (sales) {
          result = (sales['gross_income'] || 0)-(sales['sales_profit'] || 0)  - (shipments['amount'] || 0)
        }

        return result;
    }

    getMonthStatistic(period) {
      const shipments = this.getShipmentsReport(period),
            sales = this._makePeriodData(period),
            [start, end] = this.__makePeriodDate(period),
            margin = Math.round(((sales['sales_profit'] / sales['gross_income']) || 0) * 100 * 100) / 100;
      const expenses = ExpensesStore.getExpensesByDate(start, end)
            let receipt_amount = 0;
            let expenses_amount = 0;
            let expenses_shipment_amount = 0;
            let expenses_is_profit_category_amount = 0;
            let expenses_not_calc = 0
            let expenses_delivery_shipment = 0


            for (let i in expenses) {
                const rec = expenses[i];
                let amount = rec['amount'];
                if(!ExpensesStore.getAccount(rec['account_id'])['currency_id']) {
                  continue
                }

                if (ExpensesStore.getAccount(rec['account_id'])['currency_id'] !== User.get("currency_id")) {
                      amount = ExpensesStore.getAmountByRate(rec['account_id'], amount);
                }

                if(rec.shipment_id || rec.category_id === 11) {
                  expenses_shipment_amount += amount
                } else if (rec.category_id === 2) {
                  expenses_delivery_shipment += amount;
                  expenses_not_calc += amount;
                } else if (rec.is_receipt) {
                  receipt_amount += amount;
                } else {
                  const category = ExpensesStore.getCategoryById(rec.category_id)
                  if(parseInt(category?.is_profit) !== 2) {
                    if(parseInt(category?.is_profit) === 1) {
                      expenses_is_profit_category_amount += amount
                    } else {
                      expenses_amount += amount;
                    }
                  } else {
                    expenses_not_calc += amount
                  }
                }
            }

            const createList = [
              {
                title: 'sales',
                items: [
                  {
                    title: 'sales',
                    amount: sales['gross_income'],
                    info: 'sales-profit-tip',
                  },
                  {
                    title: 'sale-quantity',
                    amount: `${numberFormat(sales['items_count'] || 0)} ${T('items-e')}`,
                    type: 'text',
                  },
                  {
                    title: 'total-sales',
                    amount: `${numberFormat(sales['orders_count'] || 0)}`,
                    type: "text",
                  },
                  {
                    title: 'dashboard-sales-profit',
                    amount: (sales['sales_profit'] || 0) - expenses_is_profit_category_amount,
                    info: "net-profit-tip",
                    disabled: !User.getPermissionEnable("incomeinformation"),
                    className:'has-text-success'
                  },
                  {
                    title: 'dashboard-sales-discount',
                    amount: sales['discount_amount'],
                  },
                  {
                    title: 'average-margin',
                    amount: `${margin}%`,
                    type: "text",
                    info: "average-margin-tip",
                    disabled: !User.getPermissionEnable("incomeinformation")
                  },
                ]
              },
              {
                title: 'other_replenish',
                disabled: User.getModuleEnable('payments'),
                items: [
                  {
                    title: 'total',
                    amount: receipt_amount,
                    className: 'has-text-success'
                  }
                ]
              },
              {
                title: 'Expenses',
                items: [
                  {
                    title: 'expenses-profit-sales',
                    info: 'these-expenses-are-deducted-tip',
                    amount: expenses_is_profit_category_amount,
                    className: 'has-text-danger'
                  },
                  {
                    title: 'expenses-without-shipment',
                    amount: expenses_amount,
                    className: 'has-text-danger'
                  },
                  {
                    title: 'total',
                    amount: expenses_amount + expenses_is_profit_category_amount,
                    className: 'has-text-danger'
                  }
                ]
              },
              {
                title: 'expenses-not-deducted',
                info: 'expenses-cost-of-sales-tip',
                items: [
                  {
                    title: 'expenses-shipment',
                    amount: expenses_shipment_amount,
                    className: 'has-text-danger'
                  },
                  {
                    title: 'expenses-without-shipment',
                    amount: expenses_not_calc,
                    className: 'has-text-danger'
                  },
                  {
                    title: 'total',
                    amount: (expenses_shipment_amount || 0) + (expenses_not_calc || 0),
                    className: 'has-text-danger'
                  },
                ]
              },
              {
                title: 'Shipments',
                disabled: !User.isNeedShipment() && !User.getPermissionEnable('show-all-expenses'),
                items: [
                  {
                    title: 'dashboard-total-shipments',
                    amount: shipments['shipments_count'] || 0,
                    type: 'text',
                  },
                  {
                    title: 'dashboard-total-shipments-quantity',
                    amount: `${shipments['quantity'] || 0} ${T('items-e')}`,
                    type: 'text',
                  },
                  {
                    title: 'dashboard-total-shipments-amount',
                    amount: shipments['amount'],
                    className: 'orange-color'
                  },
                ]
              }
            ]


      return (
              <Tile kind="child" key={period} >
                <Box>
                  <Heading size={5}>{T("month-"+period)}</Heading>
                          <div className='display-flex-row' style={{alignItems: 'flex-start', gap: 20, flexWrap: 'wrap'}}>
                            <div style={{flex: 1, minWidth: 280}}>
                              {createList.map((list) => (
                                <ListMonth items={list.items} title={list.title} key={`${list.title}-list`} info={list.info}/>
                              ))}
                            </div>
                            <div style={{flex: 1, minWidth: 180}}>
                              <NumberBlock style={{fontSize: 14, fontWeight: 700}} top="total-funds-received" number={(sales['gross_income'] || 0)+receipt_amount} bottom={User.getCurrency()} money={true} />
                              <NumberBlock style={{fontSize: 14, fontWeight: 700}} top="sales-profit" info='income-from-sales-other-expenses-without-shipments' number={(sales['sales_profit'] || 0) + receipt_amount - expenses_amount - expenses_is_profit_category_amount} bottom={User.getCurrency()} money={true} />
                              <NumberBlock style={{fontSize: 14, fontWeight: 700}} top='operating-profit' info="total-funds-received-minus-expenditures" number={(sales['gross_income'] || 0) + receipt_amount - expenses_amount - expenses_is_profit_category_amount - expenses_shipment_amount - expenses_delivery_shipment} bottom={User.getCurrency()} money={true} />
                            </div>
                          </div>
                </Box>
            </Tile>

        )
    }

    renderView () {
        const accounts = this.getAccountList();

        return (
              <div className="dashboard">
                <Tile kind="ancestor">
                  <Tile vertical>
                    {this.props.isMobileSize ?

                    <Tile kind="parent" className="dashboard-statistics">
                        {this.getMobileSaleStatistics()}
                    </Tile>

                    :

                    <Tile kind="parent" className="dashboard-statistics">
                        {['today','yesterday','week','30days'].map(period => this.getTileStatisticSale(period))}
                    </Tile>
                    }
                    {User.getModuleEnable("transactions") && (
                      <Tile
                        kind="parent"
                        notification={!this.props.isMobileSize}
                        className=""
                      >
                        <Tile renderAs="article" size={6} kind="child">
                          <ProfitAndLoss
                            makeDate={this.__makePeriodDate}
                            data={this._makePeriodData}
                          />
                        </Tile>
                        <Tile renderAs="article" kind="child">
                          <ExpensesCategory makeDate={this.__makePeriodDate}/>
                        </Tile>
                      </Tile>
                    )}
                    <Tile  kind="parent">
                      <Tile kind="child">
                        {User.getPermissionEnable("showbalance") ?
                            <Box>
                              <Heading size={5}>{T('your-money')}</Heading>
                              <Heading subtitle className="your-money"><Money signClassname={true} amount={User.getBalance()} /></Heading>
                                  <List >
                                      {accounts}
                                  </List>
                              {User.getPermissionEnable("edit-balance") && <div align="center" className="margin-top-10">
                                <OpenModalButton
                                  link="/transactions/add-account"
                                  text={T('add-new-account-btn')}
                                  icon="plus-circle" size="small"/>
                              </div>}
                              </Box>
                        : null}
                      </Tile>
                      {/*{!User.getModuleEnable('payments') ? null :*/}
                      {/*<Tile kind="child">*/}
                      {/*  {this.getPlaniningExpenses()}*/}
                      {/*</Tile>*/}
                      {/*}*/}
                      {User.isNeedShipment() ?
                      <Tile kind="child">
                          <Box align="left">
                            <Heading size={5}>{T('dashboard-delivery-good')}</Heading>
                            {this.getShipmentsList(5)}
                          </Box>
                      </Tile>
                      : ""}
                    </Tile>
                    {/*<Tile kind="parent" className="month-stat" notification={this.props.isMobileSize ? false : true}>*/}
                    {/*  <Tile kind="child">*/}
                    {/*    <WidgetMonthStatistics defaultMonth={new Date().getMonth()}/>*/}
                    {/*  </Tile>*/}
                    {/*  <Tile kind="child">*/}
                    {/*    <WidgetMonthStatistics defaultMonth={new Date().getMonth() - 1}/>*/}
                    {/*  </Tile>*/}
                    {/*</Tile>*/}
                    {!User.getPermissionEnable("incomeinformation") ? null :
                    <Tile kind="parent" className="month-stat" notification={!this.props.isMobileSize}>
                        {['current','last'].map(period => this.getMonthStatistic(period))}
                    </Tile>
                    }
                  </Tile>

                </Tile>
              </div>
			)
    }

    renderDefault() {
        return (
          User.getModuleEnable('dashboard') ? this.renderView() : redirectTo("/access-denied")
        )
    }

     render () {
      return this.state.isLoading ?
         <Section >
          <div className="is-vcentered my-vcenter">
            <Loader type="Rings" color="#00BFFF" height={80} width={80} />
          </div>
        </Section>

        : this.renderDefault()
    }
}

export default connectToStores(withIsMobileSize(Dashboard), {
    sales: SalesStore,
    expenses: ExpensesStore,
    shipments: ShipmentsStore,
  });